import {HttpInterceptorFn} from '@angular/common/http'
import {inject} from "@angular/core"

import {XsrfCookieService} from "../shared/xsrf-cookie.service"

export const xsrcCookieInterceptor: HttpInterceptorFn = (request, next) => {
    const xsrf = inject(XsrfCookieService)

    if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(request.method)) {
        return next(xsrf.addXsrfHeader(request))
    } else {
        return next(request)
    }
}
