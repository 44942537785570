import {Component, Input, OnDestroy} from '@angular/core'
import {RouterOutlet} from "@angular/router"

import {VideDataService} from "../api/vide-data.service"
import {parseIntOrThrow} from "../shared/vide-helper"

@Component({
    selector: 'app-project-container',
    standalone: true,
    imports: [RouterOutlet],
    templateUrl: './project-container.component.html',
    styleUrl: './project-container.component.scss'
})
export class ProjectContainerComponent implements OnDestroy {

    /**
     * Bound to route query parameter, https://angular.io/guide/router#getting-route-information
     * This only works in the last matched route component from app.routes.ts.
     * Other components do not receive this input, but can use ActivatedRoute.queryParams to get the value.
     *
     * @param id
     */
    @Input()
    set pid(id: string) {
        this.dataService.pid = parseIntOrThrow(id)
    }

    constructor(
        private readonly dataService: VideDataService,
    ) {
    }

    ngOnDestroy(): void {
        this.dataService.pid = null
    }

}
