import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core'
import {FormBuilder, ReactiveFormsModule} from '@angular/forms'
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from '@angular/material/dialog'
import {toSignal} from "@angular/core/rxjs-interop"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatSelectModule} from "@angular/material/select"

import {map, startWith} from 'rxjs'
import {PlotlyViaWindowModule} from "angular-plotly.js"

import {getMapPlotTextFont, PLOT_CONFIG} from 'src/constants'
import {MAP_BACKGROUNDS, MapBackground} from '../mapbox/mapbox-helper'
import {objectWithPosition, PlotlyData, VideFigure, VideObjectWithPosition,} from '../vide-types'
import {Group, VideObject} from "../api/api-types"

class PlotOptions {
    background: MapBackground = 'satellite-streets'
    // textColor = 'white'
}

export type LocateObjectDialogResult = never
export type LocateObjectDialogData = MatDialogConfig<{
    object: VideObjectWithPosition,
    group: Group | null,
    // project: ProjectV2,
    objects: VideObject[],
}>['data']

@Component({
    selector: 'app-locate-object-dialog',
    standalone: true,
    templateUrl: './locate-object-dialog.component.html',
    styleUrls: ['./locate-object-dialog.component.scss'],
    imports: [
        MatFormFieldModule,
        MatSelectModule,
        PlotlyViaWindowModule,
        ReactiveFormsModule,
    ]
})
export class LocateObjectDialogComponent implements OnInit, OnDestroy {
    mapBackgrounds = MAP_BACKGROUNDS
    // textColors = ['white', 'black'] as const

    readonly optionsForm = this.formBuilder.nonNullable.group({
        background: ['satellite-streets' as MapBackground],
        // textColor: ['white' as typeof this.textColors[number]],
    })

    figure = toSignal(this.optionsForm.valueChanges.pipe(
            startWith(true),
            // tap(x => { console.warn(`First` + x) }),
            map(() => {
                if (!this.data) {
                    return
                }
                return locateObjectGetFigure(
                    this.data.object,
                    this.data.group,
                    this.data.objects,
                    this.optionsForm.getRawValue(),
                )
            }),
            // tap(x => { console.warn(`Second`, x) }),
        )
    )
    plotlyStyle = {
        width: '95vw',
        height: '75vh',
    }

    constructor(
        public dialogRef: MatDialogRef<LocateObjectDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: LocateObjectDialogData,
        private formBuilder: FormBuilder,
    ) {
    }

    ngOnInit() {
        const state = {
            modal: true,
            desc: 'Dummy state for modal dialog',
        }
        window.history.pushState(state, '')
    }

    ngOnDestroy() {
        if (window.history.state.modal) {
            window.history.back()
        }
    }

    @HostListener('window:popstate', ['$event'])
    private close() {
        this.dialogRef.close()
    }
}

 function locateObjectGetFigure(
    object: VideObjectWithPosition,
    group: Group | null,
    objects: VideObject[],
    options: PlotOptions,
): VideFigure {
    const otherObjects =
        (group?.object_ids
            ? objects.filter(o => group.object_ids.includes(o.id))
            : objects)
            .filter(o => o.id !== object.id)
    const otherData = getObjectData(otherObjects, options)
    const objectData = getMainData(object, options)

    return {
        data: [otherData, objectData],
        layout: {
            mapbox: {
                style: options.background,
                center: {lat: object.position.lat, lon: object.position.lon},
                zoom: 17,
            },
            margin: {r: 0, l: 0, t: 0, b: 0},
        },
        config: {
            mapboxAccessToken: PLOT_CONFIG.mapboxAccessToken,
            showEditInChartStudio: true,
            scrollZoom: true,
        },
    }
}

function getObjectData(os: VideObject[], options: PlotOptions): PlotlyData {
    const lat = Array<number>()
    const lon = Array<number>()
    const text = Array<string>()
    os.filter(objectWithPosition).forEach(o => {
        lat.push(o.position.lat)
        lon.push(o.position.lon)
        text.push(o.name)
    })
    const textfont = getMapPlotTextFont(options.background)
    return {
        type: 'scattermapbox',
        lat,
        lon,
        text,
        marker: {
            size: 8,
            color: textfont.color,
            // color: options.textColor,
        },
        mode: 'text+markers',
        textposition: 'bottom right',
        hoverinfo: 'text',
        textfont,
        // textfont: {color: options.textColor},
        showlegend: false,
    }
}

function getMainData(object: VideObjectWithPosition, options: PlotOptions): PlotlyData {
    const textfont = getMapPlotTextFont(options.background)
    textfont.size = 20
    return {
        type: 'scattermapbox',
        lat: [object.position.lat],
        lon: [object.position.lon],
        text: [object.name],
        marker: {
            // symbol: 'triangle-stroked',
            size: 20,
            color: 'red',
        },
        mode: 'text+markers',
        textposition: 'bottom right',
        hoverinfo: 'text',
        // textfont: {color: options.textColor, size: 20},
        textfont,
        showlegend: false,
    }
}

