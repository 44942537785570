@if (data) {
    <h1 mat-dialog-title>
        {{ data.header }}
    </h1>

    <mat-dialog-content>
        {{ data.text }}
        @if (data.textList; as list) {
            <ul>
                @for (item of list; track item) {
                    <li> {{ item }}</li>
                }
            </ul>
        }
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-raised-button [mat-dialog-close]="true" type="button">
            {{ data.positive_text ?? 'confirm' }}
        </button>
        <button mat-raised-button [mat-dialog-close]="false" type="button">
            {{ data.negative_text ?? 'cancel' }}
        </button>
    </mat-dialog-actions>
}
