import {Component, Inject} from '@angular/core'
import {
    MAT_DIALOG_DATA,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle
} from "@angular/material/dialog"
import {MatButton} from "@angular/material/button"

import {Change} from "../../../CHANGELOG"
import {ChangelogComponent} from "../../changelog/changelog.component"

@Component({
    selector: 'app-changelog-dialog',
    standalone: true,
    imports: [ChangelogComponent, MatDialogContent, MatDialogTitle, MatDialogActions, MatButton, MatDialogClose],
    templateUrl: './changelog-dialog.component.html',
    styleUrl: './changelog-dialog.component.scss'
})
export class ChangelogDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) readonly data: {
            changes: readonly Change[]
        },
    ) {
    }

}
