import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from '@angular/material/dialog'
import {KeyValuePipe} from "@angular/common"
import {toSignal} from "@angular/core/rxjs-interop"

import {BehaviorSubject, combineLatestWith, map, of} from 'rxjs'
import {PlotlyViaWindowModule} from "angular-plotly.js"

import {VideDataService} from 'src/app/api/vide-data.service'
import {
    DASHES,
    DEFAULT_TIMELINE_MARKERS,
    getSplitter,
    getTimelineTracesV2
} from 'src/app/plot/timeline-plot/timeline-functions'
import {dateToString, isNotNull, objectLength, typedKeys} from 'src/app/shared/vide-helper'
import {EMPTY_FIGURE, VideFigure,} from 'src/app/vide-types'
import {MeasureType, ProjectWithLimit, VideObject} from "../../api/api-types"
import {COLOR_SEQUENCES} from "../../shared/colors"

type ObjectDetailDialogData = MatDialogConfig<{
    project: ProjectWithLimit,
    object: VideObject,
    measureType: MeasureType | undefined | null,
}>['data']

@Component({
    imports: [
        KeyValuePipe,
        PlotlyViaWindowModule,
    ],
    selector: 'app-object-detail-dialog',
    standalone: true,
    styleUrls: ['./object-detail-dialog.component.scss'],
    templateUrl: './object-detail-dialog.component.html'
})
export class ObjectDetailDialogComponent implements OnInit, OnDestroy {

    private readonly range: [string, string]
    readonly description: { [key: string]: number | string | undefined | null } = {}
    readonly plotlyStyle = {
        width: '95vw',
        'flex-grow': '2',
        height: '100%',
    }
    fullRange$ = new BehaviorSubject<boolean | undefined>(undefined)
    plot$ = this.data?.measureType ?
        this.dataService.getExtendedMeasurements(
            this.data.project,
            this.data.object,
            this.data.measureType
        ).pipe(
            combineLatestWith(this.fullRange$),
            map(([x, f]) => {
                // console.warn(`Junk`)
                if (f === undefined) {
                    // console.warn(`Skunk`)
                    const lastDate = x.measurements.at(-1)?.measuretime
                    setTimeout(() => {
                        this.fullRange$.next((lastDate ?? '') < this.range[0])
                    })
                    const ret: VideFigure = {data: [], layout: {}, config: {}}
                    // console.warn(ret)
                    return ret
                }

                const plotMargins = 40

                const split = getSplitter({
                    yaxis: 'y',
                    legendMaxLength: 2987,
                    wrapYears: false,
                    useMeasureTypeInLabel: true,
                    yaxisOptions: {
                        transformKind: 'Resulting value',
                        zeroLevelDateTime: '',
                    }
                })(x)
                const yMin = split.valid.flat().map(x => x.value).filter(isNotNull).reduce((acc, curr) => acc < curr ? acc : curr, Infinity)
                const y = getTimelineTracesV2(
                    split,
                    {
                        y: {markers: DEFAULT_TIMELINE_MARKERS},
                        y2: {markers: DEFAULT_TIMELINE_MARKERS},
                        legendBelow: false,
                        wrapYears: false,
                        timeaxis: 'Datetime',
                    }, {y: yMin, y2: Infinity},
                    COLOR_SEQUENCES['D3'][0], DASHES[0])


                const ret: VideFigure = {
                    data: y,
                    layout: {
                        autosize: true,
                        margin: {l: plotMargins, r: plotMargins, t: plotMargins, b: plotMargins},
                        showlegend: false,
                        xaxis: {range: this.fullRange$.value ? undefined : this.range},
                        yaxis: {title: x.measure_type.measure_unit.name},
                    },
                    config: {
                        modeBarButtonsToRemove: ['select2d', 'lasso2d'],
                    },
                }
                return ret
            }),
        ) :
        of(EMPTY_FIGURE)

    readonly plot = toSignal(this.plot$)

    constructor(
        private dataService: VideDataService,
        public dialogRef: MatDialogRef<ObjectDetailDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ObjectDetailDialogData,
    ) {
        {
            let date = new Date()
            const last = dateToString(date)
            date.setFullYear(date.getFullYear() - 1)
            const first = dateToString(date)
            this.range = [first, last]
        }
        this.fillDescription()
    }

    toggleRange() {
        const current = this.fullRange$.value
        if (current !== undefined) {
            this.fullRange$.next(!current)
        }
    }

    ngOnInit(): void {
        const state = {
            modal: true,
            desc: 'Dummy state for modal dialog',
        }
        window.history.pushState(state, '')
    }

    ngOnDestroy() {
        if (window.history.state.modal) {
            window.history.back()
        }
    }

    @HostListener('window:popstate', ['$event'])
    close() {
        this.dialogRef.close()
    }

    private fillDescription() {
        if (!this.data) return
        const o = this.data.object
        const values = {
            'Aquifer': o.aquifer?.name,
            'Comment': o.comment,
            'Distance to bottom (reference − bottom level)': objectLength(o),
            'Ground level': o.ground_level,
            'Reference level': o.reference_level,
            'Type': o.object_type.name,
            // 'Bottom level': o.bottom_level,
            // 'Inclination': o.inclination,
            // 'Direction': o.direction,
            // 'Not reference after': o.not_reference_from,
            // 'X': o.x,
            // 'Y': o.y,
            // 'Lat': o.lat,
            // 'Lon': o.long,
            // 'Status': o.object_status.name,
        }
        typedKeys(values).forEach(key => {
            const element = values[key]
            if (element) {
                this.description[key] = element
            }
        })
    }
}
