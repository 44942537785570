@if (plotWaiting()) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}

@if (project(); as project) {
    <!-- A fine hack to use a false-value-->
        <!-- <ng-container *ngIf="{value: hasSomeCorrelations$ | async} as hasCorrelationsWrapper"> -->
    <mat-tab-group headerPosition="above" [selectedIndex]="selectedIndex"
                   (selectedIndexChange)="selectedIndexChange($event)">
        <mat-tab label="Object info">
            <ng-template matTabContent>
                <app-object-info></app-object-info>
            </ng-template>
        </mat-tab>
        <mat-tab label="Timeline plot">
            <ng-template matTabContent>
                <app-timeline-plot></app-timeline-plot>
            </ng-template>
        </mat-tab>
        <mat-tab label="Map plot">
            <ng-template matTabContent>
                <app-map-plot></app-map-plot>
            </ng-template>
        </mat-tab>
        @if (project.correlations_exists) {
            <mat-tab label="Correlation plot">
                <ng-template matTabContent>
                    <app-correlation-plot></app-correlation-plot>
                </ng-template>
            </mat-tab>
            <mat-tab label="Correlation map">
                <ng-template matTabContent>
                    <app-correlation-map></app-correlation-map>
                </ng-template>
            </mat-tab>
        }
    </mat-tab-group>

    <!-- Actions -->
    @if (creatingImage) {
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }

    @if (hasUncheckedData()) {
        <button
            mat-raised-button
            (click)="acceptMeasurements()"
            matTooltip="Turn status for all measurements with status Unchecked into Accepted."
            [disabled]="!ABILITY.WRITE.availableFor(project)"
        > Accept
        </button>
    }
    <button mat-raised-button [matMenuTriggerFor]="exportMenu">Export…</button>
    <mat-menu #exportMenu="matMenu">
        @if (figureDataComponent()) {
            <button mat-menu-item (click)="savePlot()"
                    matTooltip="Save the current plot, using the size from preferences"
            > Export current plot
            </button>
        }
        @if (saveDataComponent()) {
            <button mat-menu-item (click)="savePlotData()"> Export current plot data</button>
        }
    </mat-menu>
    @if (plotOptions(); as plotOptions) {
        <button mat-raised-button [matMenuTriggerFor]="optionsMenu">Plot options…</button>
        <button mat-raised-button (click)="resetPlotOptions()">
            Reset all plot options
        </button>

        <mat-menu #optionsMenu="matMenu">
            <button mat-menu-item (click)="saveCurrentOptions()">Save current options</button>
            @for (row of OptionActions; track row) {
                <button mat-menu-item [disabled]="plotOptions.length<1"
                        [matMenuTriggerFor]="actions" [matMenuTriggerData]="{action:row}"> {{ row | headerCase }}
                </button>
            }
        </mat-menu>

        <mat-menu #actions="matMenu">
            <ng-template matMenuContent let-action="action">
                @for (o of plotOptions; track o) {
                    <button mat-menu-item (click)="optionAction(action,o)"> {{ o.name }}
                    </button>
                }
            </ng-template>
        </mat-menu>
    }
}

<!-- Plotly element used in savePlot function, it has to have an element to plot in -->
<plotly-plot [style]="{display: 'none'}" divId="hiddenPlot"></plotly-plot>
