/**
 * Color sequences, good for categorical data
 */

const Colorbrewer =
{
    BrBG: [
        "rgb(84,48,5)",
        "rgb(140,81,10)",
        "rgb(191,129,45)",
        "rgb(223,194,125)",
        "rgb(246,232,195)",
        "rgb(245,245,245)",
        "rgb(199,234,229)",
        "rgb(128,205,193)",
        "rgb(53,151,143)",
        "rgb(1,102,94)",
        "rgb(0,60,48)",
    ] as const,
    PRGn: [
        "rgb(64,0,75)",
        "rgb(118,42,131)",
        "rgb(153,112,171)",
        "rgb(194,165,207)",
        "rgb(231,212,232)",
        "rgb(247,247,247)",
        "rgb(217,240,211)",
        "rgb(166,219,160)",
        "rgb(90,174,97)",
        "rgb(27,120,55)",
        "rgb(0,68,27)",
    ] as const,
    PiYG: [
        "rgb(142,1,82)",
        "rgb(197,27,125)",
        "rgb(222,119,174)",
        "rgb(241,182,218)",
        "rgb(253,224,239)",
        "rgb(247,247,247)",
        "rgb(230,245,208)",
        "rgb(184,225,134)",
        "rgb(127,188,65)",
        "rgb(77,146,33)",
        "rgb(39,100,25)",
    ] as const,
    PuOr: [
        "rgb(127,59,8)",
        "rgb(179,88,6)",
        "rgb(224,130,20)",
        "rgb(253,184,99)",
        "rgb(254,224,182)",
        "rgb(247,247,247)",
        "rgb(216,218,235)",
        "rgb(178,171,210)",
        "rgb(128,115,172)",
        "rgb(84,39,136)",
        "rgb(45,0,75)",
    ] as const,
    RdBu: [
        "rgb(103,0,31)",
        "rgb(178,24,43)",
        "rgb(214,96,77)",
        "rgb(244,165,130)",
        "rgb(253,219,199)",
        "rgb(247,247,247)",
        "rgb(209,229,240)",
        "rgb(146,197,222)",
        "rgb(67,147,195)",
        "rgb(33,102,172)",
        "rgb(5,48,97)",
    ] as const,
    RdGy: [
        "rgb(103,0,31)",
        "rgb(178,24,43)",
        "rgb(214,96,77)",
        "rgb(244,165,130)",
        "rgb(253,219,199)",
        "rgb(255,255,255)",
        "rgb(224,224,224)",
        "rgb(186,186,186)",
        "rgb(135,135,135)",
        "rgb(77,77,77)",
        "rgb(26,26,26)",
    ] as const,
    RdYlBu: [
        "rgb(165,0,38)",
        "rgb(215,48,39)",
        "rgb(244,109,67)",
        "rgb(253,174,97)",
        "rgb(254,224,144)",
        "rgb(255,255,191)",
        "rgb(224,243,248)",
        "rgb(171,217,233)",
        "rgb(116,173,209)",
        "rgb(69,117,180)",
        "rgb(49,54,149)",
    ] as const,
    RdYlGn: [
        "rgb(165,0,38)",
        "rgb(215,48,39)",
        "rgb(244,109,67)",
        "rgb(253,174,97)",
        "rgb(254,224,139)",
        "rgb(255,255,191)",
        "rgb(217,239,139)",
        "rgb(166,217,106)",
        "rgb(102,189,99)",
        "rgb(26,152,80)",
        "rgb(0,104,55)",
    ] as const,
    Spectral: [
        "rgb(158,1,66)",
        "rgb(213,62,79)",
        "rgb(244,109,67)",
        "rgb(253,174,97)",
        "rgb(254,224,139)",
        "rgb(255,255,191)",
        "rgb(230,245,152)",
        "rgb(171,221,164)",
        "rgb(102,194,165)",
        "rgb(50,136,189)",
        "rgb(94,79,162)",
    ] as const,
    Set1: [
        "rgb(228,26,28)",
        "rgb(55,126,184)",
        "rgb(77,175,74)",
        "rgb(152,78,163)",
        "rgb(255,127,0)",
        "rgb(255,255,51)",
        "rgb(166,86,40)",
        "rgb(247,129,191)",
        "rgb(153,153,153)",
    ] as const,
    Pastel1: [
        "rgb(251,180,174)",
        "rgb(179,205,227)",
        "rgb(204,235,197)",
        "rgb(222,203,228)",
        "rgb(254,217,166)",
        "rgb(255,255,204)",
        "rgb(229,216,189)",
        "rgb(253,218,236)",
        "rgb(242,242,242)",
    ] as const,
    Dark2: [
        "rgb(27,158,119)",
        "rgb(217,95,2)",
        "rgb(117,112,179)",
        "rgb(231,41,138)",
        "rgb(102,166,30)",
        "rgb(230,171,2)",
        "rgb(166,118,29)",
        "rgb(102,102,102)",
    ] as const,
    Set2: [
        "rgb(102,194,165)",
        "rgb(252,141,98)",
        "rgb(141,160,203)",
        "rgb(231,138,195)",
        "rgb(166,216,84)",
        "rgb(255,217,47)",
        "rgb(229,196,148)",
        "rgb(179,179,179)",
    ] as const,
    Pastel2: [
        "rgb(179,226,205)",
        "rgb(253,205,172)",
        "rgb(203,213,232)",
        "rgb(244,202,228)",
        "rgb(230,245,201)",
        "rgb(255,242,174)",
        "rgb(241,226,204)",
        "rgb(204,204,204)",
    ] as const,
    Set3: [
        "rgb(141,211,199)",
        "rgb(255,255,179)",
        "rgb(190,186,218)",
        "rgb(251,128,114)",
        "rgb(128,177,211)",
        "rgb(253,180,98)",
        "rgb(179,222,105)",
        "rgb(252,205,229)",
        "rgb(217,217,217)",
        "rgb(188,128,189)",
        "rgb(204,235,197)",
        "rgb(255,237,111)",
    ] as const,
    Accent: [
        "rgb(127,201,127)",
        "rgb(190,174,212)",
        "rgb(253,192,134)",
        "rgb(255,255,153)",
        "rgb(56,108,176)",
        "rgb(240,2,127)",
        "rgb(191,91,23)",
        "rgb(102,102,102)",
    ] as const,
    Paired: [
        "rgb(166,206,227)",
        "rgb(31,120,180)",
        "rgb(178,223,138)",
        "rgb(51,160,44)",
        "rgb(251,154,153)",
        "rgb(227,26,28)",
        "rgb(253,191,111)",
        "rgb(255,127,0)",
        "rgb(202,178,214)",
        "rgb(106,61,154)",
        "rgb(255,255,153)",
        "rgb(177,89,40)",
    ] as const,
    Blues: [
        "rgb(247,251,255)",
        "rgb(222,235,247)",
        "rgb(198,219,239)",
        "rgb(158,202,225)",
        "rgb(107,174,214)",
        "rgb(66,146,198)",
        "rgb(33,113,181)",
        "rgb(8,81,156)",
        "rgb(8,48,107)",
    ] as const,
    BuGn: [
        "rgb(247,252,253)",
        "rgb(229,245,249)",
        "rgb(204,236,230)",
        "rgb(153,216,201)",
        "rgb(102,194,164)",
        "rgb(65,174,118)",
        "rgb(35,139,69)",
        "rgb(0,109,44)",
        "rgb(0,68,27)",
    ] as const,
    BuPu: [
        "rgb(247,252,253)",
        "rgb(224,236,244)",
        "rgb(191,211,230)",
        "rgb(158,188,218)",
        "rgb(140,150,198)",
        "rgb(140,107,177)",
        "rgb(136,65,157)",
        "rgb(129,15,124)",
        "rgb(77,0,75)",
    ] as const,
    GnBu: [
        "rgb(247,252,240)",
        "rgb(224,243,219)",
        "rgb(204,235,197)",
        "rgb(168,221,181)",
        "rgb(123,204,196)",
        "rgb(78,179,211)",
        "rgb(43,140,190)",
        "rgb(8,104,172)",
        "rgb(8,64,129)",
    ] as const,
    Greens: [
        "rgb(247,252,245)",
        "rgb(229,245,224)",
        "rgb(199,233,192)",
        "rgb(161,217,155)",
        "rgb(116,196,118)",
        "rgb(65,171,93)",
        "rgb(35,139,69)",
        "rgb(0,109,44)",
        "rgb(0,68,27)",
    ] as const,
    Greys: [
        "rgb(255,255,255)",
        "rgb(240,240,240)",
        "rgb(217,217,217)",
        "rgb(189,189,189)",
        "rgb(150,150,150)",
        "rgb(115,115,115)",
        "rgb(82,82,82)",
        "rgb(37,37,37)",
        "rgb(0,0,0)",
    ] as const,
    OrRd: [
        "rgb(255,247,236)",
        "rgb(254,232,200)",
        "rgb(253,212,158)",
        "rgb(253,187,132)",
        "rgb(252,141,89)",
        "rgb(239,101,72)",
        "rgb(215,48,31)",
        "rgb(179,0,0)",
        "rgb(127,0,0)",
    ] as const,
    Oranges: [
        "rgb(255,245,235)",
        "rgb(254,230,206)",
        "rgb(253,208,162)",
        "rgb(253,174,107)",
        "rgb(253,141,60)",
        "rgb(241,105,19)",
        "rgb(217,72,1)",
        "rgb(166,54,3)",
        "rgb(127,39,4)",
    ] as const,
    PuBu: [
        "rgb(255,247,251)",
        "rgb(236,231,242)",
        "rgb(208,209,230)",
        "rgb(166,189,219)",
        "rgb(116,169,207)",
        "rgb(54,144,192)",
        "rgb(5,112,176)",
        "rgb(4,90,141)",
        "rgb(2,56,88)",
    ] as const,
    PuBuGn: [
        "rgb(255,247,251)",
        "rgb(236,226,240)",
        "rgb(208,209,230)",
        "rgb(166,189,219)",
        "rgb(103,169,207)",
        "rgb(54,144,192)",
        "rgb(2,129,138)",
        "rgb(1,108,89)",
        "rgb(1,70,54)",
    ] as const,
    PuRd: [
        "rgb(247,244,249)",
        "rgb(231,225,239)",
        "rgb(212,185,218)",
        "rgb(201,148,199)",
        "rgb(223,101,176)",
        "rgb(231,41,138)",
        "rgb(206,18,86)",
        "rgb(152,0,67)",
        "rgb(103,0,31)",
    ] as const,
    Purples: [
        "rgb(252,251,253)",
        "rgb(239,237,245)",
        "rgb(218,218,235)",
        "rgb(188,189,220)",
        "rgb(158,154,200)",
        "rgb(128,125,186)",
        "rgb(106,81,163)",
        "rgb(84,39,143)",
        "rgb(63,0,125)",
    ] as const,
    RdPu: [
        "rgb(255,247,243)",
        "rgb(253,224,221)",
        "rgb(252,197,192)",
        "rgb(250,159,181)",
        "rgb(247,104,161)",
        "rgb(221,52,151)",
        "rgb(174,1,126)",
        "rgb(122,1,119)",
        "rgb(73,0,106)",
    ] as const,
    Reds: [
        "rgb(255,245,240)",
        "rgb(254,224,210)",
        "rgb(252,187,161)",
        "rgb(252,146,114)",
        "rgb(251,106,74)",
        "rgb(239,59,44)",
        "rgb(203,24,29)",
        "rgb(165,15,21)",
        "rgb(103,0,13)",
    ] as const,
    YlGn: [
        "rgb(255,255,229)",
        "rgb(247,252,185)",
        "rgb(217,240,163)",
        "rgb(173,221,142)",
        "rgb(120,198,121)",
        "rgb(65,171,93)",
        "rgb(35,132,67)",
        "rgb(0,104,55)",
        "rgb(0,69,41)",
    ] as const,
    YlGnBu: [
        "rgb(255,255,217)",
        "rgb(237,248,177)",
        "rgb(199,233,180)",
        "rgb(127,205,187)",
        "rgb(65,182,196)",
        "rgb(29,145,192)",
        "rgb(34,94,168)",
        "rgb(37,52,148)",
        "rgb(8,29,88)",
    ] as const,
    YlOrBr: [
        "rgb(255,255,229)",
        "rgb(255,247,188)",
        "rgb(254,227,145)",
        "rgb(254,196,79)",
        "rgb(254,153,41)",
        "rgb(236,112,20)",
        "rgb(204,76,2)",
        "rgb(153,52,4)",
        "rgb(102,37,6)",
    ] as const,
    YlOrRd: [
        "rgb(255,255,204)",
        "rgb(255,237,160)",
        "rgb(254,217,118)",
        "rgb(254,178,76)",
        "rgb(253,141,60)",
        "rgb(252,78,42)",
        "rgb(227,26,28)",
        "rgb(189,0,38)",
        "rgb(128,0,38)",
    ] as const,
}

const Carto = {
    Burg: [
        "rgb(255, 198, 196)",
        "rgb(244, 163, 168)",
        "rgb(227, 129, 145)",
        "rgb(204, 96, 125)",
        "rgb(173, 70, 108)",
        "rgb(139, 48, 88)",
        "rgb(103, 32, 68)",
    ] as const,
    Burgyl: [
        "rgb(251, 230, 197)",
        "rgb(245, 186, 152)",
        "rgb(238, 138, 130)",
        "rgb(220, 113, 118)",
        "rgb(200, 88, 108)",
        "rgb(156, 63, 93)",
        "rgb(112, 40, 74)",
    ] as const,
    Redor: [
        "rgb(246, 210, 169)",
        "rgb(245, 183, 142)",
        "rgb(241, 156, 124)",
        "rgb(234, 129, 113)",
        "rgb(221, 104, 108)",
        "rgb(202, 82, 104)",
        "rgb(177, 63, 100)",
    ] as const,
    Oryel: [
        "rgb(236, 218, 154)",
        "rgb(239, 196, 126)",
        "rgb(243, 173, 106)",
        "rgb(247, 148, 93)",
        "rgb(249, 123, 87)",
        "rgb(246, 99, 86)",
        "rgb(238, 77, 90)",
    ] as const,
    Peach: [
        "rgb(253, 224, 197)",
        "rgb(250, 203, 166)",
        "rgb(248, 181, 139)",
        "rgb(245, 158, 114)",
        "rgb(242, 133, 93)",
        "rgb(239, 106, 76)",
        "rgb(235, 74, 64)",
    ] as const,
    Pinkyl: [
        "rgb(254, 246, 181)",
        "rgb(255, 221, 154)",
        "rgb(255, 194, 133)",
        "rgb(255, 166, 121)",
        "rgb(250, 138, 118)",
        "rgb(241, 109, 122)",
        "rgb(225, 83, 131)",
    ] as const,
    Mint: [
        "rgb(228, 241, 225)",
        "rgb(180, 217, 204)",
        "rgb(137, 192, 182)",
        "rgb(99, 166, 160)",
        "rgb(68, 140, 138)",
        "rgb(40, 114, 116)",
        "rgb(13, 88, 95)",
    ] as const,
    Blugrn: [
        "rgb(196, 230, 195)",
        "rgb(150, 210, 164)",
        "rgb(109, 188, 144)",
        "rgb(77, 162, 132)",
        "rgb(54, 135, 122)",
        "rgb(38, 107, 110)",
        "rgb(29, 79, 96)",
    ] as const,
    Darkmint: [
        "rgb(210, 251, 212)",
        "rgb(165, 219, 194)",
        "rgb(123, 188, 176)",
        "rgb(85, 156, 158)",
        "rgb(58, 124, 137)",
        "rgb(35, 93, 114)",
        "rgb(18, 63, 90)",
    ] as const,
    Emrld: [
        "rgb(211, 242, 163)",
        "rgb(151, 225, 150)",
        "rgb(108, 192, 139)",
        "rgb(76, 155, 130)",
        "rgb(33, 122, 121)",
        "rgb(16, 89, 101)",
        "rgb(7, 64, 80)",
    ] as const,
    Aggrnyl: [
        "rgb(36, 86, 104)",
        "rgb(15, 114, 121)",
        "rgb(13, 143, 129)",
        "rgb(57, 171, 126)",
        "rgb(110, 197, 116)",
        "rgb(169, 220, 103)",
        "rgb(237, 239, 93)",
    ] as const,
    Bluyl: [
        "rgb(247, 254, 174)",
        "rgb(183, 230, 165)",
        "rgb(124, 203, 162)",
        "rgb(70, 174, 160)",
        "rgb(8, 144, 153)",
        "rgb(0, 113, 139)",
        "rgb(4, 82, 117)",
    ] as const,
    Teal: [
        "rgb(209, 238, 234)",
        "rgb(168, 219, 217)",
        "rgb(133, 196, 201)",
        "rgb(104, 171, 184)",
        "rgb(79, 144, 166)",
        "rgb(59, 115, 143)",
        "rgb(42, 86, 116)",
    ] as const,
    Tealgrn: [
        "rgb(176, 242, 188)",
        "rgb(137, 232, 172)",
        "rgb(103, 219, 165)",
        "rgb(76, 200, 163)",
        "rgb(56, 178, 163)",
        "rgb(44, 152, 160)",
        "rgb(37, 125, 152)",
    ] as const,
    Purp: [
        "rgb(243, 224, 247)",
        "rgb(228, 199, 241)",
        "rgb(209, 175, 232)",
        "rgb(185, 152, 221)",
        "rgb(159, 130, 206)",
        "rgb(130, 109, 186)",
        "rgb(99, 88, 159)",
    ] as const,
    Purpor: [
        "rgb(249, 221, 218)",
        "rgb(242, 185, 196)",
        "rgb(229, 151, 185)",
        "rgb(206, 120, 179)",
        "rgb(173, 95, 173)",
        "rgb(131, 75, 160)",
        "rgb(87, 59, 136)",
    ] as const,
    Sunset: [
        "rgb(243, 231, 155)",
        "rgb(250, 196, 132)",
        "rgb(248, 160, 126)",
        "rgb(235, 127, 134)",
        "rgb(206, 102, 147)",
        "rgb(160, 89, 160)",
        "rgb(92, 83, 165)",
    ] as const,
    Magenta: [
        "rgb(243, 203, 211)",
        "rgb(234, 169, 189)",
        "rgb(221, 136, 172)",
        "rgb(202, 105, 157)",
        "rgb(177, 77, 142)",
        "rgb(145, 53, 125)",
        "rgb(108, 33, 103)",
    ] as const,
    Sunsetdark: [
        "rgb(252, 222, 156)",
        "rgb(250, 164, 118)",
        "rgb(240, 116, 110)",
        "rgb(227, 79, 111)",
        "rgb(220, 57, 119)",
        "rgb(185, 37, 122)",
        "rgb(124, 29, 111)",
    ] as const,
    Agsunset: [
        "rgb(75, 41, 145)",
        "rgb(135, 44, 162)",
        "rgb(192, 54, 157)",
        "rgb(234, 79, 136)",
        "rgb(250, 120, 118)",
        "rgb(246, 169, 122)",
        "rgb(237, 217, 163)",
    ] as const,
    Brwnyl: [
        "rgb(237, 229, 207)",
        "rgb(224, 194, 162)",
        "rgb(211, 156, 131)",
        "rgb(193, 118, 111)",
        "rgb(166, 84, 97)",
        "rgb(129, 55, 83)",
        "rgb(84, 31, 63)",
    ] as const,
    // # Diverging schemes
    Armyrose: [
        "rgb(121, 130, 52)",
        "rgb(163, 173, 98)",
        "rgb(208, 211, 162)",
        "rgb(253, 251, 228)",
        "rgb(240, 198, 195)",
        "rgb(223, 145, 163)",
        "rgb(212, 103, 128)",
    ] as const,
    Fall: [
        "rgb(61, 89, 65)",
        "rgb(119, 136, 104)",
        "rgb(181, 185, 145)",
        "rgb(246, 237, 189)",
        "rgb(237, 187, 138)",
        "rgb(222, 138, 90)",
        "rgb(202, 86, 44)",
    ] as const,
    Geyser: [
        "rgb(0, 128, 128)",
        "rgb(112, 164, 148)",
        "rgb(180, 200, 168)",
        "rgb(246, 237, 189)",
        "rgb(237, 187, 138)",
        "rgb(222, 138, 90)",
        "rgb(202, 86, 44)",
    ] as const,
    Temps: [
        "rgb(0, 147, 146)",
        "rgb(57, 177, 133)",
        "rgb(156, 203, 134)",
        "rgb(233, 226, 156)",
        "rgb(238, 180, 121)",
        "rgb(232, 132, 113)",
        "rgb(207, 89, 126)",
    ] as const,
    Tealrose: [
        "rgb(0, 147, 146)",
        "rgb(114, 170, 161)",
        "rgb(177, 199, 179)",
        "rgb(241, 234, 200)",
        "rgb(229, 185, 173)",
        "rgb(217, 137, 148)",
        "rgb(208, 88, 126)",
    ] as const,
    Tropic: [
        "rgb(0, 155, 158)",
        "rgb(66, 183, 185)",
        "rgb(167, 211, 212)",
        "rgb(241, 241, 241)",
        "rgb(228, 193, 217)",
        "rgb(214, 145, 193)",
        "rgb(199, 93, 171)",
    ] as const,
    Earth: [
        "rgb(161, 105, 40)",
        "rgb(189, 146, 90)",
        "rgb(214, 189, 141)",
        "rgb(237, 234, 194)",
        "rgb(181, 200, 184)",
        "rgb(121, 167, 172)",
        "rgb(40, 135, 161)",
    ] as const,
    // # Qualitative palettes
    Antique: [
        "rgb(133, 92, 117)",
        "rgb(217, 175, 107)",
        "rgb(175, 100, 88)",
        "rgb(115, 111, 76)",
        "rgb(82, 106, 131)",
        "rgb(98, 83, 119)",
        "rgb(104, 133, 92)",
        "rgb(156, 156, 94)",
        "rgb(160, 97, 119)",
        "rgb(140, 120, 93)",
        "rgb(124, 124, 124)",
    ] as const,
    Bold: [
        "rgb(127, 60, 141)",
        "rgb(17, 165, 121)",
        "rgb(57, 105, 172)",
        "rgb(242, 183, 1)",
        "rgb(231, 63, 116)",
        "rgb(128, 186, 90)",
        "rgb(230, 131, 16)",
        "rgb(0, 134, 149)",
        "rgb(207, 28, 144)",
        "rgb(249, 123, 114)",
        "rgb(165, 170, 153)",
    ] as const,
    Pastel: [
        "rgb(102, 197, 204)",
        "rgb(246, 207, 113)",
        "rgb(248, 156, 116)",
        "rgb(220, 176, 242)",
        "rgb(135, 197, 95)",
        "rgb(158, 185, 243)",
        "rgb(254, 136, 177)",
        "rgb(201, 219, 116)",
        "rgb(139, 224, 164)",
        "rgb(180, 151, 231)",
        "rgb(179, 179, 179)",
    ] as const,
    Prism: [
        "rgb(95, 70, 144)",
        "rgb(29, 105, 150)",
        "rgb(56, 166, 165)",
        "rgb(15, 133, 84)",
        "rgb(115, 175, 72)",
        "rgb(237, 173, 8)",
        "rgb(225, 124, 5)",
        "rgb(204, 80, 62)",
        "rgb(148, 52, 110)",
        "rgb(111, 64, 112)",
        "rgb(102, 102, 102)",
    ] as const,
    Safe: [
        "rgb(136, 204, 238)",
        "rgb(204, 102, 119)",
        "rgb(221, 204, 119)",
        "rgb(17, 119, 51)",
        "rgb(51, 34, 136)",
        "rgb(170, 68, 153)",
        "rgb(68, 170, 153)",
        "rgb(153, 153, 51)",
        "rgb(136, 34, 85)",
        "rgb(102, 17, 0)",
        "rgb(136, 136, 136)",
    ] as const,
    Vivid: [
        "rgb(229, 134, 6)",
        "rgb(93, 105, 177)",
        "rgb(82, 188, 163)",
        "rgb(153, 201, 69)",
        "rgb(204, 97, 176)",
        "rgb(36, 121, 108)",
        "rgb(218, 165, 27)",
        "rgb(47, 138, 196)",
        "rgb(118, 78, 159)",
        "rgb(237, 100, 90)",
        "rgb(165, 170, 153)",
    ] as const,
}


const Qualitative = {
    Plotly: [
        "#636EFA",
        "#EF553B",
        "#00CC96",
        "#AB63FA",
        "#FFA15A",
        "#19D3F3",
        "#FF6692",
        "#B6E880",
        "#FF97FF",
        "#FECB52",
    ] as const,
    D3: [
        "#1F77B4",
        "#FF7F0E",
        "#2CA02C",
        "#D62728",
        "#9467BD",
        "#8C564B",
        "#E377C2",
        "#7F7F7F",
        "#BCBD22",
        "#17BECF",
    ] as const,
    G10: [
        "#3366CC",
        "#DC3912",
        "#FF9900",
        "#109618",
        "#990099",
        "#0099C6",
        "#DD4477",
        "#66AA00",
        "#B82E2E",
        "#316395",
    ] as const,
    T10: [
        "#4C78A8",
        "#F58518",
        "#E45756",
        "#72B7B2",
        "#54A24B",
        "#EECA3B",
        "#B279A2",
        "#FF9DA6",
        "#9D755D",
        "#BAB0AC",
    ] as const,
    Alphabet: [
        "#AA0DFE",
        "#3283FE",
        "#85660D",
        "#782AB6",
        "#565656",
        "#1C8356",
        "#16FF32",
        "#F7E1A0",
        "#E2E2E2",
        "#1CBE4F",
        "#C4451C",
        "#DEA0FD",
        "#FE00FA",
        "#325A9B",
        "#FEAF16",
        "#F8A19F",
        "#90AD1C",
        "#F6222E",
        "#1CFFCE",
        "#2ED9FF",
        "#B10DA1",
        "#C075A6",
        "#FC1CBF",
        "#B00068",
        "#FBE426",
        "#FA0087",
    ] as const,
    Dark24: [
        "#2E91E5",
        "#E15F99",
        "#1CA71C",
        "#FB0D0D",
        "#DA16FF",
        "#222A2A",
        "#B68100",
        "#750D86",
        "#EB663B",
        "#511CFB",
        "#00A08B",
        "#FB00D1",
        "#FC0080",
        "#B2828D",
        "#6C7C32",
        "#778AAE",
        "#862A16",
        "#A777F1",
        "#620042",
        "#1616A7",
        "#DA60CA",
        "#6C4516",
        "#0D2A63",
        "#AF0038",
    ] as const,
    Light24: [
        "#FD3216",
        "#00FE35",
        "#6A76FC",
        "#FED4C4",
        "#FE00CE",
        "#0DF9FF",
        "#F6F926",
        "#FF9616",
        "#479B55",
        "#EEA6FB",
        "#DC587D",
        "#D626FF",
        "#6E899C",
        "#00B5F7",
        "#B68E00",
        "#C9FBE5",
        "#FF0092",
        "#22FFA7",
        "#E3EE9E",
        "#86CE00",
        "#BC7196",
        "#7E7DCD",
        "#FC6955",
        "#E48F72",
    ] as const,
}

// export const COLOR_SEQUENCES: { [key: string]: Readonly<Array<string>> } = {
export const COLOR_SEQUENCES = {
    ...Qualitative,
    ...Colorbrewer,
    ...Carto,
} 
