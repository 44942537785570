<mat-card>
    <mat-card-header>
        <mat-card-title>
            <h1>Notifications for {{ object()?.name }}</h1>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>

        <h2>Existing triggers</h2>

        <table mat-table [dataSource]="triggers()">
            <ng-container matColumnDef="score">
                <th mat-header-cell *matHeaderCellDef> Score</th>
                <td mat-cell *matCellDef="let trigger"> {{ trigger|json }}</td>
            </ng-container>
            <ng-container matColumnDef="unchecked">
                <th mat-header-cell *matHeaderCellDef> Test unchecked measurements</th>
                <td mat-cell *matCellDef="let trigger"> {{ trigger.unchecked }}</td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell
                    *matCellDef="let trigger"
                    [matMenuTriggerFor]="menu"
                    [matMenuTriggerData]="{trigger:trigger}"
                >
                    <mat-icon>more_vert</mat-icon>
                </td>
            </ng-container>
            <ng-container matColumnDef="measureType">
                <th mat-header-cell *matHeaderCellDef> Measure type</th>
                <td mat-cell *matCellDef="let trigger"> {{ (trigger.measureType.name) }}</td>
            </ng-container>
            <ng-container matColumnDef="recipients">
                <th mat-header-cell *matHeaderCellDef> Recipients</th>
                <td mat-cell *matCellDef="let trigger"> {{ formatRecipients(trigger) }}</td>
            </ng-container>
            <mat-text-column name="description"/>
            <mat-text-column name="comment"/>
            <mat-text-column name="active"/>
            <mat-text-column name="limit"/>
            <mat-text-column name="type"/>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
        </table>

        <mat-menu #menu="matMenu" overlapTrigger xPosition="before">
            <ng-template matMenuContent let-trigger="trigger">
                <button mat-menu-item (click)="edit(trigger)">
                    <mat-icon>edit</mat-icon>
                    <span>Edit</span>
                </button>
                <button mat-menu-item (click)="delete(trigger)">
                    <mat-icon>delete</mat-icon>
                    <span>Delete</span>
                </button>
            </ng-template>
        </mat-menu>

    </mat-card-content>

    <mat-card-actions>
        <button mat-fab (click)="addTrigger()">
            <mat-icon>add</mat-icon>
        </button>
    </mat-card-actions>
</mat-card>

<app-log-list [container]="logs"></app-log-list>
