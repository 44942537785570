import {Component, computed, Inject} from '@angular/core'
import {FormBuilder, ReactiveFormsModule, ValidatorFn, Validators} from "@angular/forms"
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog"
import {AsyncPipe} from "@angular/common"
import {toSignal} from "@angular/core/rxjs-interop"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatButtonModule} from "@angular/material/button"
import {MatInputModule} from "@angular/material/input"

import {VideDataService} from "../../api/vide-data.service"
import {DataStatus, ErrorCode} from "../../api/api-types"

export interface EditResult {
    measured_value: string,
    resulting_value: string,
    comment: string,
    data_status: DataStatus | null,
    error_code: ErrorCode | null | typeof MAGIC_STRING,
}

const NUMBER_CHANGE_REGEXP = /^[*+-]?\d*[.,]?\d*$/
export const MAGIC_STRING = 'magic delete string "£$"$"£$'

@Component({
    selector: 'app-edit',
    standalone: true,
    templateUrl: './edit.component.html',
    imports: [
        AsyncPipe,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
    ],
    styleUrls: ['./edit.component.scss']
})
export class EditComponent {
    result?: EditResult
    protected readonly MAGIC_STRING = MAGIC_STRING
    private validatorFn: ValidatorFn = (control) => {
        const value = control.getRawValue()
        if (value.measured_value !== '' && value.resulting_value !== '') {
            return {multipleValues: true}
        }
        return null
    }

    readonly form = this.formBuilder.nonNullable.group({
        measured_value: ['', Validators.pattern(NUMBER_CHANGE_REGEXP)],
        resulting_value: ['', Validators.pattern(NUMBER_CHANGE_REGEXP)],
        comment: [''],
        data_status: [null as DataStatus | null],
        error_code: [null as ErrorCode | null],
    }, {validators: this.validatorFn})

    utility = toSignal(this.dataService.utility$)
    errorCodes = computed(() => this.utility()?.error_code ?? [])
    dataStatuses = computed(() => this.utility()?.data_status ?? [])

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { number: number, names: string },
        private dialogRef: MatDialogRef<EditComponent, EditResult>,
        private formBuilder: FormBuilder,
        private dataService: VideDataService,
    ) {
    }

    apply() {
        this.form.markAllAsTouched()
        if (this.form.invalid) {
            return
        }
        return this.dialogRef.close(this.form.getRawValue())
    }

}
