import {Component} from '@angular/core'
import {MatCardModule} from "@angular/material/card"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {MatStepperModule} from "@angular/material/stepper"

import {BehaviorSubject} from "rxjs"

import {CompensateDiverComponent} from "./compensate-diver/compensate-diver.component"
import {DiverDatum, DiverService, LocalDiverData} from "./diver.service"
import {ExistingInstancesComponent} from "./existing-instances/existing-instances.component"
import {ImportBaroComponent} from "./import-baro/import-baro.component"
import {ImportDiverComponent} from "./import-diver/import-diver.component"
import {LogListComponent} from "../../log-list/log-list.component"
import {ReferenceDiverComponent} from "./reference-diver/reference-diver.component"
import {SelectDiverObjectComponent} from "./select-diver-object/select-diver-object.component"
import {VideObject} from "../../api/api-types"

export interface SlimObject extends Pick<VideObject, 'id' | 'name'> {
}

@Component({
    selector: 'app-diver',
    standalone: true,
    imports: [
        CompensateDiverComponent,
        ExistingInstancesComponent,
        ImportBaroComponent,
        ImportDiverComponent,
        LogListComponent,
        ReferenceDiverComponent,
        SelectDiverObjectComponent,

        MatCardModule,
        MatProgressBarModule,
        MatStepperModule,
    ],
    templateUrl: './diver.component.html',
    styleUrl: './diver.component.scss',
    // providers: [
    //     {
    //         provide: STEPPER_GLOBAL_OPTIONS,
    //         useValue: {showError: true},
    //     },
    // ],
})
export class DiverComponent {
    protected readonly baroCompleted = new BehaviorSubject(true)
    protected readonly httpStatus = this.service.httpStatus
    protected readonly localDiverData = new BehaviorSubject<null | LocalDiverData>(null)
    protected readonly logs = this.service.logs
    protected readonly selectedBaro = new BehaviorSubject<null | SlimObject>(null)
    protected readonly selectedObject = new BehaviorSubject<null | SlimObject>(null)

    protected readonly localCompensatedData = new BehaviorSubject<null | {
        baro: readonly DiverDatum[],
        compensated: readonly DiverDatum[],
        diver: readonly DiverDatum[],
    }>(null)
    nrUncompensated: undefined|{ database: number | undefined; file: number | undefined }
    nrUnreferenced: undefined|{ database: number | undefined; file: number | undefined; local: number | undefined }

    constructor(
        private readonly service: DiverService,
    ) {
    }
}
