import {Injectable} from '@angular/core'
import {Group, MeasureType, ObjectCategory, ObjectStatus, Project, ProjectFull, VideObject} from "../../api/api-types"
import {FormBuilder} from "@angular/forms"
import {combineLatest, debounceTime, forkJoin, map, shareReplay, startWith, switchMap, tap} from "rxjs"
import {FORM_DEBOUNCE_TIME, PLOT_COLOR} from "../../../constants"
import {AbstractMapboxService} from "../../mapbox/abstract-mapbox.service"
import {objectWithPosition, PlotlyConfig, PlotlyScattermapboxData, VideFigure} from "../../vide-types"
import {isDefined} from "../../shared/vide-helper"
import {SelectionModel} from "@angular/cdk/collections"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"

interface ProjectGroup {
    project: Project,
    group: Group,
}

@Injectable({
    providedIn: 'root'
})
export class SharedObjectsService extends AbstractMapboxService {
    protected readonly useColorbar: boolean = false
    readonly httpStatus = {total: 0, requested: 0, fetched: 0}

    readonly form = this.formBuilder.nonNullable.group({
        projects: [[] as ProjectFull[]],
        measureType: [null as null | MeasureType],
        objectCategory: [null as null | ObjectCategory],
        objectStatus: [null as null | ObjectStatus],
        group: [null as null | ProjectGroup],
        names: [''],
    })

    readonly selectionModel = new SelectionModel<number>(true, [], true,)

    readonly siblingProjects$ = combineLatest([
        this.dataService.projectNotNull$,
        this.dataService.projects$,
    ]).pipe(
        map(([current, all]) => all
            .filter(p => p.id !== current.id)
            .filter(p => {
                return p.share_type === 'Public' || (p.share_type === 'License' && p.license_id === current.license_id)
            })),
    )
    readonly groupInProjects$ = combineLatest([
        this.dataService.projectNotNull$,
        this.dataService.projects$,
    ]).pipe(
        switchMap(([current, all]) => {
            const req = all.map(p => this.dataService.getGroups(p).pipe(map(g => ({groups: g, project: p}))))
            return forkJoin(req)
        }),
        map(x => x.flatMap(y => y.groups.map(g => ({project: y.project, group: g})))),
    )
    private readonly allShareableObjects$ = this.siblingProjects$.pipe(
        switchMap(projects => {
            const requests = projects.map(p => this.dataService.getObjectsWithStatistics(p).pipe(
                map(os => os.filter(o => o.project_id === p.id).map(o => ({object: o, project: p})))
            ))
            return forkJoin(requests)
        }),
        map(x => x.flat()),
    )

    readonly filteredShareableObjects$ = combineLatest([
        this.allShareableObjects$,
        this.form.valueChanges.pipe(
            startWith(null),
            debounceTime(FORM_DEBOUNCE_TIME),
            map(() => this.form.getRawValue()),
        )
    ]).pipe(
        map(([objects, args]) => {
            console.log(args)
            const filterFn = (o: { object: VideObject, project: ProjectFull }) => {
                const pids = args.projects.map(p => p.id)
                const mtId = args.measureType?.id ?? null
                const nameFinder = args.names === '' ? () => true :
                    (x: string) => {
                        const names = new Set(args.names.split(/[\n,;]/))
                        return names.has(x)
                    }
                return pids.includes(o.object.project_id)
                    && (args.objectCategory === null || args.objectCategory.id == o.object.object_type.object_category.id)
                    && (args.objectStatus === null || args.objectStatus.id === o.object.object_status.id)
                    && (mtId === null || o.object.statistics.some(s => s.measure_type.id === mtId))
                    && (args.group === null || args.group.group.object_ids.includes(o.object.id))
                    && nameFinder(o.object.name)
            }
            return objects
                .filter(filterFn)
                .sort((a, b) => a.object.name.localeCompare(b.object.name))
        }),
        tap(x => {
            console.warn(x)
        }),
        shareReplay({bufferSize: 1, refCount: true}),
    )

    /**
     * Objects in this project that are borrowed from one of the available projects
     */
    readonly existingSharedObjectIds$ = combineLatest([
        this.dataService.objects$,
        this.siblingProjects$,
    ]).pipe(
        map(([objects, projects]) => {
            return objects.filter(o => projects.find(p => p.id === o.project_id)).map(o => o.id)
        })
    )
    readonly figure$ = combineLatest([
        this.filteredShareableObjects$,
        this.selectionModel.changed.pipe(startWith(null)),
        this.forceRelayout$,
    ]).pipe(
        map(([objects, selectionChange]) => {
            const selectedIds = this.selectionModel.selected
            return this.getFigure(objects, selectedIds)
        }),
    )
    readonly selectedObjectsWithDifferentHeightSystem$ = combineLatest([
        this.dataService.projectNotNull$,
        this.filteredShareableObjects$,
        this.selectionModel.changed.pipe(startWith(null)),
    ]).pipe(
        debounceTime(FORM_DEBOUNCE_TIME),
        map(([p, objects, selectionChange]) => objects.filter(o => {
            const source = this.selectionModel
            return source.isSelected(o.object.id)
        }).filter(o => o.project.height_system.id !== p.height_system.id))
    )

    constructor(
        private readonly formBuilder: FormBuilder,
    ) {
        super()
        this.existingSharedObjectIds$.pipe(takeUntilDestroyed()).subscribe(ids => {
            this.selectionModel.setSelection(...ids)
        })
    }

    protected override getMapConfig(toImageButtonOptions?: { filename: string }): PlotlyConfig {
        const baseConfig = super.getMapConfig(toImageButtonOptions)
        baseConfig.modeBarButtonsToRemove = ['toImage']
        return baseConfig
    }

    private getFigure(
        x: { object: Omit<VideObject, "statistics">; project: ProjectFull }[],
        selectedIds: number[],
    ) {
        const os = x
            .map(y =>
                objectWithPosition(y.object) ? {object: y.object, project: y.project} : null)
            .filter(isDefined)
        const lat = os.map(o => o.object.position.lat)
        const lon = os.map(o => o.object.position.lon)
        const customdata = os.map(o => o.object.id)
        const text = os.map(o => `${o.object.name} [${o.project.name}]`)
        const color = os.map(o =>
            selectedIds.includes(o.object.id) ? PLOT_COLOR.object_selected : PLOT_COLOR.object_unselectd)
        const data: PlotlyScattermapboxData = {
            customdata,
            marker: {color,},
            type: "scattermapbox",
            text,
            lon,
            lat,
            hoverinfo: "text",
            hoverlabel: {bgcolor: 'blue'},
            mode: 'markers',
            // mode: options.label === 'none' ? 'markers' : 'text+markers',
            textposition: 'middle right',
            textfont: {color: PLOT_COLOR.object_unselectd},
        }
        const layout = this.getLayout(x.map(x => x.object), {background: 'open-street-map'})
        const config = this.getMapConfig()
        const figure: VideFigure = {data: [data], layout, config}
        return figure
    }

    toggle(id: number) {
        this.selectionModel.toggle(id)
    }

    select(ids: number[]) {
        this.selectionModel.select(...ids)
    }
}
