import { Injectable } from '@angular/core';
import {VIDE_ROUTES2, VideDataService} from "./api/vide-data.service"
import {BroadcastService} from "./broadcast/broadcast.service"
import {combineLatestWith, Subscription} from "rxjs"
import {BroadcastChannelWrapper} from "./broadcast/broadcast-channel-wrapper"
import {POPUP_NAME} from "../constants"
import {Router} from "@angular/router"
import {BroadcastType} from "./broadcast/broadcast-type"
import {getRandomString} from "./shared/vide-helper"

@Injectable({
  providedIn: 'root'
})
export class PopupWindowService {

    private readonly broadcastSubscriptions: Subscription[] = []
    private readonly broadcasters: BroadcastChannelWrapper<any>[] = []

    constructor(
      private readonly dataService:VideDataService,
      private readonly broadcastService: BroadcastService,
      private readonly router: Router,
    ) { }

    maybeOpen(tag: string) {
        if (this.dataService.popupRef) {
            // Already connected to a popup
            this.dataService.popupRef.focus()
            return false
        } else {
            // Need to set up a new. Get rid of old stuff first.
            // Unsubscribe to old channels and clear the list
            this.unsubscribeBroadcasts()
            this.openPopupWindow()

            // Make new subscriptions
            this.setupBroadcasters(tag)

            return true
        }
    }

    private unsubscribeBroadcasts() {
        this.broadcastSubscriptions.forEach(s => {
            s.unsubscribe()
        })
        this.broadcastSubscriptions.splice(0)
        this.broadcasters.forEach(b => {
            this.broadcastService.destroy(b)
        })
        this.broadcasters.splice(0)
    }

    private openPopupWindow() {
        // throw Error("Not implemented")
        const url = this.getPopupUrl()
        if (!url) return
        const newX = window.screenX + 20000
        const newY = window.screenY + 20000
        const newH = window.innerHeight
        const newW = Math.max(window.innerWidth / 2, 600)
        this.dataService.popupRef = window.open(url, POPUP_NAME, `popup,left=${newX},top=${newY},width=${newW},height=${newH}`)
    }
    private getPopupUrl() {
        // throw Error("Not implemented")
        const url = this.router.serializeUrl(
            this.router.createUrlTree(
                this.dataService.videLink(VIDE_ROUTES2.popupSelect),
                {
                    queryParamsHandling: "merge"
                }))
        return url
    }
    private setupBroadcasters(tag: string) {
        // Listen for oid changes from the popup window.
        const broadcaster = this.broadcastService.get(BroadcastType.OBJECT_IDS, tag)
        this.broadcasters.push(broadcaster)
        this.broadcastSubscriptions.push(
            broadcaster.data$.pipe(combineLatestWith(this.dataService.project$)).subscribe(([x, p]) => {
                console.warn(x)
                this.dataService.selectionModel.select(...x.added)
                this.dataService.selectionModel.deselect(...x.removed)
            }))
        // Listen for window close broadcast
        const broadcasterClose = this.broadcastService.get(BroadcastType.ACTION, tag)
        this.broadcasters.push(broadcasterClose)
        this.broadcastSubscriptions.push(broadcasterClose.data$.subscribe(x => {
            if (x.target === POPUP_NAME && x.action === 'close') {
                this.dataService.popupRef = null
                this.navigateToTag(null)
            }
        }))
    }
    private navigateToTag(tag: string | null) {
        this.router.navigate([], {queryParams: {tag}, queryParamsHandling: 'merge'}).then(r => {
            // console.log("Navigated", r)
        })
    }

    popupWindow(){
        const tag = getRandomString(5)
        this.navigateToTag(tag)
    }



}
