import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogModule} from "@angular/material/dialog"
import {MatButtonModule} from "@angular/material/button"

import {Change} from "../../../CHANGELOG"
import {ChangelogComponent} from "../../changelog/changelog.component"

@Component({
    selector: 'app-changelog-dialog',
    standalone: true,
    imports: [
        ChangelogComponent,

        MatButtonModule,
        MatDialogModule,
    ],
    templateUrl: './changelog-dialog.component.html',
    styleUrl: './changelog-dialog.component.scss'
})
export class ChangelogDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) readonly data: {
            changes: readonly Change[]
        },
    ) {
    }

}
