import {AfterViewInit, Component, viewChild} from '@angular/core'
import {MatDialog} from "@angular/material/dialog"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MatButtonModule, MatFabButton} from "@angular/material/button"
import {MatCardModule} from "@angular/material/card"
import {MatIconModule} from "@angular/material/icon"
import {MatTableDataSource, MatTableModule} from "@angular/material/table"
import {MatTooltipModule} from "@angular/material/tooltip"
import {MatSort, MatSortModule} from "@angular/material/sort"


import {ComponentCanDeactivate} from "../../can-deactivate.guard"
import {VideDataService} from "../../api/vide-data.service"
import {LogContainer} from "../../log-list/log-container"
import {LogListComponent} from "../../log-list/log-list.component"
import {OwnedUser} from "../../api/api-types"
import {EditUserComponent, EditUserComponentData, EditUserComponentResult} from "./edit-user/edit-user.component"
import {ConfirmDialogComponent, ConfirmDialogData} from "../../dialogs/confirm-dialog/confirm-dialog.component"
import {SortedTextColumnComponent} from "../../shared/sorted-text-column/sorted-text-column.component"
import {Unpacked} from "../../vide-types"

@Component({
    selector: 'app-admin-users',
    standalone: true,
    imports: [
        LogListComponent,
        MatButtonModule,
        MatCardModule,
        MatFabButton,
        MatIconModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        SortedTextColumnComponent,
    ],
    templateUrl: './admin-users.component.html',
    styleUrl: './admin-users.component.scss'
})
export class AdminUsersComponent extends ComponentCanDeactivate implements AfterViewInit {
    protected readonly dataSource = new MatTableDataSource<Unpacked<typeof this.dataService.users$>>()

    protected readonly logs = new LogContainer()
    protected readonly columnsToDisplay = [
        'name',
        'email',
        'license',
        'ability',
        'comment',
        'edit',
        'delete',
    ]
    private readonly sort = viewChild(MatSort)

    override canDeactivate(): boolean {
        // Nothing here to keep actually
        return true
    }

    constructor(
        private readonly dataService: VideDataService,
        private readonly dialog: MatDialog,
    ) {
        super()
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
            switch (sortHeaderId) {
                case 'license':
                    return data[sortHeaderId].name
                default:
                    return (data as any)[sortHeaderId] ?? ''

            }
        }
        this.dataService.users$.pipe(takeUntilDestroyed()).subscribe(users => {
            this.dataSource.data = users
        })
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort() ?? null
    }

    addUser() {
        this.dialog.open<
            EditUserComponent,
            EditUserComponentData,
            EditUserComponentResult
        >(EditUserComponent, {disableClose: true})
    }

    edit(user: OwnedUser) {
        this.dialog.open<
            EditUserComponent,
            EditUserComponentData,
            EditUserComponentResult
        >(EditUserComponent, {data: user,})
    }

    delete(user: OwnedUser) {
        if (user) {
            this.dialog.open<
                ConfirmDialogComponent,
                ConfirmDialogData
            >(ConfirmDialogComponent, {
                data: {
                    header: `Really delete '${user.name}'?`,
                    text: `All the user's groups and options will be deleted`,
                    positive_text: 'Delete',
                    negative_text: 'Cancel',
                },
            }).afterClosed().subscribe(async result => {
                if (result === true) {
                    this.dataService.deleteUser(user).subscribe(ret => {
                        this.logs.add(ret, `Delete '${user.email}'`)
                        if (ret.success) {
                            this.dataService.reloadUserData()
                        }
                    })
                }
            })
        }
    }


}
