import {toSignal} from "@angular/core/rxjs-interop"
import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core'
import {MatIcon} from "@angular/material/icon"
import {MatTooltip} from "@angular/material/tooltip"
import {MatButton} from "@angular/material/button"
import {MatSlider, MatSliderThumb} from "@angular/material/slider"
import {MatSlideToggle} from "@angular/material/slide-toggle"
import {ReactiveFormsModule} from "@angular/forms"
import {
    MatExpansionPanel,
    MatExpansionPanelActionRow,
    MatExpansionPanelDescription,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle
} from "@angular/material/expansion"

import {NgSelectModule} from "@ng-select/ng-select"
import {PlotlyComponent, PlotlySharedModule} from "angular-plotly.js"

import {VideDataService} from 'src/app/api/vide-data.service'
import {getNormalPositionSize, MAPBOX_MARKERS} from 'src/app/mapbox/mapbox-helper'
import {HasFigureData} from "../has-figure-data"
import {CorrelationMapService, DISPLAY_VALUES} from './correlation-map.service'
import {of} from "rxjs"
import {fromWorker} from "observable-webworker"

function testWorkers() {
    const input$ = of('Hello from main thread')
    fromWorker<string, string>(
        () => new Worker(new URL('./correlation-map.worker', import.meta.url), {type: 'module'}),
        input$,
    ).subscribe(message => {
        console.log(message) // Outputs 'Hello from webworker'
    })
}

@Component({
    selector: 'app-correlation-map',
    templateUrl: './correlation-map.component.html',
    styleUrls: ['./correlation-map.component.scss'],
    providers: [
        {provide: HasFigureData, useExisting: CorrelationMapComponent},
    ],
    standalone: true,
    imports: [
        PlotlySharedModule,
        MatExpansionPanel,
        MatExpansionPanelHeader,
        MatExpansionPanelTitle,
        MatExpansionPanelDescription,
        NgSelectModule,
        ReactiveFormsModule,
        MatSlideToggle,
        MatSlider,
        MatSliderThumb,
        MatExpansionPanelActionRow,
        MatButton,
        MatTooltip,
        MatIcon,
    ],
})
export class CorrelationMapComponent implements HasFigureData, AfterViewInit, OnDestroy {
// export class CorrelationMapComponent extends HasFigureData implements AfterViewInit, OnDestroy {
    @ViewChild(PlotlyComponent) plotlyComponent?: PlotlyComponent

    readonly displayValues = DISPLAY_VALUES
    readonly mapMarkers = MAPBOX_MARKERS
    readonly MAP_BACKGROUNDS = this.dataService.MAP_BACKGROUNDS

    readonly deviationStatuses = toSignal(this.dataService.deviationStatuses$, {initialValue: null})
    readonly objectsDiscarded = toSignal(this.dataService.objectsDiscarded$)
    readonly objectsReferenced = toSignal(this.dataService.objectsReferenced$, {initialValue: null})
    readonly objectsToDisplay = toSignal(this.dataService.objectsToDisplay$)
    readonly figure = toSignal(this.dataService.figure$)
    readonly plotlyStyle = toSignal(this.dataService.plotlyStyle$)
    readonly timePeriods = toSignal(this.dataService.timePeriods$, {initialValue: null})
    readonly plotFormGroup = this.dataService.form
    readonly selectMarkedObjects = this.dataService.selectMarkedObjects.bind(this.dataService)
    readonly onPlotlyRelayout = this.dataService.plotlyRelayout.bind(this.dataService)
    readonly popupRef = toSignal(this.dataService.popupRef$)

    constructor(
        private readonly dataService: CorrelationMapService,
        private readonly videDataService: VideDataService,
    ) {
        // super()
        // testWorkers()
    }

    get canUseMarked() {
        const value = this.plotFormGroup.getRawValue()
        return value.display === 'Deviation from reference' && value.markStatuses?.length
    }

    ngOnDestroy(): void {
        // not strictly necessary, but let's not keep the unloaded element in memory.
        this.dataService.plotlyHost = undefined
    }

    ngAfterViewInit(): void {
        this.dataService.plotlyHost = this
    }

    async getFigureData() {
        const opt = this.videDataService.plotlyOptions.config.toImage
        const objects = this.objectsToDisplay()
        const figure = this.figure()
        if (objects && figure) {
            const x = getNormalPositionSize(objects, opt, true)
            const firstLayout = figure.layout
            const mapbox = {...x, style: figure.layout.mapbox?.style}
            const layout = {...firstLayout, mapbox}
            return {...figure, layout}
        } else {
            throw Error("Cannot get figure or objects")
        }
    }

    resetPlotOptions() {
        this.plotFormGroup.reset()
    }
}
