import {MatFormFieldModule} from "@angular/material/form-field"
import {MatInputModule} from "@angular/material/input"
import {Component, computed, effect} from '@angular/core'
import {MatButtonModule} from "@angular/material/button"
import {MatCardModule} from "@angular/material/card"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {MatTabsModule} from "@angular/material/tabs"
import {toSignal} from "@angular/core/rxjs-interop"
import {ReactiveFormsModule} from "@angular/forms"

import {concat, map, Subscription, tap} from "rxjs"
import {NgSelectModule} from "@ng-select/ng-select"

import {SharedObjectsService} from "./shared-objects.service"
import {LogListComponent} from "../../log-list/log-list.component"
import {VideDataService} from "../../api/vide-data.service"
import {ComponentCanDeactivate} from "../../can-deactivate.guard"
import {LogContainer} from "../../log-list/log-container"
import {equalIds} from "../../shared/vide-helper"
import {SharedObjectsListComponent} from "./shared-objects-list/shared-objects-list.component"
import {SharedObjectsMapComponent} from "./shared-objects-map/shared-objects-map.component"

@Component({
    selector: 'app-shared-objects',
    standalone: true,
    imports: [
        LogListComponent,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressBarModule,
        MatTabsModule,
        NgSelectModule,
        ReactiveFormsModule,
        SharedObjectsListComponent,
        SharedObjectsMapComponent,
    ],
    templateUrl: './shared-objects.component.html',
    styleUrl: './shared-objects.component.scss'
})
export class SharedObjectsComponent extends ComponentCanDeactivate {

    readonly httpStatus = this.service.httpStatus
    private subscription?: Subscription
    protected readonly form = this.service.form
    protected readonly logs = new LogContainer()
    protected readonly project = this.videService.project
    protected readonly siblingProjects = toSignal(this.service.siblingProjects$, {initialValue: []})
    protected readonly siblingGroups = toSignal(this.service.groupInProjects$, {initialValue: []})
    private readonly utility = toSignal(this.videService.utility$)
    protected readonly objectCategories = computed(() => this.utility()?.object_category ?? [])
    protected readonly objectStatuses = computed(() => this.utility()?.object_status ?? [])
    protected readonly measureTypes = computed(() => this.utility()?.measure_type ?? [])
    protected readonly objectWithoutCoordinatesExists = toSignal(this.service.filteredShareableObjects$.pipe(map(os => os.some(o => o.object.position === null))), {initialValue: false})
    protected readonly heightSystemProblems = toSignal(this.service.selectedObjectsWithDifferentHeightSystem$, {initialValue: []})
    protected readonly existingSharedObjectIds = toSignal(this.service.existingSharedObjectIds$, {initialValue: []})

    constructor(
        private readonly service: SharedObjectsService,
        private readonly videService: VideDataService,
    ) {
        super()
        effect(() => {
            const projects = this.siblingProjects()
            const control = this.form.controls.projects
            if (control.value.length === 0) {
                // If none selected, select the first
                const first = projects.at(0)
                if (first) this.form.patchValue({projects: [first]}, {emitEvent: true})
            } else {
                // If some selected, reset them to the new available ones (maybe some project disappeared?)
                const selectedIds = control.value.map(p => p.id)
                const current = projects.filter(p => selectedIds.includes(p.id))
                control.setValue(current, {emitEvent: false})
            }
        })
    }

    protected readonly equalIds = equalIds

    override canDeactivate() {
        const current = new Set(this.service.selectionModel.selected)
        const original = new Set(this.existingSharedObjectIds())
        return this.isEqualSets(current, original)
    }

    isEqualSets<T>(a: Set<T>, b: Set<T>) {
        if (a === b) return true
        if (a.size !== b.size) return false
        for (const value of a) if (!b.has(value)) return false
        return true
        // For new set functions
        // return a.size === b.size && a.isSubsetOf(b);
    }

    save() {
        this.subscription?.unsubscribe()

        const p = this.project()
        if (!p) {
            console.error("No project found")
            return
        }
        const existingIds = this.existingSharedObjectIds()
        const selectedIds = this.service.selectionModel.selected

        const added = selectedIds.filter(id => !existingIds.includes(id))
        const removed = existingIds.filter(id => !selectedIds.includes(id))
        console.warn("added ", added)
        console.warn("removed ", removed)

        this.httpStatus.total = added.length + removed.length
        this.httpStatus.requested = 0
        this.httpStatus.fetched = 0
        const addRequests = added.map(id => {
            this.httpStatus.requested++
            return this.videService.setSharedObject(p, {id}).pipe(
                tap(res => {
                    if (res.success) {
                        this.httpStatus.fetched++
                    } else {
                        this.logs.add(res, `Share ${id}`)
                    }
                }),
            )
        })
        const removeRequests = removed.map(id => {
            this.httpStatus.requested++
            return this.videService.deleteSharedObject(p, {id}).pipe(
                tap(res => {
                    if (res.success) {
                        this.httpStatus.fetched++
                    } else {
                        this.logs.add(res, `Remove ${id}`)
                    }
                }),
            )
        })
        if (this.httpStatus.total === 0) {
            console.log("Nothing to save")
            this.form.markAsPristine()
            return
        }
        this.subscription = concat(...addRequests, ...removeRequests).subscribe({
            next: value => {
                console.log(value)
            },
            complete: () => {
                if (this.httpStatus.fetched === this.httpStatus.total) {
                    this.form.markAsPristine()
                    this.httpStatus.total = 0
                }
                this.videService.reloadProjectData()
                this.subscription?.unsubscribe()
            }
        })
    }

    reset() {
        this.service.selectionModel.setSelection(...this.existingSharedObjectIds())
    }

    selectAllProjects() {
        const ps = this.siblingProjects()
        this.form.controls.projects.setValue(ps)
    }
}
