import {Component, computed, isDevMode, viewChildren} from '@angular/core'
import {MatButtonModule} from "@angular/material/button"
import {MatChipsModule} from "@angular/material/chips"
import {MatDialog} from "@angular/material/dialog"
import {MatDividerModule} from "@angular/material/divider"
import {MatIconModule} from "@angular/material/icon"
import {MatMenuModule, MatMenuTrigger} from "@angular/material/menu"
import {MatSlideToggleModule} from "@angular/material/slide-toggle"
import {MatToolbarModule} from "@angular/material/toolbar"
import {MatTooltipModule} from "@angular/material/tooltip"
import {NgOptimizedImage} from "@angular/common"
import {ReactiveFormsModule} from "@angular/forms"
import {RouterLink} from "@angular/router"
import {toSignal} from "@angular/core/rxjs-interop"

import {switchMap} from "rxjs"

import {ABILITY} from "../ability"
import {APP_NAME, POPUP_NAME} from "../../constants"
import {AuthService} from "../auth/auth.service"
import {HelpService} from "../help/help.service"
import {OutdatedStatisticsComponent} from "../dialogs/outdated-statistics/outdated-statistics.component"
import {PopupWindowService} from "../popup-window.service"
import {SelectLimitComponent} from "../select-limit/select-limit.component"
import {SelfUser} from "../api/api-types"
import {VIDE_ROUTES2, VideDataService} from "../api/vide-data.service"
import {environment} from "../../environments/environment"

@Component({
    selector: 'app-toolbar',
    imports: [
        SelectLimitComponent,

        MatButtonModule,
        MatChipsModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatTooltipModule,
        NgOptimizedImage,
        ReactiveFormsModule,
        RouterLink,
    ],
    templateUrl: './toolbar.component.html',
    styleUrl: './toolbar.component.scss'
})
export class ToolbarComponent {

    protected readonly window = window
    protected readonly POPUP_NAME = POPUP_NAME
    protected readonly ABILITY = ABILITY
    protected readonly APP_NAME = APP_NAME

    private readonly triggers = viewChildren(MatMenuTrigger)
    private readonly version = toSignal(this.dataService.version$)

    readonly project = this.dataService.project
    readonly user = this.dataService.user
    readonly smallScreen = this.dataService.smallScreen
    readonly outdatedStatistics = this.dataService.outdatedStatistics
    readonly userAdminAbility = toSignal(this.dataService.userAdminAbility$)
    readonly failedJobs = toSignal(this.dataService.failedJobs$, {initialValue: []})
    readonly cave = computed(() => {
        const res: { severity: 'warn' | 'info', message: string, fn?: () => any, link?: string[] }[] = []
        if (this.outdatedStatistics().length > 0) {
            res.push({
                severity: "warn",
                message: 'Statistics is outdated or missing for some object(s)',
                fn: this.outdatedDialog.bind(this)
            })
        }
        if (isDevMode()) {
            res.push({severity: 'info', message: 'Developer mode'})
        } else {
            // dev mode does not use versions, so this only makes sense here
            if (this.version()?.backend_version !== environment.appVersion) {
                res.push({
                    severity: 'warn', message: 'Version mismatch for frontend and backend',
                })
            }
        }
        if (this.version()?.database_type !== 'production') {
            res.push({message: `DB: ${this.version()?.database_name}`, severity: 'info'})
        }
        if (this.failedJobs().length > 0) {
            res.push({
                message: `${this.failedJobs().length} failed jobs`,
                severity: 'warn',
                link: this.videLink(VIDE_ROUTES2.adminFailedJobs)
            })
        }
        return res
    })
    readonly caveColor = computed(() => this.cave().some(c => c.severity === 'warn'))
    readonly limitDescription = toSignal(this.dataService.project$.pipe(
        switchMap(p => this.dataService.getLimitDescription(p))
    ))

    readonly validateHttpRequestControl = this.dataService.validateHttpRequestControl

    constructor(
        private readonly auth: AuthService,
        private readonly dataService: VideDataService,
        private readonly dialog: MatDialog,
        private readonly popup: PopupWindowService,
        private readonly help: HelpService
    ) {
    }

    videLink = this.dataService.videLink.bind(this.dataService)

    userTooltip(user: SelfUser) {
        return `${user.name ?? ''} (${user.email}) ${user.vide_admin ? 'Vide admin' : ''}`
    }

    outdatedDialog() {
        this.dialog.open(OutdatedStatisticsComponent)
    }

    test() {
        console.warn('logging')
    }

    toggleHighMap() {
        this.dataService.bigMapControl.setValue(!this.dataService.bigMapControl.value)
    }

    get bigMap() {
        return this.dataService.bigMapControl.value
    }

    get popupRef() {
        return this.dataService.popupRef
    }

    popupWindow = this.popup.popupWindow.bind(this.popup)

    initial(name: string | null) {
        return name?.substring(0, 1).toLocaleUpperCase() ?? '?'
    }

    signOut() {
        this.auth.logout().then(_r => {
            console.log('Sign out successful')
        })
    }

    limitSaved() {
        // Close all menus, as I cannot get hold on a specific one...
        this.triggers().forEach(t => {
            t.closeMenu()
        })
    }

    clickCave(c: { severity: "warn" | "info"; message: string; fn?: () => void }) {
        if (c.fn) c.fn()
    }

    openHelp() {
        this.help.open()
    }

    protected readonly isDevMode = isDevMode
    protected readonly VIDE_ROUTES2 = VIDE_ROUTES2
}
