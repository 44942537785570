@if (userForm.controls.imageOptions; as options) {
    <mat-card>
        <mat-card-header>
            <mat-card-title>
                Save image options
            </mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <p>
                The size will be used for the <code>Save plot</code> function.
            </p>
            <p>
                @if (options.controls.width; as control) {
                    <mat-form-field>
                        <mat-label>Width (pixels)</mat-label>
                        <input matInput type="number" [formControl]="control">
                        <mat-error>{{ getErrorMessage(control) }}</mat-error>
                    </mat-form-field>
                }
                @if (options.controls.height; as control) {
                    <mat-form-field>
                        <mat-label>Height (pixels)</mat-label>
                        <input matInput type="number" [formControl]="control">
                        <mat-error>{{ getErrorMessage(control) }}</mat-error>
                    </mat-form-field>
                }
            </p>
            <p>
                The filename will be used for <code>Download plot as png</code>
                function in the modebar and the <code>Save plot</code> function.
            </p>
            <p>
                @if (options.controls.filename; as control) {
                    <mat-form-field>
                        <mat-label>File name</mat-label>
                        <input matInput type="text" [formControl]="control">
                        <mat-error>{{ getErrorMessage(control) }}</mat-error>
                    </mat-form-field>
                }
            </p>
        </mat-card-content>

        <mat-card-actions>
            <button mat-raised-button (click)="saveImageOptions()">Save as default</button>
            <button mat-raised-button (click)="options.reset()">Restore default</button>
        </mat-card-actions>
    </mat-card>
}


<mat-card>
    <mat-card-header>
        <mat-card-title>
            Default timeline zoom
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <p>
            The default time interval used in timeline plots. Set to 0 to disable.
        </p>
        <p>
            @if (userForm.controls.zoomOptions.controls.interval; as control) {
                <mat-form-field>
                    <mat-label>Interval</mat-label>
                    <input matInput type="number" [formControl]="control">
                    <mat-error>{{ getErrorMessage(control) }}</mat-error>
                </mat-form-field>
            }
            @if (userForm.controls.zoomOptions.controls.period; as control) {
                <mat-form-field>
                    <mat-label>Period</mat-label>
                    <select matNativeControl [formControl]="control">
                        @for (i of Interval; track i) {
                            <option [ngValue]="i">{{ i }}</option>
                        }
                    </select>
                    <mat-error>{{ getErrorMessage(control) }}</mat-error>
                </mat-form-field>
            }
        </p>
    </mat-card-content>

    <mat-card-actions>
        <button mat-raised-button (click)="saveZoomOptions()">Save as default</button>
        <button mat-raised-button (click)="userForm.controls.zoomOptions.reset()">Restore default</button>
    </mat-card-actions>
</mat-card>


<mat-card>
    <mat-card-header>
        <mat-card-title>
            Update password
        </mat-card-title>
    </mat-card-header>

    @if (userForm.controls.credentials; as group) {
        <mat-card-content>
            <form>
                @if (group.controls.current_password; as control) {
                    <mat-form-field>
                        <mat-label>Old password</mat-label>
                        <input matInput type="password" [formControl]="control" autocomplete="current-password">
                        <mat-error>{{ getErrorMessage(control) }}</mat-error>
                    </mat-form-field>
                }
                @if (group.controls.password; as control) {
                    <mat-form-field>
                        <mat-label>New password</mat-label>
                        <input matInput type="password" [formControl]="control" autocomplete="new-password">
                        <mat-error>{{ getErrorMessage(control) }}</mat-error>
                    </mat-form-field>
                }
                @if (group.controls.password_confirmation; as control) {
                    <mat-form-field>
                        <mat-label>New password confirmation</mat-label>
                        <input matInput type="password" [formControl]="control" autocomplete="new-password">
                        <mat-error>{{ getErrorMessage(control) }}</mat-error>
                    </mat-form-field>
                }
                <input hidden type="email" [formControl]="group.controls.email" autocomplete="username email">
            </form>
        </mat-card-content>
    }

    <mat-card-actions>
        <button mat-raised-button type="button" (click)="changePassword()"> Change password</button>
    </mat-card-actions>
</mat-card>

<mat-card>
    <mat-card-header>
        <mat-card-title> Update name</mat-card-title>
    </mat-card-header>

    <mat-card-content>
        @if (userForm.controls.name; as control) {
            <mat-form-field class="full-width">
                <mat-label>User name</mat-label>
                <input matInput type="text" [formControl]="control" autocomplete="name">
                <mat-error>{{ getErrorMessage(control) }}</mat-error>
            </mat-form-field>
        }
    </mat-card-content>

    <mat-card-actions>
        <button mat-raised-button type="button" (click)="updateName()"> Save name</button>
        <button mat-raised-button type="button" (click)="reset()"> Reset</button>
    </mat-card-actions>
</mat-card>

<mat-card>
    <mat-card-content>
        @if (user(); as u) {
            <p>
                Signed in as {{ u.name }} &lt;{{ u.email }}&gt;
            </p>
        }
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button type="button" (click)="logout()"> Sign out</button>
        <button mat-raised-button type="button" (click)="clearLastVersion()"> Clear seen changelog entries</button>
    </mat-card-actions>
</mat-card>

<app-log-list [container]="logs"></app-log-list>
