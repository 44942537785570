@if (userAdminAbility(); as ability) {
    <mat-toolbar color="primary">

        @if (window.name === POPUP_NAME) {
            <!-- popup-->
            <span class="toolbar-spacer"></span>
            Object select: {{ project()?.name }}
            <span class="toolbar-spacer"></span>
        } @else {
            <!-- normal-->
            <button mat-icon-button [matMenuTriggerFor]="mainMenu" aria-label="main menu">
                <mat-icon>menu</mat-icon>
            </button>

            @if (smallScreen()) {
                <img class="vide-logo" [alt]="APP_NAME" ngSrc="assets/Akvifar-transparent.png" width="60" height="25"
                     priority>
            } @else {
                <img class="vide-logo" [alt]="APP_NAME" ngSrc="assets/Akvifar-transparent.png" width="120" height="45"
                     priority>
            }


            <span class="toolbar-spacer"></span>

            <!--            Secret link -->
            @if (project(); as p) {
                <a class="vide-link" [routerLink]="videLink(VIDE_ROUTES2.project.project,{project:p})"
                   queryParamsHandling="merge">{{ p.name }} </a>
                <span [style.width]="'1em'"></span>

                @if (smallScreen()) {
                    <span class="toolbar-spacer"></span>

                } @else {

                    <button mat-button [matMenuTriggerFor]="limitMenu">
                        {{ limitDescription() }}
                        <mat-icon>arrow_drop_down</mat-icon>
                    </button>

                    <span class="toolbar-spacer"></span>

                    <button mat-icon-button (click)="toggleHighMap()" matTooltip="Toggle large plot and map"
                            [color]="bigMap?'accent':undefined" aria-label="Toggle large plot and map">
                        <mat-icon>height</mat-icon>
                    </button>
                    <button mat-icon-button (click)="popupWindow()" matTooltip="Popup select window"
                            [color]="popupRef?'accent':undefined" aria-label="Popup select window">
                        <mat-icon class="material-symbols-outlined">ungroup</mat-icon>
                    </button>
                }
            }
            @if (cave().length) {
                <button
                    [color]="caveColor()?'warn':'accent'"
                    mat-mini-fab
                    [matMenuTriggerFor]="caveMenu"
                >{{ cave().length }}
                </button>
                <mat-menu #caveMenu>
                    @if (user(); as user) {
                        <div mat-menu-item><{{ user.email }}></div>
                        <button mat-menu-item (click)="signOut()">Sign out</button>
                    }
                    @for (c of cave(); track $index) {
                        @if (c.link) {
                            <a mat-menu-item [routerLink]="c.link">
                                @if (c.severity === 'warn') {
                                    <mat-icon>warning</mat-icon>
                                }
                                {{ c.message }}
                            </a>
                        } @else {
                            <button mat-menu-item (click)="clickCave(c)">
                                @if (c.severity === 'warn') {
                                    <mat-icon>warning</mat-icon>
                                }
                                {{ c.message }}
                            </button>
                        }
                    }
                </mat-menu>
            }
        }
    </mat-toolbar>


    <mat-menu #limitMenu="matMenu" [overlapTrigger]="true">
        <ng-template matMenuContent>
            <app-select-limit (saved)="limitSaved()"/>
        </ng-template>
    </mat-menu>

    <mat-menu #adminMenu="matMenu">
        @if (ability.has('Project admin') || ability.has('Vide admin')) {
            <a [routerLink]="videLink(VIDE_ROUTES2.adminProjects)" mat-menu-item>Projects</a>
        }
        @if (ability.has('License owner') || ability.has('Vide admin')) {
            <a [routerLink]="videLink(VIDE_ROUTES2.adminUsers)" mat-menu-item>Users</a>
            <a [routerLink]="videLink(VIDE_ROUTES2.adminLicenses)" mat-menu-item>Licenses</a>
        }
        @if (ability.has('Vide admin')) {
            <a [routerLink]="videLink(VIDE_ROUTES2.adminEconomy)" mat-menu-item>Economy</a>
            <a [routerLink]="videLink(VIDE_ROUTES2.adminFailedJobs)" mat-menu-item>Failed jobs</a>
            <mat-slide-toggle [formControl]="validateHttpRequestControl">Validate HTTP responses</mat-slide-toggle>
        }
    </mat-menu>

    <mat-menu #mainMenu="matMenu">
        <a mat-menu-item routerLink=""> Select project </a>
        @if (project(); as project) {

            <mat-divider/>

            <a mat-menu-item [routerLink]="videLink(VIDE_ROUTES2.project.project)" queryParamsHandling="merge"> Project
                page </a>
            @if (ABILITY.WRITE.availableFor(project)) {
                <button
                    mat-menu-item
                    [matMenuTriggerFor]="importMenu"
                    [matMenuTriggerData]="{project}"
                >Import
                </button>
            }
            @if (ability.has('Vide admin')) {
                <button mat-menu-item [routerLink]="videLink(VIDE_ROUTES2.correlation)" queryParamsHandling="merge">
                    Correlation analysis
                </button>
            }
            <a mat-menu-item matTooltip="View (edit) selected objects"
               matTooltipPosition="left" [routerLink]="videLink(VIDE_ROUTES2.objects)" queryParamsHandling="merge">
                View objects</a>
            <a mat-menu-item matTooltipPosition="left" matTooltip="View (edit) measurements for the selected objects"
               [routerLink]="videLink(VIDE_ROUTES2.measurements)" queryParamsHandling="merge">
                View measurements</a>
            <!--    <a mat-menu-item matTooltipPosition="left"-->
                <!--       matTooltip="View (edit) events for the selected objects" [routerLink]="videLink('events')"-->
                <!--       queryParamsHandling="merge">-->
                <!--        View events</a>-->
            <a mat-menu-item matTooltip="Edit groups (using the selected objects)"
               matTooltipPosition="left" [routerLink]="videLink(VIDE_ROUTES2.groups)" queryParamsHandling="merge"> Edit
                groups</a>
            <a mat-menu-item matTooltip="Batch process of plots for the selected objects"
               matTooltipPosition="left" [routerLink]="videLink(VIDE_ROUTES2.batchPlot)" queryParamsHandling="merge"
               target="_blank">
                Batch plots</a>
            <a mat-menu-item matTooltip="For small screens"
               matTooltipPosition="left" [routerLink]="videLink(VIDE_ROUTES2.fieldApp)"> Field app</a>

        }
        @if (ability.size) {
            <mat-divider/>
            <button
                mat-menu-item
                [matMenuTriggerFor]="adminMenu"
            > Admin
            </button>
        }

        <mat-divider/>

        <a mat-menu-item [routerLink]="videLink(VIDE_ROUTES2.preferences)"> Settings </a>
        <a mat-menu-item [routerLink]="videLink(VIDE_ROUTES2.about)"> About </a>
        <button mat-menu-item (click)="openHelp()"> Help (F1)</button>
    </mat-menu>


    <mat-menu #importMenu="matMenu">
        <ng-template matMenuContent let-project="project">
            <!--    Auto updating objects from private sources    -->
            @if (project.unoson_sources.length > 0) {
                <a mat-menu-item
                   matTooltip="TeleControlNet"
                   [routerLink]="videLink(VIDE_ROUTES2.tcn)"
                   queryParamsHandling="merge"
                >
                    TeleControlNet
                </a>

                <mat-divider/>
            }

            <!--  General stuff  -->
            <a mat-menu-item
               matTooltip="Files exported from TMO or {{APP_NAME}} or gvr-files"
               [routerLink]="videLink(VIDE_ROUTES2.importFile)"
               queryParamsHandling="merge"
            >
                File
            </a>
            <a mat-menu-item
               matTooltip="Import measurements from diver office, and calculate compensation"
               [routerLink]="videLink(VIDE_ROUTES2.importDiver)"
               queryParamsHandling="merge"
            >
                Diver
            </a>

            <mat-divider/>

            <a mat-menu-item
               matTooltip="Access objects from other projects"
               [routerLink]="videLink(VIDE_ROUTES2.importShares2)"
               queryParamsHandling="merge"
            >
                Share
            </a>

            <mat-divider/>

            <!--       Auto updating objects from public sources    -->
            <a mat-menu-item
               matTooltip="SGU ground water stations"
               [routerLink]="videLink(VIDE_ROUTES2.importSgu)"
               queryParamsHandling="merge"
            >
                SGU
            </a>
            <a mat-menu-item
               matTooltip="SMHI weather and water level stations"
               [routerLink]="videLink(VIDE_ROUTES2.importSmhi)"
               queryParamsHandling="merge"
            >
                SMHI
            </a>
            <a mat-menu-item
               matTooltip="Stockholms stad Geoarkivet"
               [routerLink]="videLink(VIDE_ROUTES2.importGeoarkivet)"
               queryParamsHandling="merge"
            >
                Stockholms stad Geoarkivet
            </a>
            <a mat-menu-item
               matTooltip="Göteborgs stad grundvatten"
               [routerLink]="videLink(VIDE_ROUTES2.importGoteborg)"
               queryParamsHandling="merge"
            >
                Göteborgs stad grundvatten
            </a>
        </ng-template>
    </mat-menu>

}
