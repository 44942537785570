<mat-card>
    <mat-card-header>
        <mat-card-title>Project admin</mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <table mat-table matSort [dataSource]="dataSource" multiTemplateDataRows>
            <app-sorted-text-column name="name"/>
            <app-sorted-text-column name="comment"/>

            <ng-container matColumnDef="share_type">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Share type</th>
                <td mat-cell *matCellDef="let user">
                    {{ user.share_type }}
                </td>
            </ng-container>
            <ng-container matColumnDef="license">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>License</th>
                <td mat-cell *matCellDef="let user">
                    {{ user.owner_name }}
                </td>
            </ng-container>
            <ng-container matColumnDef="coordinate_system">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Coordinate system</th>
                <td mat-cell *matCellDef="let project">
                    {{ project.coordinate_system.name }}
                </td>
            </ng-container>
            <ng-container matColumnDef="height_system">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Height system</th>
                <td mat-cell *matCellDef="let user">
                    {{ user.height_system.name }}
                </td>
            </ng-container>
            <ng-container matColumnDef="ability">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Permission</th>
                <td mat-cell *matCellDef="let project">
                    {{ project.ability }}
                </td>
            </ng-container>
            <ng-container matColumnDef="open">
                <th mat-header-cell *matHeaderCellDef>Open</th>
                <td mat-cell *matCellDef="let project">
                    @if (project.ability) {
                        <a [routerLink]="videLink('project', {project})">
                            <mat-icon>arrow_forward</mat-icon>
                        </a>
                    }
                </td>
            </ng-container>

            <!--  Buttons  -->
            <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let project">
                    @if (ABILITY.ADMIN.availableFor(project) || userIsLicenseOwner(project) || userAdminAbility()?.has('Vide admin')) {
                        <button
                            mat-icon-button aria-label="edit" (click)="edit(project)"
                            matTooltip="Edit {{project.name}} properties">
                            <mat-icon>edit</mat-icon>
                        </button>
                    }
                </td>
            </ng-container>
            <ng-container matColumnDef="users">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let project">
                    @if (ABILITY.ADMIN.availableFor(project) || userIsLicenseOwner(project) || userAdminAbility()?.has('Vide admin')) {
                        <button
                            mat-icon-button aria-label="edit" (click)="editUsers(project)"
                            matTooltip="Edit {{project.name}} users">
                            <mat-icon>person</mat-icon>
                        </button>
                    }
                </td>
            </ng-container>
            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let project">
                    @if (ABILITY.ADMIN.availableFor(project) || userIsLicenseOwner(project) || userAdminAbility()?.has('Vide admin')) {
                        <button mat-icon-button aria-label="delete" (click)="delete(project)"
                                matTooltip="Delete {{project.name}}">
                            <mat-icon>delete</mat-icon>
                        </button>
                    }
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
        </table>
    </mat-card-content>

    <mat-card-actions>
        <button mat-fab aria-label="add user" (click)="addProject()" matTooltip="Add user">
            <mat-icon>add</mat-icon>
        </button>

    </mat-card-actions>

    <mat-card-footer>
        <app-log-list [container]="logs"/>
    </mat-card-footer>

</mat-card>
