import {HttpRequest, HttpXsrfTokenExtractor} from "@angular/common/http"
import { Injectable, inject } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class XsrfCookieService {
  private xsrfTokenExtractor = inject(HttpXsrfTokenExtractor);


    addXsrfHeader(request: HttpRequest<any>) {
        let xsrfToken = this.xsrfTokenExtractor.getToken() ?? ''
        // console.warn("adding xsrf token ", xsrfToken, request.url)
        return request.clone({headers: request.headers.set("X-XSRF-TOKEN", xsrfToken)})
    }
}
