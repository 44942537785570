<mat-card>
    <mat-card-header>
        <mat-card-title> SMHI weather and water level stations</mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <p> Add stations to the project. It will be shared from the SMHI project. New measurements will be added
            nightly. </p>
        <p> Data provided from <a href="https://www.smhi.se/">SMHI</a> under licence <a
            href="https://creativecommons.org/licenses/by/4.0/">CC4.0</a>. </p>

        <div class="vide-columns-wide">
            <ng-select
                [matTooltip]="canDeactivate()?'':'Reset selection to change measure type'"
                [items]="parameters()"
                placeholder="Measure type"
                [clearable]="false"
                [formControl]="form.controls.parameter"
            >
                <ng-template ng-option-tmp ng-label-tmp let-item="item">
                    {{ item.parameter.title }} {{ item.parameter.summary }}
                </ng-template>
            </ng-select>
            <mat-checkbox [formControl]="form.controls.showInactive">Show inactive stations</mat-checkbox>
        </div>

        <mat-menu #clickMenu>
            <ng-template matMenuContent let-element="element">
                @if (element.tainted) {
                    <button mat-menu-item disableRipple>{{ getName(element) }} in progress</button>
                } @else if (element.external) {
                    <button mat-menu-item (click)="remove(element)">Remove {{ getName(element) }}</button>
                } @else {
                    <button mat-menu-item (click)="add(element)">Add {{ getName(element) }}</button>
                }
                <button mat-menu-item (click)="showProperties(element)">Show all properties</button>
            </ng-template>
        </mat-menu>

        <mat-tab-group>
            <mat-tab label="List">
                <table mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button
                                [disabled]="element.tainted"
                                [matMenuTriggerFor]="clickMenu"
                                [matMenuTriggerData]="{element}"
                                mat-icon-button>
                                <mat-icon>more_horiz</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="from">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>From</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.station.from|date : DATETIME_FORMAT }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="to">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>To</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.station.to|date : DATETIME_FORMAT }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="imported">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Imported</th>
                        <td mat-cell *matCellDef="let element">
                            @if (element.external) {
                                <mat-icon>check</mat-icon>
                            }
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="active">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Active</th>
                        <td mat-cell *matCellDef="let element">
                            @if (element.station.active) {
                                <mat-icon>check</mat-icon>
                            }
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Active</th>
                        <td mat-cell *matCellDef="let element">
                            {{ getName(element) }}
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </mat-tab>
            <!-- Smhi objects always have coordinates           -->
            <mat-tab label="Map">
                <ng-template matTabContent>
                    @if (figure(); as figure) {
                        <plotly-plot
                            [data]="figure.data"
                            [layout]="figure.layout"
                            [config]="figure.config"
                            [style]="plotlyStyle"
                            [useResizeHandler]="true"
                            (plotlyClick)="plotlyClick($event)"
                            (relayout)="onPlotlyRelayout($event)"
                        />
                    } @else {
                        <div [style.height]="plotlyStyle.height" class="plot-spinner">
                            <mat-spinner mode="indeterminate"/>
                        </div>
                    }
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>

<mat-card>
    @if (httpStatus.vide.hasValue()) {
        <mat-progress-bar mode="indeterminate"/>
    } @else if (httpExtern()) {
        <mat-progress-bar mode="buffer"/>
    }
    <app-log-list [container]="logs"/>
</mat-card>

