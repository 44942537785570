import {Component, computed, effect} from '@angular/core'
import {AbstractControl, FormBuilder, ReactiveFormsModule, ValidatorFn, Validators} from '@angular/forms'
import {toSignal} from "@angular/core/rxjs-interop"
import {MatCardModule} from "@angular/material/card"
import {MatInputModule} from "@angular/material/input"
import {MatSlideToggleModule} from "@angular/material/slide-toggle"
import {MatButtonModule} from "@angular/material/button"
import {MatSelectModule} from "@angular/material/select"
import {MatListModule} from "@angular/material/list"

import {firstValueFrom} from 'rxjs'

import {VideDataService} from 'src/app/api/vide-data.service'
import {ComponentCanDeactivate} from 'src/app/can-deactivate.guard'
import {LogContainer} from 'src/app/log-list/log-container'
import {getErrorMessage, joinWithEnding} from 'src/app/shared/vide-helper'
import {ABILITY} from "../../ability"
import {Group} from "../../api/api-types"
import {LogListComponent} from "../../log-list/log-list.component"


@Component({
    selector: 'app-edit-groups-objects',
    standalone: true,
    templateUrl: './edit-groups-objects.component.html',
    styleUrls: ['./edit-groups-objects.component.scss'],
    imports: [
        LogListComponent,
        MatButtonModule,
        MatCardModule,
        MatInputModule,
        MatListModule,
        MatSelectModule,
        MatSlideToggleModule,
        ReactiveFormsModule,
    ]
})
export class EditGroupsObjectsComponent extends ComponentCanDeactivate {
    readonly project = this.dataService.project
    readonly selectedObjects = this.dataService.selectedObjects
    readonly groups = toSignal(this.dataService.groups$, {initialValue: []})
    /** Groups with at least one of the selected objects */
    readonly groupsWithSomeObject = computed(() =>
        this.groups().filter(g =>
            g.object_ids.some(oid => this.dataService.selectionModel.isSelected(oid))))
    readonly objectsList = computed(() =>
        joinWithEnding(this.selectedObjects().map(o => o.name)))

    readonly logs = new LogContainer()

    readonly nameExistsValidator: ValidatorFn = (control: AbstractControl) => {
        // console.debug('validating...', control.value)
        const newName = (control.value as string).trim()
        const groups = this.groups()
        const x = groups.find(g => g.name === newName)
        return x ? {nameExists: {global: x.global}} : null
    }

    readonly form = this.fb.nonNullable.group({
        create: this.fb.nonNullable.group({
            global: [false],
            name: ['', [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(255),
                this.nameExistsValidator,
            ]],
        }),
        add: [null as Group | null, Validators.required],
        remove: [null as Group | null, Validators.required],

    })
    protected readonly getErrorMessage = getErrorMessage
    protected readonly ABILITY = ABILITY

    constructor(
        private readonly dataService: VideDataService,
        private readonly fb: FormBuilder,
    ) {
        super()
        effect(() => {
            // If we come back here, for another project, this has to be reevaluated
            const p = this.project()
            const action = ABILITY.WRITE.availableFor(p) ? 'enable' : 'disable'
            this.form.controls.create.controls.global[action]()
        })
    }

    reset() {
        this.form.reset()
    }

    canDeactivate(): boolean {
        return !this.form.dirty
    }

    async createGroup() {
        const control = this.form.controls.create
        control.markAllAsTouched()
        if (control.valid) {
            const values = control.getRawValue()
            const name = values.name.trim()
            const project = this.project() // await firstValueFrom(this.project$.pipe(filter(isDefined),))
            if (!project) {
                throw Error("Project not found")
            }
            const objectIds = await this.getSelectedObjectIds()
            const x = await firstValueFrom(this.dataService.createGroup(project, name, objectIds, values.global))
            this.logs.add(x, `Create group '${name}'`)
            if (x.success) {
                this.reload()
                control.reset()
            }
        }
    }

    async addToGroup() {
        const control = this.form.controls.add
        control.markAllAsTouched()
        const group = control.value
        if (control.valid && group) {
            const objectIds = await this.getSelectedObjectIds()
            const x = await firstValueFrom(this.dataService.updateGroup({
                id: group.id,
                object_ids: group.object_ids.concat(objectIds),
                // set order, so new objects will go last. If there was no order, now there is. At least we do not
                // lose order.
                order: true,
            }))
            this.logs.add(x, `Add objects to group '${group.name}'`)
            if (x.success) {
                this.reload()
                control.reset()
            }
        }
    }

    async removeFromGroup() {
        const control = this.form.controls.remove
        control.markAllAsTouched()
        const group = control.value
        if (control.valid && group) {
            const objectIds = await this.getSelectedObjectIds()
            const remainingIds = group.object_ids.filter(id => !objectIds.includes(id))
            const x = await firstValueFrom(this.dataService.updateGroup({
                id: group.id,
                object_ids: remainingIds,
            }))
            this.logs.add(x, `Remove objects from group '${group.name}'`)
            if (x.success) {
                this.reload()
                control.reset()
            }
        }
    }

    private async getSelectedObjectIds() {
        const objects = (this.selectedObjects())
        // const objects = await firstValueFrom(this.selectedObjects$)
        return objects.map(o => o.id)
    }

    private reload() {
        console.warn("Should reload")
        this.dataService.reloadProjectData()
    }
}
