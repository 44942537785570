import {AfterViewInit, Component, computed, signal, ViewChild} from '@angular/core'
import {AsyncPipe, JsonPipe} from "@angular/common"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop"
import {RouterLink} from "@angular/router"
import {MatDialog} from "@angular/material/dialog"
import {Title} from "@angular/platform-browser"
import {MatPaginator, MatPaginatorModule, PageEvent} from "@angular/material/paginator"
import {MatSort, MatSortModule, Sort} from "@angular/material/sort"
import {MatCheckboxModule} from "@angular/material/checkbox"
import {MatTableDataSource, MatTableModule} from "@angular/material/table"
import {SelectionModel} from "@angular/cdk/collections"
import {MatTooltipModule} from "@angular/material/tooltip"
import {MatToolbarModule} from "@angular/material/toolbar"
import {MatButtonModule} from "@angular/material/button"

import {EmptyError, forkJoin, map, tap} from "rxjs"

import {
    getPageTitle,
    isDefined,
    isNotNull,
    pageSizeOptions,
    saveSpreadsheet,
    SpreadSheetDefinition
} from "../shared/vide-helper"
import {VideDataService} from "../api/vide-data.service"
import {ComponentCanDeactivate} from "../can-deactivate.guard"
import {Mutable} from "../vide-types"
import {getTooltip, VideObject} from "../api/api-types"
import {ABILITY} from "../ability"
import {EditObjectsBulkComponent} from "./edit-objects-bulk/edit-objects-bulk.component"
import {AddObjectComponent, AddObjectComponentData} from "./add-object/add-object.component"
import {LogContainer} from "../log-list/log-container"
import {HeaderCasePipe} from "../pipes/header-case.pipe"
import {LogListComponent} from "../log-list/log-list.component"

type Handler = (row: VideObject, edit: Partial<Mutable<VideObject>>) => void

function getSorter(activeP: string, direction: 'asc' | 'desc') {
    let getVale: (x: VideObject) => string | number | null | boolean | undefined
    const active = activeP as (keyof VideObject) | 'ew' | 'ns'
    switch (active) {
        case "last_changed_by":
        case "level_quality":
        case "well_dimension":
        case "source":
        case "ground_level":
        case "readonly":
        case "filter_length":
        case "project_id":
        case "original_information":
        case "alias":
        case "time_created":
        case "id":
        case "direction":
        case "not_reference_from":
        case "inclination":
        case "measurableDepth":
        case "bottom_level":
        case "time_changed":
        case "correlation_reference":
        case "reference_level":
        case "coordinate_quality":
        case "correlation_base":
        case "directions":
        case "name":
        case "comment":
            getVale = (x: VideObject) => x[active]
            break
        case "ew":
            getVale = (x: VideObject) => x.positionM?.x
            break
        case "ns":
            getVale = (x: VideObject) => x.positionM?.y
            break
        case "owner":
            getVale = (x: VideObject) => x[active]?.project_owner
            break
        case "aquifer":
        case "coordinate_determination_method":
        // case "coordinate_system":
        case "filter_type":
        case "object_status":
        case "object_type":
        case "pipe_material":
        // case "position":
        case "settlement_position":
        // case "statistics":
        case "tip_type":
            getVale = (x: VideObject) => x[active]?.name
            break
        default:
            throw Error(`Unknown property ${active}`)
    }
    let res: 1 | -1
    switch (direction) {
        case "asc":
            res = 1
            break
        case "desc":
            res = -1
            break
        default:
            throw Error(`Unknown direction ${direction}`)
    }
    return (a: VideObject, b: VideObject) => {
        const aValue = getVale(a)
        const bValue = getVale(b)
        if (!isDefined(aValue) && !isDefined(bValue)) return 0
        // null sorted last
        if (!isDefined(aValue)) return res
        if (!isDefined(bValue)) return -res
        return bValue < aValue ? res : -res
    }
}

@Component({
    selector: 'app-view-objects',
    standalone: true,
    imports: [
        AsyncPipe,
        HeaderCasePipe,
        JsonPipe,
        LogListComponent,
        MatButtonModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatSortModule,
        MatTableModule,
        MatToolbarModule,
        MatTooltipModule,
        RouterLink,
    ],
    templateUrl: './view-objects.component.html',
    styleUrl: './view-objects.component.scss'
})
export class ViewObjectsComponent extends ComponentCanDeactivate implements AfterViewInit {
    @ViewChild(MatPaginator) paginator?: MatPaginator
    @ViewChild(MatSort) sort?: MatSort
    readonly log = new LogContainer()
    readonly project = this.dataService.project
    readonly selection = new SelectionModel<number>(
        true,
        [],
        true,
    )
    objectColumnsFirst: ReadonlyArray<keyof Omit<VideObject, 'statistics' | 'position' | 'positionM'>> = [
        'name',
        'owner',
        'alias',
        'comment',
        'directions',
        'source',
        'original_information',
    ]
    correlationColumns: ReadonlyArray<keyof Omit<VideObject, 'statistics' | 'position' | 'positionM'>> = [
        'correlation_base',
        'correlation_reference',
    ]
    objectColumnsLast: ReadonlyArray<keyof Omit<VideObject, 'statistics' | 'position' | 'positionM'>> = [
        "coordinate_determination_method",
        "coordinate_quality",
        "level_quality",
        "aquifer",
        "object_status",
        "object_type",
        "bottom_level",
        "reference_level",
        "ground_level",
        "inclination",
        "direction",
        "measurableDepth",
        "well_dimension",
        "filter_length",
        "filter_type",
        "pipe_material",
        "tip_type",
        "settlement_position",
    ]
    // readonly columnsToDisplay = ['select', 'edit', ...this.objectColumnsFirst, 'ew', 'ns', ...this.objectColumnsLast]
    readonly columnsToDisplay = computed(() => {
            const project = this.project()
            const edits = ABILITY.WRITE.availableFor(project) ? ['select'] : []
            const correlations = project?.correlations_exists ? this.correlationColumns : []
            return edits.concat([...this.objectColumnsFirst, 'ew', 'ns', ...this.objectColumnsLast]).concat(correlations)
        }
    )
    readonly ABILITY = ABILITY

    private readonly edits = new Map<number, Partial<Mutable<VideObject>>>()
    // private readonly edits = new Map<number, Partial<{ ew: number, ns: number } & Mutable<VideObjectNew2Type>>>()
    protected readonly objects = this.dataService.selectedObjects

    private readonly paging2 = signal<PageEvent | undefined>(undefined)
    private readonly sorting2 = signal<Sort | undefined>(undefined)

    readonly projectWaiting = toSignal(this.dataService.projectWaiting$)
    private readonly utility = toSignal(this.dataService.utility$)

    readonly aquifers = computed(() => this.utility()?.aquifer)
    readonly objectStatuses = computed(() => this.utility()?.object_status)
    readonly objectTypes = computed(() => this.utility()?.object_type)
    readonly selectable2 = computed(() =>
        this.objects()
            .filter(o => !o.readonly)
            .map(o => o.id))
    readonly pageSize = computed(() => {
        const length = this.objects().length
        return pageSizeOptions(length)
    })
    readonly dataSource2 = computed(() => {
        const p = this.paging2()
        if (!p) return []
        const s = this.sorting2()
        const os = this.objects()
        if (s && s.direction !== '') {
            os.sort(getSorter(s.active, s.direction))
        }
        const start = p.pageSize * p.pageIndex
        const end = start + p.pageSize
        return os.slice(start, end)
    })

    constructor(
        private readonly dataService: VideDataService,
        private readonly dialog: MatDialog,
        private readonly title: Title,
    ) {
        super()
        this.dataService.project$.pipe(takeUntilDestroyed()).subscribe(p => {
            this.title.setTitle(getPageTitle(p, 'object view'))
        })
    }

    canDeactivate(): boolean {
        return this.edits.size === 0
    }

    ngAfterViewInit(): void {
        this.sort?.sortChange.subscribe(x => {
            console.log(x)
            this.sorting2.set(x)
        })
        const ev: PageEvent = {
            // Use some sensible defaults in case the paginator does not appear
            length: this.paginator?.length ?? 10,
            pageIndex: this.paginator?.pageIndex ?? 0,
            pageSize: this.paginator?.pageSize ?? 10,
        }
        this.paging2.set(ev)
    }

    page(event: PageEvent) {
        this.paging2.set(event)
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        return this.selectable2().length === this.selection.selected.length
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ? this.selection.clear() : this.selection.select(...this.selectable2())
    }

    getCellStatus(row: VideObject, def: 'ew' | 'ns' | typeof this.objectColumnsFirst[number] & typeof this.objectColumnsLast[number]) {
        // if (def === 'owner') {
        //     // Owner property is not editable...
        // }
        switch (def) {
            case "owner":
                return {value: row.owner ? `${row.owner.project_name} [${row.owner.project_owner}]` : '', dirty: false}
            case "ew":
                return {value: row.positionM?.x, dirty: false}
            case "ns":
                return {value: row.positionM?.y, dirty: false}
        }
        const originalProperty = row[def]
        const editedProperty = this.edits.get(row.id)?.[def]
        const property = editedProperty === undefined ? originalProperty : editedProperty
        const value = (typeof property === 'object') ? property?.name : property
        const dirty = editedProperty !== undefined && originalProperty !== property

        return {value, dirty}
    }

    save() {
        const project = this.project()
        if (!project) {
            console.error("No project found")
            return
        }
        // console.warn(this.edits)
        const calls = Array.from(this.edits).map(([id, data]) =>
            this.dataService.updateObject(project, {id, ...data}).pipe(
                tap(x => {
                    this.log.add(x, `Save ${data.name}`)
                }),
                map(x => {
                    return {id, result: x}
                }),
            ))
        forkJoin(calls).subscribe({
            next: x => {
                let reload = false
                // console.log(`Save complete `, x)
                if (x.every(res => res.result.success)) {
                    console.log("All passed")
                    this.edits.clear()
                    reload = true
                } else {
                    if (x.some(r => r.result)) {
                        reload = true
                    }
                }
                if (reload) this.dataService.reloadProjectData()
            },
            error: err => {
                if (err instanceof EmptyError) {
                    console.warn(`No changes to save detected`)
                } else {
                    console.error(`Some error saving: `, err)
                }
            },
            // complete: () => {                 console.log(`Completed save`)            },
        })
    }

    resetEdits() {
        this.edits.clear()
    }

    editSelected() {

        const aquifers = this.aquifers()
        const objectStatuses = this.objectStatuses()
        const objectTypes = this.objectTypes()
        if (!aquifers || !objectStatuses || !objectTypes) return
        const ref = this.dialog.open<
            EditObjectsBulkComponent,
            EditObjectsBulkComponent['data'],
            EditObjectsBulkComponent['result']
        >(
            EditObjectsBulkComponent,
            {data: {aquifers, objectStatuses, objectTypes,},},
        )

        function getCommentHandler(x: EditObjectsBulkComponent['result']): Handler | null {
            const input = x?.comment
            if (input === '' || input === undefined) return null
            let updater: (x: string | null) => string
            // let updateString = ''
            if (input.startsWith('+')) {
                const updateString = input.substring(1)
                updater = (x: string | null) => {
                    return ((x ?? '') + updateString).trim()
                }
            } else {
                const updateString = input.trim()
                updater = () => updateString
            }
            return (row, edit) => {
                const oldValue = edit.comment === undefined ? row.comment : edit.comment
                // const oldValue = row.edits.comment === undefined ? row.value.comment : row.edits.comment
                edit.comment = updater(oldValue)
            }

        }

        function getAquiferHandler(x: EditObjectsBulkComponent['result']): Handler | null {
            const input = x?.aquifer
            if (input === undefined) return null
            return (_row, edit) => {
                edit.aquifer = input
            }
        }

        function getObjectStatusHandler(x: EditObjectsBulkComponent['result']): Handler | null {
            const input = x?.object_status
            if (input === undefined) return null
            return (_row, edit) => {
                edit.object_status = input
            }
        }

        function getObjectTypeHandler(x: EditObjectsBulkComponent['result']): Handler | null {
            const input = x?.object_type
            if (input === undefined) return null
            return (_row, edit) => {
                edit.object_type = input
            }
        }

        function getCorrelationBaseHandler(x: EditObjectsBulkComponent['result']): Handler | null {
            const input = x?.correlation_base
            if (input === undefined) return null
            return (row, edit) => {
                edit.correlation_base = input
            }
        }

        function getCorrelationRefHandler(x: EditObjectsBulkComponent['result']): Handler | null {
            const input = x?.correlation_reference
            if (input === undefined) return null
            return (_row, edit) => {
                edit.correlation_reference = input
            }
        }

        function getNotReferenceHandler(x: EditObjectsBulkComponent['result']): Handler | null {
            const input = x?.not_reference_from
            if (input === undefined) return null
            return (_row, edit) => {
                edit.not_reference_from = input
            }
        }

        ref.afterClosed().subscribe(x => {
            if (x !== undefined) {
                const handlers = [
                    getCommentHandler(x),
                    getAquiferHandler(x),
                    getObjectTypeHandler(x),
                    getObjectStatusHandler(x),
                    getCorrelationBaseHandler(x),
                    getCorrelationRefHandler(x),
                    getNotReferenceHandler(x),
                ].filter(isNotNull)

                this.objects()
                    .filter(o => this.selection.isSelected(o.id))
                    .forEach(o => {
                        const id = o.id
                        if (!this.edits.has(id)) {
                            this.edits.set(id, {})
                        }
                        const edit = this.edits.get(id)
                        if (edit === undefined) throw new Error("Impossible")
                        handlers.forEach(h => {
                            h(o, edit)
                        })
                    })
            }
        })
    }

    addObject() {
        const project = this.project()
        if (!project) {
            console.error("No project found")
            return
        }
        const ref = this.dialog.open<
            AddObjectComponent, AddObjectComponentData, AddObjectComponent['result']
        >(AddObjectComponent)
        ref.afterClosed().subscribe(object => {
            if (object) {
                this.dataService.createObject(project, object).subscribe(x => {
                    this.log.add(x, `Add object ${object.name}`)
                    if (x.success) {
                        this.dataService.selectionModel.select(x.data.id)
                        this.dataService.reloadProjectData()
                    }
                })
            }
        })
    }

    exportData(data: VideObject[]) {
        const p = this.project()
        if (!p) return
        const format: SpreadSheetDefinition<VideObject> = [
            {header: 'Name', value: x => x.name},
            {header: 'Alias', value: x => x.alias},
            {header: 'Comment', value: x => x.comment},
            {header: 'Directions', value: x => x.directions},
            {header: 'Source', value: x => x.source},
            {header: 'Coordinate system', value: () => p.coordinate_system.name},
            {header: 'E/W', value: x => x.positionM?.x},
            {header: 'N/S', value: x => x.positionM?.y},
            {header: 'Coordinate determination method', value: x => x.coordinate_determination_method?.name},
            {header: 'Coordinate precision', value: x => x.coordinate_quality},
            {header: 'Level precision', value: x => x.level_quality},
            {header: 'Aquifer', value: x => x.aquifer?.name},
            {header: 'Status', value: x => x.object_status.name},
            {header: 'Type', value: x => x.object_type.name},
            {header: 'Bottom level', value: x => x.bottom_level},
            {header: 'Reference level', value: x => x.reference_level},
            {header: 'Ground level', value: x => x.ground_level},
            {header: 'Inclination', value: x => x.inclination},
            {header: 'Bearing', value: x => x.direction},
            {header: 'Measurable depth', value: x => x.measurableDepth},
            {header: 'Well dimension', value: x => x.well_dimension},
            {header: 'Filter length', value: x => x.filter_length},
            {header: 'Filter type', value: x => x.filter_type?.name},
            {header: 'Pipe material', value: x => x.pipe_material?.name},
            {header: 'Tip type', value: x => x.tip_type?.name},
            {header: 'Settlement position', value: x => x.settlement_position?.name},
            {header: 'Permanently affected from', value: x => x.not_reference_from},
            // {header: 'Correlation base', value: x => x.correlation_base},
            // {header: 'Correlation reference', value: x => x.correlation_reference},
            {header: 'Time changed', value: x => x.time_changed},
            {header: 'Time created', value: x => x.time_created},
            {header: 'Last changed by', value: x => x.last_changed_by},
        ] as const

        return saveSpreadsheet(data, format)

    }


    protected readonly getTooltip = getTooltip
}
