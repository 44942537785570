import { Component } from '@angular/core';

@Component({
  selector: 'app-object-select-help',
  imports: [],
  templateUrl: './object-select-help.component.html',
  styleUrl: './object-select-help.component.scss'
})
export class ObjectSelectHelpComponent {

}
