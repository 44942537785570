import {Component, Inject} from '@angular/core'
import {AbstractControl, FormControl, ReactiveFormsModule, ValidationErrors, Validators} from '@angular/forms'
import {
    MAT_DIALOG_DATA,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogRef,
    MatDialogTitle
} from '@angular/material/dialog'
import {MatButton} from '@angular/material/button'
import {MatInput} from '@angular/material/input'
import {MatError, MatFormField} from '@angular/material/form-field'

import {firstValueFrom} from 'rxjs'

import {VideDataService} from 'src/app/api/vide-data.service'
import {getErrorMessage} from 'src/app/shared/vide-helper'
import {OptionsFormat, Unpacked} from 'src/app/vide-types'

@Component({
    selector: 'app-get-option-name',
    templateUrl: './get-option-name.component.html',
    styleUrls: ['./get-option-name.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, MatDialogContent, MatFormField, MatInput, ReactiveFormsModule, MatError, MatDialogActions, MatButton, MatDialogClose]
})
export class GetOptionNameComponent {
    readonly nameUnique = (control: AbstractControl): ValidationErrors | null => {
        const value = control.value
        if (typeof value !== 'string') return {invalid: true}

        const name = value.trim()
        const found = this.data.existingNames.includes(name)

        return found ? {nameExists: true} : null
    }
    readonly control = new FormControl('', {
        nonNullable: true,
        validators: [Validators.required, this.nameUnique]
    })

    error: string = ''

    result?: Unpacked<ReturnType<typeof this.dataService.savePlotOptions>>

    async save() {
        if (!this.control.valid) return

        const name = this.control.value

        const res = await firstValueFrom(this.dataService.savePlotOptions({name, options: this.data.options}))
        if (res.success) {
            this.dialogRef.close(res)
        } else {
            this.error = res.error
        }

    }

    constructor(
        private dataService: VideDataService,
        public dialogRef: MatDialogRef<GetOptionNameComponent, GetOptionNameComponent['result']>,
        @Inject(MAT_DIALOG_DATA) public data: {
            existingNames: string[],
            options: OptionsFormat
        },
    ) {
    }

    getErrorMessage = getErrorMessage

}
