import {NO_OBJECT_MAP_POSITION, SINGLE_OBJECT_ZOOM_LEVEL} from "src/constants"
import {isNotNull} from "../shared/vide-helper"
import { objectWithMetricPosition, objectWithPosition, PlotlyIcon,} from "../vide-types"
import {VideObject} from "../api/api-types"
import {getPreciseDistance} from "geolib"

export const MAPBOX_MARKERS = [
    {name: 'small', marker: {size: 6} as const},
    {name: 'medium', marker: {size: 8} as const},
    {name: 'large', marker: {size: 12} as const},
    {name: 'x-large', marker: {size: 16} as const},
    {name: 'xx-large', marker: {size: 20} as const},
] as const
export type MapboxMarker = typeof MAPBOX_MARKERS[number]

export interface MapPosition {
    center: {
        lat: number,
        lon: number
    },
    zoom: number
}

export function getNormalPosition(objects: readonly Pick<VideObject,'position'|'positionM'>[], element: HTMLElement | undefined, useColorbar: boolean): MapPosition {
// export function getNormalPosition(objects: readonly Pick<VideObjectNew2Type, 'position'|'positionM' >[], element: HTMLElement | undefined, useColorbar: boolean): MapPosition {
    const firstSvg = element?.querySelectorAll('svg').item(0)
    const size = firstSvg ? {width: firstSvg.width.baseVal.value, height: firstSvg.height.baseVal.value} : undefined
    return getNormalPositionSize(objects, size, useColorbar)
}

export function getNormalPositionSize(objects: readonly Pick<VideObject,'position'|'positionM'>[], size: {
    width: number,
    height: number
} | undefined, useColorbar: boolean): MapPosition {
    const objectsWithPosition = objects.filter(objectWithPosition)
    if (objectsWithPosition.length === 0) {
        return NO_OBJECT_MAP_POSITION
    }
    const lats = objectsWithPosition.map(o => o.position.lat).filter(isNotNull)
    const longs = objectsWithPosition.map(o => o.position.lon).filter(isNotNull)
    const latMin = Math.min(...lats)
    const latMax = Math.max(...lats)
    const lonMin = Math.min(...longs)
    const lonMax = Math.max(...longs)

    const latMid = (latMax + latMin) / 2.0
    const lonMid = (lonMax + lonMin) / 2.0

    function zoomNoElement() {
        const latDiff = latMax - latMin
        const latZoom = Math.log2(180 / latDiff)
        const lonDiff = lonMax - lonMin
        const lonZoom = Math.log2(360 / lonDiff)
        return Math.min(latZoom, lonZoom, SINGLE_OBJECT_ZOOM_LEVEL)
    }

    function zoom() {
        // const firstSvg = element?.querySelectorAll('svg').item(0)
        if (!size) {
            // console.warn("No SVG found on element")
            return
        }
        // TODO: use lat/long for calculating distances to display
        const objectsWithMetricPosition2 = objects.filter(objectWithMetricPosition)
        if (objectsWithMetricPosition2.length < 2) {
            return objectsWithPosition.length === 1 ? SINGLE_OBJECT_ZOOM_LEVEL : NO_OBJECT_MAP_POSITION.zoom
        }
        // const ews = objectsWithMetricPosition2.map(o => o.positionM.x)
        // const ewMin = Math.min(...ews)
        // const ewMax = Math.max(...ews)
        // const ewDistance = ewMax - ewMin
        // const nss = objectsWithMetricPosition2.map(o => o.positionM.y)
        // const nsMin = Math.min(...nss)
        // const nsMax = Math.max(...nss)
        // const nsDistance = nsMax - nsMin

        const latDist=getPreciseDistance({lat:latMin,lon:lonMid},{lat:latMax,lon:lonMid})
        const lonDist=getPreciseDistance({lat:latMid,lon:lonMin},{lat:latMid,lon:lonMax})

        const colorbarWidth = useColorbar ? (2 * 10 + 30 + 40) : 0 // padding + thickness + whatever as default
        const objectBorder = 50
        const res_0 = 40075.016686 * 1000 / 512 // resolution at equator at zoom level 0
        const xPixels = size.width - colorbarWidth - 2 * objectBorder
        const yPixels = size.height - 2 * objectBorder

        // const desiredXResolution = ewDistance / xPixels
        // const desiredYResolution = nsDistance / yPixels
        // console.log(ewDistance, lonDist)
        // console.log(nsDistance, latDist)
        const desiredXResolution = lonDist / xPixels
        const desiredYResolution = latDist / yPixels
        const latitudeFactor = Math.cos(latMid * Math.PI / 180)

        const xZoom = Math.log2(res_0 * latitudeFactor / desiredXResolution)
        const yZoom = Math.log2(res_0 * latitudeFactor / desiredYResolution)

        return Math.min(xZoom, yZoom)
    }


    const zoom1 = zoom() ?? zoomNoElement()
    return {
        center: {lat: latMid, lon: lonMid},
        zoom: zoom1,
    }

}

export const IconMinimize: PlotlyIcon = {
    height: 512,
    width: 512,
    path: `M200 287.1H64c-12.94 0-24.62 7.797-29.56 19.75c-4.969 11.97-2.219
    25.72 6.937 34.87l30.06 30.06l-62.06 62.07c-12.49 12.5-12.5 32.75-.0012
    45.25l22.62 22.62c12.5 12.5 32.76 12.5 45.26 .0012l62.06-62.07l30.06
    30.06c6.125 6.125 14.31 9.375 22.62 9.375c4.125 0 8.281-.7969
    12.25-2.437c11.97-4.953 19.75-16.62 19.75-29.56V311.1C224 298.7 213.3
    287.1 200 287.1zM312 224h135.1c12.94 0 24.62-7.797 29.56-19.75c4.969-11.97
    2.219-25.72-6.937-34.87l-30.06-30.06l62.06-62.07c12.5-12.5 12.5-32.76
    .0003-45.26l-22.62-22.62c-12.5-12.5-32.76-12.5-45.26-.0003l-62.06
    62.07l-30.06-30.06c-9.156-9.141-22.87-11.84-34.87-6.937C295.8 39.39 288
    51.06 288 64v135.1C288 213.3 298.7 224 312 224zM204.3 34.44C192.3 29.47
    178.5 32.22 169.4 41.38L139.3 71.44L77.25 9.374C64.75-3.123 44.49-3.123
    31.1 9.374l-22.63 22.63c-12.49 12.49-12.49 32.75 .0018 45.25l62.07
    62.06L41.38 169.4C35.25 175.5 32 183.7 32 192c0 4.125 .7969 8.281 2.438
    12.25C39.39 216.2 51.07 224 64 224h135.1c13.25 0 23.1-10.75
    23.1-23.1V64C224 51.06 216.2 39.38 204.3 34.44zM440.6
    372.7l30.06-30.06c9.141-9.156 11.84-22.88 6.938-34.87C472.6 295.8 460.9
    287.1 448 287.1h-135.1c-13.25 0-23.1 10.75-23.1 23.1v135.1c0 12.94 7.797
    24.62 19.75 29.56c11.97 4.969 25.72 2.219 34.87-6.937l30.06-30.06l62.06
    62.06c12.5 12.5 32.76 12.5 45.26 .0002l22.62-22.62c12.5-12.5 12.5-32.76
    .0002-45.26L440.6 372.7z`,
}

export const IconMaximize: PlotlyIcon = {
    height: 512,
    width: 448,
    path: `M447.1 319.1v135.1c0 13.26-10.75 23.1-23.1 23.1h-135.1c-12.94
    0-24.61-7.781-29.56-19.75c-4.906-11.1-2.203-25.72
    6.937-34.87l30.06-30.06L224 323.9l-71.43 71.44l30.06 30.06c9.156 9.156
    11.91 22.91 6.937 34.87C184.6 472.2 172.9 479.1 160 479.1H24c-13.25
    0-23.1-10.74-23.1-23.1v-135.1c0-12.94 7.781-24.61 19.75-29.56C23.72 288.8
    27.88 288 32 288c8.312 0 16.5 3.242 22.63 9.367l30.06
    30.06l71.44-71.44L84.69 184.6L54.63 214.6c-9.156 9.156-22.91 11.91-34.87
    6.937C7.798 216.6 .0013 204.9 .0013 191.1v-135.1c0-13.26 10.75-23.1
    23.1-23.1h135.1c12.94 0 24.61 7.781 29.56 19.75C191.2 55.72 191.1 59.87
    191.1 63.1c0 8.312-3.237 16.5-9.362 22.63L152.6 116.7l71.44
    71.44l71.43-71.44l-30.06-30.06c-9.156-9.156-11.91-22.91-6.937-34.87c4.937-11.95
    16.62-19.75 29.56-19.75h135.1c13.26 0 23.1 10.75 23.1 23.1v135.1c0
    12.94-7.781 24.61-19.75 29.56c-11.1 4.906-25.72
    2.203-34.87-6.937l-30.06-30.06l-71.43 71.43l71.44
    71.44l30.06-30.06c9.156-9.156 22.91-11.91 34.87-6.937C440.2 295.4 447.1
    307.1 447.1 319.1z`,
}


export const MAP_BACKGROUNDS_PUBLIC = [
    // public data, no key needed
    "open-street-map",
    "carto-positron",
    "carto-darkmatter",
    "stamen-terrain",
    "stamen-toner",
    "stamen-watercolor",
] as const
export const MAP_BACKGROUNDS_APIKEY = [
    // mapbox server, api key needed
    "basic",
    "streets",
    "outdoors",
    "light",
    "dark",
    "satellite",
    "satellite-streets",
] as const
export const MAP_BACKGROUNDS = [...MAP_BACKGROUNDS_PUBLIC, ...MAP_BACKGROUNDS_APIKEY] as const

export type MapBackground = typeof MAP_BACKGROUNDS[number]

export const ICONS = [
    'airfield',
    'airport',
    'alcohol-shop',
    'amusement-park',
    'aquarium',
    'art-gallery',
    'attraction',
    'beer',
    'bicycle-share',
    'bicycle',
    'bus',
    'cafe',
    'campsite',
    'car',
    'castle',
    'cemetery',
    'cinema',
    'circle-stroked',
    'circle',
    'clothing-store',
    'college',
    'dentist',
    'doctor',
    'dog-park',
    'drinking-water',
    'embassy',
    'entrance',
    'fast-food',
    'ferry',
    'fire-station',
    'fuel',
    'garden',
    'golf',
    'grocery',
    'harbor',
    'heliport',
    'hospital',
    'ice-cream',
    'information',
    'laundry',
    'library',
    'lodging',
    'marker',
    'monument',
    'mountain',
    'museum',
    'music',
    'park',
    'pharmacy',
    'picnic-site',
    'place-of-worship',
    'playground',
    'police',
    'post',
    'prison',
    'rail-light',
    'rail-metro',
    'rail',
    'religious-christian',
    'religious-jewish',
    'religious-muslim',
    'restaurant',
    'rocket',
    'school',
    'shop',
    'stadium',
    'star',
    'suitcase',
    'swimming',
    'theatre',
    'toilet',
    'town-hall',
    'triangle-stroked',
    'triangle',
    'veterinary',
    'volcano',
    'zoo',
]

/** Keep these for reference */
export const ICON_DONT_WORK = [
    'aerialway',
    'american-football',
    'animal-shelter',
    'arrow',
    'bakery',
    'bank-JP',
    'bank',
    'bar',
    'barrier',
    'baseball',
    'basketball',
    'bbq',
    'beach',
    'blood-bank',
    'bowling-alley',
    'bridge',
    'building-alt1',
    'building',
    'car-rental',
    'car-repair',
    'casino',
    'castle-JP',
    'caution',
    'cemetery-JP',
    'charging-station',
    'city',
    'college-JP',
    'commercial',
    'communications-tower',
    'confectionery',
    'construction',
    'convenience',
    'cricket',
    'cross',
    'dam',
    'danger',
    'defibrillator',
    'diamond',
    'elevator',
    'emergency-phone',
    'entrance-alt1',
    'farm',
    'fence',
    'ferry-JP',
    'fire-station-JP',
    'fitness-centre',
    'florist',
    'furniture',
    'gaming',
    'garden-centre',
    'gift',
    'globe',
    'hairdresser',
    'hardware',
    'heart',
    'highway-rest-area',
    'home',
    'horse-riding',
    'hospital-JP',
    'hot-spring',
    'industry',
    'jewelry-store',
    'karaoke',
    'landmark-JP',
    'landmark',
    'landuse',
    'lighthouse-JP',
    'lighthouse',
    'logging',
    'marker-stroked',
    'mobile-phone',
    'monument-JP',
    'natural',
    'observation-tower',
    'optician',
    'paint',
    'park-alt1',
    'parking-garage',
    'parking',
    'pitch',
    'police-JP',
    'post-JP',
    'racetrack-boat',
    'racetrack-cycling',
    'racetrack-horse',
    'racetrack',
    'ranger-station',
    'recycling',
    'religious-buddhist',
    'religious-shinto',
    'residential-community',
    'restaurant-bbq',
    'restaurant-noodle',
    'restaurant-pizza',
    'restaurant-seafood',
    'restaurant-sushi',
    'road-accident',
    'roadblock',
    'school-JP',
    'scooter',
    'shelter',
    'shoe',
    'skateboard',
    'skiing',
    'slaughterhouse',
    'slipway',
    'snowmobile',
    'soccer',
    'square-stroked',
    'square',
    'star-stroked',
    'table-tennis',
    'teahouse',
    'telephone',
    'tennis',
    'town',
    'viewpoint',
    'village',
    'volleyball',
    'warehouse',
    'waste-basket',
    'watch',
    'water',
    'waterfall',
    'watermill',
    'wetland',
    'wheelchair',
    'windmill',
]
