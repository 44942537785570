<p>Select limit for oldest data to fetch</p>
<!--TODO: use normal style for elements-->
<div>
    <!--        Interval-->
    <input [style.width]="'3em'" [formControl]="form.controls.period.controls.interval" type="number" min="1" max="99">
    <select [formControl]="form.controls.period.controls.period">
        @for (i of Interval;track i){
            <option [ngValue]="i">{{i}}</option>
        }
    </select>
    <button mat-raised-button (click)="save('period')" >Use interval</button>
</div>
<div>
    <!--        Start date-->
    <input [max]="INPUT_DATE_MAX"  [formControl]="form.controls.date.controls.date" type="date">
    <button (click)="save('date')" >Use date</button>
</div>
<div>
    <!--        Start date-->
    <button (click)="saveNone()" >Use no limit</button>
</div>

<!--<div class="debug">-->
<!--    <div>{{form.getRawValue() | json}}</div>-->
<!--</div>-->
