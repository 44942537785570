<mat-card>
    <mat-card-header>
        <mat-card-title>
            Changelog
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        @for (change of changes(); track change.version) {
            <article>
                <h3>{{ change.version }} &mdash; {{ change.date }}</h3>
                <em>{{ change.general }}</em>
                <dl>
                    @for (q of CHANGE_TYPES; track q) {
                        @if (change[q].length) {
                            <dt>{{ q }}</dt>
                            @for (item of change[q]; track item) {
                                <dd>{{ item }}</dd>
                            }
                        }
                    }
                </dl>
            </article>
        }
    </mat-card-content>
</mat-card>
