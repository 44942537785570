import {BreakpointObserver} from '@angular/cdk/layout'
import {Component, effect, ElementRef, Renderer2, viewChild} from '@angular/core'
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop"
import {FormControl, ReactiveFormsModule} from '@angular/forms'
import {Title} from '@angular/platform-browser'
import {MatSlider, MatSliderThumb} from "@angular/material/slider"
import {MatTooltip} from "@angular/material/tooltip"

import {filter, map, startWith} from 'rxjs'

import {VideDataService} from '../api/vide-data.service'
import {getPageTitle, isDefined} from '../shared/vide-helper'
import {ObjectSelectComponent} from "../object-select/object-select/object-select.component"
import {PlotSelectComponent} from "../plot/plot-select/plot-select.component"

class Proportion {
    /**
     * @param leftProportion The amount (in percent) the left panel should occupy
     */
    constructor(private leftProportion: number) {
    }

    get left() {
        return this.leftProportion
    }

    get right() {
        return (100 - this.leftProportion)
    }

    get showLeft() {
        return this.leftProportion != 0
    }

    get showRight() {
        return this.leftProportion != 100
    }
}

const MIN_PROPORTION = 15 as const

/**
 * Component routed for /project/{pid}.  Sets the page tile.
 */
@Component({
    selector: 'app-project',
    standalone: true,
    templateUrl: './project.component.html',
    styleUrls: ['./project.component.scss'],
    imports: [
        MatSlider,
        MatTooltip,
        MatSliderThumb,
        ReactiveFormsModule,
        ObjectSelectComponent,
        PlotSelectComponent,
    ]
})
export class ProjectComponent {
    readonly left = viewChild<ElementRef>('left')
    readonly right = viewChild<ElementRef>('right')

    readonly proportionsSlider = new FormControl(50, {nonNullable: true})
    private readonly proportion$ = this.proportionsSlider.valueChanges.pipe(
        startWith(this.proportionsSlider.value),
        map(vc => {
            if (vc > 0 && vc <= MIN_PROPORTION) {
                this.proportionsSlider.setValue(0)
            } else if (vc < 100 && vc >= (100 - MIN_PROPORTION)) {
                this.proportionsSlider.setValue(100)
            } else {
                return new Proportion(vc)
            }
            return null
        }),
        filter(isDefined),
    )

    private readonly smallScreen$ = this.breakpointObserver.observe(['screen and (max-width: 960px)']).pipe(map(b => {
        return b.matches
    }))
    readonly smallScreen = toSignal(this.smallScreen$, {initialValue: false})
    readonly proportion = toSignal(this.proportion$)

    constructor(
        private readonly dataServiceV2: VideDataService,
        private readonly titleService: Title,
        private readonly renderer: Renderer2,
        private readonly breakpointObserver: BreakpointObserver,
    ) {
        this.dataServiceV2.popupRef$.pipe(takeUntilDestroyed()).subscribe(ref => {
            // If there is a popup, show only plot part. If it goes away, show even split.
            this.proportionsSlider.reset(ref ? 0 : 50)
        })
        this.dataServiceV2.project$.pipe(takeUntilDestroyed()).subscribe(p => {
            this.titleService.setTitle(getPageTitle(p))
        })
        effect(() => {
            const p = this.proportion()
            const b = this.smallScreen()
            if (p) {
                this.updateSizes(p, b)
            }
        })
    }

    updateSizes(x: Proportion, smallScreen: boolean) {
        const gutter = 10
        const left = this.left()
        const right = this.right()
        if (!left || !right) {
            console.log('Missing element for split')
            return
        }
        this.renderer.setStyle(left.nativeElement, 'margin-right', `${gutter}px`)
        if (smallScreen) {
            this.renderer.setStyle(left.nativeElement, 'width', `100%`)
            this.renderer.setStyle(right.nativeElement, 'width', `100%`)
        } else {
            this.renderer.setStyle(left.nativeElement, 'width', `calc(${x.left}% - ${gutter / 2}px)`)
            this.renderer.setStyle(right.nativeElement, 'width', `calc(${x.right}% - ${gutter / 2}px)`)
        }
        window.dispatchEvent(new Event('resize'))
    }

    onResetProportions() {
        this.proportionsSlider.reset(50)
    }
}

