<b> Optional step </b>
<p>
    Import new measurements from a Baro-diver. The file format is assumed to be similar to csv-files from DiverOffice
    and time is interpreted as local time.
</p>

<div class="vide-form-field">
    <div class="vide-columns-wide vide-form-field-row">
        <app-input
            type="file"
            [formControl]="form.controls.file"
            [multiple]="false"
            placeholder="Select file"
            accept=".csv"
        />
    </div>
</div>

@if (fileError(); as e) {
    <div class="error">
        {{ e }}
    </div>
}

@if (figure(); as figure) {
    <p>Click in the plot to set start and end of interval to use.</p>
    <plotly-plot
        [data]="figure.data"
        [layout]="figure.layout"
        [config]="figure.config"
        [useResizeHandler]="true"
        (plotlyClick)="plotlyClick($event)"
        (doubleClick)="doubleClick()"
    />
}

@if (annotations(); as ann) {
    @if (ann[0]; as begin) {
        <div>Begin: {{ begin.x }}</div>
    }
    @if (ann[1]; as end) {
        <div>End: {{ end.x }}</div>
    }
}

<div>
    Found {{ nrMeasurements() ?? 'no' }} measurements
</div>

@if (!service.completed.value && showError.value) {
    <div class="error">
        Save measurements or clear the input to continue
    </div>
}

<button
    mat-raised-button
    [matMenuTriggerFor]="newObjectMenu"
    [disabled]="!nrMeasurements() || saving.value"
> {{ saving.value ? 'Saving' : 'Save...' }}
</button>

<mat-menu #newObjectMenu>
    <button mat-menu-item (click)="saveNew()">Save to new object</button>
    <button mat-menu-item (click)="saveExisting()">Save to existing object</button>
</mat-menu>

<div class="stepper-navigate">
    <button mat-button matStepperPrevious type="button" disabled>Previous</button>
    <button mat-button matStepperNext type="button" (click)="localNext()">Next</button>
</div>
