@if (data?.importType; as typeInfo) {
    @if (data?.importInfo; as info) {

        <h2 mat-dialog-title>{{ typeInfo.title }}</h2>

        <mat-dialog-content>

            <p>{{ typeInfo.description }}</p>


            <ul>
                <li> Expected file type: {{ typeInfo.fileType }}.</li>
                @if (typeInfo.requiredHeaders.join(', '); as req) {
                    <li>
                        Required columns: {{ req }}
                    </li>
                }
                @if (typeInfo.optionalHeaders.join(', '); as opt) {
                    <li>
                        Optional columns: {{ opt }}
                    </li>
                }
                @if (typeInfo.ignoredHeaders.join(', '); as req) {
                    <li>
                        Ignored columns: {{ req }}
                    </li>
                }
                @for (item of typeInfo.info; track item) {
                    <li>
                        {{ item }}
                    </li>
                }
                <li>{{ info.requestTimeLimit }}</li>
                <li>{{ info.importErrorAction }}</li>
            </ul>
        </mat-dialog-content>

        <mat-dialog-actions>
            <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
            <button mat-button [mat-dialog-close]="true">Close</button>
        </mat-dialog-actions>

    }
} @else {
    No input
}
