import {formatNumber} from '@angular/common'
import {Inject, Injectable, LOCALE_ID} from '@angular/core'
import {FormBuilder} from '@angular/forms'
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"

import {combineLatest, filter, finalize, firstValueFrom} from 'rxjs'
import {combineLatestWith, debounceTime, map, shareReplay, startWith, switchAll, tap} from 'rxjs/operators'

import {VideDataService} from 'src/app/api/vide-data.service'
import {AbstractMapboxService} from 'src/app/mapbox/abstract-mapbox.service'
import {MAP_BACKGROUNDS_APIKEY} from 'src/app/mapbox/mapbox-helper'
import {
    assertNever,
    enableFields,
    filterNullAndUndefined,
    isDefined,
    partitionArray,
    valueOrFirst
} from 'src/app/shared/vide-helper'
import {
    ExtendedVideObjectV2,
    objectWithPosition,
    PlotlyScattermapboxData,
    PlotlyScattermapboxMarker,
    VideFigure,
} from 'src/app/vide-types'
import {FORM_DEBOUNCE_TIME, NUMBER_FORMAT} from 'src/constants'
import {MapPlotOptions} from '../map-plot-options'
import {DataDeviation, DeviationStatus, UserProject, VideObject} from "../../api/api-types"

export const DISPLAY_VALUES = [
    'Deviation from reference',
    'Correlation r²',
    'Probability of deviation from reference (p)'
] as const

// const CUSTOM_COLORSCALE: PlotlyPlot['data'][number]['colorscale'] = [
//     [0.0, 'rgb(255,0,0)'],
//     [0.1, 'rgb(255,255,255)'],
//     [0.9, 'rgb(255,255,255)'],
//     [1.0, 'rgb(0,0,255)']
// ]
// const CUSTOM_COLORSCALE: PlotlyPlot['data'][number]['colorscale'] = [
//     [0.0, 'rgb(165,0,38)'],
//     [0.111111111111, 'rgb(215,48,39)'],
//     [0.222222222222, 'rgb(244,109,67)'],
//     [0.333333333333, 'rgb(253,174,97)'],
//     [0.444444444444, 'rgb(254,224,144)'],
//     [0.555555555556, 'rgb(224,243,248)'],
//     [0.666666666667, 'rgb(171,217,233)'],
//     [0.777777777778, 'rgb(116,173,209)'],
//     [0.888888888889, 'rgb(69,117,180)'],
//     [1.0, 'rgb(49,54,149)']
// ]
const CUSTOM_COLORSCALE: VideFigure['data'][number]['colorscale'] = [
    [0.0, 'rgb(165,0,38)'],
    // [0.111111111111, 'rgb(215,48,39)'],
    // [0.222222222222, 'rgb(244,109,67)'],
    // [0.333333333333, 'rgb(253,174,97)'],
    [0.1, 'rgb(255,255,255)'],
    [0.9, 'rgb(255,255,255)'],
    // [0.666666666667, 'rgb(171,217,233)'],
    // [0.777777777778, 'rgb(116,173,209)'],
    // [0.888888888889, 'rgb(69,117,180)'],
    [1.0, 'rgb(49,54,149)']
]
// const CUSTOM_COLORSCALE: PlotlyPlot['data'][number]['colorscale'] = [
//     [0.0, 'rgb(165,0,38)'],
//     // [0.111111111111, 'rgb(215,48,39)'],
//     // [0.222222222222, 'rgb(244,109,67)'],
//     // [0.333333333333, 'rgb(253,174,97)'],
//     [0.1, 'rgb(254,224,144)'],
//     [0.9, 'rgb(224,243,248)'],
//     // [0.666666666667, 'rgb(171,217,233)'],
//     // [0.777777777778, 'rgb(116,173,209)'],
//     // [0.888888888889, 'rgb(69,117,180)'],
//     [1.0, 'rgb(49,54,149)']
// ]

class CorrelationMapOptions extends MapPlotOptions {
    display: typeof DISPLAY_VALUES[number] = 'Deviation from reference'
    time_ekv: number | null = null
    correlationRef: VideObject | null = null
    markStatuses: DeviationStatus[] | null = null
    useFontSize = false
    fontSize = 12
}

type TimePeriod = {
    begin: string
    end: string
    time_ekv: number
}

@Injectable({
    providedIn: 'root',
})
export class CorrelationMapService extends AbstractMapboxService {

    protected useColorbar: boolean = true

    readonly plotlyStyle$ = this.dataService.plotlyStyle$
    readonly project$ = this.dataService.project$
    readonly deviationStatuses$ = this.dataService.utility$.pipe(
        map(u => u.deviation_status))
    readonly popupRef$ = this.dataService.popupRef$

    readonly form = this.formBuilder.nonNullable.group(new CorrelationMapOptions())

    /** Objects with some correlation and lat and long will pass, the rest will be in fail */
    private readonly xxx$ = this.dataService.selectedObjects$.pipe(
        map(os => partitionArray(
            os,
            o => o.statistics.some(s => s.correlation_exists) && objectWithPosition(o),
        )),
        // tap(x => { console.warn(x) }),
    )
    private readonly objectsToConsider$ = this.xxx$.pipe(map(x => x.pass))
    readonly objectsDiscarded$ = this.xxx$.pipe(map(x => x.fail))

    public readonly objectsToDisplay$ = this.objectsToConsider$.pipe(
        combineLatestWith(this.dataService.projectNotNull$),
        map(async ([os,p]) => {
            const x = os.map(async o => await firstValueFrom(this.dataService.getExtendedObject(p,o)))
            return Promise.all(x)
        }),
        switchAll(),
        // tap(x => { console.warn(x) }),
        // shareReplay(1),
        shareReplay({bufferSize: 1, refCount: true}),
    )

    readonly objectsReferenced$ = this.objectsToDisplay$.pipe(
        map(os => os.flatMap(o => o.correlations)),
        map(cs => cs.map(c => c.ref_object.id)),
        // tap(x => { console.log(x) }),
        map(ids => {
            const set = new Set<number>()
            ids.forEach(id => {
                set.add(id)
            })
            return set
        }),
        combineLatestWith(this.dataService.objects$),
        // tap(x => { console.log(x) }),
        map(([set, objects]) => objects.filter(o => set.has(o.id))),
        // tap(x => { console.log(x) }),
    )

    readonly timePeriods$ = this.objectsToDisplay$.pipe(
        // tap(x => { console.log(x) }),
        map(os => {
            const timeEkv = os.flatMap(o => o.correlations.flatMap(c => c.aberrations.map(a => a.time_ekv)))
            const set = new Set(timeEkv)
            return Array.from(set).sort((a, b) => b - a)
        }),
        combineLatestWith(this.project$.pipe(filter(isDefined),)),
        map(([tes, p]) => filterNullAndUndefined(tes.map(te => getTimePeriod(te, p)))),
        tap(tps => {
            const first = tps.at(0)
            this.form.patchValue({time_ekv: first?.time_ekv ?? null})
        }),
    )
    readonly figure$ = combineLatest([
        this.objectsToDisplay$,
        this.form.valueChanges.pipe(
            startWith(true),
            debounceTime(FORM_DEBOUNCE_TIME),
            map(_ => this.form.getRawValue())),
        this.dataService.plotlyToImage$,
        this.forceRelayout$,
    ]).pipe(
        // tap(x => { console.warn(x) }),
        map(([os, options, toImage]) => {
            const config = this.getMapConfig(toImage.toImageOptions)
            return {
                data: this.getData(os, options),
                layout: this.getLayout(os, options),
                config: config,
                revision: this.revision++,
            }
        }),
    )

    readonly markedObjects: VideObject[] = []
    private revision = 0

    constructor(
        // private dataService: VideDataService,
        private formBuilder: FormBuilder,
        private projectDataService: VideDataService,
        @Inject(LOCALE_ID) private locale: string,
    ) {
        super()
        this.form.valueChanges.pipe(takeUntilDestroyed()).subscribe(() => {
            const v = this.form.getRawValue()
            enableFields(this.form, ['time_ekv', 'markStatuses'], v.display === 'Deviation from reference')
            enableFields(this.form, ['correlationRef'], v.display === 'Correlation r²')
            const enableShowLabels = (MAP_BACKGROUNDS_APIKEY as ReadonlyArray<string>).includes(v.background)
            enableFields(this.form, ['showLabels'], enableShowLabels)
        })
    }

    get options() {
        return this.form.getRawValue()
    }

    set options(value: ReturnType<typeof this.form.getRawValue>) {
        this.form.patchValue(value)
    }

    selectMarkedObjects() {
        const oids = this.markedObjects.map(o => o.id)
        this.projectDataService.selectionModel.setSelection(...oids)
    }

    private getData(objects: ExtendedVideObjectV2[], options: CorrelationMapOptions): PlotlyScattermapboxData[] {
        switch (options.display) {
            case 'Deviation from reference':
                return this.getDeviationData(objects, options)
            case 'Correlation r²':
                return this.getCorrelationR2(objects, options)
            case 'Probability of deviation from reference (p)':
                return this.getProbabilityData(objects, options)
            default:
                assertNever(options.display)
        }
    }

    private getProbabilityData(objects: ExtendedVideObjectV2[], options: CorrelationMapOptions) {
        this.markedObjects.splice(0)
        const lat = Array<number>()
        const lon = Array<number>()
        const lat_mark = Array<number>()
        const lon_mark = Array<number>()
        const hovertext = Array<string>()
        const text = Array<string>()
        const color = Array<number>()
        const lat_n = Array<number>()
        const lon_n = Array<number>()
        const text_n = Array<string>()
        objects.forEach(o => {
            /*
             * Find all matching correlation/aberration pairs for that time_ekv.
             * Pick the correlation with the smallest predIC and use it for plotting.
             *
             * Find the DataDeviations for the object matching the correlations
             * measure type and the time_ekv.
             *
             * If there is an aberration but no deviation, something is wrong in the
             * analysis
             *
             */

            if (options.time_ekv && objectWithPosition(o)) {
                const [correlation, aberration] = getBestCA(o, options.time_ekv)
                const deviation = o.statistics
                    .find(s => s.measure_type.id === correlation?.measure_type.id)?.data_deviations
                    .find(d => d.time_ekv === aberration?.time_ekv)
                if (aberration !== undefined) {
                    if (deviation === undefined) {
                        console.error(`Found aberration but no deviation, should not happen`)
                    }
                    const value = aberration.p * Math.sign(aberration.difference_ovpv)
                    const valueString = formatNumber(value, this.locale, NUMBER_FORMAT)
                    const diffOvpv = (aberration.difference_ovpv > 0 ? '+' : '')
                        + formatNumber(aberration.difference_ovpv, this.locale, NUMBER_FORMAT)
                    const unit = correlation.measure_type.measure_unit.name
                    lat.push(o.position.lat)
                    lon.push(o.position.lon)
                    hovertext.push(`${o.name} : ${deviation?.deviation_status.name} : ` +
                        `${diffOvpv} ${unit} : ${correlation?.ref_object.name}`)
                    text.push(valueString)
                    color.push(value)
                    if (deviation && this.showMarker(options, deviation)) {
                        this.markedObjects.push(o)
                        lat_mark.push(o.position.lat)
                        lon_mark.push(o.position.lon)
                    }
                } else {
                    lat_n.push(o.position.lat)
                    lon_n.push(o.position.lon)
                    text_n.push(o.name + ' : no correlation')
                }
            }
        })
        // console.warn(`options`, options)
        const marker = this.getMarkerTrace({lat: lat_mark, lon: lon_mark})
        const valid = this.getValidTraces(
            {lat, lon, hovertext, text, color},
            options,
            {
                ...options.marker.marker,
                colorbar: {title: 'p'},
                cmin: -1,
                cmax: 1,
                colorscale: CUSTOM_COLORSCALE,
                // colorscale: 'RdBu',
                reversescale: false,
            },
        )
        const invalid = this.getInvalidTraces({lat_n, lon_n, text_n})
        return [...marker, ...valid, ...invalid]
    }

    private getDeviationData(objects: ExtendedVideObjectV2[], options: CorrelationMapOptions) {
        this.markedObjects.splice(0)
        const lat = Array<number>()
        const lon = Array<number>()
        const lat_mark = Array<number>()
        const lon_mark = Array<number>()
        const hovertext = Array<string>()
        const text = Array<string>()
        const color = Array<number>()
        const lat_n = Array<number>()
        const lon_n = Array<number>()
        const text_n = Array<string>()
        objects.forEach(o => {
            /*
             * Find all matching correlation/aberration pairs for that time_ekv.
             * Pick the correlation with the smallest predIC and use it for plotting.
             *
             * Find the DataDeviations for the object matching the correlations
             * measure type and the time_ekv.
             *
             * If there is an aberration but no deviation, something is wrong in the
             * analysis
             *
             */
            if (objectWithPosition(o) && options.time_ekv) {
                const [correlation, aberration] = getBestCA(o, options.time_ekv)
                const deviation = o.statistics
                    .find(s => s.measure_type.id === correlation?.measure_type.id)?.data_deviations
                    .find(d => d.time_ekv === aberration?.time_ekv)
                if (aberration !== undefined) {
                    if (deviation === undefined) {
                        console.error(`Found aberration but no deviation, should not happen`)
                    }
                    const diffOvpv = (aberration.difference_ovpv > 0 ? '+' : '')
                        + formatNumber(aberration.difference_ovpv, this.locale, NUMBER_FORMAT)
                    const unit = correlation.measure_type.measure_unit.name
                    lat.push(o.position.lat)
                    lon.push(o.position.lon)
                    hovertext.push(`${o.name} : ${deviation?.deviation_status.name} : ` +
                        `${diffOvpv} ${unit} : ${correlation?.ref_object.name}`)
                    text.push(diffOvpv)
                    // text.push(diffOvpv)
                    color.push(aberration.difference_ovpv)
                    if (deviation && this.showMarker(options, deviation)) {
                        // console.error(`Found deviation status `)
                        this.markedObjects.push(o)
                        lat_mark.push(o.position.lat)
                        lon_mark.push(o.position.lon)
                    }
                } else {
                    lat_n.push(o.position.lat)
                    lon_n.push(o.position.lon)
                    text_n.push(o.name + ' : no correlation')
                }
            }
        })
        // console.warn(`options`, options)
        const marker = this.getMarkerTrace({lat: lat_mark, lon: lon_mark})
        const valid = this.getValidTraces(
            {lat, lon, hovertext, text, color},
            options,
            {
                ...options.marker.marker,
                colorbar: {title: 'Difference'},
                cmid: 0.0,
                colorscale: 'RdBu',
                reversescale: true,
            },
        )
        const invalid = this.getInvalidTraces({lat_n, lon_n, text_n})
        return [...marker, ...valid, ...invalid]
    }

    private showMarker(options: CorrelationMapOptions, deviation: DataDeviation) {
        return options.markStatuses?.find(m => m.id === deviation.deviation_status.id) !== undefined
    }

    private getMarkerTrace(data: { lat: number[]; lon: number[] }): PlotlyScattermapboxData[] {
        return [
            {
                type: 'scattermapbox',
                ...data,
                marker: {
                    color: 'black',
                    size: 14,
                },
            },
            {
                type: 'scattermapbox',
                ...data,
                marker: {
                    color: 'yellow',
                    size: 12,
                },
            },
        ]
    }

    private getInvalidTraces(data: { lat_n: number[]; lon_n: number[]; text_n: string[] }): PlotlyScattermapboxData[] {
        return [
            {
                type: 'scattermapbox',
                lat: data.lat_n,
                lon: data.lon_n,
                text: data.text_n,
                hoverinfo: 'text',
                // hovertext: ICONS,
                marker: {
                    color: 'black',
                    opacity: 0.5,
                    size: 6,
                },
            },
        ]
    }

    private getValidTraces(
        data: { lat: number[]; lon: number[]; hovertext: string[]; text: string[]; color: number[] },
        options: CorrelationMapOptions,
        marker: Partial<PlotlyScattermapboxMarker>,
    ): PlotlyScattermapboxData[] {
        const normalSize = valueOrFirst(options.marker.marker.size, 8)

        const textfont = options.useFontSize ?
            {
                size: options.fontSize,
            } :
            undefined

        return [
            {
                type: 'scattermapbox',
                lat: data.lat,
                lon: data.lon,
                marker: {
                    size: normalSize + 1,
                    color: 'black',
                },
            },
            {
                type: 'scattermapbox',
                lat: data.lat,
                lon: data.lon,
                text: data.text,
                hovertext: data.hovertext,
                hoverinfo: 'text',
                marker: {
                    colorbar: {},
                    color: data.color,
                    colorscale: 'RdBu',
                    reversescale: true,
                    allowoverlap: true,
                    ...marker,
                },
                mode: options.showLabels ? 'text+markers' : 'markers',
                textposition: 'middle right',
                textfont,
            },
        ]
    }

    private getCorrelationR2(objects: ExtendedVideObjectV2[], options: CorrelationMapOptions) {
        const lat = Array<number>()
        const lon = Array<number>()
        const hovertext = Array<string>()
        const text = Array<string>()
        const color = Array<number>()
        const lat_n = Array<number>()
        const lon_n = Array<number>()
        const text_n = Array<string>()
        objects.forEach(o => {
            // Exclude reference object from the value plotting
            if (objectWithPosition(o) && o.id !== options.correlationRef?.id) {
                const value = this.getCorrelationValue(o, options)
                if (value && Number.isFinite(value)) {
                    const valueString = formatNumber(value, this.locale, NUMBER_FORMAT)
                    lat.push(o.position.lat)
                    lon.push(o.position.lon)
                    hovertext.push(o.name + ' : ' + valueString)
                    text.push(valueString)
                    color.push(value!)
                } else {
                    lat_n.push(o.position.lat)
                    lon_n.push(o.position.lon)
                    text_n.push(o.name + ' : no correlation')
                }
            }
        })
        const valid = this.getValidTraces({lat, lon, hovertext, text, color},
            options, {...options.marker.marker, colorbar: {title: 'r²'}, cmin: 0, cmax: 1, colorscale: 'Blues'},
        )
        const invalid = this.getInvalidTraces({lat_n, lon_n, text_n})
        const refTrace = this.getCorrelationReferenceTrace(options.correlationRef)
        if (refTrace) {
            valid.push(refTrace)
        }
        return [...valid, ...invalid]
    }

    private getCorrelationReferenceTrace(o: VideObject | null): PlotlyScattermapboxData | null {
        return o && objectWithPosition(o)
            ? {
                // error marker
                type: 'scattermapbox',
                'lat': [o.position.lat],
                'lon': [o.position.lon],
                'text': [`Reference object (${o.name})`],
                hoverinfo: 'text',
                // hovertext: ICONS,
                marker: {
                    color: 'black',
                    opacity: 0.5,
                    size: 26,
                },
            } : null
    }

    private getCorrelationValue(o: ExtendedVideObjectV2 | null, options: CorrelationMapOptions) {
        let ret
        if (options.correlationRef && o) {
            const ref_id = options.correlationRef.id
            const corr = o.correlations.find(c => c.ref_object.id === ref_id)
            ret = corr?.r2
        }
        return ret ?? null
    }

}

function getTimePeriod(time_ekv: number, p: UserProject): TimePeriod | null {
    if (p.correlation_options === null) {
        console.error("No merging period for project, strange.")
        return null
    }
    const mid = new Date(1900, 0, 1, 0, 0, 0)
    const mergingPeriod = p.correlation_options.merging_period
    let displayFn = function (d: Date) {
        return d.toLocaleDateString()
    }
    if (mergingPeriod % 2 !== 0) {
        console.error('Merging period not an even number, what to do??? Maybe it works')
        displayFn = function (d: Date) {
            return d.toLocaleString()
        }
    }
    mid.setDate(mergingPeriod * time_ekv - 1)

    /**
     * Crossing DST boundaries does not seem to be correctly calculated, the time
     * is still 00:00:00 after setHours(), but the time zone changed between
     * +01:00 and +02:00.  This is actually what we want here, so thanks.
     */

    const begin = new Date(mid)
    begin.setHours(-12 * mergingPeriod)
    const end = new Date(mid)
    end.setHours(12 * mergingPeriod) // half the merging period
    return {time_ekv: time_ekv, begin: displayFn(begin), end: displayFn(end)} as const
}

/**
 * Returns the Correlation Aberration tuple with the lowest pred_ic for the
 * given time period (time_ekv).
 */
function getBestCA(o: ExtendedVideObjectV2, time_ekv: number) {
    const cs = o.correlations.slice()
    cs.sort((a, b) => a.pred_ic - b.pred_ic)

    for (const c of cs) {
        for (const a of c.aberrations) {
            if (a.time_ekv === time_ekv)
                return [c, a] as const
        }
    }
    return [undefined, undefined] as const
}

