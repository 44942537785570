import {Component, input} from '@angular/core'


@Component({
    selector: 'app-debug',
    standalone: true,
    imports: [],
    templateUrl: './debug.component.html',
    styleUrl: './debug.component.scss'
})
export class DebugComponent {
    readonly inline = input(false, {
        transform: (value: boolean | string) => typeof value === 'string' ? value === '' : value,
    })
}
