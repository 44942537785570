import {Component, computed, effect} from '@angular/core'
import {
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogRef,
    MatDialogTitle
} from '@angular/material/dialog'
import {toSignal} from "@angular/core/rxjs-interop"

import {VideDataService} from 'src/app/api/vide-data.service'
import {OutdatedStatisticsService} from "../../shared/outdated-statistics.service"
import {MatButton} from "@angular/material/button"

@Component({
    standalone: true,
    selector: 'app-outdated-statistics',
    templateUrl: './outdated-statistics.component.html',
    styleUrls: ['./outdated-statistics.component.scss'],
    imports: [
        MatDialogActions,
        MatDialogContent,
        MatDialogTitle,
        MatButton,
        MatDialogClose
    ]
})
export class OutdatedStatisticsComponent {

    readonly outdatedObjects =this.dataService.outdatedStatistics
    readonly maxNameLength = computed(() => {
        const objects = this.outdatedObjects()
        const maxNameLength = objects.reduce((previousValue, currentValue) => Math.max(previousValue, currentValue.name.length), -Infinity)
        if (isFinite(maxNameLength)) {
            return (maxNameLength + 1) + 'ch'
        }
        return
    })

    constructor(
        public dialogRef: MatDialogRef<OutdatedStatisticsComponent>,
        private dataService: VideDataService,
        private statistics: OutdatedStatisticsService,
    ) {
        effect(() => {
            if (this.outdatedObjects().length === 0) {
                console.debug(`outdated updated`)
                this.dialogRef.close()
            }
        })
    }

    async dispatchStatistics() {
        return this.statistics.updateOutdatedStatistics()
    }
}

