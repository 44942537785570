@if (figure(); as plot) {
  <plotly-plot [data]="plot.data" [layout]="plot.layout" [config]="plot.config" [useResizeHandler]="true"
  [style]="plotlyStyle()" (relayout)="onPlotlyRelayout($event)" [updateOnlyWithRevision]="true" [revision]="plot.revision" />
}


@if (objectsDiscarded(); as osWC) {
  @if (osWC.length > 0) {
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Unmapped objects
        </mat-panel-title>
        <mat-panel-description>
          {{ osWC.length }} objects without correlation or coordinates
        </mat-panel-description>
      </mat-expansion-panel-header>
      @for (o of osWC; track o.id) {
        <span>{{ o.name }}@if (!$last) {
          <span>, </span>
        }</span>
      }
    </mat-expansion-panel>
  }
}

<mat-expansion-panel class="vide-form-field" expanded>
  <mat-expansion-panel-header>
    <mat-panel-title>
      Options
    </mat-panel-title>
  </mat-expansion-panel-header>

  @if (plotFormGroup.controls; as cntrl){
    <div class="vide-columns-wide vide-form-field-row">

      <ng-select [items]="displayValues" [clearable]="false" [formControl]="cntrl.display">
        <ng-template ng-label-tmp let-item="item">
          Display: {{ item }}
        </ng-template>
      </ng-select>

      <ng-select [items]="timePeriods()" [clearable]="false" bindValue="time_ekv"
        [formControl]="cntrl.time_ekv">
        <ng-template ng-option-tmp let-tp="item">
          {{ tp.begin }}&mdash;{{ tp.end }}
        </ng-template>
        <ng-template ng-label-tmp let-tp="item">
          Time range: {{ tp.begin }}&mdash;{{ tp.end }}
        </ng-template>
      </ng-select>

      <ng-select [items]="deviationStatuses()" [clearable]="true" [formControl]="cntrl.markStatuses"
        [multiple]="true" placeholder="Mark deviation status" bindLabel="name">
      </ng-select>

      <ng-select [items]="objectsReferenced()" [clearable]="false" [formControl]="cntrl.correlationRef"
        [multiple]="false" placeholder="Correlation reference object" bindLabel="name">
        <ng-template ng-label-tmp let-item="item">
          Reference: {{ item.name }}
        </ng-template>
      </ng-select>

      <ng-select [items]="mapMarkers" [formControl]="cntrl.marker" [clearable]="false" bindLabel="name">
        <ng-template ng-label-tmp let-item="item">
          Markers: {{ item.name }}
        </ng-template>
      </ng-select>

      <ng-select [items]="MAP_BACKGROUNDS" [formControl]="cntrl.background" groupBy="names" [clearable]="false">
        <ng-template ng-optgroup-tmp let-item="item">
          <b> {{ item.title }}</b>
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
          {{ item.name ?? item }}
        </ng-template>
        <ng-template ng-label-tmp let-item="item">
          Background: {{ item.name ?? item }}
        </ng-template>
      </ng-select>

    </div>

    <div class="vide-columns-wide vide-form-field-row">
      <mat-slide-toggle [formControl]="cntrl.useFontSize">Set font size</mat-slide-toggle>
      <mat-slider min="6" max="40" discrete showTickMarks [style.width]="'50%'"
        [disabled]="!cntrl.useFontSize.value">
        <input [formControl]="cntrl.fontSize" matSliderThumb>
      </mat-slider>
    </div>

    <div class="vide-form-field-row">
      <mat-slide-toggle [formControl]="cntrl.showLabels">
        Show labels
      </mat-slide-toggle>
    </div>
  }

  <mat-action-row>
    <button mat-raised-button (click)="resetPlotOptions()" matTooltip="Reset options">
      <mat-icon aria-label="Reset options" fontIcon="restart_alt"></mat-icon>
    </button>
    @if (!popupRef()) {
      <button mat-raised-button (click)="selectMarkedObjects()" [disabled]="!canUseMarked"
        matTooltip="Select the currently marked objects, unselecting all others">
        Select marked objects
      </button>
    }
  </mat-action-row>
</mat-expansion-panel>

<!--<div class="debug">-->
<!--    &lt;!&ndash; otc: {{ ( objectsToConsider$ | async)?.length }} <br> &ndash;&gt;-->
<!--    &lt;!&ndash; {{objects$ | async | json}} <br> &ndash;&gt;-->
<!--    &lt;!&ndash; J: {{J$ | async | json}}<br> &ndash;&gt;-->
<!--    &lt;!&ndash; J: {{J$ | async | async|json}}<br> &ndash;&gt;-->
<!--    &lt;!&ndash; x: {{x$ | async | json}} <br> &ndash;&gt;-->
<!--    &lt;!&ndash; xy length: {{ (xy$ | async)?.length}} <br> &ndash;&gt;-->
<!--    &lt;!&ndash; time periods: {{timePeriods$ | async | json}} <br> &ndash;&gt;-->
<!--    &lt;!&ndash; control: {{plotFormGroup.value | json}} <br> &ndash;&gt;-->
<!--    &lt;!&ndash; options: {{selectedOptions$ | async | json}} <br> &ndash;&gt;-->
<!--</div>-->
