import {Routes} from '@angular/router'

import {canDeactivateGuard} from "./can-deactivate.guard"

const VIDE_ROUTES = {
    project: {
        _base: 'project',
        correlations: 'correlations',
        popupSelect: 'popup-select',
        objects: 'objects',
        measurements: 'measurements',
        import: 'import',
        batchPlot: 'batch-plot',
        fieldApp: 'field-app',
        fieldApp2: 'field-app2',
        groups: 'groups',
        events: 'events',
        object: {
            _base: 'object',
            triggers: 'triggers',
            edit: 'edit',
        }
    },
    about: 'about',
    preferences: 'preferences',
} as const

// TODO: make VIDE_ROUTES2 contain this info, and transform it to the Routes object!
export const oldRoutes: Routes = [
    {
        path: '',
        children: [
            {path: '', loadComponent: () => import('./welcome/welcome.component').then(m => m.WelcomeComponent)},
            {
                path: 'admin',
                children: [
                    {
                        path: 'licenses',
                        loadComponent: () => import('./admin/licenses/admin-licenses.component').then(m => m.AdminLicensesComponent),
                        canDeactivate: [canDeactivateGuard]
                    },
                    {
                        path: 'users',
                        loadComponent: () => import('./admin/admin-users/admin-users.component').then(m => m.AdminUsersComponent),
                        canDeactivate: [canDeactivateGuard]
                    },
                    {
                        path: 'economy',
                        loadComponent: () => import('./admin/fees/fees.component').then(m => m.FeesComponent),
                        canDeactivate: [canDeactivateGuard]
                    },
                    {
                        path: 'projects',
                        loadComponent: () => import('./admin/admin-projects/admin-projects.component').then(m => m.AdminProjectsComponent),
                        canDeactivate: [canDeactivateGuard]
                    },
                    {
                        path: 'failed-jobs',
                        loadComponent: () => import('./admin/failed-jobs/failed-jobs.component').then(m => m.FailedJobsComponent),
                        canDeactivate: [canDeactivateGuard]
                    },
                ],
            },
            {
                path: 'reset-password',
                loadComponent: () => import('./auth/reset-password/reset-password.component').then(m => m.ResetPasswordComponent)
            },
            {
                path: 'set-password',
                loadComponent: () => import('./auth/set-password/set-password.component').then(m => m.SetPasswordComponent)
            },
            {
                path: VIDE_ROUTES.preferences,
                loadComponent: () => import('./preferences/preferences.component').then(m => m.PreferencesComponent),
                canDeactivate: [canDeactivateGuard]
            },
            {
                path: VIDE_ROUTES.about,
                loadComponent: () => import('./about/about.component').then(m => m.AboutComponent)
            },
            {path: 'test', loadComponent: () => import('./test/test.component').then(m => m.TestComponent)},
            {
                path: VIDE_ROUTES.project._base + '/:pid',
                loadComponent: () => import('./project-container/project-container.component').then(m => m.ProjectContainerComponent),
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        loadComponent: () => import('./project/project.component').then(m => m.ProjectComponent),
                    },
                    {
                        path: VIDE_ROUTES.project.correlations,
                        loadComponent: () => import('./correlation-analysis/correlation-analysis.component').then(m => m.CorrelationAnalysisComponent)
                    },
                    {
                        path: VIDE_ROUTES.project.popupSelect,
                        loadComponent: () => import('./object-select/popup-select/popup-select.component').then(m => m.PopupSelectComponent),
                    },
                    {
                        path: VIDE_ROUTES.project.object._base + '/:oid',
                        children: [
                            {
                                path: VIDE_ROUTES.project.object.triggers,
                                loadComponent: () => import('./triggers/triggers.component').then(m => m.TriggersComponent),
                                canDeactivate: [canDeactivateGuard],
                            },
                            {
                                path: VIDE_ROUTES.project.object.edit,
                                loadComponent: () => import('./edit-object/edit-object.component').then(m => m.EditObjectComponent),
                                canDeactivate: [canDeactivateGuard],
                            }
                        ],
                    },
                    {
                        path: VIDE_ROUTES.project.batchPlot,
                        loadComponent: () => import('./plot/batch-plot/batch-plot.component').then(m => m.BatchPlotComponent)
                    },
                    {
                        path: VIDE_ROUTES.project.fieldApp,
                        loadComponent: () => import('./field/field.component').then(m => m.FieldComponent),
                        canDeactivate: [canDeactivateGuard],
                    },
                    {
                        path: VIDE_ROUTES.project.measurements,
                        loadComponent: () => import('./view-measurements/view-measurements.component').then(m => m.ViewMeasurementsComponent),
                        canDeactivate: [canDeactivateGuard]
                    },
                    {
                        path: VIDE_ROUTES.project.objects,
                        loadComponent: () => import('./view-objects/view-objects.component').then(m => m.ViewObjectsComponent),
                        canDeactivate: [canDeactivateGuard],
                    },
                    {
                        path: 'groups',
                        loadComponent: () => import('./edit-groups/edit-groups-container/edit-groups-container.component').then(m => m.EditGroupsContainerComponent),
                        children: [
                            {path: '', redirectTo: 'existing', pathMatch: 'full'},
                            {
                                path: 'existing',
                                loadComponent: () => import('./edit-groups/edit-groups-existing/edit-groups-existing.component').then(m => m.EditGroupsExistingComponent),
                                canDeactivate: [canDeactivateGuard]
                            },
                            {
                                path: 'names',
                                loadComponent: () => import('./edit-groups/edit-groups-names/edit-groups-names.component').then(m => m.EditGroupsNamesComponent),
                                canDeactivate: [canDeactivateGuard]
                            },
                            {
                                path: 'selected',
                                loadComponent: () => import('./edit-groups/edit-groups-objects/edit-groups-objects.component').then(m => m.EditGroupsObjectsComponent),
                                canDeactivate: [canDeactivateGuard]
                            },
                        ],
                    },

                    {
                        path: VIDE_ROUTES.project.import,
                        children: [
                            {
                                path: 'file',
                                loadComponent: () => import('./import/import-file/import-file.component').then(m => m.ImportFileComponent),
                                canDeactivate: [canDeactivateGuard]
                            },
                            {
                                path: 'diver',
                                loadComponent: () => import('./import/diver/diver.component').then(m => m.DiverComponent),
                                canDeactivate: [canDeactivateGuard]
                            },
                            {
                                path: 'shared2',
                                loadComponent: () => import('./import/shared-objects/shared-objects.component').then(m => m.SharedObjectsComponent),
                                canDeactivate: [canDeactivateGuard]
                            },
                            {
                                path: 'sgu',
                                loadComponent: () => import('./import/sgu/sgu.component').then(m => m.SguComponent),
                                canDeactivate: [canDeactivateGuard]
                            },
                            {
                                path: 'smhi',
                                loadComponent: () => import('./import/smhi/smhi.component').then(m => m.SmhiComponent),
                                canDeactivate: [canDeactivateGuard]
                            },
                            {
                                path: 'geoarkivet',
                                loadComponent: () => import('./import/geoarkivet/geoarkivet.component').then(m => m.GeoarkivetComponent),
                                canDeactivate: [canDeactivateGuard]
                            },
                            {
                                path: 'goteborg',
                                loadComponent: () => import('./import/goteborg/goteborg.component').then(m => m.GoteborgComponent),
                                canDeactivate: [canDeactivateGuard]
                            },
                            {
                                path: 'tcn',
                                loadComponent: () => import('./import/unoson/unoson.component').then(m => m.UnosonComponent),
                                canDeactivate: [canDeactivateGuard],
                            },
                            {
                                path: 'tcn/edit',
                                loadComponent: () => import('./import/unoson/tcn-edit-select/tcn-edit-select.component').then(m => m.TcnEditSelectComponent),
                            },

                        ],
                    },
                ]
            },

        ],
    },
    {
        path: '**',
        loadComponent: () => import('./page-not-found/page-not-found.component').then(m => m.PageNotFoundComponent)
    },
]
