{
  "name": "vide",
  "version": "1.54.2-14",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --configuration development",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "ng lint",
    "cxsd": "cxsd",
    "e2e": "ng e2e",
    "analyze": "ng build --configuration production --stats-json && webpack-bundle-analyzer ./dist/frontend/stats.json"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.11",
    "@angular/cdk": "^18.2.12",
    "@angular/common": "^18.2.11",
    "@angular/compiler": "^18.2.11",
    "@angular/core": "^18.2.11",
    "@angular/forms": "^18.2.11",
    "@angular/material": "^18.2.12",
    "@angular/platform-browser": "^18.2.11",
    "@angular/platform-browser-dynamic": "^18.2.11",
    "@angular/router": "^18.2.11",
    "@ng-select/ng-select": "^13.0",
    "@ng-web-apis/common": "^3.0.1",
    "@ng-web-apis/geolocation": "^3.0.1",
    "angular-plotly.js": "^6.0.0",
    "compare-versions": "^6.1.0",
    "geolib": "^3.3.4",
    "mapbox-gl": "^2.15.0",
    "observable-webworker": "^6.0.1",
    "plotly.js-dist-min": "^2.27.1",
    "proj4": "^2.9.0",
    "rxjs": "^7.8.0",
    "superstruct": "^2.0.2",
    "tslib": "^2.3.0",
    "webgl-detector": "^1.1.11",
    "whatwg-mimetype": "^4.0.0",
    "zipcelx": "^1.6.2",
    "zone.js": "^0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.11",
    "@angular-eslint/builder": "18.4.0",
    "@angular-eslint/eslint-plugin": "18.4.0",
    "@angular-eslint/eslint-plugin-template": "18.4.0",
    "@angular-eslint/schematics": "18.4.0",
    "@angular-eslint/template-parser": "18.4.0",
    "@angular/cli": "^18.2.11",
    "@angular/compiler-cli": "^18.2.11",
    "@puppeteer/ng-schematics": "^0.6.0",
    "@types/jasmine": "~5.1.0",
    "@types/mapbox-gl": "^2.7.15",
    "@types/plotly.js": "^2.12.18",
    "@types/plotly.js-dist-min": "^2.3.1",
    "@types/proj4": "^2.5.2",
    "@types/regression": "^2.0.2",
    "@types/whatwg-mimetype": "^3.0.2",
    "@types/zipcelx": "^1.5.0",
    "@typescript-eslint/eslint-plugin": "^7.2.0",
    "@typescript-eslint/parser": "^7.2.0",
    "eslint": "^8.57.0",
    "jasmine": "5.1.0",
    "jasmine-core": "~5.1.0",
    "karma": "^6.4.1",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "^5.4",
    "webpack-bundle-analyzer": "^4.10.2"
  }
}
