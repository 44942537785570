<mat-card>
    <mat-card-header>
        <mat-card-title> Stockholms stad Geoarkivet</mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <p> Add stations to the project. It will be shared from the Geoarkivet project. New water level measurements
            will be added nightly. </p>
        <p> Data provided from <a href="https://etjanster.stockholm.se/geoarkivet/">Geoarkviet</a>. </p>

        <mat-menu #clickMenu>
            <ng-template matMenuContent let-element="element">
                @if (element.tainted) {
                    <button mat-menu-item disableRipple>{{ getName(element) }} in progress</button>
                } @else if (element.external) {
                    <button mat-menu-item (click)="remove(element)">Remove {{ getName(element) }}</button>
                } @else {
                    <button mat-menu-item (click)="add(element)">Add {{ getName(element) }}</button>
                }
            </ng-template>
        </mat-menu>

        <mat-tab-group>
            <mat-tab label="List">
                <table mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button
                                [disabled]="element.tainted"
                                [matMenuTriggerFor]="clickMenu"
                                [matMenuTriggerData]="{element}"
                                mat-icon-button>
                                <mat-icon>more_horiz</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="from">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>From</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.feature.properties.ProtocolData_ObservationDate_Min }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="investigation">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Investigation</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.feature.properties.GroundInvestigationName }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="to">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>To</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.feature.properties.ProtocolData_ObservationDate_Max }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="imported">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Imported</th>
                        <td mat-cell *matCellDef="let element">
                            @if (element.external) {
                                <mat-icon>check</mat-icon>
                            }
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                        <td mat-cell *matCellDef="let element">
                            {{ getName(element) }}
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </mat-tab>

            <mat-tab label="Map">
                <ng-template matTabContent>
                    @if (figure(); as figure) {
                        <plotly-plot
                            [data]="figure.data"
                            [layout]="figure.layout"
                            [config]="figure.config"
                            [style]="plotlyStyle"
                            [useResizeHandler]="true"
                            (plotlyClick)="plotlyClick($event)"
                            (relayout)="onPlotlyRelayout($event)"
                        />
                    } @else {
                        <div [style.height]="plotlyStyle.height" class="plot-spinner">
                            <mat-spinner mode="indeterminate"/>
                        </div>
                    }
                </ng-template>
            </mat-tab>
        </mat-tab-group>

    </mat-card-content>
</mat-card>

<mat-card>
    @if (httpExtern()) {
        <mat-progress-bar mode="buffer"/>
    }
    @if (httpStatus.vide.hasValue()) {
        <mat-progress-bar mode="indeterminate"/>
    }
    <app-log-list [container]="logs"/>
</mat-card>
