import {Component, input} from '@angular/core'
import {MatButtonModule} from "@angular/material/button"
import {MatDialog} from "@angular/material/dialog"
import {MatIconModule} from "@angular/material/icon"
import {MatStepperModule} from "@angular/material/stepper"
import {MatTableModule} from "@angular/material/table"
import {toObservable, toSignal} from "@angular/core/rxjs-interop"

import {combineLatest, map, switchMap} from "rxjs"

import {DiverInstance, Measurement} from "../../../api/api-types"
import {DiverService} from "../diver.service"
import {InputComponent} from "../../../forms/input/input.component"
import {SlimObject} from "../diver.component"
import {VideDataService} from "../../../api/vide-data.service"
import {ConfirmDialogComponent} from "../../../dialogs/confirm-dialog/confirm-dialog.component"
import {DatePipe, JsonPipe} from "@angular/common"
import {DATETIME_FORMAT} from "../../../../constants"

@Component({
    selector: 'app-existing-instances',
    standalone: true,
    imports: [
        InputComponent,

        MatButtonModule,
        MatIconModule,
        MatStepperModule,
        MatTableModule,
        DatePipe,
        JsonPipe,
    ],
    templateUrl: './existing-instances.component.html',
    styleUrl: './existing-instances.component.scss'
})
export class ExistingInstancesComponent {
    readonly diverObject = input.required<SlimObject>()
    readonly uncompensatedMeasurements = toSignal(combineLatest([
        this.dataService.projectNotNull$,
        toObservable(this.diverObject),
        this.dataService.measureTypeDiverUncompensated$,
    ]).pipe(
        switchMap(([project, object, mt]) =>
            this.dataService.getMeasurements(project, object, mt)),
        map(mr => mr.measurements),
    ))
    readonly unreferencedMeasurements = toSignal(combineLatest([
        this.dataService.projectNotNull$,
        toObservable(this.diverObject),
        this.dataService.measureTypeDiverUnreferenced$,
    ]).pipe(
        switchMap(([project, object, mt]) =>
            this.dataService.getMeasurements(project, object, mt)),
        map(mr => mr.measurements),
    ))

    readonly annotations$ = combineLatest([
        this.dataService.projectNotNull$,
        toObservable(this.diverObject)
    ]).pipe(
        switchMap(([p, o]) => {
            return this.dataService.getDiverAnnotations(p, o)
        }),
    )

    displayedColumns = [
        'comment',
        'first_date',
        // "id",
        // "object_id",
        // "reference_measurement_id",
        "reference",
        'serial_number',
        'created_at',
        'updated_at',
        'delete',
    ]

    constructor(
        private readonly dataService: VideDataService,
        private readonly diverService: DiverService,
        private readonly dialog: MatDialog,
    ) {
    }

    delete(element: DiverInstance) {
        this.diverService.deleteInstance(element)
    }


    async deleteMeasurements(object: SlimObject, measurements: readonly Measurement[]) {
        this.dialog.open<
            ConfirmDialogComponent,
            ConfirmDialogComponent['data'],
            ConfirmDialogComponent['response']
        >(ConfirmDialogComponent, {
            data: {
                text: `Really delete ${measurements.length} measurements? This cannot be undone.`,
                header: `Delete measurements
                         from ${object.name}`,
                positive_text: 'Delete',
                negative_text: 'Cancel',
            }
        }).afterClosed().subscribe(x => {
            if (x) this.diverService.deleteMeasurements(object, measurements)
        })
    }

    protected readonly DATETIME_FORMAT = DATETIME_FORMAT
}
