import {Component, input} from '@angular/core'
import {MatIconModule} from "@angular/material/icon"

@Component({
    imports: [
        MatIconModule,
    ],
    selector: 'app-menu-selection',
    styleUrl: './menu-selection.component.scss',
    templateUrl: './menu-selection.component.html'
})
export class MenuSelectionComponent {
    steps = input.required<readonly string[]>()
}
