import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms'

export class FileValidator {
    /**
     * Function to control content of files
     *
     * @param bytes max number of bytes allowed
     *
     */
    static maxContentSize(bytes: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const actualSize = control && control.value instanceof FileList
                ? calculateSize(control.value)
                : 0
            return (actualSize > bytes)
                ? {maxContentSize: {actualSize: actualSize, maxSize: bytes}}
                : null
        }
    }

    static nrFiles(nr: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const actualNr = control.value?.length ?? 0
            return control && (control.value instanceof FileList) && actualNr !== nr
                ? {nrFiles: {required: nr, actual: actualNr}}
                : null
        }
    }

}

function calculateSize(list: FileList) {
    let size = 0
    for (let index = 0; index < list.length; index++) {
        const file = list.item(index)
        if (file) {
            size += file.size
        }
    }
    return size
}
