import {Component} from '@angular/core'
import {MatListModule} from "@angular/material/list"
import {RouterLink} from "@angular/router"
import {MatCardModule} from "@angular/material/card"

import {PublicDataComponent} from "../public-data/public-data.component"

@Component({
    selector: 'app-help',
    standalone: true,
    imports: [
        PublicDataComponent,
        MatListModule,
        RouterLink,

        MatCardModule,
    ],
    templateUrl: './help.component.html',
    styleUrl: './help.component.scss'
})
export class HelpComponent {

}
