import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {FormControl} from "@angular/forms"

import {catchError, map, of} from 'rxjs'
import * as S from "superstruct"

import {getHttpErrorMessage} from '../shared/vide-helper'
import {Mutable, Nullable, omit, OptionsFormat, pick, PlotOption, RequireKeys,} from '../vide-types'
import {ApiHelper} from './api-helper'
import {
    Aquifer,
    BackendVersion,
    CoordinateDeterminationMethod,
    CoordinateSystem,
    DatabaseUtilities,
    DataStatus,
    DiverInstance,
    ErrorCode,
    ExternalObject,
    FilterType,
    GoteborgStation,
    Group,
    GroupNoOids,
    ImportParameter,
    KnownUser,
    LentObject,
    License,
    MeasureType,
    ObjectFile,
    ObjectStatus,
    ObjectType,
    OwnedUser,
    PipeMaterial,
    Position,
    Project,
    ProjectFull,
    ProjectUser,
    ProjectWithLimit,
    RawExtendedObject,
    RawMeasurements,
    RawObject,
    RawPlotOptions,
    RawStatistics,
    RawTrigger,
    SelfUser,
    SettlementPosition,
    TipType,
    Trigger,
    UnosonLocation,
    User,
    UserOptions,
    UserProject,
    VideObject,
    VideObjectBasicType
} from "./api-types"
import {ProjectAbility} from "../ability"

type ExternalObjectType = 'sgu' | 'smhi' | 'geoarkivet' | 'goteborg' | 'unoson'

type ErrorCodeOrResultingValue = {
    error_code: ErrorCode,
    resulting_value?: number | null
} | {
    error_code?: ErrorCode | null,
    resulting_value: number
}

/** Responsible for the api calls.  Should only be used in the basic DataService service. */
@Injectable({
    providedIn: 'root',
})
export class ApiCallService {
    private readonly apiHelper: ApiHelper
    private readonly noCacheHeader = new HttpHeaders({'Cache-Control': ['no-cache']})
    public readonly validateHttpRequestControl: FormControl<boolean>

    constructor(
        http: HttpClient,
    ) {
        this.apiHelper = new ApiHelper(http)
        this.validateHttpRequestControl = this.apiHelper.validateHttpRequestControl
    }

    getUtilities() {
        return this.apiHelper.getWrapped(DatabaseUtilities, 'utilities')
    }

    getVersion() {
        return this.apiHelper.getWrapped(BackendVersion, 'version')
    }

    //////////////////////////////////////////////////////////////////////////////
    // Groups
    //////////////////////////////////////////////////////////////////////////////
    getGroups(project: Project) {
        return this.apiHelper.getWrapped(S.array(Group), `projects/${project.id}/groups`)
    }

    updateGroup(row: RequireKeys<Partial<Group & { order: boolean }>, 'id'>) {
        const x = {
            name: row.name,
            global: row.global,
            object_ids: row.object_ids,
            order: row.order,
        }
        return this.apiHelper.patch(`groups/${row.id}`, x)
    }

    deleteGroup(group: Group) {
        return this.apiHelper.delete(`groups/${group.id}`, undefined)
    }

    // objectIds cannot easily be taken from Object[], as Group.object_ids is also used...
    createGroup(project: Project, name: string, objectIds: number[], global = false) {
        if (objectIds.length === 0) throw new Error("Cannot create group with no objects")
        console.debug('Create group ', name)
        const x = {name, oids: objectIds, global}
        return this.apiHelper.postWrapped(GroupNoOids, `projects/${project.id}/groups`, x)
    }

    //////////////////////////////////////////////////////////////////////////////
    // Project users
    //////////////////////////////////////////////////////////////////////////////

    getProjectUsers(p: Project) {
        return this.apiHelper.getWrapped(S.array(ProjectUser), `projects/${p.id}/users`)
    }

    setProjectUser(p: Project, u: { id: number } & Pick<ProjectUser, 'comment' | 'ability'>) {
        const body = {comment: u.comment, ability: u.ability}
        return this.apiHelper.put(`projects/${p.id}/users/${u.id}`, body)
    }

    newProjectUser(p: Project, u: { email: string, } & Pick<ProjectUser, 'comment' | 'ability'>) {
        return this.apiHelper.postNoContent(`projects/${p.id}/users`, {
            email: u.email,
            comment: u.comment,
            ability: u.ability
        })
    }

    removeProjectUser(p: Project, u: { id: number, }) {
        return this.apiHelper.delete(`projects/${p.id}/users/${u.id}`)
    }

    //////////////////////////////////////////////////////////////////////////////
    // Projects
    //////////////////////////////////////////////////////////////////////////////
    getUsersProjects() {
        const endpoint = 'projects'
        const struct = S.array(UserProject)
        return this.apiHelper.getWrapped(struct, endpoint)
    }

    getOwnedProjects() {
        const endpoint = `admin/projects`
        const struct = S.array(Project)
        return this.apiHelper.getWrapped(struct, endpoint)
    }

    createProject(project: { license: License | null } & Pick<ProjectFull,
        | 'comment'
        | 'coordinate_system'
        | 'height_system'
        | 'name'
        // | 'license'
        // | 'standard_aquifer'
        // | 'standard_measure_type'
        // | 'standard_object_type'
    >) {
        const body = {
            comment: project.comment,
            // coordinate_system_id: project.coordinate_system?.id,
            height_system_id: project.height_system?.id,
            name: project.name,
            default_epsg: project.coordinate_system.id,
            license_id: project.license?.id,
            // standard_aquifer_id: project.standard_aquifer?.id ?? null,
            // standard_measure_type_id: project.standard_measure_type?.id ?? null,
            // standard_object_type_id: project.standard_object_type?.id ?? null,
        }
        return this.apiHelper.postWrapped(Project, `projects`, body)
    }

    updateProject(row: RequireKeys<Partial<Pick<ProjectFull,
        | 'comment'
        | 'coordinate_system'
        | 'height_system'
        | 'id'
        | 'name'
        | 'share_type'
    >>, 'id'>) {
        const body = {
            name: row.name,
            comment: row.comment,
            default_epsg: row.coordinate_system?.id,
            share_type: row.share_type,
            // coordinate_system_id: row.coordinate_system?.id,
            height_system_id: row.height_system?.id,
            // standard_aquifer_id: row.standard_aquifer?.id ?? null,
            // standard_measure_type_id: row.standard_measure_type?.id ?? null,
            // standard_object_type_id: row.standard_object_type?.id ?? null,
        }
        return this.apiHelper.patch(`projects/${row.id}`, body)
    }

    deleteProject(project: Project) {
        return this.apiHelper.delete(`projects/${project.id}`)
    }

    //////////////////////////////////////////////////////////////////////////////
    // File import
    //////////////////////////////////////////////////////////////////////////////

    /**
     * File upload. This request reports events to monitor the upload. It
     * does not catch any errors.
     *
     * Not that the returned Observable must be subscribed to.
     *
     */
    importFile(
        project: Project,
        data: {
            coordinate_system: CoordinateSystem,
            file: File,
            type: string,
            allowOldData: boolean,
            allowUpdate: boolean,
            markAsOriginal: boolean,
        },
    ) {
        const x = new FormData()
        x.append('epsg', data.coordinate_system.id.toString())
        x.append('file', data.file)
        x.append('type', data.type)
        x.append('allow_old_data', data.allowOldData ? '1' : '0')
        x.append('allow_update', data.allowUpdate ? '1' : '0')
        x.append('original_information', data.markAsOriginal ? '1' : '0')

        return this.apiHelper.postWithProgress(`projects/${project.id}/import`, x)
    }

    //////////////////////////////////////////////////////////////////////////////
    // Statistics
    //////////////////////////////////////////////////////////////////////////////
    getStatistics(projectId: number) {
        return this.apiHelper.getWrapped(S.array(RawStatistics), `projects/${projectId}/statistics`)
    }

    getOutdatedStatisticObjectIds(project: Project, refetch = false) {
        return this.apiHelper.getWrapped(
            S.object({
                object_ids: S.array(S.integer())
            }),
            `projects/${project.id}/outdated`,
            undefined,
            refetch ? this.noCacheHeader : undefined,
        )
    }

    updateStatisticsForObject(objectId: number) {
        return this.apiHelper.patch(`objects/${objectId}/statistics`)
    }

    updateStatisticsProjectId(projectId: number) {
        return this.apiHelper.patch(`projects/${projectId}/statistics`)
    }

    //////////////////////////////////////////////////////////////////////////////
    // Objects
    //////////////////////////////////////////////////////////////////////////////
    getObjects(projectId: number) {
        return this.apiHelper.getWrapped(S.array(RawObject), `projects/${projectId}/objects`)
    }

    getLentObjects(project: Project) {
        return this.apiHelper.getWrapped(S.array(LentObject), `admin/projects/${project.id}/lent-objects`)
    }

    getObject(project: Project, objectId: number) {
        // return this.apiHelper.getWrappedNoValidate(RawExtendedObject2, `objects/${objectId}`)
        return this.apiHelper.getWrapped(RawExtendedObject, `projects/${project.id}/objects/${objectId}`)
        // return this.apiHelper.getWrapped(`objects/${objectId}`).pipe(map(x => S.create(x, RawExtendedObject2)))
    }

    private readonly objectNoSetAttributes = [
        'last_changed_by',
        'positionM',
        'project_id',
        'readonly',
        'statistics',
        'time_changed',
        'time_created',
    ] as const

    updateObject(
        project: Project,
        row: Readonly<RequireKeys<
            Partial<Omit<VideObject, typeof this.objectNoSetAttributes[number]>>
            , 'id'>>
    ) {
        // TODO: refactor as createObject
        const data = omit(this.handleObjectIdAttributes(row), ...this.objectNoSetAttributes)
        return this.apiHelper.patch(`projects/${project.id}/objects/${(row.id)}`, data)
    }

    private readonly objectPrimitiveValueAttributes = [
        'name', 'alias', 'bottom_level', 'comment', 'coordinate_quality', 'correlation_base', 'correlation_reference',
        'direction', 'directions', 'filter_length', 'ground_level', 'inclination', 'level_quality', 'measurableDepth',
        'not_reference_from', 'original_information', 'reference_level', 'source', 'well_dimension'
    ] as const

    createObject(
        project: Project,
        data2: {
            // Required
            name: VideObject['name']
            object_status: ObjectStatus,
            object_type: ObjectType,
        } & Partial<{
            // Optional primitives
            alias: string | null,
            aquifer: Aquifer | null,
            bottom_level: number | null,
            comment: string | null,
            coordinate_quality: number | null,
            correlation_base: boolean,
            correlation_reference: boolean,
            direction: number | null,
            directions: string | null,
            filter_length: number | null,
            filter_type: FilterType | null,
            ground_level: number | null,
            inclination: number | null,
            level_quality: number | null,
            measurableDepth: number | null,
            not_reference_from: string | null,
            original_information: boolean,
            reference_level: number | null,
            source: string | null,
            well_dimension: number | null,
            // Optional objects
            coordinate_determination_method: CoordinateDeterminationMethod | null,
            pipe_material: PipeMaterial | null,
            position: Position | null,
            settlement_position: SettlementPosition | null,
            tip_type: TipType | null,
        }>
    ) {
        const primitiveValues = pick(data2, ...this.objectPrimitiveValueAttributes)
        const body = {
            // Object attributes
            // Required
            object_status_id: data2.object_status.id,
            object_type_id: data2.object_type.id,
            //Optional
            aquifer_id: data2.aquifer?.id,
            coordinate_determination_method_id: data2.coordinate_determination_method?.id,
            filter_type_id: data2.filter_type?.id,
            pipe_material_id: data2.pipe_material?.id,
            position: data2.position ? {lat: data2.position.lat, lon: data2.position.lon,} : undefined,
            settlement_position_id: data2.settlement_position?.id,
            tip_type_id: data2.tip_type?.id,
            // Primitive value attributes, required and optional
            ...primitiveValues,
        }
        return this.apiHelper.postWrapped(RawObject, `projects/${project.id}/objects`, body)
    }

    deleteObject(project: Project, object: VideObject) {
        return this.apiHelper.delete(`projects/${project.id}/objects/${object.id}`)
    }

    private handleObjectIdAttributes(row: Readonly<Partial<VideObject>>) {
        const objectAttributes = [
            'aquifer',
            'coordinate_determination_method',
            // 'coordinate_system',
            'filter_type',
            'pipe_material',
            'settlement_position',
            'tip_type',
            'object_status',
            'object_type',
        ] as const
        const inputCopy: Partial<Mutable<typeof row>>
            = {...row}

        // Get id from object attributes and delete from inputCopy
        const idAttributes: { [index: string]: any } = {}
        for (const att of objectAttributes) {
            const att2 = `${att}_id`
            const value = row[att]
            // get the id if value is defined, keep null, keep undefined
            if (value !== undefined) {
                idAttributes[att2] = value ? value.id : value
            }
            delete inputCopy[att]
        }

        // map epsg to the code
        const ret = {
            ...inputCopy,
            ...idAttributes,
        }

        return ret
    }

    //////////////////////////////////////////////////////////////////////////////
    // Object files
    //////////////////////////////////////////////////////////////////////////////

    getObjectFiles(project: Project, object: VideObject) {
        return this.apiHelper.getWrapped(S.array(ObjectFile), `projects/${project.id}/objects/${object.id}/files`)
    }

    createFile(project: Project, object: VideObject, file: File) {
        const x = new FormData()
        x.append('file', file)
        return this.apiHelper.postWrapped(ObjectFile, `projects/${project.id}/objects/${object.id}/files`, x)
    }

    getFileUrl(project: Project, file: ObjectFile) {
        return this.apiHelper.getApiUrl(`projects/${project.id}/objects/${file.object_id}/files/${file.id}`)
    }

    deleteFile(project: Project, file: ObjectFile) {
        return this.apiHelper.delete(`projects/${project.id}/objects/${file.object_id}/files/${file.id}`)
    }

    //////////////////////////////////////////////////////////////////////////////
    // Import stuff
    //////////////////////////////////////////////////////////////////////////////
    getImportInfo() {
        return this.apiHelper.getWrapped(ImportParameter, 'import-parameters')
    }


    getImportGoteborgStations() {
        return this.apiHelper.getWrapped(S.array(GoteborgStation), 'import-parameters/goteborg')
    }

    getImportUnosonLocations(project: Project, name: string) {
        const params = new HttpParams().append('name', name)
        return this.apiHelper.getWrapped(S.array(UnosonLocation), `projects/${project.id}/unoson`, params)
    }

    // //////////////////////////////////////////////////////////////////////////////
    // // Unoson sources
    // //////////////////////////////////////////////////////////////////////////////
    // createUnoson(
    //     project: Project,
    //     object: Pick<VideObject,'id'>,
    //     locationId: string,
    //     connectionName: string,
    // ) {
    //     return this.apiHelper.postWrapped(ExternalObject, `projects/${project.id}/objects/${object.id}/unoson`, {
    //         locationId,
    //         connectionName
    //     })
    // }

    //////////////////////////////////////////////////////////////////////////////
    // External sources
    //////////////////////////////////////////////////////////////////////////////
    getExternalObjects(
        project: Project,
        type: ExternalObjectType,
        connectionName: string | undefined = undefined
    ) {
        let params = new HttpParams().append('type', type)
        if (connectionName) {
            params = params.append('connectionName', connectionName)
        }
        return this.apiHelper.getWrapped(S.array(ExternalObject), `projects/${project.id}/external_sources`, params)
    }

    createExternalObject(
        project: Project,
        type: ExternalObjectType,
        properties: {},
    ) {
        const body = {
            type,
            properties,
        }
        return this.apiHelper.postWrapped(ExternalObject, `projects/${project.id}/external_sources`, body)
    }

    deleteExternalSource(project: Project, x: ExternalObject) {
        return this.apiHelper.delete(`projects/${project.id}/external_sources/${x.id}`)
    }

    //////////////////////////////////////////////////////////////////////////////
    // Triggers
    //////////////////////////////////////////////////////////////////////////////

    getTriggers(project: Project, object: VideObject) {
        return this.apiHelper.getWrapped(S.array(RawTrigger), `projects/${project.id}/objects/${object.id}/triggers`)
    }

    private readonly triggerProperties = [
        'active',
        'comment',
        'description',
        'limit',
        'recipients',
        'type',
        'unchecked',
    ] as const

    createTrigger(project: Project, object: VideObject, measureType: MeasureType, x: Pick<Trigger, typeof this.triggerProperties[number]>) {
        const body = {
            ...pick(x, 'active', 'comment', 'limit', 'unchecked', 'description', 'recipients', 'type'),
            measure_type_id: measureType.id,
        }
        return this.apiHelper.postWrapped(RawTrigger, `projects/${project.id}/objects/${object.id}/triggers`, body)
    }

    updateTrigger(project: Project, object: VideObject, trigger: Trigger, x: Partial<Trigger>) {
        const body = {
            ...pick(x, ...this.triggerProperties)
        }
        return this.apiHelper.patch(`projects/${project.id}/objects/${object.id}/triggers/${trigger.id}`, body)
    }

    deleteTrigger(project: Project, object: VideObject, trigger: Trigger) {
        return this.apiHelper.delete(`projects/${project.id}/objects/${object.id}/triggers/${trigger.id}`)
    }

    //////////////////////////////////////////////////////////////////////////////
    // Admin Users
    //////////////////////////////////////////////////////////////////////////////

    /** User belonging to license owned by you */
    getOwnedUsers() {
        return this.apiHelper.getWrapped(S.array(OwnedUser), 'admin/users')
    }

    /** All users known, by owning project or being project admin or owning users */
    getKnownUsers() {
        return this.apiHelper.getWrapped(S.array(KnownUser), 'admin/known-users')
    }

    /**
     * Update user records, allowed properties depend on the current user's permissions.
     */
    updateUser(row: {
        id: number,
        comment: string | null,
        name: string | null,
        ability: ProjectAbility | null,
        // options: UserOptions | null,
    }) {
        return this.apiHelper.patch(`admin/users/${row.id}`, {
            name: row.name,
            comment: row.comment,
            ability: row.ability,
            // options: JSON.stringify(row.options)
        })
    }

    deleteUser(user: User) {
        return this.apiHelper.delete(`admin/users/${user.id}`)
    }

    createUser(row: {
        email: string,
        name: string,
        comment?: string | null,
        license: License,
        ability: ProjectAbility | null,
    }) {
        const body = {
            email: row.email,
            name: row.name,
            comment: row.comment,
            license_id: row.license.id,
            ability: row.ability,
        }
        return this.apiHelper.postWrapped(User, 'admin/users', body)
    }

    //////////////////////////////////////////////////////////////////////////////
    // Self users
    //////////////////////////////////////////////////////////////////////////////
    /**
     * Get current user, or null if not logged in. Does not throw error on e.g., 401.
     */
    getUser() {
        return this.apiHelper.getWrapped(SelfUser, 'user').pipe(
            // map(x => S.create(x, SelfUser2)),
            catchError((err, _caught) => {
                console.warn(getHttpErrorMessage(err))
                console.error(err)
                return of(null)
            }),
        )
    }

    loginUser(credentials: { email: string; password: string; remember: boolean }) {
        return this.apiHelper.postNoContent('login', credentials)
    }

    logoutUser() {
        return this.apiHelper.postNoContent('logout')
    }

    forgotPassword(email: string) {
        return this.apiHelper.postNoContent('forgot-password', {email})
    }

    resetPassword(credentials: {
        email: string
        password: string
        password_confirmation: string
        token: string
    }) {
        return this.apiHelper.postNoContent('reset-password', credentials)
    }

    updatePassword(credentials: {
        current_password: string
        password: string
        password_confirmation: string
    }) {
        return this.apiHelper.postNoContent('update-password', credentials)
    }

    updateSelfName(row: {
        name: string | null,
    }) {
        return this.apiHelper.patch(`user`, {
            name: row.name,
        })
    }

    /**
     * Update user options.
     */
    updateSelfOptions(options: Partial<Nullable<UserOptions>> | null) {
        console.warn(options)
        return this.apiHelper.put(`options`, {
            options: JSON.stringify(options)
        })
    }

    /**
     * Get user options.
     */
    getSelfOptions() {
        return this.apiHelper.getWrapped(S.nullable(S.string()), `options`,).pipe(
            map(s => {
                if (s) {
                    const input = JSON.parse(s)
                    for (const key in input) {
                        if (input[key] === null) {
                            delete input[key]
                        }
                    }
                    const [error, value] = S.validate(input, UserOptions)
                    if (error) {
                        console.warn("Error parsing user options ", input, error)
                        console.warn("resetting user options")
                        this.updateSelfOptions(null).subscribe(x => {
                            console.log(x)
                        })
                        return null
                    } else {
                        return value
                    }
                } else {
                    return null
                }
            })
        )
    }


    //////////////////////////////////////////////////////////////////////////////
    // Plot options for user
    //////////////////////////////////////////////////////////////////////////////

    savePlotOptions(value: { name: string, options: OptionsFormat }) {
        const body = {name: value.name, options: JSON.stringify(value.options)}
        return this.apiHelper.postWrapped(RawPlotOptions, 'plot-options', body)
        // return this.apiHelper.postWrapped<RawPlotOptions>('plot-options', body)
    }

    updatePlotOptions(row: { id: number, options: PlotOption['options'] }) {
        const body = {options: JSON.stringify(row.options)}
        return this.apiHelper.patch(`plot-options/${row.id}`, body)
    }

    deletePlotOptions(id: number) {
        return this.apiHelper.delete(`plot-options/${id}`)
    }

    getPlotOptions() {
        return this.apiHelper.getWrapped(S.array(RawPlotOptions), 'plot-options')
        // .pipe(            map(response => S.create(response, S.array(RawPlotOptions2)))        )
        // return this.apiHelper.getWrapped<RawPlotOptions[]>('plot-options')
    }

    //////////////////////////////////////////////////////////////////////////////
    // Licenses
    //////////////////////////////////////////////////////////////////////////////
    getLicenses() {
        return this.apiHelper.getWrapped(S.array(License), 'licenses')
    }

    createLicense(data: {
        capacity: { read: number, measure: number, write: number, admin: number },
        comment: string | null,
        name: string,
        ownerEmail: null | string,
        unosonCredentials: readonly {
            client_id: string,
            client_secret: string;
            name: string,
            password: string,
            username: string,
        }[] | undefined
    }) {
        let body: any = {
            comment: data.comment,
            name: data.name,
            nr_admin: data.capacity.admin,
            nr_measure: data.capacity.measure,
            nr_read: data.capacity.read,
            nr_write: data.capacity.write,
            owner: data.ownerEmail,
            unoson_credentials: data.unosonCredentials,
        }
        return this.apiHelper.postWrapped(License, 'licenses', body)
    }

    updateLicense(l: License, data: {
        capacity: { read: number, measure: number, write: number, admin: number },
        comment: string | null,
        name: string,
        ownerEmail: null | string,
        unosonCredentials: readonly {
            client_id: string,
            client_secret: string;
            name: string,
            password: string,
            username: string,
        }[] | undefined,
    }) {
        const body = {
            nr_read: data.capacity.read,
            nr_measure: data.capacity.measure,
            nr_write: data.capacity.write,
            nr_admin: data.capacity.admin,
            comment: data.comment,
            name: data.name,
            owner: data.ownerEmail,
            unoson_credentials: data.unosonCredentials,
        }
        return this.apiHelper.patch(`licenses/${l.id}`, body)
    }


    //////////////////////////////////////////////////////////////////////////////
    // Measurements
    //////////////////////////////////////////////////////////////////////////////

    getMeasurementsCsv(project: ProjectWithLimit, object: Pick<VideObject, 'id'>, measureType: MeasureType) {
        // getMeasurementsCsv(project: number, object: number, measureType: number) {
        let params = new HttpParams().append('type', measureType.id)
        if (project.dateLimit) {
            params = params.append('from', project.dateLimit)
        }

        return this.apiHelper.getCSV(`projects/${project.id}/objects/${object.id}/measurements`, params).pipe(
        )
    }

    getMeasurementsJson(project: ProjectWithLimit, object: VideObject, measureType: MeasureType) {
        let params = new HttpParams().append('type', measureType.id)
        if (project.dateLimit) {
            params = params.append('from', project.dateLimit)
        }
        return this.apiHelper.getWrapped(S.object({
            measure_type: MeasureType,
            measurements: S.array(RawMeasurements)
        }), `projects/${project.id}/objects/${object.id}/measurements`, params)
    }

    getMeasurement(project: Project, measurementId: number) {
        return this.apiHelper.getWrapped(
            S.assign(RawMeasurements, S.object({object: RawObject})), `projects/${project.id}/measurements/${measurementId}`)
    }

    createFieldMeasurement(project: Project, object: VideObject,
                           row: {
                               comment: string | null
                               // data_status: DataStatus | null
                               error_code: ErrorCode | null
                               measure_type: MeasureType
                               measured_value: number | null
                               measuretime: string
                               resulting_value: number | null
                           }) {
        const x = {
            comment: row.comment,
            // data_status_id: row.data_status?.id ?? null,
            error_code_id: row.error_code?.id ?? null,
            measure_type_id: row.measure_type.id,
            measured_value: row.measured_value,
            measuretime: row.measuretime,
            resulting_value: row.resulting_value,
        }
        return this.apiHelper.postWrapped(RawMeasurements, `projects/${project.id}/objects/${object.id}/field-measurements`, x)
    }

    createMeasurements(project: Project, object: Pick<VideObject, 'id'>, rows: readonly {
        comment?: string | null
        dataStatus?: DataStatus | null
        errorCode?: ErrorCode | null
        measureType: MeasureType
        measuredValue?: number | null
        measuretime: string
        resultingValue?: number | null
        diverInstallation?: DiverInstance
    }[], update = false) {
        const measurements = rows.map(m => ({
            comment: m.comment,
            data_status_id: m.dataStatus?.id,
            error_code_id: m.errorCode?.id,
            measure_type_id: m.measureType.id,
            measured_value: m.measuredValue,
            measuretime: m.measuretime,
            resulting_value: m.resultingValue,
            diver_installation_id: m.diverInstallation?.id
        }))
        const body = {update, measurements}
        return this.apiHelper.postNoContent(`projects/${project.id}/objects/${object.id}/measurements`, body)
    }

    // createMeasurementsOLD(project: Project,
    //                       args: {
    //                           update: boolean,
    //                           measurements: readonly ({
    //                               object: Pick<VideObject, 'id'>,
    //                               comment?: string | null
    //                               dataStatus: DataStatus | null
    //                               // error_code?: ErrorCode | null
    //                               measureType: MeasureType
    //                               measured_value?: number | null
    //                               measuretime: string
    //                               // resulting_value?: number | null
    //                               diver_installation?: DiverInstance
    //                           } & ErrorCodeOrResultingValue)[],
    //                       }
    // ) {
    //     const body = {
    //         update: args.update,
    //         measurements: args.measurements.map(m => {
    //             // if (!m.resulting_value && !m.error_code){
    //             //     throw new Error("value or text code required")
    //             // }
    //             return ({
    //                 object_id: m.object.id,
    //                 comment: m.comment,
    //                 data_status_id: m.dataStatus?.id,
    //                 error_code_id: m.error_code?.id,
    //                 measure_type_id: m.measureType.id,
    //                 measured_value: m.measured_value,
    //                 measuretime: m.measuretime,
    //                 resulting_value: m.resulting_value,
    //                 diver_installation_id: m.diver_installation?.id
    //             })
    //         })
    //     }
    //     return this.apiHelper.postNoContent(
    //         `projects/${project.id}/measurements/batch`,
    //         body)
    // }

    // TODO: change this to a batch update with many rows.
    updateMeasurements(
        project: Project,
        rows: readonly {
            comment?: string | null
            data_status?: DataStatus | null
            error_code?: ErrorCode | null
            id: number,
            measure_type?: MeasureType | null
            measured_value?: number | null
            object_id: number,
            resulting_value?: number | null
            // TODO: correlation_status too ?
        }[]) {
        const updates = rows.map(row => {
            // This is nullable, thus special treatment. Otherwise, the ?. operator will return undefined
            const error_code_id = row.error_code === null ? null : row.error_code?.id

            return {
                comment: row.comment,
                data_status_id: row.data_status?.id,
                error_code_id,
                id: row.id,
                measure_type_id: row.measure_type?.id,
                measured_value: row.measured_value,
                object_id: row.object_id,
                resulting_value: row.resulting_value,
            }
        })
        return this.apiHelper.patch(`projects/${project.id}/measurements`, {updates})
    }

    updateMeasurementField(
        project: Project,
        object: VideObject,
        row: {
            id: number
            comment?: string | null
            error_code?: ErrorCode | null
            measure_type?: MeasureType | null
            measured_value?: number | null
            measuretime?: string | null
            resulting_value?: number | null
            // TODO: correlation_status too ?
        }) {
        // This is nullable, thus special treatment. Otherwise, the ?. operator will return undefined
        const error_code_id = row.error_code === null ? null : row.error_code?.id

        const x = {
            comment: row.comment,
            error_code_id,
            measure_type_id: row.measure_type?.id,
            measuretime: row.measuretime,
            measured_value: row.measured_value,
            resulting_value: row.resulting_value,
        }

        // return this.apiHelper.patch(`projects/${project.id}/measurements/${row.id}`, x)
        return this.apiHelper.patch(`projects/${project.id}/objects/${object.id}/measurements/${row.id}`, x)
    }

    deleteMeasurements(project: Project, object: Pick<VideObject, 'id'>, measurementIds: readonly number[]) {
        return this.apiHelper.delete(`projects/${project.id}/objects/${object.id}/measurements`, {measurementIds})
    }


    //////////////////////////////////////////////////////////////////////////////
    // Diver stuff
    //////////////////////////////////////////////////////////////////////////////
    getDiverAnnotations(project: Project, object: Pick<VideObject, 'id'>) {
        return this.apiHelper.getWrapped(S.array(DiverInstance), `projects/${project.id}/objects/${object.id}/diverAnnotations`)
    }

    createDiverAnnotations(project: Project, object: Pick<VideObject, 'id'>, args: {
        comment?: string
        first_date: string
        serial_number?: string
        reference_measurement_id?: number
    }) {

        const body = pick(args, 'serial_number', 'comment', 'first_date', 'reference_measurement_id')
        return this.apiHelper.postWrapped(DiverInstance, `projects/${project.id}/objects/${object.id}/diverAnnotations`, body)
    }

    updateDiverAnnotation(project: Project, object: VideObject, args: {
        id: number,
        name?: string,
        measurement_id?: number,
        comment?: null | string
    }) {
        throw new Error('outdated')
        const data = pick(args, 'name', 'measurement_id', 'comment')
        console.warn(data)
        return this.apiHelper.patch(`projects/${project.id}/objects/${object.id}/diverAnnotations/${args.id}`, data)
    }

    deleteDiverAnnotation(project: Project, instance: DiverInstance,) {
        return this.apiHelper.delete(`projects/${project.id}/objects/${instance.object_id}/diverAnnotations/${instance.id}`)
    }

    //////////////////////////////////////////////////////////////////////////////
    // Shared objects
    //////////////////////////////////////////////////////////////////////////////
    setSharedObject(sharedToProject: Pick<Project, "id">, object: Pick<VideObjectBasicType, "id">, options?: {
        comment: string | null;
        readonly: boolean
    }) {
        const body = {comment: options?.comment, readonly: options?.readonly}
        return this.apiHelper.put(`projects/${sharedToProject.id}/shared/${object.id}`, body)
        // return this.apiHelper.postNoContent(`projects/${sharedToProject.id}/shared/${object.id}`, options)
    }

    // /** @deprecated */
    // updateSharedObjects(project: Project, args: {
    //     add?: Pick<VideObjectBasicType, 'id'> [],
    //     remove?: Pick<VideObjectBasicType, 'id'> [],
    // }) {
    //     const body = {
    //         add: args.add?.map(row => ({id: row.id})),
    //         remove: args.remove?.map(row => ({id: row.id})),
    //     }
    //     return this.apiHelper.patch(`projects/${project.id}/shared/batch`, body)
    // }

    deleteSharedObject(sharedToProject: Pick<Project, 'id'>, object: Pick<VideObjectBasicType, 'id'>) {
        return this.apiHelper.delete(`projects/${sharedToProject.id}/shared/${object.id}`)
    }

}
