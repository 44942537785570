import {Injectable} from '@angular/core'
import {SelectionModel} from "@angular/cdk/collections"

import * as S from "superstruct"
import {combineLatest, filter, firstValueFrom, map, startWith, switchMap} from "rxjs"

import {AbstractMapboxService} from "../../mapbox/abstract-mapbox.service"
import {ExternalObject, Position, PositionM} from "../../api/api-types"
import {LogContainer} from "../../log-list/log-container"
import {PLOT_COLOR} from "../../../constants"
import {PlotlyScattermapboxData, VideFigure} from "../../vide-types"
import {TypedHttpService} from "../typed-http.service"
import {isDefined} from "../../shared/vide-helper"
import {HttpCounterService} from "../../shared/http-counter.service"

const GeoarkivetFeature = S.object({
        type: S.literal("Feature"),
        id: S.string(),// "groundwater.8738",
        geometry: S.object({
            type: S.literal("Point"),
            /** [x, y, z] */
            coordinates: S.tuple([S.number(), S.number(), S.number()]),
        }),
        geometry_name: S.literal("PointGeometry"),
        properties: S.object({
            Id: S.integer(),// 8738,
            GroundInvestigationId: S.integer(),// 198,
            GroundInvestigationName: S.string(),// "Miljöstation, Globen",
            GroundInvestigationResponsibleOrganizationName: S.nullable(S.string()),//"Vägverket",
            BoreHoleInvestigationDate: S.nullable(S.never()),// null,
            Name: S.string(),// "97A131",
            ObjectType: S.literal('Grundvattenrör'),
            ProtocolData_ObservationDate_Min: S.nullable(S.string()),// "1964-10-14",
            ProtocolData_ObservationDate_Max: S.nullable(S.string()),// "1964-10-14",
            ProtocolData_Num: S.nullable(S.integer()),//2,
            Position_N_Rounded: S.integer(),// 6575389,
            Position_E_Rounded: S.integer(),// 154949,
            Position_Z: S.number(),//42.272,
            PlanSymbolImage_GeoDocumentId: S.integer(),//30445,
            ProfileImage_GeoDocumentId: S.integer(),//30446,
            ProfileDocument_GeoDocumentId: S.integer(),//30447
        }),
        /** [xMin, yMin, xMax, yMax] */
        bbox: S.tuple([S.number(), S.number(), S.number(), S.number()]),
    },
)
type GeoarkivetFeature = S.Infer<typeof GeoarkivetFeature>
const GeoarkivetBase = S.object({
    type: S.literal('FeatureCollection'),
    features: S.array(GeoarkivetFeature),
    crs: S.object({
        type: S.literal('name'),
        properties: S.object({
            name: S.literal("urn:ogc:def:crs:EPSG::3011"),
        })
    }),
    bbox: S.tuple([S.number(), S.number(), S.number(), S.number()]),
    totalFeatures: S.integer(),
    numberMatched: S.integer(),
    numberReturned: S.integer(),
    timeStamp: S.string(), //"2024-03-26T13:06:12.867Z"
})
const ExternalObjectGeoarkivet = S.assign(ExternalObject, S.object({
    options: S.object({
        id: S.integer(),
        // the rest are not used, but delivered... maybe we should stop them in the backend resource?
        updated: S.unknown(),
    })
}))

export type GeoarkivetObject = {
    external: ExternalObject | null
    position: Position
    positionM: PositionM
    feature: GeoarkivetFeature
    tainted: boolean
}

@Injectable({
    providedIn: 'root'
})
export class GeoarkivetService extends AbstractMapboxService {
    /**
     * See
     * http://opengeospatial.github.io/e-learning/wfs/text/operations.html
     * for more info on searching and query in the request
     * @private
     */
    private readonly baseUrl = 'https://openexpl.stockholm.se/geoserver/geoarchive/wfs?SERVICE=WFS&REQUEST=GetFeature&typeName=groundwater&outputFormat=json'
    private readonly ExternalType = 'geoarkivet'
    protected override useColorbar: boolean = false
    readonly httpStatus = {
        vide: new SelectionModel<number>(true, [], true),
        extern: this.counter.waiting$,

    }
    readonly logs = new LogContainer('Save')
    readonly externalSelection$ = this.dataService.project$.pipe(
        filter(isDefined),
        switchMap((p) => this.dataService.getExternalObjects(p, this.ExternalType)),
        map(externals => S.create(externals, S.array(ExternalObjectGeoarkivet))),
    )
    readonly features$ = this.http.getTyped(this.baseUrl, GeoarkivetBase).pipe(
        map(base => base.features),
    )
    readonly stations$ = combineLatest([
        this.features$,
        this.dataService.getConverter(3011),
        this.externalSelection$,
        this.httpStatus.vide.changed.pipe(startWith(null)),
    ]).pipe(
        map(([features, converter, externalSelection]) => {
            return features.map(f => {
                const [x, y] = f.geometry.coordinates
                const [lon, lat] = converter.forward([x, y])
                const tainted = this.httpStatus.vide.isSelected(GeoarkivetService.getId({feature: f}))
                const external = externalSelection.find(x => x.options.id === GeoarkivetService.getId({feature: f})) ?? null
                const ret: GeoarkivetObject = {
                    external,
                    feature: f,
                    position: {lat, lon},
                    positionM: {x, y},
                    tainted,
                }
                return ret
            })
        })
    )
    readonly figure$ = combineLatest([
        this.stations$,
        this.forceRelayout$,
    ]).pipe(
        map(([features,]) => {
            const customdata = features as any[]
            const color = features.map(o =>
                o.external
                    ? PLOT_COLOR.object_selected
                    : PLOT_COLOR.object_unselectd
            )
            const hovertemplate = features.map(o => {
                const p = o.feature.properties
                const desc = []
                {
                    let key: keyof typeof p
                    for (key in p) {
                        desc.push(`${key}: ${p[key]}`)
                    }
                }
                return `<em>%{text} </em> <br>` + desc.join("<br>") + "<extra></extra>"
            })
            const text = features.map(o => GeoarkivetService.getName({feature: o.feature}))
            const data: PlotlyScattermapboxData[] = [{
                customdata,
                hovertemplate,
                lat: features.map(p => p.position.lat),
                lon: features.map(p => p.position.lon),
                marker: {
                    color,
                },
                text,
                type: 'scattermapbox',
            }]
            const layout = this.getLayout(features, {background: 'open-street-map'})
            const config = this.getMapConfig({filename: 'asdf'})
            const figure: VideFigure = {
                config,
                data,
                layout,
            }
            return figure
        })
    )

    constructor(
        private readonly http: TypedHttpService,
        private readonly counter: HttpCounterService,
    ) {
        super()
    }

    async remove(x: GeoarkivetObject) {
        if (!x.external) return
        const project = await firstValueFrom(this.dataService.projectNotNull$)
        this.httpStatus.vide.select(GeoarkivetService.getId(x))
        const res = await firstValueFrom(
            this.dataService.deleteExternalSource(project, x.external)
        ).finally(() => {
            this.httpStatus.vide.deselect(GeoarkivetService.getId(x))
        })
        this.logs.add(res, `Remove ${GeoarkivetService.getName(x)}`)
        if (res.success) {
            this.dataService.reloadProjectData()
        }
    }

    async add(s: GeoarkivetObject) {
        const properties = {id: GeoarkivetService.getId(s)}
        const project = await firstValueFrom(this.dataService.projectNotNull$)
        this.httpStatus.vide.select(GeoarkivetService.getId(s))
        const res = await firstValueFrom(
            this.dataService.createExternalObject(project, this.ExternalType, properties)
        ).finally(() => {
            this.httpStatus.vide.deselect(GeoarkivetService.getId(s))
        })
        this.logs.add(res, `Add ${GeoarkivetService.getName(s)}`)
        if (res.success) {
            this.dataService.reloadProjectData()
        }
    }

    static getId(s: Pick<GeoarkivetObject, 'feature'>) {
        return s.feature.properties.Id
    }

    static getName(s: Pick<GeoarkivetObject, 'feature'>) {
        return s.feature.properties.Name
    }
}
