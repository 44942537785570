<div class="padded">
    <h1>Help topics
        <button mat-icon-button (click)="close()" aria-label="close">
            <mat-icon>close</mat-icon>
        </button>
    </h1>

    <ng-select
        [items]="HELP_SUBJECTS"
        [formControl]="subject"
        placeholder="SSSSSS"
        [clearable]="false"
    />
</div>

@switch (subject.value) {
    @case ('Public data') {
        <app-public-data/>
    }
    @case ('Plots') {
        <app-plot-info/>
    }
    @case ('User abilities') {
        <app-ability/>
    }
    @case ('Diver') {
        <app-diver-info/>
    }
    @case ('Object select'){
        <app-object-select-help/>
    }
    @default {
        {{ subject.value }} has no content
    }
}
