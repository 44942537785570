import {Component, input} from '@angular/core'
import {MatCardModule} from "@angular/material/card"

import {Change, CHANGE_TYPES} from "../../CHANGELOG"

@Component({
    selector: 'app-changelog',
    standalone: true,
    imports: [MatCardModule],
    templateUrl: './changelog.component.html',
    styleUrl: './changelog.component.scss'
})
export class ChangelogComponent {
    readonly CHANGE_TYPES = CHANGE_TYPES
    readonly changes = input.required<readonly Change[]>()
}
