import {AfterViewInit, Component, viewChild} from '@angular/core'
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop"
import {MatDialog} from "@angular/material/dialog"
import {MatTableDataSource, MatTableModule} from "@angular/material/table"
import {RouterLink} from "@angular/router"
import {MatButtonModule} from "@angular/material/button"
import {MatIconModule} from "@angular/material/icon"
import {MatTooltipModule} from "@angular/material/tooltip"
import {MatCardModule} from "@angular/material/card"
import {MatSort, MatSortModule} from "@angular/material/sort"

import {combineLatest, debounceTime, firstValueFrom, map} from "rxjs"

import {LogContainer} from "../../log-list/log-container"
import {ComponentCanDeactivate} from "../../can-deactivate.guard"
import {LogListComponent} from "../../log-list/log-list.component"
import {Project} from "../../api/api-types"
import {VIDE_ROUTES2, VideDataService} from "../../api/vide-data.service"
import {ConfirmDialogComponent, ConfirmDialogData} from "../../dialogs/confirm-dialog/confirm-dialog.component"
import {
    EditProjectComponent,
    EditProjectComponentData,
    EditProjectComponentResult
} from "./edit-project/edit-project.component"
import {
    EditProjectUsersComponent,
    EditProjectUsersComponentData,
    EditProjectUsersComponentResult
} from "./edit-project-users/edit-project-users.component"
import {compareName} from "../../shared/vide-helper"
import {ABILITY} from "../../ability"
import {SortedTextColumnComponent} from "../../shared/sorted-text-column/sorted-text-column.component"
import {Unpacked} from "../../vide-types"

@Component({
    selector: 'app-admin-projects',
    standalone: true,
    imports: [
        LogListComponent,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        RouterLink,
        SortedTextColumnComponent,
    ],
    templateUrl: './admin-projects.component.html',
    styleUrl: './admin-projects.component.scss'
})
export class AdminProjectsComponent extends ComponentCanDeactivate implements AfterViewInit {
    protected readonly userAdminAbility = toSignal(this.dataService.userAdminAbility$)
    private readonly ownedProjects = toSignal(this.dataService.ownedProjects$, {initialValue: []})
    private readonly sort = viewChild(MatSort)

    protected readonly logs = new LogContainer()

    /**
     * Projects you own or can read
     */
    protected readonly allProjects$ = combineLatest([
        this.dataService.projects$,
        this.dataService.ownedProjects$,
    ]).pipe(
        // Small wait, so both inputs have updated when reloaded. Is there a better way?
        debounceTime(100),
        map(([projects, ownedProjects,]) => {
            const allP = [
                ...ownedProjects,
                ...projects,
            ]
            const map = new Map<number, Unpacked<typeof allP>>()
            allP.forEach(p => map.set(p.id, p))

            return Array.from(map.values()).sort(compareName)
        })
    )
    protected readonly dataSource = new MatTableDataSource<Unpacked<typeof this.allProjects$>>()

    protected readonly columnsToDisplay = [
        'name',
        'license',
        'ability',
        'open',
        'comment',
        'coordinate_system',
        'height_system',
        'share_type',
        'edit',
        'users',
        'delete',
    ] as const

    override canDeactivate(): boolean {
        // Nothing here to keep actually
        return true
    }


    constructor(
        private readonly dataService: VideDataService,
        private readonly dialog: MatDialog,
    ) {
        super()
        this.allProjects$.pipe(takeUntilDestroyed()).subscribe(all => {
            this.dataSource.data = all
        })
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
            switch (sortHeaderId) {
                case 'coordinate_system':
                case 'height_system':
                    return data[sortHeaderId].name
                default:
                    return (data as any)[sortHeaderId] ?? ''

            }
        }
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort() ?? null
    }

    addProject() {
        this.dialog.open<
            EditProjectComponent,
            EditProjectComponentData,
            EditProjectComponentResult
        >(EditProjectComponent).afterClosed().subscribe(r => {
            if (r) {
                this.dataService.reloadProjectData()
            }
        })
    }

    async edit(project: Project) {
        const lentObjects = await firstValueFrom(this.dataService.getLentObjects(project))

        this.dialog.open<
            EditProjectComponent,
            EditProjectComponentData,
            EditProjectComponentResult
        >(EditProjectComponent, {
            data: {
                ...project,
                lentObjects
            }
        }).afterClosed().subscribe(x => {
            if (x) {
                this.dataService.reloadProjectData()
            }
        })
    }

    delete(p: Project) {
        this.dialog.open<
            ConfirmDialogComponent,
            ConfirmDialogData,
            ConfirmDialogComponent['response']
        >(ConfirmDialogComponent, {
            data: {
                header: `Delete ${p.name}`,
                text: 'All object of the project will be deleted. This cannot be undone.',
                negative_text: 'Cancel',
                positive_text: 'Delete',
            }
        }).afterClosed().subscribe(answer => {
            if (answer) {
                this.dataService.deleteProject(p).subscribe(ret => {
                    this.logs.add(ret, `Delete ${p.name}`)
                    if (ret.success) this.dataService.reloadUserData()
                })
            }
        })

    }

    editUsers(project: Project) {
        this.dialog.open<
            EditProjectUsersComponent,
            EditProjectUsersComponentData,
            EditProjectUsersComponentResult
        >(EditProjectUsersComponent, {
            data: {project}
        }).afterClosed().subscribe(answer => {
            if (answer) {
                this.dataService.reloadProjectData()
                this.dataService.reloadUserData()
            }
        })
    }

    videLink = this.dataService.videLink.bind(this.dataService)

    protected readonly ABILITY = ABILITY

    userIsLicenseOwner(project: Project) {
        // console.warn(`check if user owns project ${project.name}`)
        return this.ownedProjects().some(p => p.id === project.id)
    }

    protected readonly VIDE_ROUTES2 = VIDE_ROUTES2
}
