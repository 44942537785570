import {AfterViewInit, Component, OnDestroy, viewChild, ViewChild} from '@angular/core'
import {DatePipe} from "@angular/common"
import {MatButtonModule} from "@angular/material/button"
import {MatCardModule} from "@angular/material/card"
import {MatCheckboxModule} from "@angular/material/checkbox"
import {MatDialog} from "@angular/material/dialog"
import {MatIconModule} from "@angular/material/icon"
import {MatMenuModule, MatMenuTrigger} from "@angular/material/menu"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner"
import {MatSort, MatSortModule} from "@angular/material/sort"
import {MatTableDataSource, MatTableModule} from "@angular/material/table"
import {MatTabsModule} from "@angular/material/tabs"
import {MatTooltipModule} from "@angular/material/tooltip"
import {ReactiveFormsModule} from "@angular/forms"
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop"

import {NgSelectModule} from "@ng-select/ng-select"
import {PlotlyComponent, PlotlyViaWindowModule} from 'angular-plotly.js'

import {ComponentCanDeactivate} from 'src/app/can-deactivate.guard'
import {PlotlyMouseEvent} from 'src/app/vide-types'
import {DATETIME_FORMAT, PLOT_CONFIG} from 'src/constants'
import {SmhiObject, SmhiService} from "./smhi.service"
import {LogListComponent} from "../../log-list/log-list.component"
import {moveMenu} from "../../shared/mat-menu"
import {ConfirmDialogComponent, ConfirmDialogData} from "../../dialogs/confirm-dialog/confirm-dialog.component"

@Component({
    standalone: true,
    selector: 'app-smhi',
    templateUrl: './smhi.component.html',
    imports: [
        LogListComponent,

        DatePipe,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatIconModule,
        MatMenuModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatTooltipModule,
        NgSelectModule,
        PlotlyViaWindowModule,
        ReactiveFormsModule,
    ],
    styleUrls: ['./smhi.component.scss']
})
export class SmhiComponent extends ComponentCanDeactivate implements AfterViewInit, OnDestroy {
    @ViewChild(PlotlyComponent) plotlyComponent?: PlotlyComponent
    private readonly sort = viewChild.required(MatSort)
    protected readonly DATETIME_FORMAT = DATETIME_FORMAT
    protected readonly dataSource = new MatTableDataSource<SmhiObject>([])
    protected readonly figure = toSignal(this.service.figure$)
    protected readonly form = this.service.form
    protected readonly httpExtern = toSignal(this.service.httpStatus.extern)
    protected readonly httpStatus = this.service.httpStatus
    protected readonly logs = this.service.logs
    protected readonly parameters = toSignal(this.service.parameters$)
    protected readonly plotlyStyle = PLOT_CONFIG.plotlyStyle
    protected readonly stationsToShow$ = this.service.stationsToShow$
    protected readonly trigger = viewChild(MatMenuTrigger)

    protected readonly add = this.service.add.bind(this.service)
    protected readonly getName = SmhiService.getName
    protected readonly onPlotlyRelayout = this.service.plotlyRelayout.bind(this.service)
    protected readonly remove = this.service.remove.bind(this.service)

    protected readonly displayedColumns = [
        'name',
        'active',
        'from',
        'to',
        'imported',
        'action',
    ] as const

    constructor(
        private readonly service: SmhiService,
        private readonly dialog: MatDialog,
    ) {
        super()
        this.stationsToShow$.pipe(takeUntilDestroyed()).subscribe(s => {
            this.dataSource.data = s
        })
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
            switch (sortHeaderId) {
                case 'name':
                    return SmhiService.getName(data)
                case 'from':
                    return data.station.from ?? ''
                case 'to':
                    return data.station.to ?? ''
                case 'active':
                    return data.station.active ? 1 : 0
                case 'imported':
                    return data.external ? 1 : 0
                default:
                    console.warn(`Unhandled column ${sortHeaderId}`)
                    return ''
            }
        }
    }


    override canDeactivate() {
        return true
    }

    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort()
        this.service.plotlyHost = this
    }

    ngOnDestroy(): void {
        // not strictly necessary, but let's not keep the unloaded element in memory.
        this.service.plotlyHost = undefined
    }

    plotlyClick(event: PlotlyMouseEvent) {
        const custom = event.points[0]?.customdata as SmhiObject | undefined
        if (typeof custom !== 'object') {
            console.error("Strange type of custom data")
            return
        }
        const trigger = this.trigger()

        if (trigger) {
            trigger.menuData = {element: custom,}
            trigger.openMenu()
        }
        setTimeout(moveMenu(event), 0)
    }

    showProperties(element: SmhiObject) {
        console.log(element)
        const prop = Object.entries(element.station).filter(([a, b]) => typeof b !== 'object').map(([a, b]) => `${a}: ${b}`).join("\n")
        this.dialog.open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
            data: {
                header: `Properties for ${element.station.name}`,
                positive_text: null,
                negative_text: null,
                textPre: prop

            }
        })
    }
}


