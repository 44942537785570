@if (formGroup.controls; as controls) {
    @if (belowSurfaceProblem(controls.measureTypes.value, controls.transformKind.value); as msg) {
        <div class="warning"> {{ msg }}</div>
    }
    <!-- What to plot -->
    <div class="vide-form-field-row vide-columns-wide">
        <ng-select
            [items]="measureTypes"
            bindLabel="name"
            [formControl]="controls.measureTypes"
            placeholder="Measure types"
            [multiple]="true"
            [compareWith]="equalIds"
        >
            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                <span class="ng-value-label"> {{ item.name }} [{{ item.measure_unit.name }}]</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
                {{ item.name }} [{{ item.measure_unit.name }}]
            </ng-template>
        </ng-select>

        <ng-select
            [items]="MEASUREMENT_TRANSFORM_KINDS"
            [formControl]="controls.transformKind"
            placeholder="Measurement transform"
            [multiple]="false"
            [clearable]="false"
        >
            <ng-template ng-label-tmp let-item="item">
                Measurement transformation: {{ item }}
            </ng-template>
        </ng-select>

        <ng-select
            [items]="horizontals"
            [multiple]="true"
            [closeOnSelect]=false
            [formControl]="controls.horizontal"
            placeholder="Horizontal line"
            bindLabel="label"
        />
        <ng-select
            placeholder="yaxis"
            [items]=AxisTypes
            [formControl]="controls.yaxis"
            [clearable]="false"
        >
            <ng-template ng-label-tmp let-item="item">
                Yaxis: {{ item }}
            </ng-template>
        </ng-select>
    </div>
    <div class="vide-form-field-row">
        Zero level date:
        <app-datetime
            [formControl]="controls.zeroLevelDateTime"
        />
    </div>


    <!-- Markers -->
    @if (controls.markers.controls; as clsM) {
        <div class="vide-form-field-row">
            Markers:
            <mat-slide-toggle [formControl]="clsM.allMeasurements">All measurements</mat-slide-toggle>
            <mat-slide-toggle [formControl]="clsM.nonChecked" [matTooltip]="TOOLTIPS.notChecked">Non-checked
            </mat-slide-toggle>
            <mat-slide-toggle [formControl]="clsM.affected" [matTooltip]="TOOLTIPS.affected">Affected
            </mat-slide-toggle>
            @if (useCorrelation) {
                <mat-slide-toggle [formControl]="clsM.correlationStatus"
                                  [matTooltip]="TOOLTIPS.correlationStatus">Correlation status
                </mat-slide-toggle>
            }
            <mat-slide-toggle [formControl]="clsM.textCode">Text code</mat-slide-toggle>
            <mat-slide-toggle [formControl]="clsM.notification">Notification</mat-slide-toggle>
        </div>
    }

}
