import {FormBuilder, Validators} from "@angular/forms"
import {Injectable} from '@angular/core'
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"

import {combineLatest, finalize, map, of, startWith, switchMap} from "rxjs"

import {Counter} from "../../../shared/counter"
import {DiverDatum, DiverService} from "../diver.service"
import {PLOTLY_SCATTER_TYPE} from "../../../shared/vide-helper"
import {Statistics, VideObject} from "../../../api/api-types"
import {VideDataService} from "../../../api/vide-data.service"
import {VideFigure} from "../../../vide-types"
import {measurementNumeric} from "../../../plot/measurement-categories"

@Injectable({
    providedIn: 'root'
})
export class BarometerMeasurementsService {
    readonly waiting = new Counter()
    readonly pressureInputControl = this.fb.nonNullable.control(null as null | {
        object: VideObject,
        statistics: Statistics
    }, Validators.required)
    readonly pressureCombos$ = this.dataService.objects$.pipe(
        map(objects => {
            return objects.map(o => o.statistics
                .filter(s => {
                    return s.measure_type.constant_name === 'air_pressure' || s.measure_type.constant_name === 'air_pressure_sea_level'
                })
                .map(s => ({object: o, statistics: s})))
                .flat()
        }),
    )
    readonly pressureData$ = combineLatest(([
        this.dataService.projectNotNull$,
        this.pressureInputControl.valueChanges.pipe(startWith(null)),
    ])).pipe(
        switchMap(([p, baro,]) => {
            if (baro === null) {
                return of(null)
            }
            this.waiting.increase()
            return this.dataService.getMeasurements(p, baro.object, baro.statistics.measure_type).pipe(
                map(x => {
                    let factor: number
                    switch (x.measure_type.measure_unit.constant_name) {
                        case 'mH2O':
                            factor = 1
                            break
                        case 'hecto_pascal':
                            const g = 9.818
                            const rho = 999.975 // at 4°C
                            factor = 100 / (g * rho)
                            break
                        default:
                            throw new Error("Wrong measure type " + x.measure_type.measure_unit.name)
                    }
                    const data: readonly DiverDatum[] = x.measurements.filter(measurementNumeric).map(m => ({
                        value: factor * m.resulting_value,
                        time_t: (new Date(m.measuretime)).getTime(),
                        dateTime: m.measuretime,
                    }))
                    return {data, object: baro.object, unitName: 'mH2O'}
                }),
                finalize(() => {
                    this.waiting.decrease()
                }),
            )
        }),
    )

    readonly figure$ = this.pressureData$.pipe(
        map(data => {
            if (!data) return null
            const figure: VideFigure = {
                data: [{
                    type: PLOTLY_SCATTER_TYPE,
                    x: data.data.map(m => m.dateTime),
                    y: data.data.map(m => m.value),
                    // mode: "lines+markers",
                    name: "Air pressure",
                }],
                layout: {
                    title: `${data.object.name} air pressure`,
                    yaxis: {title: data.unitName}
                },
                config: {},
            }
            return figure
        }),
    )

    constructor(
        private readonly fb: FormBuilder,
        private readonly dataService: VideDataService,
        private readonly diverService: DiverService,
    ) {
        this.pressureData$.pipe(takeUntilDestroyed()).subscribe(data=>{
            this.diverService.setPressureData(data)
        })
    }
}
