<h1>About {{ APP_NAME }}</h1>

<mat-card>
    <mat-card-header>
        <mat-card-title>Version</mat-card-title>
    </mat-card-header>

    <mat-card-content>
        @if (version(); as bv) {
            <ul>
                <li> Frontend version: {{ frontendVersion }}</li>
                <li> Backend version: {{ bv.backend_version }}</li>
                @if (frontendVersion !== bv.backend_version) {
                    <li><em class="warning">Version mismatch! Maybe reload without caching?</em></li>
                }
                <li> Database: {{ bv.database_name }}</li>
                @if (bv.database_comment; as c) {
                    <li> Database comment: {{ c }}</li>
                }
            </ul>
        }
    </mat-card-content>
    @if (userAdminAbility()?.has('Vide admin')) {
        <mat-card-actions>
            <a mat-raised-button target="_blank" [href]="bugLink()">Report an issue using Jira (new window)</a>
        </mat-card-actions>
    }
</mat-card>

<app-changelog [changes]="CHANGES"></app-changelog>
