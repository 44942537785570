import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogModule, MatDialogRef} from "@angular/material/dialog"
import {Component, computed, Inject} from '@angular/core'
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatCheckboxModule} from "@angular/material/checkbox"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatInputModule} from "@angular/material/input"
import {MatOptionModule} from "@angular/material/core"
import {MatSelectModule} from "@angular/material/select"
import {toSignal} from "@angular/core/rxjs-interop"

import {KnownUser, MeasureType, Trigger, TriggerTypeValues, VideObject} from "../../api/api-types"
import {VideDataService} from "../../api/vide-data.service"
import {equalIds, getErrorMessage} from "../../shared/vide-helper"

export type EditTriggerData = MatDialogConfig<{
    trigger?: Omit<Trigger, 'id'>,
    object: VideObject
}>['data']
export type EditTriggerResult = Pick<Trigger,
    | 'active'
    | 'comment'
    | 'description'
    | 'limit'
    | 'measureType'
    | 'recipients'
    | 'type'
    | 'unchecked'
>

@Component({
    selector: 'app-edit-trigger',
    standalone: true,
    imports: [
        MatButtonModule,
        MatCheckboxModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        ReactiveFormsModule,
    ],
    templateUrl: './edit-trigger.component.html',
    styleUrl: './edit-trigger.component.scss'
})
export class EditTriggerComponent {
    public result: EditTriggerResult | undefined

    protected readonly utility = toSignal(this.dataService.utility$)
    protected readonly measureTypes = computed(() => {
        const mt = this.utility()?.measure_type
        const o = this.object
        if (!(o && mt)) {
            return undefined
        }
        const active = o.statistics.map(s => s.measure_type)
        const activeSet = new Set(active.map(t => t.id))
        const other = mt.filter(t => !activeSet.has(t.id))
        return [
            {
                label: 'With existing measurements',
                items: active
            },
            {
                label: 'Without existing measurements',
                items: other
            }]
    })
    protected readonly knownUsers = toSignal(this.dataService.knownUsers$, {initialValue: []})
    protected readonly form = this.fb.nonNullable.group({
        description: ['', Validators.required],
        comment: ['' as string | null],
        limit: [0],
        type: ['min' as Trigger['type']],
        active: [true],
        unchecked: [false],
        recipients: [[] as KnownUser[]],
        measureType: [null as null | MeasureType, Validators.required],
    })
    private readonly object: VideObject | undefined

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: EditTriggerData,
        private readonly dataService: VideDataService,
        private readonly fb: FormBuilder,
        private readonly ref: MatDialogRef<EditTriggerComponent, EditTriggerComponent['result']>
    ) {
        this.object = this.data?.object
        if (this.data?.trigger) {
            this.form.patchValue(this.data.trigger)
        } else {
            this.form.patchValue({measureType: this.data?.object.statistics.at(0)?.measure_type})
            const thisUser = this.dataService.user()
            if (thisUser) {
                this.form.patchValue({recipients: [thisUser]})
            }
        }
    }

    save() {
        this.form.markAllAsTouched()
        if (this.form.invalid || this.form.pristine) return

        const value = this.form.getRawValue()
        if (value.measureType === null) {
            return
        }
        const mt = value.measureType
        this.ref.close({...value, measureType: mt})
    }

    protected readonly TriggerTypeValues = TriggerTypeValues
    protected readonly equalIds = equalIds
    protected readonly getErrorMessage = getErrorMessage
}
