<h1 mat-dialog-title>Send password reset link</h1>

<mat-dialog-content>
    @if (form; as control) {
        <div>
            <mat-form-field>
                <mat-label>Username (email)</mat-label>
                <input matInput type="email" [formControl]="control" email required autocomplete="email username">
                <mat-error>{{ getErrorMessage(control) }}</mat-error>
            </mat-form-field>
        </div>
    }
    <app-log-list [container]="logs"/>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button matTooltip="Send reset email" (click)="sendLink()">Reset password</button>
    <button mat-raised-button matTooltip="Cancel" mat-dialog-close>Cancel</button>
</mat-dialog-actions>
