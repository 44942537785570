import {Component} from '@angular/core'
import {AbstractControl, FormBuilder, ReactiveFormsModule, ValidatorFn, Validators} from '@angular/forms'
import {Title} from '@angular/platform-browser'
import {ActivatedRoute} from '@angular/router'
import {MatCardModule} from "@angular/material/card"
import {JsonPipe} from "@angular/common"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatButtonModule} from "@angular/material/button"
import {MatInputModule} from "@angular/material/input"

import {LogContainer} from 'src/app/log-list/log-container'
import {getErrorMessage, getPageTitle} from 'src/app/shared/vide-helper'
import {AuthService} from '../auth.service'
import {LogListComponent} from "../../log-list/log-list.component"

const passwordMatchValidator: ValidatorFn = (control: AbstractControl) => {
    const a = (control.value.password as string).trim()
    const b = (control.value.password_confirmation as string).trim()
    return a === b ? null : {passwordMismatch: true}
}


@Component({
    standalone: true,
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    imports: [
        JsonPipe,
        LogListComponent,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
    ]
})
export class ResetPasswordComponent {

    token: string | null
    readonly logs = new LogContainer()
    resetSuccessful = false

    readonly passwordForm = this.formBuilder.nonNullable.group({
        email: ['', [Validators.email, Validators.required]],
        password: ['', [Validators.required, Validators.minLength(6)]],
        password_confirmation: ['', [Validators.required]],
    }, {validators: passwordMatchValidator})

    readonly main = document.location.protocol + '//' + document.location.host

    constructor(
        private readonly auth: AuthService,
        private readonly route: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        title: Title,
    ) {
        title.setTitle(getPageTitle(null, 'Reset password'))
        this.token = this.route.snapshot.queryParamMap.get('token')
    }

    getErrorMessage = getErrorMessage

    resetPassword() {
        this.passwordForm.markAllAsTouched()
        if (this.token) {
            // if (this.token && this.passwordForm.valid) {
            const credentials = {...this.passwordForm.getRawValue(), token: this.token}
            this.auth.resetPassword(credentials)
                .then(x => {
                    this.logs.add(x, 'Reset')
                    if (x.success) {
                        this.resetSuccessful = true
                    }
                })
        }
    }
}
