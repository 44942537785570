<!--<div>-->
<!--    Found {{ nrInputSignal()?.file ?? 'no' }} compensated measurements from file-->
<!--</div>-->
<!--<div>-->
<!--    Found {{ nrInputSignal()?.local ?? 'no' }} calculated compensated measurements-->
<!--</div>-->
<!--<div>-->
<!--    Found {{ nrInputSignal()?.database ?? 'no' }} compensated measurements from database-->
<!--</div>-->


<mat-form-field [style.width]="'25rem'">
    <mat-label>
        Object with reference level measurement
    </mat-label>
    <mat-select
        [formControl]="form.controls.refObject"
    >
        @for (item of possibleReferenceObjects(); track item.object.id) {
            <mat-option [value]="item">
                {{ item.object.name }}
                {{ item.statistics.measure_type.name }}
            </mat-option>
        } @empty {
            <mat-option>Found no objects</mat-option>
        }
    </mat-select>
</mat-form-field>

<mat-form-field [style.width]="'25rem'">
    <mat-label>
        Reference level
    </mat-label>
    <mat-select
        [formControl]="form.controls.refMeasurement"
    >
        @for (item of possibleReferenceMeasurements(); track item.id) {
            <mat-option [value]="item">
                {{ item.measuretime }}
                {{ item.resulting_value }}
            </mat-option>
        } @empty {
            <mat-option>No measurement in current timespan</mat-option>
        }
    </mat-select>
</mat-form-field>
<div>Measurements at most {{ DIVER.REFERENCE_OUTSIDE_DATES_DAYS }} days before/after the compensated measurements are
    considered for reference.
</div>
<div>
    Calculated {{ nrReferenced() ?? 'no' }} values
</div>

@if (figure(); as figure) {
    <plotly-plot
        [data]="figure.data"
        [layout]="figure.layout"
        [config]="figure.config"
        [useResizeHandler]="true"
    />
}

<button mat-raised-button
        [disabled]="!nrReferenced() || !figure() || saving.value"
        (click)="save(diverObject())"
>
    @if (saving.value) {
        Saving...
    } @else {
        Save to {{ diverObject().name }}
    }
</button>

<div class="stepper-navigate">
    <button mat-button matStepperPrevious type="button">Previous</button>
    <button mat-button matStepperNext type="button">Next</button>
</div>

