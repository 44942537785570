import {Component, ElementRef, viewChild} from '@angular/core'
import {MatButtonModule} from "@angular/material/button"
import {MatDialog} from "@angular/material/dialog"
import {MatMenuModule} from "@angular/material/menu"
import {MatStepperModule, MatStepperNext} from "@angular/material/stepper"
import {ReactiveFormsModule} from "@angular/forms"
import {outputFromObservable, toSignal} from "@angular/core/rxjs-interop"

import {BehaviorSubject, firstValueFrom} from "rxjs"
import {PlotlyViaWindowModule} from "angular-plotly.js"

import {ImportBaroService} from "./import-baro.service"
import {InputComponent} from "../../../forms/input/input.component"
import {LogListComponent} from "../../../log-list/log-list.component"
import {NewObjectNameDialogComponent} from "../new-object-name-dialog/new-object-name-dialog.component"
import {ObjectSelectDialogComponent} from "../../../dialogs/object-select-dialog/object-select-dialog.component"
import {VideDataService} from "../../../api/vide-data.service"
import {VideObject} from "../../../api/api-types"

@Component({
    selector: 'app-import-baro',
    standalone: true,
    imports: [
        InputComponent,
        LogListComponent,

        MatButtonModule,
        MatMenuModule,
        MatStepperModule,
        PlotlyViaWindowModule,
        ReactiveFormsModule,
    ],
    templateUrl: './import-baro.component.html',
    styleUrl: './import-baro.component.scss'
})
export class ImportBaroComponent {
    protected readonly annotations = toSignal(this.service.baroAnnotations$)
    protected readonly completed = outputFromObservable(this.service.completed)
    protected readonly figure = toSignal(this.service.baroFigure$)
    protected readonly fileError = toSignal(this.service.fileError)
    protected readonly form = this.service.form
    protected readonly nrMeasurements = toSignal(this.service.baroNrMeasurements$)
    protected readonly object = outputFromObservable(this.service.selectedBaro)
    protected readonly objects = toSignal(this.videService.objects$, {initialValue: []})
    protected readonly saving = new BehaviorSubject(false)
    protected readonly showError = new BehaviorSubject(false)
    protected readonly nextButton = viewChild.required(MatStepperNext, {read: ElementRef})

    constructor(
        readonly service: ImportBaroService,
        private readonly videService: VideDataService,
        private readonly dialog: MatDialog,
    ) {
    }

    readonly plotlyClick = this.service.baroFigureClick.bind(this.service)
    readonly doubleClick = this.service.baroDoubleClick.bind(this.service)

    async saveExisting() {
        const ref = this.dialog.open<
            ObjectSelectDialogComponent<VideObject>,
            ObjectSelectDialogComponent<VideObject>['data'],
            ObjectSelectDialogComponent<VideObject>['result']
        >(ObjectSelectDialogComponent, {
            data: {
                objects: this.objects(),
                name: 'object for Baro-diver data',
                search: true,
                confirmText: 'Save'
            }
        })
        const object = await firstValueFrom(ref.afterClosed())
        if (!object) {
            return
        }
        this.saving.next(true)
        await this.service.save(object).finally(() => {
            this.saving.next(false)
        })
    }

    async saveNew() {
        const ref =
            this.dialog.open<
                NewObjectNameDialogComponent,
                NewObjectNameDialogComponent['data'],
                NewObjectNameDialogComponent['result']
            >(NewObjectNameDialogComponent, {
                data: {
                    title: 'Name of new object',
                    objects: this.objects(),
                    positiveText: 'Save to',
                }
            })
        const name = await firstValueFrom(ref.afterClosed())
        if (!name) {
            return
        }
        this.saving.next(true)
        const res = await this.service.saveNew(name).finally(() => {
            this.saving.next(false)
        })
        if (res) {
            this.nextStep()
        }
    }

    private nextStep() {
        const el = this.nextButton().nativeElement
        if (el instanceof HTMLButtonElement) {
            setTimeout(() => {
                el.click()
            }, 10)
        }

    }

    localNext() {
        this.showError.next(true)
    }
}
