import {Injectable} from '@angular/core'

import {BehaviorSubject} from "rxjs"

@Injectable({
    providedIn: 'root'
})
export class ColormapService {

    private _change = new BehaviorSubject<void>(undefined)
    private _useColormap = false

    constructor() {
    }

    private readonly _colormap = new Map<number, string>()

    set useColormap(value: boolean) {
        this._useColormap = value
        this._change.next()
    }

    get useColormap(): boolean {
        return this._useColormap
    }

    readonly change = this._change.asObservable()

    get(id: number) {
        return this._colormap.get(id)
    }

    set(id: number, color: string) {
        this._colormap.set(id, color)
        this._change.next()
    }

    clear() {
        this._colormap.clear()
        this._change.next()
    }
}
