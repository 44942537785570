import {AfterViewInit, Component, computed, OnDestroy, ViewChild} from '@angular/core'
import {MatButtonModule} from "@angular/material/button"
import {MatExpansionModule} from "@angular/material/expansion"
import {MatIconModule} from "@angular/material/icon"
import {MatTooltipModule} from "@angular/material/tooltip"
import {ReactiveFormsModule} from "@angular/forms"
import {toSignal} from "@angular/core/rxjs-interop"

import {NgSelectModule} from "@ng-select/ng-select"
import {PlotMouseEvent, PlotSelectionEvent} from "plotly.js"
import {PlotlyComponent, PlotlyViaWindowModule} from "angular-plotly.js"

import {MAP_LABELS, MAP_MARKERS, MapDataService} from './map-data.service'
import {isNumber} from "../../shared/vide-helper"

/**
 * This component is self-sufficient, it displays the objects from the chosen
 * project on a map.
 */
@Component({
    selector: 'app-object-map',
    standalone: true,
    templateUrl: './object-map.component.html',
    styleUrls: ['./object-map.component.scss'],
    imports: [
        MatButtonModule,
        MatExpansionModule,
        MatIconModule,
        MatTooltipModule,
        NgSelectModule,
        PlotlyViaWindowModule,
        ReactiveFormsModule,
    ]
})
export class ObjectMapComponent implements OnDestroy, AfterViewInit {
    @ViewChild(PlotlyComponent) plotlyComponent?: PlotlyComponent

    readonly plotlyStyle = toSignal(this.dataService.plotlyStyle$)
    readonly figure = toSignal(this.dataService.figure$)
    readonly nrObjects = computed(() => this.figure()?.data?.at(0)?.lat?.length)

    readonly MAP_BACKGROUNDS = this.dataService.MAP_BACKGROUNDS
    readonly mapOptionsForm = this.dataService.mapOptionsForm
    readonly selectionModel = this.dataService.selectionModel
    readonly mapMarkers = MAP_MARKERS
    readonly mapLabels = MAP_LABELS

    readonly mapHelpText = `Select/unselect single objects by clicking on them.
  Use Box select or Lasso select in the plot toolbar to select multiple
  objects. Selecting objects with box or lasso will replace previous selection.`


    constructor(
        private dataService: MapDataService,
    ) {
    }

    onPlotlyRelayout = this.dataService.plotlyRelayout.bind(this.dataService)

    plotlySelected(event: PlotSelectionEvent) {
        const ids = event.points.map(p => p.customdata).filter(isNumber)
        this.selectionModel.setSelection(...ids)
        return
    }

    plotlyClick(event: PlotMouseEvent) {
        const x = event.points[0]
        if (typeof x?.customdata === 'number') {
            // should be the object id
            this.selectionModel.toggle(x.customdata)
        }
    }

    ngOnDestroy(): void {
        // Let's not keep the unloaded element in memory.
        this.dataService.plotlyHost = undefined
    }

    ngAfterViewInit(): void {
        this.dataService.plotlyHost = this
    }
}



