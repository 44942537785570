import {NgZone} from "@angular/core"

import {Observable, Subscriber} from "rxjs"

/**
 * Data type T to be sent to the other window is serialized using the structured clone algorithm. This means you can
 * pass a broad variety of data objects safely to the destination window without having to serialize them yourself.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Structured_clone_algorithm
 */
export class BroadcastChannelWrapper<T> {
    private readonly channel: BroadcastChannel

    readonly data$: Observable<T> = new Observable<T>((subscriber: Subscriber<T>) => {
        this.channel.onmessage = (event: MessageEvent<T>) => {
            this.ngZone.run(() => subscriber.next(event.data))
        }
        this.channel.onmessageerror = (event: MessageEvent<T>) => {
            this.ngZone.run(() => subscriber.error(event.data))
        }
    })

    constructor(private readonly ngZone: NgZone, tag: string) {
        this.channel = new BroadcastChannel(tag)
    }

    get tag() {
        return this.channel.name
    }

    close() {
        this.channel.close()
    }

    send(data: T) {
        this.channel.postMessage(data)
    }
}
