import {Component} from '@angular/core'
import {MatButtonModule} from "@angular/material/button"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {MatSelectModule} from "@angular/material/select"
import {MatStepperModule} from "@angular/material/stepper"
import {ReactiveFormsModule} from "@angular/forms"
import {toSignal} from "@angular/core/rxjs-interop"

import {NgSelectModule} from "@ng-select/ng-select"
import {PlotlySharedModule} from "angular-plotly.js"

import {BarometerMeasurementsService} from "./barometer-measurements.service"
import {Unpacked} from "../../../vide-types"

@Component({
    selector: 'app-barometer-measurements',
    imports: [
        MatButtonModule,
        MatFormFieldModule,
        MatProgressBarModule,
        MatSelectModule,
        MatStepperModule,
        NgSelectModule,
        PlotlySharedModule,
        ReactiveFormsModule,
    ],
    templateUrl: './barometer-measurements.component.html',
    styleUrl: './barometer-measurements.component.scss'
})
export class BarometerMeasurementsComponent {
    // inputs
    // outputs
    // view children
    // signals
    protected readonly figure = toSignal(this.service.figure$)
    protected readonly pressureCombos = toSignal(this.service.pressureCombos$)
    protected readonly waiting = toSignal(this.service.waiting.waiting$, {initialValue: 0})
    // properties
    protected readonly form = this.service.pressureInputControl

    constructor(
        private readonly service: BarometerMeasurementsService,
    ) {
    }

    protected searchFn(term: string, item: Unpacked<BarometerMeasurementsComponent['pressureCombos']>) {
        return item?.object.name.toLocaleLowerCase().includes(term.toLocaleLowerCase())
    }

}
