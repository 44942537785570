<h1 mat-dialog-title>
    @if (data) {
        Edit project users in {{ data.project.name }}
    }
</h1>

<mat-dialog-content>
    <p>
        If a user is listed, but the ability cannot be set, the user has no allowed ability. This can be changed by the
        user's license owner.
    </p>
    <table mat-table matSort [dataSource]="dataSource">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name</th>
            <td *matCellDef="let row">{{ row.user.name }}</td>
        </ng-container>
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email</th>
            <td *matCellDef="let row">{{ row.user.email }}</td>
        </ng-container>
        <ng-container matColumnDef="access">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Access</th>
            <td *matCellDef="let row">
                @if (availableAbilities(row.user.ability); as aaa) {
                    <div class="vide-editable-value" [class.dirty]="row.form.controls.ability.dirty">
                        <!--  Ng-select doesn't work here for some reason... use native controls for now. -->
                        <!--                                                <ng-select-->
                        <!--                                                    [items]="aaa"-->
                        <!--                                                    [formControl]="row.form.controls.ability"-->
                        <!--                                                    [clearable]="false"-->
                        <!--                                                />-->
                        <select [formControl]="row.form.controls.ability">
                            @for (a of aaa; track a) {
                                <option [ngValue]="a">{{ a }}</option>
                            }
                        </select>
                    </div>
                }
            </td>
        </ng-container>
        <ng-container matColumnDef="comment">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Comment</th>
            <td *matCellDef="let row">
                @if (row.form.controls.comment; as cntrl) {
                    <div class="vide-editable-value" [class.dirty]="cntrl.dirty">
                        <input matInput [formControl]="cntrl"/>
                        <!-- <app-input [formControl]="cntrl"/>  -->
                    </div>
                }
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
    </table>
    <mat-divider/>
    <h2>Add external user</h2>
    <p>
        Add an existing user of {{ APP_NAME }}, not listed above, to the project
    </p>
    <input matInput type="email" [formControl]="newUserForm">
    <button mat-button (click)="addEmail()">Add</button>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button (click)="save()"> Save</button>
    <button mat-raised-button [mat-dialog-close]="someEdit">Exit</button>
</mat-dialog-actions>

<app-log-list [container]="logs"/>

@if (waiting) {
    <mat-progress-bar mode="buffer" [value]="100*completed/waiting"/>
}
