<!--<mat-form-field>-->
<!--    <mat-label>-->
<!--        Air pressure object-->
<!--    </mat-label>-->
<!--    <mat-select [formControl]="form">-->
<!--        @for (item of pressureCombos(); track item.object.id) {-->
<!--            <mat-option [value]="item">-->
<!--                {{ item.object.name }}-->
<!--                {{ item.statistics.measure_type.name }}-->
<!--                ({{ item.statistics.n_value }} measurements)-->
<!--            </mat-option>-->
<!--        }-->
<!--    </mat-select>-->
<!--</mat-form-field>-->

<div class="vide-columns-wide vide-form-field vide-form-field-row">
    <ng-select
        [formControl]="form"
        [items]="pressureCombos()"
        [searchFn]="searchFn"
        notFoundText="No objects found"
        placeholder="Ait pressure objects"
    >
        <ng-template ng-option-tmp ng-label-tmp let-item="item">
            {{ item.object.name }}
            {{ item.statistics.measure_type.name }}
            ({{ item.statistics.n_value }} measurements)
        </ng-template>
    </ng-select>
</div>

@if (figure(); as figure) {
    <plotly-plot
        [data]="figure.data"
        [layout]="figure.layout"
        [config]="figure.config"
        [useResizeHandler]="true"
    />
}

@if (waiting() > 0) {
    <mat-progress-bar mode="indeterminate"/>
}

<div class="stepper-navigate">
    <button mat-button matStepperPrevious type="button">Previous</button>
    <button mat-button matStepperNext type="button">Next</button>
</div>
