@if (projects(); as ps) {
    <section>
        @if (smallScreen()) {
            <button mat-raised-button (click)="onMeasurements(ps)">Field measurements</button>
        }
        <h2 class="mat-h2">Select project</h2>
        @if (ps.length === 0) {
            <p>Found no projects.</p>
        } @else {
            <div class="vide-columns-wide">
                <mat-nav-list>
                    @for (p of ps; track p) {
                        <mat-list-item [matTooltip]="p.coordinate_system.name +' ' + p.height_system.name"
                                       (click)="selectProject(p)">
                            <a matListItemTitle [routerLink]="projectLink(p)">{{ p.name }}</a>
                            @if (p.comment) {
                                <span matListItemLine>{{ p.comment }}</span>
                            }
                            @if (p.correlations_exists) {
                                <span matListItemLine>Uses correlations</span>
                            }
                            <span matListItemMeta>{{ p.ability }} </span>
                        </mat-list-item>
                    }
                </mat-nav-list>
            </div>
        }
    </section>
}
