@if (object(); as object) {

    @if (figure(); as figure) {
        <plotly-plot
            [data]="figure.data"
            [layout]="figure.layout"
            [config]="figure.config"
            [useResizeHandler]="true"
        />
    }

    <div>
        Calculated {{ nrCompensated() ?? 'no' }} values
    </div>

    <div>
        Continue with the following steps according to the type,
        or save the measurements to {{ object.name }}.
        Saving will overwrite existing uncompensated measurements with the new unreferenced values.
    </div>


    @if (saving()) {
        <mat-progress-bar mode="indeterminate"/>
    }

    <div class="stepper-navigate">
        <button mat-button matStepperPrevious type="button">Previous</button>
        <button mat-button matStepperNext type="button">Next</button>
        <button
            mat-button
            [disabled]="!nrCompensated()||saving()"
            (click)="save(object)"
        > @if (saving()) {
            Saving...
        } @else {
            Save to {{ object.name }}
        }
        </button>
    </div>
}
