@if (figure(); as plot) {
    <plotly-plot
        [data]="plot.data"
        [layout]="plot.layout"
        [config]="plot.config"
        [useResizeHandler]="true"
        [style]="plotlyStyle()"
        (relayout)="plotlyRelayout($event)"
    />
}

@if (objectsWithoutCoordinates(); as osWC) {
    @if (osWC.length > 0) {
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Unmapped objects ({{ nrObjectsWithoutCoordinates() }})</mat-panel-title>
            </mat-expansion-panel-header>
            {{ osWC }}
        </mat-expansion-panel>
    }
}

<ng-container>
    @if (mapPlotOptionsForm.controls.options.controls; as controls) {
        <mat-expansion-panel expanded class="vide-form-field"
        >
            <mat-expansion-panel-header>
                <mat-panel-title> Options</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="vide-columns-wide vide-form-field-row">
                <ng-select [items]="displayKinds()" [formControl]="controls.displayKind" groupBy="names"
                           [clearable]="false">
                    <ng-template ng-optgroup-tmp let-item="item">
                        <b> {{ item.title }}</b>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        {{ item }}
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item">
                        Display: {{ item }}
                    </ng-template>
                </ng-select>
                <ng-select [items]="measureTypes()" bindLabel="name" [formControl]="controls.measureType"
                           placeholder="Measure type" [clearable]="false">
                    <ng-template ng-label-tmp let-item="item">
                        Measure type: {{ item.name }}
                    </ng-template>
                </ng-select>
                <ng-select [formControl]="controls.transformKind" [searchable]="true"
                           placeholder="Measurement transformation"
                           [clearable]="false">
                    @for (t of MAP_PLOT_VALUE_DISPLAY; track t) {
                        <ng-option [value]="t" [disabled]="transformationDisabled(t)">
                            Measurement transformation: {{ t }}
                        </ng-option>
                    }
                </ng-select>
                <ng-select [items]="MAP_BACKGROUNDS" [formControl]="controls.background" groupBy="names"
                           [clearable]="false">
                    <ng-template ng-optgroup-tmp let-item="item">
                        <b> {{ item.title }}</b>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        {{ item.name ?? item }}
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item">
                        Background: {{ item.name ?? item }}
                    </ng-template>
                </ng-select>
                <ng-select [formControl]="controls.marker" [items]="MAPBOX_MARKERS" [searchable]="true"
                           placeholder="Marker"
                           bindLabel="name" [clearable]="false">
                    <ng-template ng-label-tmp let-item="item">
                        Marker: {{ item.name }}
                    </ng-template>
                </ng-select>
                <ng-select [formControl]="controls.includeAffected" [clearable]="false">
                    <ng-option [value]="true">Affected measurements included</ng-option>
                    <ng-option [value]="false">Affected measurements not included</ng-option>
                </ng-select>
            </div>
            <div class="vide-columns-wide vide-form-field-row">
                <mat-slide-toggle [formControl]="controls.useFontSize">Set font size</mat-slide-toggle>
                <mat-slider min="6" max="40" discrete showTickMarks [style.width]="'50%'"
                            [disabled]="!controls.useFontSize.value">
                    <input [formControl]="controls.fontSize" matSliderThumb>
                </mat-slider>
            </div>
            <div class="vide-form-field-row">
                <mat-slide-toggle [formControl]="controls.showLabels">Show labels</mat-slide-toggle>
                <mat-slide-toggle [formControl]="controls.objectNameInLabel">Object name in label</mat-slide-toggle>
                <mat-slide-toggle [formControl]="controls.onlyValue">Hide object without value</mat-slide-toggle>
            </div>
            <div [class.vide-disabled]="controls.last.disabled" class="vide-form-field-row">
                Time range:
                <app-input placeholder="" type="date" [max]="INPUT_DATE_MAX"
                           [formControl]="controls.first"></app-input>
                &mdash;
                <app-input placeholder="" type="date" [max]="INPUT_DATE_MAX"
                           [formControl]="controls.last"></app-input>
            </div>
            <div class="vide-form-field-row">
                Zero level date:
                <app-datetime [formControl]="controls.zeroLevelDateTime"/>
            </div>

            <mat-action-row>
                @if (!popupRef()) {
                    <button mat-raised-button (click)="selectObjectsWithValue()"
                            matTooltip="Deselect the objects that currently have no value">
                        Deselect objects without data
                    </button>
                }
                <button mat-raised-button (click)="resetPlotOptions()" matTooltip="Reset options">
                    <mat-icon aria-label="Reset options" fontIcon="restart_alt"></mat-icon>
                </button>
            </mat-action-row>
        </mat-expansion-panel>
    }

    @if (mapPlotOptionsForm.controls.colorScale.controls; as controls) {
        <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
                <mat-panel-title> Colorscale options</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="vide-form-field-row">
                <mat-slide-toggle [formControl]="controls.fixColorScale">Fixed colorscale</mat-slide-toggle>
                <span [class.vide-disabled]="controls.cmin.disabled"> min: </span>
                <app-input placeholder="" type="number" [clearable]="false"
                           [formControl]="controls.cmin"></app-input>
                <span [class.vide-disabled]="controls.cmax.disabled"> max: </span>
                <app-input placeholder="" type="number" [clearable]="false"
                           [formControl]="controls.cmax"></app-input>
            </div>
        </mat-expansion-panel>
    }
</ng-container>

<!--<div class="debug">-->
<!--    &lt;!&ndash; without: {{objectsWithoutCoordinates$ | async | json}} <br> &ndash;&gt;-->
<!--    &lt;!&ndash; with: {{objectsWithCoordinates$ | async | json}} <br> &ndash;&gt;-->
<!--    &lt;!&ndash; colorspan: {{currentColorspan | json}}<br> &ndash;&gt;-->
<!--    &lt;!&ndash; Form: {{mapPlotOptionsForm.getRawValue() | json}} &ndash;&gt;-->
<!--    &lt;!&ndash; Data: {{plotData$ | async | json}} <br> &ndash;&gt;-->
<!--    &lt;!&ndash; O: {{objects$ | async | json}} <br> &ndash;&gt;-->
<!--</div>-->
