import {Component, effect, inject} from '@angular/core'
import {FormControl, ReactiveFormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatIconModule} from "@angular/material/icon"

import {NgSelectComponent} from "@ng-select/ng-select"

import {AbilityComponent} from "../ability/ability.component"
import {DiverInfoComponent} from "../diver-info/diver-info.component"
import {HELP_SUBJECTS, HelpService, HelpSubject} from "../help.service"
import {ObjectSelectHelpComponent} from "../object-select-help/object-select-help.component"
import {PlotInfoComponent} from "../plot-info/plot-info.component"
import {PublicDataComponent} from "../public-data/public-data.component"

@Component({
    imports: [
        AbilityComponent,
        DiverInfoComponent,
        NgSelectComponent,
        ObjectSelectHelpComponent,
        PlotInfoComponent,
        PublicDataComponent,

        MatButtonModule,
        MatIconModule,
        ReactiveFormsModule,
    ],
    selector: 'app-help-sidenav',
    styleUrl: './help-sidenav.component.scss',
    templateUrl: './help-sidenav.component.html'
})
export class HelpSidenavComponent {
    protected readonly help = inject(HelpService)
    protected readonly subject = new FormControl<HelpSubject>('Plots', {nonNullable: true})

    constructor() {
        effect(() => {
            const state = this.help.state()
            if (state) {
                const subjectRequest = this.help.subjectRequest()
                console.log(subjectRequest)
                if (subjectRequest) {
                    this.subject.setValue(subjectRequest)
                    return
                }
                // Guess a relevant help page to open
                const parts = window.location.pathname.split('/')
                let value: HelpSubject
                switch (parts.at(-1)) {
                    case 'diver':
                        value = 'Diver'
                        break
                    case'smhi':
                    case'geoarkivet':
                    case'goteborg':
                    case 'sgu':
                        value = 'Public data'
                        break
                    default:
                        value = 'Plots'
                }
                if (parts.at(-2) === 'admin') {
                    value = 'User abilities'
                }

                this.subject.setValue(value)
            }
        })
    }

    close() {
        this.help.close()
    }

    protected readonly HELP_SUBJECTS = HELP_SUBJECTS
}
