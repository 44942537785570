import {Injectable} from '@angular/core'
import {FormBuilder} from '@angular/forms'
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"

import {combineLatest, delay, distinctUntilKeyChanged, filter, firstValueFrom, map, startWith} from 'rxjs'

import {AbstractMapboxService} from 'src/app/mapbox/abstract-mapbox.service'
import {IconMinimize, MAP_BACKGROUNDS_APIKEY, MapBackground} from 'src/app/mapbox/mapbox-helper'
import {objectWithPosition, PlotlyConfig, PlotlyScattermapboxData, PlotlySelected, VideFigure} from 'src/app/vide-types'
import {getMapPlotTextFont, PLOT_COLOR} from 'src/constants'
import {getObjectFullName, isDefined} from "../../shared/vide-helper"
import {ObjectSelectionDataService} from '../object-selection-data.service'
import {VideObject} from "../../api/api-types"
import {ColormapService} from "../../shared/colormap.service"
import {ObjectHighlightService} from "../../shared/object-highlight.service"

export const MAP_MARKERS: ReadonlyArray<{
    name: string,
    marker: { size: number } & PlotlySelected['marker']
}> = [
    {name: 'small', marker: {size: 4}},
    {name: 'medium', marker: {size: 6}},
    {name: 'medium solid', marker: {size: 6, opacity: 1}},
    {name: 'large', marker: {size: 8}},
    {name: 'x-large', marker: {size: 12}},
] as const
type MapMarker = typeof MAP_MARKERS[number]
const MARKER_HIGHLIGHT_SIZE = 20
export const MAP_LABELS = [
    'none',
    'selected',
    'all',
] as const
type MapLabel = typeof MAP_LABELS[number]

@Injectable({
    providedIn: 'root',
})
export class MapDataService extends AbstractMapboxService {
    readonly project$ = this.dataService.project$
    readonly plotlyStyle$ = this.dataService.plotlyStyle$
    readonly selectedObjects$ = this.dataService.selectedObjects$
    readonly selectionModel = this.dataService.selectionModel
    readonly objectsToDisplay$ = this.objectSelectionService.objectsToDisplay$
    readonly mapOptionsForm = this.formBuilder.nonNullable.group({
        background: ['outdoors' as MapBackground],
        marker: [MAP_MARKERS[1] as MapMarker],
        label: [MAP_LABELS[2] as MapLabel],
    })
    readonly figure$ = combineLatest([
        this.objectsToDisplay$,
        this.highlight.highlights$,
        this.dataService.plotlyToImage$,
        this.colorMapService.change,
        this.mapOptionsForm.valueChanges.pipe(startWith(true)),
        this.forceRelayout$,
        this.plotlyStyle$.pipe(delay(0)), // hack to get timing right for plot resize
    ]).pipe(
        // tap(x=>{console.log(x)}),
        map(([os, objectToHighlight, toImage,]) => {
            // console.warn(`new plotData ...`, this.currentPosition, os)
            const mapOptions = this.mapOptionsForm.getRawValue()
            const data = [this.objectMapperData(os, this.selectionModel, mapOptions, objectToHighlight)]
            const layout = this.getLayout(os, mapOptions)
            layout.dragmode = 'zoom'
            layout.clickmode = 'event'
            const config = this.getMapConfig(toImage.toImageOptions)
            const ret: VideFigure = {
                data,
                layout,
                config,
            }
            return ret
        }),
    )
    readonly zoomToSelectedButton = {
        name: 'zoomSelected',
        title: 'Zoom to selected objects',
        icon: IconMinimize,
        click: this.zoomToSelected.bind(this),
    }
    protected readonly useColorbar = false

    constructor(
        private readonly objectSelectionService: ObjectSelectionDataService,
        private readonly colorMapService: ColormapService,
        private readonly formBuilder: FormBuilder,
        private readonly highlight: ObjectHighlightService,
    ) {
        super()
        this.project$.pipe(
            takeUntilDestroyed(),
            filter(isDefined),
            distinctUntilKeyChanged('id')).subscribe(_p => {
            // console.debug(`clearing current position for new project ${p?.name}.`)
            this.clearPosition()
        })
        this.mapOptionsForm.controls.background.valueChanges.pipe(takeUntilDestroyed()).subscribe(v => {
            const ppp: readonly string[] = MAP_BACKGROUNDS_APIKEY
            const action = (ppp.includes(v)) ? 'enable' : 'disable'
            this.mapOptionsForm.controls.label[action]()
        })
    }

    async zoomToSelected() {
        console.warn(`Zoom to selected`)
        const os = await firstValueFrom(this.dataService.selectedObjects$)
        return this.zoomToObjects(os.filter(objectWithPosition))
    }

    protected override getMapConfig(toImageButtonOptions?: { filename: string }): PlotlyConfig {
        const baseConfig = super.getMapConfig(toImageButtonOptions)
        baseConfig.modeBarButtonsToAdd?.push(this.zoomToSelectedButton)
        baseConfig.modeBarButtonsToRemove = ['toImage']
        return baseConfig
    }


    private objectMapperData(
        objects: readonly VideObject[],
        selectionModel: { isSelected: (id: number) => boolean },
        options: ReturnType<MapDataService['mapOptionsForm']['getRawValue']>,
        objectToHighlight?: number
    ): PlotlyScattermapboxData {
        const color = Array<string>()
        const customdata = Array<number>()
        const hovertemplate = Array<string>()
        const lat = Array<number>()
        const lon = Array<number>()
        const opacity = Array<number>()
        const size = Array<number>()
        const text = Array<string>()
        objects.filter(objectWithPosition).forEach(o => {
            const sel = selectionModel.isSelected(o.id)
            const name = getObjectFullName(o)

            color.push(sel
                ? (this.colorMapService.useColormap ? this.colorMapService.get(o.id) : undefined) ?? PLOT_COLOR.object_selected
                : PLOT_COLOR.object_unselectd)
            customdata.push(o.id)
            hovertemplate.push(`${name}<extra>${o.comment?.replaceAll("\n", "<br>") ?? ''}</extra>`)
            lat.push(o.position.lat)
            lon.push(o.position.lon)
            opacity.push(sel ? 1 : (options.marker.marker.opacity ?? PLOT_COLOR.object_unselected_opacity))
            size.push(o.id === objectToHighlight ? MARKER_HIGHLIGHT_SIZE : options.marker.marker.size)
            text.push(options.label === 'selected' && !sel ? '' : name)
        })

        return {
            customdata,
            hoverinfo: "text",
            hoverlabel: {bgcolor: 'blue'},
            hovertemplate,
            lat,
            lon,
            marker: {
                color,
                opacity,
                size,
            },
            mode: options.label === 'none' ? 'markers' : 'text+markers',
            text,
            textfont: getMapPlotTextFont(options.background),
            textposition: 'middle right',
            type: "scattermapbox",
        }
    }
}
