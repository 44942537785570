@if (figure(); as figure) {
    <plotly-plot
        [data]="figure.data"
        [layout]="figure.layout"
        [config]="figure.config"
        [useResizeHandler]="true"
        [style]="plotlyStyle()"
        (selected)="plotlySelected($event)"
        (plotlyClick)="plotlyClick($event)"
        (relayout)="onPlotlyRelayout($event)"
    />
}
<mat-expansion-panel class="vide-form-field" expanded>
    <mat-expansion-panel-header>
        <mat-panel-title>Map options
            <button mat-icon-button [matTooltip]="mapHelpText" aria-label="Help">
                <mat-icon>help</mat-icon>
            </button>
        </mat-panel-title>
        <mat-panel-description>
            {{ nrObjects() }} objects shown
        </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="vide-columns vide-form-field-row">
        <ng-select [items]="MAP_BACKGROUNDS" [formControl]="mapOptionsForm.controls.background" groupBy="names"
                   [clearable]="false">
            <ng-template ng-optgroup-tmp let-item="item">
                <b> {{ item.title }}</b>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
                {{ item.name ?? item }}
            </ng-template>
            <ng-template ng-label-tmp let-item="item">
                Background: {{ item.name ?? item }}
            </ng-template>
        </ng-select>
        <ng-select [items]="mapMarkers" [formControl]="mapOptionsForm.controls.marker" [clearable]="false"
                   bindLabel="name">
            <ng-template ng-label-tmp let-item="item">
                Markers: {{ item.name }}
            </ng-template>
        </ng-select>
        <ng-select [items]="mapLabels" [formControl]="mapOptionsForm.controls.label" [clearable]="false">
            <ng-template ng-label-tmp let-item="item">
                Labels: {{ item }}
            </ng-template>
        </ng-select>
    </div>
</mat-expansion-panel>
