import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogModule} from '@angular/material/dialog'
import {MatButtonModule} from "@angular/material/button"

export type ConfirmDialogResult = boolean
export type ConfirmDialogData = MatDialogConfig<{
    header: string,
    text?: string,
    textList?: string[],
    textPre?: string,
    positive_text?: string | null,
    negative_text?: string | null,
}>['data']
export type ConformDialogResult = boolean

@Component({
    standalone: true,
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    imports: [
        MatDialogModule,
        MatButtonModule,
    ]
})
export class ConfirmDialogComponent {
    response: ConfirmDialogResult = false

    constructor(
        @Inject(MAT_DIALOG_DATA) readonly data: ConfirmDialogData,
    ) {
    }
}
