import {Component} from '@angular/core'
import {MatCardModule} from "@angular/material/card"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {MatStepperModule} from "@angular/material/stepper"
import {toSignal} from "@angular/core/rxjs-interop"

import {BarometerMeasurementsComponent} from "./barometer-measurements/barometer-measurements.component"
import {CompensateDiverComponent} from "./compensate-diver/compensate-diver.component"
import {DiverAnnotationsComponent} from "./diver-annotations/diver-annotations.component"
import {DiverService} from "./diver.service"
import {ImportDiverComponent} from "./import-diver/import-diver.component"
import {LogListComponent} from "../../log-list/log-list.component"
import {ReferenceDiverComponent} from "./reference-diver/reference-diver.component"
import {SelectDiverObjectComponent} from "./select-diver-object/select-diver-object.component"
import {VideObject} from "../../api/api-types"

export interface SlimObject extends Pick<VideObject, 'id' | 'name'> {
}

@Component({
    selector: 'app-diver',
    standalone: true,
    imports: [
        BarometerMeasurementsComponent,
        CompensateDiverComponent,
        DiverAnnotationsComponent,
        ImportDiverComponent,
        LogListComponent,
        ReferenceDiverComponent,
        SelectDiverObjectComponent,

        MatCardModule,
        MatProgressBarModule,
        MatStepperModule,
    ],
    templateUrl: './diver.component.html',
    styleUrl: './diver.component.scss',
})
export class DiverComponent {
    protected readonly logs = this.service.logs
    protected readonly selectedObject = toSignal(this.service.selectedObject$)

    constructor(
        protected readonly service: DiverService,
    ) {
    }
}
