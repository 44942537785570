import {Component, effect, ElementRef, input, viewChild} from '@angular/core'
import {MatButtonModule} from "@angular/material/button"
import {MatSelectModule} from "@angular/material/select"
import {MatStepperModule, MatStepperNext} from "@angular/material/stepper"
import {ReactiveFormsModule} from "@angular/forms"
import {outputFromObservable, toSignal} from "@angular/core/rxjs-interop"

import {PlotlyViaWindowModule} from "angular-plotly.js"

import {CompensateDiverService} from "./compensate-diver.service"
import {InputComponent} from "../../../forms/input/input.component"
import {LocalDiverData} from "../diver.service"
import {SlimObject} from "../diver.component"
import {map} from "rxjs"
import {VideObject} from "../../../api/api-types"

@Component({
    selector: 'app-compensate-diver',
    standalone: true,
    imports: [
        InputComponent,

        MatButtonModule,
        MatSelectModule,
        MatStepperModule,
        PlotlyViaWindowModule,
        ReactiveFormsModule,
    ],
    templateUrl: './compensate-diver.component.html',
    styleUrl: './compensate-diver.component.scss'
})
export class CompensateDiverComponent {
    protected readonly baroCombos = toSignal(this.service.baroCombos$)
    protected readonly data = outputFromObservable(this.service.localCompensatedData$)
    protected readonly figure = toSignal(this.service.compensatedFigure$)
    protected readonly form = this.service.baroForm
    protected readonly nextButton = viewChild.required(MatStepperNext, {read: ElementRef})
    protected readonly nrCompensated = toSignal(this.service.compensatedNrData$)
    protected readonly nrInput = outputFromObservable(this.service.nrInputData$)
    protected readonly saving = this.service.saving

    readonly baroObject = input.required<SlimObject | null>()
    readonly diverObject = input.required<SlimObject>()
    readonly localDiverData = input.required<LocalDiverData | null>()

    constructor(
        private readonly service: CompensateDiverService,
    ) {
        effect(() => {
            const diver = this.diverObject()
            this.service.diverObject.next(diver)
        }, {allowSignalWrites: true})
        effect(() => {
            const diverData = this.localDiverData()
            this.service.fileData.next(diverData)
        }, {allowSignalWrites: true})
        effect(() => {
            const o = this.baroObject()
            const x = this.baroCombos()?.find(c => c.object.id === o?.id)
            if (x) {
                this.form.setValue(x)
            }
        })
    }

    async save(object: Pick<VideObject, 'id' | 'name'>) {
        const res = await this.service.save(object)
        if (res) {
            const el = this.nextButton().nativeElement
            if (el instanceof HTMLButtonElement) {
                el.click()
            }
        }
    }

}
