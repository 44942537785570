import {Component, input} from '@angular/core'
import {MatCell, MatCellDef, MatColumnDef, MatHeaderCell, MatHeaderCellDef} from "@angular/material/table"
import {MatSortHeader} from "@angular/material/sort"
import {CdkTextColumn} from "@angular/cdk/table"

@Component({
    selector: 'app-sorted-text-column',
    standalone: true,
    imports: [
        MatCell,
        MatCellDef,
        MatColumnDef,
        MatHeaderCell,
        MatHeaderCellDef,
        MatSortHeader,
    ],
    templateUrl: './sorted-text-column.component.html',
    styleUrl: './sorted-text-column.component.scss'
})
export class SortedTextColumnComponent<T> extends CdkTextColumn<T> {
    disableSort = input(false, {
        transform: (value: boolean | string) => typeof value === 'string' ? value === '' : value,
    })
}
