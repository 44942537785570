import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogModule, MatDialogRef} from "@angular/material/dialog"
import {MatButtonModule} from "@angular/material/button"

import {ImportParameter, ImportType} from "../../api/api-types"


export type ImportFileInfoDialogResult = never
export type ImportFileInfoDialogData = MatDialogConfig<{
    importInfo: ImportParameter
    importType: ImportType
}>['data']

@Component({
    selector: 'app-import-file-info-dialog',
    standalone: true,
    imports: [
        MatButtonModule,
        MatDialogModule,
    ],
    templateUrl: './import-file-info-dialog.component.html',
    styleUrl: './import-file-info-dialog.component.scss'
})
export class ImportFileInfoDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<ImportFileInfoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ImportFileInfoDialogData,
    ) {
    }
}
