import {HttpErrorResponse, HttpInterceptorFn, HttpStatusCode} from '@angular/common/http'
import {inject} from "@angular/core"
import {MatSnackBar} from "@angular/material/snack-bar"
import {MatDialog} from "@angular/material/dialog"

import {catchError, mergeMap, of, retry, tap} from "rxjs"

import {
    LoginDialogComponent,
    LoginDialogComponentData,
    LoginDialogComponentResult
} from "../dialogs/login-dialog/login-dialog.component"
import {XsrfCookieService} from "../shared/xsrf-cookie.service"

export const errorInterceptor: HttpInterceptorFn = (request, next) => {
    const snackBar = inject(MatSnackBar)
    const dialog = inject(MatDialog)
    const xsrf = inject(XsrfCookieService)

    function tryLogin() {
        const ref = dialog.open<
            LoginDialogComponent,
            LoginDialogComponentData,
            LoginDialogComponentResult
        >(LoginDialogComponent, {
            disableClose: true,
        })
        return ref.afterClosed().pipe(
            tap(x => {
                console.warn(x)
            }),
        )
    }

    return next(request).pipe(
        catchError(error => {
            if (error instanceof HttpErrorResponse) {
                // No login for reset-password or set-password
                if ((error.status === 401 || error.status === 419) && !location.href.includes('set-password')) {
                    return tryLogin().pipe(
                        mergeMap(() => next(xsrf.addXsrfHeader(request))),
                        // tap(x => {                                console.warn(x)                            }),
                    )
                } else if (error.status === 500) {
                    return next(request)
                }

            }
            throw error
        }),
        retry({
            count: 1, delay: (error, retryCount) => {
                // If the error is a 500, throw the error so we retry.
                // This is because there is some Swoole problem in the backend, and it just crashes sometimes.
                if (error instanceof HttpErrorResponse) {
                    if (error.status !== HttpStatusCode.InternalServerError)
                        throw error
                }
                // All other cases, just carry on with normal error handling.
                return of(null)
            }
        }),
        catchError((error, _caught) => {
            if (request.method === 'GET' && error instanceof HttpErrorResponse) {
                switch (error.status) {
                    case HttpStatusCode.Unauthorized:
                        console.warn('Authorization error, try to log in again')
                        // return this.tryLogin().pipe(map(() => caught), switchAll())
                        break
                    case HttpStatusCode.NotFound:
                        console.warn('Not found ', error)
                        snackBar.open(error.message, 'Dismiss')
                        break
                    default:
                        console.warn("some http error occurred:", error)
                        snackBar.open(error.message, 'Dismiss')
                        break
                }
            }
            throw error
        }),
    )
}
