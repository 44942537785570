import { Component } from '@angular/core';

@Component({
  selector: 'app-correlation-analysis',
  standalone: true,
  imports: [],
  templateUrl: './correlation-analysis.component.html',
  styleUrl: './correlation-analysis.component.scss'
})
export class CorrelationAnalysisComponent {

}
