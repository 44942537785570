import {HttpInterceptorFn} from '@angular/common/http'
import {inject} from "@angular/core"

import {finalize} from "rxjs"

import {HttpCounterService} from "../shared/http-counter.service"

export const counterInterceptor: HttpInterceptorFn = (req, next) => {
    const counter = inject(HttpCounterService)

    counter.increase()

    return next(req).pipe(
        finalize(() => {
            counter.decrease()
        })
    )
}
