<table mat-table matSort [dataSource]="dataSource">
    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="isSomeSelected() && isAllSelected()"
                          [indeterminate]="isSomeSelected() && !isAllSelected()">
            </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
            <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? selectionModel.toggle(row.object.id) : null"
                [checked]="selectionModel.isSelected(row.object.id)"
            />
        </td>
    </ng-container>
    <ng-container matColumnDef="object">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Object</th>
        <td mat-cell *matCellDef="let row"> {{ row.object.name }}</td>
    </ng-container>
    <ng-container matColumnDef="project">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Project</th>
        <td mat-cell *matCellDef="let row"> {{ row.project.name }}</td>
    </ng-container>
    <ng-container matColumnDef="noCoordinates">
        <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row"> {{ row.object.position ? '' : 'No position' }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
</table>

<mat-paginator
    showFirstLastButtons
    [length]="shareableObjectIds().length"
    [pageSize]="pageSize"
/>
