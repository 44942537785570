import {PostResponse} from "src/app/api/api-helper"
import {LogMessage} from "./log-list.component"

function firstUppercase(s: string) {
    return s.at(0)?.toLocaleUpperCase() + s.slice(1)
}

export class LogContainer {
    messages: LogMessage[] = []

    constructor(private defaultAction: string = 'Action') {
    }

    /**
     * Add a success or fail message depending on the status of data
     *
     * @param data
     * @param action
     */
    add(data: PostResponse<any>, action?: string) {
        const tag = firstUppercase(action ?? this.defaultAction)
        const msg = data.success
            ? `${tag} successful`
            : `${tag} fail: ${data.error}`
        this.messages.push({error: !data.success, message: msg})
    }

    addPlainMessage(message: string) {
        this.messages.push({error: false, message})
    }
}
