<table
    recycleRows
    matSort
    mat-table
    [dataSource]="dataSource"
    class=""
>
<!--    <ng-container matColumnDef="test">-->
<!--        <th mat-header-cell *matHeaderCellDef> Test</th>-->
<!--        <td mat-cell *matCellDef="let junk"> {{ junk|json }}</td>-->
<!--    </ng-container>-->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name</th>
        <td mat-cell *matCellDef="let row">
            {{ row.object.name }}
        </td>
    </ng-container>
    <ng-container matColumnDef="owner">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Source project</th>
        <td mat-cell *matCellDef="let row">
            {{ row.object.owner?.project_name }}
        </td>
    </ng-container>
    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Measure type</th>
        <td mat-cell *matCellDef="let row">
            <!-- {{ row.statistics | json}} -->
            {{ row.statistics.measure_type.name }}
            ({{ row.statistics.measure_type.measure_unit.name }})
        </td>
    </ng-container>
    <ng-container matColumnDef="first_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>First date</th>
        <td mat-cell *matCellDef="let row">
            {{ row.statistics.first_date| date : DATE_FORMAT }}
        </td>
    </ng-container>
    <ng-container matColumnDef="last_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Last date</th>
        <td mat-cell *matCellDef="let row">
            {{ row.statistics.last_date| date : DATE_FORMAT }}
        </td>
    </ng-container>
    <ng-container matColumnDef="last_date_value">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
            matTooltip="Date of last measurement with numeric value if different from last date">
            Last date (numeric)
        </th>
        <td mat-cell *matCellDef="let row" [class.shadow]="row.statistics.last_date === row.statistics.last_date_value">
            {{ row.statistics.last_date_value | date : DATE_FORMAT }}
        </td>
    </ng-container>
    <ng-container matColumnDef="fill_rate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="number"
            matTooltip="Only valid measurements are shown" style="text-align: right;">Current fill rate
        </th>
        <td mat-cell *matCellDef="let row" class="number">
            {{ getLastFillRate(row) | percent }}
        </td>
    </ng-container>
    @for (def of numberColumns; track def.id) {
        <ng-container [matColumnDef]="def.id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [matTooltip]="def.tooltip">
                {{ def.header }}
            </th>
            <td mat-cell *matCellDef="let row" class="number"
                [matTooltip]="def.format?row.statistics[def.id]:undefined">
                {{ row.statistics[def.id] | number : def.format }}
            </td>
        </ng-container>
    }

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"
        [class.active]="selectionModel.isSelected(myRowData.object.id)"
        (click)="selectionModel.toggle(myRowData.object.id)">
    </tr>
</table>

@if (pageSize(); as pageSize) {
    <mat-paginator
        [pageSize]="pageSize.options[0]"
        [length]="pageSize.length"
        [pageSizeOptions]="pageSize.options"
    />
}

<button mat-raised-button (click)="exportTable()">Export table</button>
