import {Component} from '@angular/core'
import {MatCardModule} from "@angular/material/card"
import {APP_NAME} from "../../../constants"

@Component({
    selector: 'app-ability',
    standalone: true,
    imports: [
        MatCardModule,
    ],
    templateUrl: './ability.component.html',
    styleUrl: './ability.component.scss'
})
export class AbilityComponent {

    protected readonly APP_NAME = APP_NAME
}
