import {Component, Inject} from '@angular/core'
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms"
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogModule, MatDialogRef} from "@angular/material/dialog"
import {MatButtonModule} from "@angular/material/button"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatInputModule} from "@angular/material/input"
import {MatTooltipModule} from "@angular/material/tooltip"

import {AuthService} from "../../auth/auth.service"
import {LogContainer} from "../../log-list/log-container"
import {LogListComponent} from "../../log-list/log-list.component"
import {getErrorMessage} from "../../shared/vide-helper"

export type RequestResetLinkData = MatDialogConfig<{ email: string }>['data']
export type RequestResetLinkResult = boolean | any

@Component({
    imports: [
        LogListComponent,

        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatTooltipModule,
        ReactiveFormsModule,
    ],
    selector: 'app-request-reset-link',
    styleUrl: './request-reset-link.component.scss',
    templateUrl: './request-reset-link.component.html'
})
export class RequestResetLinkComponent {
    readonly control = this.formBuilder.nonNullable.control(this.data?.email ?? '', Validators.required)
    readonly logs = new LogContainer()

    constructor(
        @Inject(MAT_DIALOG_DATA) readonly data: RequestResetLinkData,
        private readonly auth: AuthService,
        private readonly formBuilder: FormBuilder,
        private readonly ref: MatDialogRef<RequestResetLinkComponent, RequestResetLinkResult>,
    ) {
    }

    protected readonly getErrorMessage = getErrorMessage

    sendLink() {
        this.control.markAllAsTouched()
        if (!this.control.valid) {
            console.warn("Form invalid")
            return
        }
        console.log(`Password reset request`)
        const email = this.control.value
        this.auth.sendResetLink(email).then(x => {
            console.warn(x)
            if (x.success) {
                this.ref.close(true)
            } else {
                this.logs.add(x, 'Request reset link')
            }
        })
    }
}
