import {CdkDragDrop, DragDropModule, moveItemInArray} from '@angular/cdk/drag-drop'
import {Component, effect, OnInit} from '@angular/core'
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms'
import {MatDialog} from '@angular/material/dialog'
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop"
import {MatCardModule} from "@angular/material/card"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatSelectModule} from "@angular/material/select"
import {MatTooltipModule} from "@angular/material/tooltip"
import {MatIconModule} from "@angular/material/icon"
import {MatSlideToggleModule} from "@angular/material/slide-toggle"
import {MatInputModule} from "@angular/material/input"
import {MatListModule} from "@angular/material/list"
import {MatButtonModule} from "@angular/material/button"

import {firstValueFrom} from 'rxjs'

import {VideDataService} from 'src/app/api/vide-data.service'
import {ComponentCanDeactivate} from 'src/app/can-deactivate.guard'
import {ConfirmDialogComponent, ConfirmDialogData} from 'src/app/dialogs/confirm-dialog/confirm-dialog.component'
import {ObjectSelectDialogComponent} from 'src/app/dialogs/object-select-dialog/object-select-dialog.component'
import {LogContainer} from 'src/app/log-list/log-container'
import {arraysEqual, filterNullAndUndefined, getErrorMessage} from 'src/app/shared/vide-helper'
import {ABILITY} from "../../ability"
import {Group, VideObject} from "../../api/api-types"
import {LogListComponent} from "../../log-list/log-list.component"

@Component({
    selector: 'app-edit-groups-existing',
    standalone: true,
    templateUrl: './edit-groups-existing.component.html',
    styleUrls: ['./edit-groups-existing.component.scss'],
    imports: [
        DragDropModule,
        LogListComponent,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTooltipModule,
        ReactiveFormsModule,
    ]
})
export class EditGroupsExistingComponent extends ComponentCanDeactivate implements OnInit {
    readonly tooltipGlobal = "A global group is available for all database admin-users. Otherwise the group is personal and only accessible to the current user." as const
    readonly tooltipOrder = "Drag rows to change order" as const

    readonly project = this.dataService.project
    readonly objects = toSignal(this.dataService.objects$, {initialValue: []})
    readonly groups = toSignal(this.dataService.groups$)
    editableList: VideObject[] = []
    originalEditableList: VideObject[] = []
    readonly logs = new LogContainer()

    readonly groupControl = new FormControl<Group | null>(null, {
        validators: [Validators.required],
        nonNullable: true,
    })
    readonly form = new FormGroup({
        name: new FormControl('', {
            validators: [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(255),
            ],
            nonNullable: true,
        }),
        global: new FormControl(false, {nonNullable: true}),
        ordered: new FormControl(true, {nonNullable: true}),
    })
    getErrorMessage = getErrorMessage
    protected readonly ABILITY = ABILITY

    constructor(
        private dataService: VideDataService,
        private dialog: MatDialog,
    ) {
        super()
        this.form.valueChanges.pipe(takeUntilDestroyed()).subscribe(_ => {
            this.updateGroupEnabled()
        })
        effect(() => {
            const newGroups = this.groups()
            const current = this.groupControl.value
            const newG = newGroups?.find(g => g.id === current?.id) ?? null
            this.groupControl.setValue(newG)
        })
    }

    canDeactivate(): boolean {
        return !this.form.dirty && listEqualOnId(this.originalEditableList, this.editableList)
    }

    ngOnInit(): void {
        this.groupControl.valueChanges.subscribe(vs => {
            console.warn("got new group", vs)
            this.resetGroupValues(vs)
        })
    }

    drop(event: CdkDragDrop<VideObject[]>) {
        console.log(event)
        moveItemInArray(this.editableList, event.previousIndex, event.currentIndex)
        this.updateGroupEnabled()
    }

    deleteFromGroup(idx: number) {
        this.editableList.splice(idx, 1)
        this.updateGroupEnabled()
    }

    async copyGroup() {
        this.groupControl.markAsTouched()
        const group = this.groupControl.value
        const project = this.project()
        if (group && project) {
            const newName = group.name + ' (copy)'
            // const project = await firstValueFrom(this.project$.pipe(filter(isDefined),))
            const g = await firstValueFrom(this.dataService.createGroup(project, newName, group.object_ids))
            this.logs.add(g, `Group copy (new name '${newName}')`)
            if (g.success) {
                this.dataService.reloadProjectData()
            }
        }
    }

    delete() {
        this.groupControl.markAsTouched()
        const group = this.groupControl.value
        if (group) {
            console.debug('Delete group ', group.name)
            const dialogRef = this.dialog.open<
                ConfirmDialogComponent,
                ConfirmDialogData
            >(ConfirmDialogComponent,
                {
                    data: {
                        header: `Really delete '${group.name}'?`,
                        positive_text: 'Delete',
                        negative_text: 'Cancel',
                    },
                },
            )
            dialogRef.afterClosed().subscribe(result => {
                console.debug(result)
                if (result === true) {
                    this.realDeleteGroup(group)
                }
            })
        }
    }

    async save() {
        this.groupControl.markAllAsTouched()
        this.form.markAllAsTouched()
        const group = this.groupControl.value
        const objectIds = this.editableList.map(item => item.id)
        if (group && this.form.valid && (!this.form.pristine || !arraysEqual(group.object_ids, objectIds))) {
            const values = this.form.getRawValue()
            const x = await firstValueFrom(this.dataService.updateGroup(
                {
                    id: group.id,
                    global: values.global,
                    name: values.name,
                    object_ids: objectIds,
                    order: values.ordered,
                },
            ))
            this.logs.add(x, 'Save')
            console.warn(x)
            if (x.success) {
                this.dataService.reloadProjectData()
                this.form.markAsPristine()
                // console.log(`Select the same group again?`)
            }
        }
    }

    resetGroup() {
        const g = this.groupControl.value
        if (g) {
            this.resetGroupValues(g)
        }
    }

    async addObjectToList() {
        this.groupControl.markAsTouched()
        const objects = this.objects()
        const ref = this.dialog.open<
            ObjectSelectDialogComponent<VideObject>,
            ObjectSelectDialogComponent<VideObject>['data'],
            ObjectSelectDialogComponent<VideObject>['result']
        >(ObjectSelectDialogComponent, {
            data: {
                objects: objects,
                search: true,
                name: 'Add object',
            },
        })
        ref.afterClosed().subscribe(o => {
            if (o && this.editableList.find(x => x.id === o.id) === undefined) {
                this.editableList.push(o)
                this.updateGroupEnabled()
                console.debug(`Added ${o.name} `)
            }
        })
    }

    private updateGroupEnabled() {
        const action = this.canDeactivate() ? 'enable' : 'disable'
        this.groupControl[action]({emitEvent: false})
    }

    private resetGroupValues(group: Group | null) {
        this.resetObjectList(group)
        this.form.markAsPristine()
        if (group) {
            this.form.patchValue({name: group.name, global: group.global, ordered: true}, {emitEvent: true})
            // this.form.patchValue({ name: group.name, global: group.global, ordered: true }, { emitEvent: false })
        } else {
            this.form.reset()
        }
    }

    private resetObjectList(group: Group | null) {
        const os = this.objects()
        const groupObjects = os.filter(o => group?.object_ids.includes(o.id))
        const sortedObjects = filterNullAndUndefined(group?.object_ids.map(id => groupObjects.find(o => o.id === id)) ?? [])
        this.editableList = sortedObjects
        this.originalEditableList = sortedObjects.slice()
    }

    private async realDeleteGroup(group: Group) {
        const x = await firstValueFrom(this.dataService.deleteGroup(group))
        console.debug(x)
        this.logs.add(x, 'Delete')
        if (x.success) this.dataService.reloadProjectData()
        return x
    }
}

function listEqualOnId<T extends { id: number }>(a: T[], b: T[]): boolean {
    if (a.length !== b.length) {
        return false
    }
    for (let index = 0; index < a.length; index++) {
        if (a[index]?.id !== b[index]?.id)
            return false
    }
    return true
}

