import {Component, effect, input} from '@angular/core'
import {MatProgressBarModule} from "@angular/material/progress-bar"

import {AuthService} from "../auth.service"
import {Unpacked} from "../../vide-types"

@Component({
    selector: 'app-set-password',
    standalone: true,
    imports: [
        MatProgressBarModule
    ],
    templateUrl: './set-password.component.html',
    styleUrl: './set-password.component.scss'
})
export class SetPasswordComponent {
    // Query param binding
    email = input<string>()
    protected result: Unpacked<AuthService['sendResetLink']> | undefined

    constructor(
        private readonly auth: AuthService,
    ) {
        effect(() => {
            const email = this.email()
            console.warn(`Got email ${email}`)
            if (email) {
                this.auth.sendResetLink(email).then(r => {
                        this.result = r
                        if (r.success) {
                            setTimeout(() => {
                                window.close()
                            }, 2000)
                        }
                    }
                )
            }
        })
    }
}
