import {Component, ElementRef, viewChild} from '@angular/core'
import {MatButtonModule} from "@angular/material/button"
import {MatMenuModule} from "@angular/material/menu"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {MatSelectModule} from "@angular/material/select"
import {MatSlideToggleModule} from "@angular/material/slide-toggle"
import {MatStepperModule, MatStepperNext} from "@angular/material/stepper"
import {ReactiveFormsModule} from "@angular/forms"
import {toSignal} from "@angular/core/rxjs-interop"

import {BehaviorSubject} from "rxjs"
import {NgSelectModule} from "@ng-select/ng-select"
import {PlotlyViaWindowModule} from "angular-plotly.js"

import {DIVER} from "../../../../constants"
import {DiverService} from "../diver.service"
import {ReferenceDiverService} from "./reference-diver.service"
import {SlimObject} from "../diver.component"
import {Unpacked} from "../../../vide-types"

@Component({
    selector: 'app-reference-diver',
    standalone: true,
    imports: [
        MatButtonModule,
        MatMenuModule,
        MatProgressBarModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatStepperModule,
        NgSelectModule,
        PlotlyViaWindowModule,
        ReactiveFormsModule,
    ],
    templateUrl: './reference-diver.component.html',
    styleUrl: './reference-diver.component.scss'
})
export class ReferenceDiverComponent {
    // properties
    private readonly _saving = new BehaviorSubject(false)
    protected readonly DIVER = DIVER
    protected readonly form = this.service.form
    // inputs
    // outputs
    // view children
    protected readonly nextButton = viewChild.required(MatStepperNext, {read: ElementRef})
    // signals
    protected readonly annotations = toSignal(this.service.referenceAnnotations$)
    protected readonly figure = toSignal(this.service.refFigure$)
    protected readonly nrReferenced = toSignal(this.service.nrReferenced$)
    protected readonly object = toSignal(this.diverService.selectedObject$)
    protected readonly possibleReferenceMeasurements = toSignal(this.service.possibleRefMeasurements$, {initialValue: []})
    protected readonly possibleReferenceObjects = toSignal(this.service.possibleRefObjects$)
    protected readonly saving = toSignal(this._saving)

    constructor(
        private readonly service: ReferenceDiverService,
        private readonly diverService: DiverService,
    ) {
    }

    readonly plotlyClick = this.service.diverFigureClick.bind(this.service)
    readonly doubleClick = this.service.diverDoubleClick.bind(this.service)

    protected searchFn(term: string, item: Unpacked<ReferenceDiverComponent['possibleReferenceObjects']>) {
        return item?.object.name.toLocaleLowerCase().includes(term.toLocaleLowerCase())
    }

    async save(object: SlimObject) {
        this._saving.next(true)
        const res = await this.service.save(object).finally(() => {
            this._saving.next(false)
        })
        if (res) {
            const el = this.nextButton().nativeElement
            if (el instanceof HTMLButtonElement) {
                el.click()
            }
        }
    }
}
