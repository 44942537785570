@if (object(); as object) {
    <!--    <mat-form-field>-->
    <!--        <mat-label>-->
    <!--            Object with reference level measurement-->
    <!--        </mat-label>-->
    <!--        <mat-select-->
    <!--            [formControl]="form.controls.refObject"-->
    <!--        >-->
    <!--            @for (item of possibleReferenceObjects(); track item.object.id) {-->
    <!--                <mat-option [value]="item">-->
    <!--                    {{ item.object.name }}-->
    <!--                    {{ item.statistics.measure_type.name }}-->
    <!--                </mat-option>-->
    <!--            } @empty {-->
    <!--                <mat-option>Found no objects</mat-option>-->
    <!--            }-->
    <!--        </mat-select>-->
    <!--    </mat-form-field>-->

    <!--    <mat-form-field>-->
    <!--        <mat-label>-->
    <!--            Reference level-->
    <!--        </mat-label>-->
    <!--        <mat-select-->
    <!--            [formControl]="form.controls.refMeasurement"-->
    <!--        >-->
    <!--            @for (item of possibleReferenceMeasurements(); track item.id) {-->
    <!--                <mat-option [value]="item">-->
    <!--                    {{ item.measuretime }}-->
    <!--                    {{ item.resulting_value }}-->
    <!--                </mat-option>-->
    <!--            } @empty {-->
    <!--                <mat-option>No measurement in current timespan</mat-option>-->
    <!--            }-->
    <!--        </mat-select>-->
    <!--    </mat-form-field>-->

    <div class="vide-columns-wide vide-form-field vide-form-field-row">
        <ng-select
            [formControl]="form.controls.refObject"
            [items]="possibleReferenceObjects()"
            [searchFn]="searchFn"
            notFoundText="No objects found"
            placeholder="Object with level measurements"
        >
            <ng-template ng-option-tmp ng-label-tmp let-item="item">
                {{ item.object.name }}
                {{ item.statistics.measure_type.name }}
            </ng-template>
        </ng-select>
        <ng-select
            [formControl]="form.controls.refMeasurement"
            [items]="possibleReferenceMeasurements()"
            [searchable]="false"
            notFoundText="No measurements in current timespan"
            placeholder="Reference level"
        >
            <ng-template ng-option-tmp ng-label-tmp let-item="item">
                {{ item.measuretime }}
                {{ item.resulting_value }}
            </ng-template>
        </ng-select>
    </div>

    <div>
        Measurements at most {{ DIVER.REFERENCE_OUTSIDE_DATES_DAYS }} days before/after the compensated measurements are
        considered for reference.
    </div>


    @if (figure(); as figure) {
        <p>Click in the plot to set start and end of interval to use. Double click to clear.</p>
        <plotly-plot
            [data]="figure.data"
            [layout]="figure.layout"
            [config]="figure.config"
            [useResizeHandler]="true"
            (plotlyClick)="plotlyClick($event)"
            (doubleClick)="doubleClick()"
        />
    }

    <!--    <div class="vide-columns-wide">-->
    <div>
        @if (annotations(); as ann) {
            @if (ann[0]; as begin) {
                <div>Begin: {{ begin.x }}</div>
            }
            @if (ann[1]; as end) {
                <div>End: {{ end.x }}</div>
            }
        }

        <p> Calculated {{ nrReferenced() ?? 'no' }} values</p>
        <div>
            Saving will overwrite existing unreferenced measurements with the new referenced values.
        </div>
    </div>

    @if (saving()) {
        <mat-progress-bar mode="indeterminate"/>
    }

    <div class="stepper-navigate">
        <button mat-button matStepperPrevious type="button">Previous</button>
        <button mat-button matStepperNext type="button">Next</button>
        <button mat-button
                [disabled]="!nrReferenced() || !figure() || saving()"
                (click)="save(object)"
        >
            @if (saving()) {
                Saving...
            } @else {
                Save to {{ object.name }}
            }
        </button>
    </div>

}
