import {KeyValuePipe} from "@angular/common"
import {Component} from '@angular/core'
import {HttpClient} from "@angular/common/http"

import {ApiCallService} from "../api/api-call.service"
import {COLOR_SEQUENCES} from "../shared/colors"
import {TypedHttpService} from "../import/typed-http.service"
import {VideDataService} from "../api/vide-data.service"
import {parseCsv} from "../csv/csv-parser"


@Component({
    selector: 'app-test',
    templateUrl: './test.component.html',
    styleUrls: ['./test.component.scss'],
    imports: [
        KeyValuePipe,
    ]
})
export class TestComponent {

    constructor(
        private readonly service: VideDataService,
        private readonly api: ApiCallService,
        private readonly http: HttpClient,
        private readonly typedHttp: TypedHttpService,
    ) {
        console.warn(parseCsv(['a,b,c', "\"a\nb\",c,d"].join("\n"), {eol: "\n"}))

    }

    protected readonly colorSequences = COLOR_SEQUENCES

    getJunk(key: string) {
        const k = key as keyof typeof this.colorSequences
        // return [1,2,3]
        return [...this.colorSequences[k]]
    }

    // Test with https://openlayers.org/
    // ngAfterViewInit() {
    //     // this.map = new Map({
    //     //     layers: [
    //     //         new TileLayer({
    //     //             // source:new OSM(),
    //     //             source: new TileWMS({
    //     //                 url: 'https://maps3.sgu.se/geoserver/jord/ows',
    //     //                 params: {layers: 'jord:SE.GOV.SGU.JORD.GRUNDLAGER.25K'},
    //     //                 projection: 'EPSG:3857'
    //     //             }),
    //     //         }),
    //     //     ],
    //     //     target: 'map',
    //     //     view: new View({
    //     //         projection: 'EPSG:4326',
    //     //         // projection:'EPSG:3857',
    //     //         center: [18, 59.5],
    //     //         zoom: 15, maxZoom: 18,
    //     //     }),
    //     // })
    //     //
    //     // const c = new ScaleLine({
    //     //     // target: 'line',
    //     //     // bar:true,
    //     // })
    //     // this.map.addControl(c)
    // }

}
