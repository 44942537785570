@if (container) {
  @if (container.messages.length >0) {
    <mat-card>
      <mat-list>
        @for (row of container.messages; track row) {
          <mat-list-item [class.error]="row.error">
            {{row.message}}
          </mat-list-item>
        }
      </mat-list>
    </mat-card>
  }
}