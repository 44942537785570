<mat-card>
    <mat-card-header>
        <mat-card-title class="mat-h2">
            Edit groups using the selected objects
        </mat-card-title>
        @if (objectsList()) {
            <mat-card-subtitle class="object-list">
                {{ objectsList() }}
            </mat-card-subtitle>
        } @else {
            <mat-card-subtitle class="error">No objects selected</mat-card-subtitle>

        }
    </mat-card-header>

    <mat-card-content>
        @if (form.controls.create; as group) {
            <mat-card>
                <mat-card-header>
                    <mat-card-title class="mat-h2">
                        Create new group
                    </mat-card-title>
                </mat-card-header>
                @if (form.controls.create.controls.name; as cntrl) {
                    <mat-card-content>
                        <mat-form-field>
                            <mat-label>New group name</mat-label>
                            <input matInput type="text" [formControl]="cntrl">
                            <mat-error>{{ getErrorMessage(cntrl) }}</mat-error>
                        </mat-form-field>
                        <mat-slide-toggle [formControl]="form.controls.create.controls.global">Global</mat-slide-toggle>
                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-raised-button (click)="createGroup()">
                            Create new group
                            @if (cntrl.value; as name) {
                                <span>'{{ name }}'</span>
                            }
                        </button>
                    </mat-card-actions>
                }
            </mat-card>
        }
        @if (form.controls.add; as control) {
            <mat-card>
                <mat-card-header class="mat-h2">
                    <mat-card-title>
                        <h2> Add to group </h2>
                    </mat-card-title>
                    <mat-card-subtitle>
                        Add the selected objects to an existing group. The objects will be added last; the order can be
                        changed from the Edit tab.
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <mat-form-field>
                        <mat-label>Select group</mat-label>
                        <mat-select [formControl]="control">
                            @for (g of groups(); track g.id) {
                                <mat-option [value]="g"
                                            [disabled]="g.global && !ABILITY.WRITE.availableFor(project())">
                                    {{ g.name }}
                                </mat-option>
                            }
                        </mat-select>
                        <mat-error>{{ getErrorMessage(control) }}</mat-error>
                    </mat-form-field>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-raised-button (click)="addToGroup()">
                        Add selected objects @if (control.value?.name; as name) {
                        <span>to '{{ name }}'</span>
                    }
                    </button>
                </mat-card-actions>
            </mat-card>
        }
        @if (form.controls.remove; as control) {
            <mat-card>
                <mat-card-header>
                    <mat-card-title class="mat-h2">
                        Remove from group
                    </mat-card-title>
                    <mat-card-subtitle>
                        Remove the selected objects that are present in the group.
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <mat-form-field>
                        <mat-label>Select group</mat-label>
                        <mat-select [formControl]="control">
                            @for (g of groupsWithSomeObject(); track g.id) {
                                <mat-option
                                    [value]="g"
                                    [disabled]="g.global && !ABILITY.WRITE.availableFor(project()) "
                                >
                                    {{ g.name }}
                                </mat-option>
                            }
                        </mat-select>
                        <mat-error>{{ getErrorMessage(control) }}
                        </mat-error>
                    </mat-form-field>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-raised-button (click)="removeFromGroup()">
                        Remove selected objects @if (control.value?.name; as name) {
                        <span>from '{{ name }}'</span>
                    }
                    </button>
                </mat-card-actions>
            </mat-card>
        }
    </mat-card-content>


    <mat-action-list>
        <button mat-raised-button (click)="reset()">Reset</button>
    </mat-action-list>
</mat-card>

<app-log-list [container]="logs"></app-log-list>
