import {Component} from '@angular/core'
import {KeyValuePipe} from '@angular/common'
import {MatButtonModule} from "@angular/material/button"
import {MatCardModule} from '@angular/material/card'
import {MatDialog} from "@angular/material/dialog"
import {MatIconModule} from "@angular/material/icon"
import {toSignal} from "@angular/core/rxjs-interop"

import {VideDataService} from "../../api/vide-data.service"
import {ABILITY_LIST} from "../../ability"
import {
    AddLicenseComponent,
    AddLicenseComponentData,
    AddLicenseComponentResult
} from "./add-license/add-license.component"
import {LogContainer} from "../../log-list/log-container"
import {LogListComponent} from "../../log-list/log-list.component"
import {License} from "../../api/api-types"
import {ComponentCanDeactivate} from "../../can-deactivate.guard"

@Component({
    selector: 'app-admin-licenses',
    templateUrl: './admin-licenses.component.html',
    styleUrls: ['./admin-licenses.component.scss'],
    standalone: true,
    imports: [
        LogListComponent,

        KeyValuePipe,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
    ]
})
export class AdminLicensesComponent extends ComponentCanDeactivate {
    protected readonly abilities = ABILITY_LIST
    protected readonly userAbility = toSignal(this.dataService.userAdminAbility$)
    protected readonly licenses = toSignal(this.dataService.licenses$)

    protected readonly logs = new LogContainer()

    constructor(
        private readonly dataService: VideDataService,
        private readonly dialog: MatDialog,
    ) {
        super()
        console.warn("refactor this component, this looks ugly")
    }

    addLicense() {
        const ref = this.dialog.open<
            AddLicenseComponent,
            AddLicenseComponentData,
            AddLicenseComponentResult
        >(AddLicenseComponent)

        // ref.afterClosed().subscribe(value => {
        //     if (value) {
        //         this.addLicenseReal(value)
        //     } else {
        //         console.log('No result from dialog')
        //     }
        // })
    }

    // private addLicenseReal(value: AddLicenseComponentResult) {
    //     console.log(value)
    //     this.dataService.createLicense(value).subscribe({
    //         next: res => {
    //             console.log(res)
    //             this.logs.add(res, `Add license '${value.name}'`)
    //             if (res.success) {
    //                 this.dataService.reloadUserData()
    //             }
    //         },
    //         complete: () => {
    //             console.log('Complete')
    //         }
    //     })
    // }

    editLicense(l: License) {
        console.log(l)

        const ref = this.dialog.open<
            AddLicenseComponent,
            AddLicenseComponentData,
            AddLicenseComponentResult
        >(AddLicenseComponent, {data: l})

        // ref.afterClosed().subscribe(value => {
        //     if (value) {
        //         this.updateLicenseReal(l, value)
        //     } else {
        //         console.log('No result from dialog')
        //     }
        // })

    }

    // private updateLicenseReal(l: License, value: AddLicenseComponentResult) {
    //     console.log(value)
    //     this.dataService.updateLicense(l, value).subscribe({
    //         next: res => {
    //             console.log(res)
    //             this.logs.add(res, `Update license '${value.name}'`)
    //             if (res.success) {
    //                 this.dataService.reloadUserData()
    //             }
    //         },
    //         complete: () => {
    //             console.log('Complete')
    //         }
    //     })
    // }

    canDeactivate(): boolean {
        // TODO: no check for now
        return true
    }
}
