import {Component, HostListener, output} from '@angular/core'
import {JsonPipe} from "@angular/common"
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms"

import {switchMap} from "rxjs"

import {INPUT_DATE_MAX} from "../../constants"
import {DateLimit, DatePeriod, Interval} from "../api/limit-storage"
import {VideDataService} from "../api/vide-data.service"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MatButton} from "@angular/material/button"


@Component({
    selector: 'app-select-limit',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        JsonPipe,
        MatButton
    ],
    templateUrl: './select-limit.component.html',
    styleUrl: './select-limit.component.scss',
})
export class SelectLimitComponent {
    @HostListener('click', ['$event'])
    @HostListener('keydown', ['$event'])
    stopClosing(event: Event | MouseEvent | KeyboardEvent) {
        event.stopPropagation()
    }

    readonly saved = output()

    protected readonly Interval = Interval
    protected readonly INPUT_DATE_MAX = INPUT_DATE_MAX

    private readonly controls = {
        period: this.fb.nonNullable.group({
            type: ['period' as const],
            interval: [1, Validators.required],
            period: ['months' as DatePeriod, Validators.required]
        }),
        date: this.fb.nonNullable.group({
            type: ['date' as const],
            date: ['', Validators.required]
        }),
    }
    protected form = this.fb.nonNullable.group(this.controls)

    readonly project = this.service.project$
    readonly limit$ = this.service.project$.pipe(
        switchMap(p => this.service.getLimit(p)),
    )

    constructor(
        private readonly fb: FormBuilder,
        private readonly service: VideDataService,
    ) {
        this.limit$.pipe(takeUntilDestroyed()).subscribe(limit => {
            if (limit) {
                this.form.patchValue({[limit.type]: limit})
            }
        })
    }

    save(tag: keyof typeof this.controls) {
        this.form.markAllAsTouched()
        if (!this.form.controls[tag].valid) return
        const values = this.form.getRawValue()
        const limit = values[tag]
        this.setLimit(limit)
    }

    private setLimit(limit: DateLimit) {
        this.service.setLimit(limit)
        this.saved.emit()
    }

    saveNone() {
        this.setLimit(null)
    }
}
