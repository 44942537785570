<mat-card>
    <mat-card-header>
        <mat-card-title>Failed jobs</mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <table mat-table matSort [dataSource]="dataSource">
            <app-sorted-text-column name="id"/>
            <app-sorted-text-column name="uuid"/>
            <app-sorted-text-column name="connection"/>
            <app-sorted-text-column name="queue"/>
            <app-sorted-text-column name="failed_at"/>

            <ng-container matColumnDef="exception">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Exception</th>
                <td mat-cell *matCellDef="let row" (click)="displayException(row)"
                    matTooltip="Click to display stack trace">
                    {{ row.exception.split("\n").at(0) }}
                </td>
            </ng-container>
            <ng-container matColumnDef="jobName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Job name</th>
                <td mat-cell *matCellDef="let row">
                    {{ row.payload.displayName }}
                </td>
            </ng-container>
            <ng-container matColumnDef="displayPayload">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Payload</th>
                <td mat-cell *matCellDef="let row">
                    <button mat-icon-button matTooltip="Display payload" aria-description="Display payload"
                            (click)="displayPayload(row)">
<!--                        <span class="material-symbols-outlined">ungroup</span>-->
                        <mat-icon class="material-symbols-outlined">expand_content</mat-icon>
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="delete">
                <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                    <button mat-icon-button matTooltip="Delete job" aria-description="Delete job"
                            (click)="deleteJob(row)">
                        <mat-icon class="material-symbols-outlined">delete</mat-icon>
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="retry">
                <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                    <button mat-icon-button matTooltip="Retry job" aria-description="Retry job"
                            (click)="retryJob(row)">
                        <mat-icon class="material-symbols-outlined">redo</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
            <tr *matNoDataRow>
                <td>No failed jobs</td>
            </tr>
        </table>
    </mat-card-content>

    <!--    <mat-card-actions>-->
    <!--        <button mat-fab aria-label="add user" (click)="addUser()" matTooltip="Add user">-->
    <!--            <mat-icon>add</mat-icon>-->
    <!--        </button>-->
    <!--    </mat-card-actions>-->

    <!--    <mat-card-footer>-->
    <!--        <app-log-list [container]="logs"/>-->
    <!--    </mat-card-footer>-->

</mat-card>
