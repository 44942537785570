@if (result) {
    @if (result.success) {
        <p> A password reset link will be mailed to {{ email() }}, please check your inbox. </p>
        <p> You may close this page. </p>
    } @else {
        <p>Error: {{ result.error }}</p>
    }
} @else {
    <p> Requesting password reset link </p>
    <mat-progress-bar/>
}
