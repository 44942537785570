<h1 mat-dialog-title>
    @if (data) {
        Edit {{ data.name }}
    } @else {
        Add license
    }
</h1>


<mat-dialog-content>
    @if (userAbility()?.has('Vide admin')) {
        @if (form.controls.name; as control) {
            <mat-form-field>
                <mat-label>Company (license name)</mat-label>
                <input matInput [formControl]="control">
                <mat-error>{{ getErrorMessage(control) }}</mat-error>
            </mat-form-field>
        }
        @if (form.controls.comment; as control) {
            <mat-form-field>
                <mat-label>Comment</mat-label>
                <textarea matInput [formControl]="control"></textarea>
                <mat-error>{{ getErrorMessage(control) }}</mat-error>
            </mat-form-field>
        }
        @if (form.controls.ownerEmail; as control) {
            <mat-form-field>
                <mat-label>Owner</mat-label>
                <select matNativeControl [formControl]="control">
                    @for (o of users(); track o.id) {
                        <option [ngValue]="o.email">{{ o.email }}</option>
                    }
                    <option [ngValue]=null>none</option>
                </select>
                <mat-error>{{ getErrorMessage(control) }}</mat-error>
            </mat-form-field>
        }

        <h2>Capacity</h2>
        @if (form.controls.capacity.controls; as cntrls) {
            @if (cntrls.read; as control) {
                <mat-form-field>
                    <mat-label>Nr read</mat-label>
                    <input matInput type="number" [formControl]="control">
                    <mat-error>{{ getErrorMessage(control) }}</mat-error>
                </mat-form-field>
            }
            @if (cntrls.measure; as control) {
                <mat-form-field>
                    <mat-label>Nr measure</mat-label>
                    <input matInput type="number" [formControl]="control">
                    <mat-error>{{ getErrorMessage(control) }}</mat-error>
                </mat-form-field>
            }
            @if (cntrls.write; as control) {
                <mat-form-field>
                    <mat-label>Nr write</mat-label>
                    <input matInput type="number" [formControl]="control">
                    <mat-error>{{ getErrorMessage(control) }}</mat-error>
                </mat-form-field>
            }
            @if (cntrls.admin; as control) {
                <mat-form-field>
                    <mat-label>Nr admin</mat-label>
                    <input matInput type="number" [formControl]="control">
                    <mat-error>{{ getErrorMessage(control) }}</mat-error>
                </mat-form-field>
            }
        }
        <mat-divider/>
    }
    <h2>Unoson connections</h2>
    @for (cntrl of form.controls.unosonCredentials.controls; track $index) {
        <div>
            @if (cntrl.controls.name; as c) {
                <mat-form-field>
                    <mat-label>Connection name</mat-label>
                    <input matInput [formControl]="c">
                    <mat-error>{{ getErrorMessage(c) }}</mat-error>
                </mat-form-field>
            }
            @if (cntrl.controls.client_id; as c) {
                <mat-form-field>
                    <mat-label>Client id</mat-label>
                    <input matInput [formControl]="c">
                    <mat-error>{{ getErrorMessage(c) }}</mat-error>
                </mat-form-field>
            }
            @if (cntrl.controls.client_secret; as c) {
                <mat-form-field>
                    <mat-label>Client secret</mat-label>
                    <input matInput [formControl]="c">
                    <mat-error>{{ getErrorMessage(c) }}</mat-error>
                </mat-form-field>
            }
            @if (cntrl.controls.username; as c) {
                <mat-form-field>
                    <mat-label>Username</mat-label>
                    <input matInput [formControl]="c">
                    <mat-error>{{ getErrorMessage(c) }}</mat-error>
                </mat-form-field>
            }
            @if (cntrl.controls.password; as c) {
                <mat-form-field>
                    <mat-label>Password</mat-label>
                    <input matInput [formControl]="c">
                    <mat-error>{{ getErrorMessage(c) }}</mat-error>
                </mat-form-field>
            }
            <button mat-mini-fab matTooltip="Delete {{cntrl.controls.name.value}}" (click)="deleteCredential($index)">
                <mat-icon>delete</mat-icon>
            </button>

        </div>
    }
    <div>
        <button mat-mini-fab (click)="addConnection()">
            <mat-icon>add</mat-icon>
        </button>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    @if (data) {
        <button mat-raised-button (click)="save(data)"> Save</button>
    } @else {
        <button mat-raised-button (click)="add()"> Add</button>
    }
    <button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
<app-log-list [container]="logs"/>
@if (waiting) {
    <mat-progress-bar mode="indeterminate"/>
}
