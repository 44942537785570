import {Injectable} from '@angular/core'
import {VideDataService} from "../api/vide-data.service"
import {firstValueFrom} from "rxjs"
import {OUTDATED_CONFIG} from "../../constants"

@Injectable({
    providedIn: 'root'
})
export class OutdatedStatisticsService {

    constructor(
        private dataService: VideDataService,
    ) {
    }

    async updateOutdatedStatistics() {
        const orig = this.dataService.outdatedStatistics()
        if (orig.length > 0) {
            const x = await firstValueFrom(this.dataService.updateStatisticsCurrentProject())
            if (x.success) {
                console.log(`${orig.length} outdated statistics, requesting update`)
                const noOutdated = await this.waitUntilNoOutdated(orig.length)
                if (noOutdated) {
                    console.log(`No more outdated statistics!`)
                    this.dataService.reloadProjectData()
                } else {
                    console.log(`Giving upp on outdated statistics`)
                }
            }
        } else {
            console.log("No outdated statistics")
        }
    }

    private async waitUntilNoOutdated(startNrObjects: number): Promise<boolean> {
        // Heuristic constant, somewhat sensible from practical use
        const timePerObject = OUTDATED_CONFIG.waitPerObject
        const firstGuess = startNrObjects * timePerObject

        return new Promise((resolve) => {
            let startTime = Date.now()
            let lastTime = startTime
            let lastNrObjects = startNrObjects
            const timingMap = new Map<number, number>()
            timingMap.set(0, startNrObjects)
            let counter = 0
            const approximateTime = (): number => {
                const guess = startTime === lastTime
                    ? firstGuess
                    : startNrObjects === lastNrObjects
                        // No object done, so double the time
                        ? 2 * (lastTime - startTime)
                        : (lastTime - startTime) / ((startNrObjects / lastNrObjects) - 1)
                // Always wait at least 1 second
                return Math.max(guess, 1000)
            }
            const scheduleNext = () => {
                const nextGuess = approximateTime()
                console.error(`Scheduling outdated check in ${nextGuess} ms`)
                setTimeout(localFn, nextGuess)
            }
            const localFn = () => {
                ++counter
                console.log(`Called localFn for the ${counter}:th time`)
                firstValueFrom(this.dataService.freshOutdated$).then(({object_ids: ids}) => {
                    console.log(`Got outdatedStatistics: `, ids)
                    lastNrObjects = ids.length
                    lastTime = Date.now()
                    if (ids.length === 0) {
                        console.log(timingMap)
                        resolve(true)
                    } else if (counter >= OUTDATED_CONFIG.maxReloads) {
                        resolve(false)
                    } else {
                        timingMap.set(lastTime - startTime, ids.length)
                        scheduleNext()
                    }
                })
            }
            scheduleNext()
        })
    }


}
