@if (figure(); as figure) {
    <plotly-plot [data]="figure.data" [layout]="figure.layout" [config]="figure.config" [useResizeHandler]="true"
                 [style]="plotlyStyle">
    </plotly-plot>
}

<form [formGroup]="optionsForm">
    <mat-form-field>
        <mat-label>Map background</mat-label>
        <mat-select formControlName="background">
            @for (b of mapBackgrounds; track b) {
                <mat-option [value]="b">{{ b }}</mat-option>
            }
        </mat-select>
    </mat-form-field>
<!--    <mat-form-field>-->
<!--        <mat-label>Label color</mat-label>-->
<!--        <mat-select formControlName="textColor">-->
<!--            @for (c of textColors; track c) {-->
<!--                <mat-option [value]="c">{{ c }}</mat-option>-->
<!--            }-->
<!--        </mat-select>-->
<!--    </mat-form-field>-->
</form>
