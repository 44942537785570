<p>
    Select an existing object or create a new one. It will be used to load and save diver data in the following steps.
</p>

<mat-tab-group>
    <mat-tab label="Existing">
        @if (form.controls.object; as cntrl) {
            <div class="vide-columns-wide vide-form-field vide-form-field-row">
                <ng-select
                    [clearable]="false"
                    [formControl]="cntrl"
                    [items]="objects()"
                    bindLabel="name"
                    placeholder="Diver object"
                />
            </div>
            @if (cntrl.invalid && (cntrl.dirty || cntrl.touched)) {
                <div class="error">{{ getErrorMessage(cntrl) }}</div>
            } @else {
                <div [style.visibility]="'hidden'">X</div>
            }
        }

    </mat-tab>
    <mat-tab label="Create new">
        @if (form.controls.name; as cntrl) {
            <div class="vide-columns-wide vide-form-field vide-form-field-row">
                <app-input type="text" [formControl]="cntrl" placeholder="Object name"/>
            </div>
            @if (cntrl.invalid && (cntrl.dirty || cntrl.touched)) {
                <div class="error">{{ getErrorMessage(cntrl) }}</div>
            } @else {
                <div [style.visibility]="'hidden'">X</div>
            }
            <button mat-raised-button (click)="newObject()" [disabled]="cntrl.invalid">Create new
                object {{ cntrl.value }}
            </button>
        }
    </mat-tab>
</mat-tab-group>


<div class="stepper-navigate">
    <button mat-button matStepperPrevious type="button" disabled>Previous</button>
    <button mat-button matStepperNext type="button" (click)="localNext()">Next</button>
</div>
