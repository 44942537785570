import {FormBuilder, Validators} from "@angular/forms"
import {Injectable} from '@angular/core'

import {
    BehaviorSubject,
    catchError,
    combineLatest,
    combineLatestWith,
    firstValueFrom,
    map,
    of,
    shareReplay,
    startWith,
    Subject,
    switchMap,
    tap
} from "rxjs"

import {VideDataService} from "../../../api/vide-data.service"
import {
    annotationsToAnnotations,
    DIVER_DATA_TYPES,
    DiverDataType,
    DiverService,
    figureClick,
    getFigure,
    getFilterFn,
    LocalDiverData,
    parseDiverOfficeData,
    readAsText
} from "../diver.service"
import {PlotlyLayout, PlotlyMouseEvent} from "../../../vide-types"
import {FileValidator} from "../../import-file/file-validator"
import {MeasureType, VideObject} from "../../../api/api-types"
import {assertNever} from "../../../shared/vide-helper"

@Injectable({
    providedIn: 'root'
})
export class ImportDiverService {
    readonly saving = new BehaviorSubject(false)
    readonly fileError = new Subject<string>()
    readonly form = this.fb.nonNullable.group({
        code: [DIVER_DATA_TYPES[0] as DiverDataType, Validators.required],
        file: [null as FileList | null, [Validators.required, FileValidator.nrFiles(1),]],
    })
    private _diverAnnotations = new BehaviorSubject<NonNullable<PlotlyLayout['annotations']>>([])
    readonly diverAnnotations$ = this._diverAnnotations.pipe(
        map(annotationsToAnnotations),
    )
    private readonly selectedFile$ = this.form.controls.file.valueChanges.pipe(
        startWith(null),
        map(v => v?.item(0)),
    )
    readonly rawDiverFileData$ = this.selectedFile$.pipe(
        switchMap(file => file ? readAsText(file).then(parseDiverOfficeData) : of(null)),
        catchError(err => {
            if (err instanceof Error) this.fileError.next(err.message)
            return of(null)
        }),
    )

    readonly diverFileData$ = this.rawDiverFileData$.pipe(
        tap(_x => {
            this.resetDiverAnnotations()
        }),
        combineLatestWith(this.form.controls.code.valueChanges.pipe(startWith(null))),
        map(([data, _]) => {
            if (!data) {
                return null
            }
            const code = this.form.controls.code.value
            return {diverData: data, type: code}
        }),
        shareReplay({refCount: true, bufferSize: 1})
    )
    readonly diverFigure$ = combineLatest([
        this.diverFileData$,
        this.diverAnnotations$,
    ]).pipe(
        map(([x, annotations]) => {
            return x ? getFigure(x.diverData.data, x.type, annotations) : null
        }),
    )
    readonly diverClippedFileData$ = combineLatest([
        this.diverFileData$,
        this.diverAnnotations$,
    ]).pipe(
        map(([x, annotations]) => {
            if (!x) return null
            const clippedData = x.diverData.data.filter(getFilterFn(annotations))
            const ret: LocalDiverData = {measurements: clippedData, type: x.type, serial: x.diverData.serial}
            return ret
        }),
    )
    readonly diverNrMeasurements$ = this.diverClippedFileData$.pipe(
        map(data => data?.measurements.length),
    )

    private resetDiverAnnotations() {
        this._diverAnnotations.next([])
    }

    diverDoubleClick() {
        this.resetDiverAnnotations()
    }

    diverFigureClick(event: PlotlyMouseEvent) {
        figureClick(this._diverAnnotations, event)
    }

    constructor(
        private readonly fb: FormBuilder,
        private readonly dataService: VideDataService,
        private readonly diverService: DiverService,
    ) {
    }

    async save(object: Pick<VideObject, 'id' | 'name'>) {
        const localData = await firstValueFrom(this.diverClippedFileData$)
        if (!localData || localData.measurements.length < 1) {
            console.error('No input values')
            return
        }
        let mt: MeasureType
        switch (localData.type) {
            case "Uncompensated":
                mt = await firstValueFrom(this.dataService.measureTypeDiverUncompensated$)
                break
            case "Unreferenced":
                mt = await firstValueFrom(this.dataService.measureTypeDiverUnreferenced$)
                break
            case "Referenced":
                mt = await firstValueFrom(this.diverService.measureTypeDiver$)
                break
            default:
                assertNever(localData.type)
        }

        this.saving.next(true)
        const x = await this.diverService.saveToObject(localData.measurements, {
            // errorCode: null,
            measureType: mt,
            object,
            update: false,
        }).finally(() => {
            this.saving.next(false)
        })
        if (!x.success) {
            return
        }
        this.form.patchValue({file: null})

        const y = await this.diverService.createAnnotation(object, {
            comment: localData.type,
            first_date: localData.measurements.at(0)!.dateTime,
            serial_number: localData?.serial,
        })
        return y.success
    }
}
