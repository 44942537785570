@if (data) {
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <mat-dialog-content>
        <mat-form-field>
            <input matInput
                   [formControl]="control"
            />
            <mat-error>{{ getErrorMessage(control) }}</mat-error>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button
            mat-raised-button
            [mat-dialog-close]="control.value"
            [disabled]="control.invalid"
        >
            {{ data.positiveText }} {{ control.value }}
        </button>
        <button mat-raised-button mat-dialog-close>Cancel</button>
    </mat-dialog-actions>
} @else {
    No data
}
