import {
    AbstractControl,
    FormBuilder,
    ReactiveFormsModule,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms'
import {ActivatedRoute} from '@angular/router'
import {Component} from '@angular/core'
import {MatButtonModule} from "@angular/material/button"
import {MatCardModule} from "@angular/material/card"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatInputModule} from "@angular/material/input"
import {Title} from '@angular/platform-browser'

import {AuthService} from '../auth.service'
import {LogContainer} from 'src/app/log-list/log-container'
import {LogListComponent} from "../../log-list/log-list.component"
import {getErrorMessage, getPageTitle} from 'src/app/shared/vide-helper'

const passwordMatchValidator: ValidatorFn = (control: AbstractControl) => {
    const a = (control.value.password as string).trim()
    const b = (control.value.password_confirmation as string).trim()
    return a === b ? null : {passwordMismatch: true}
}

const passwordValidator: ValidatorFn = (control: AbstractControl) => {
    const a = (control.value as string).trim()
    let errors: ValidationErrors = {}

    /**
     *  Password::min(8)
     *         ->letters()
     *         ->mixedCase()
     *         ->numbers()
     *         ->symbols()
     *         ->uncompromised()
     */

    // Find a letter
    if (a.match(/\p{L}/u) === null) {
        errors['noLetter'] = true
    }
    // Find upper and lower case
    if (a.match(/(\p{Ll}+.*\p{Lu})|(\p{Lu}+.*\p{Ll})/u) === null) {
        errors['mixedCaseLetters'] = true
    }
    // Find numbers
    if (a.match(/\p{N}/u) === null) {
        errors['noNumber'] = true
    }
    // Find symbol
    if (a.match(/\p{Z}|\p{S}|\p{P}/u) === null) {
        errors['noSymbol'] = true
    }

    return errors
}

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    imports: [
        LogListComponent,

        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
    ]
})
export class ResetPasswordComponent {

    token: string | null
    readonly logs = new LogContainer()
    // resetSuccessful = false
    resetResult: null | boolean = null

    readonly passwordForm = this.formBuilder.nonNullable.group({
        email: ['', [Validators.email, Validators.required]],
        password: ['', [Validators.required, Validators.minLength(8), passwordValidator]],
        password_confirmation: ['', [Validators.required]],
    }, {validators: passwordMatchValidator})

    readonly main = document.location.protocol + '//' + document.location.host

    constructor(
        private readonly auth: AuthService,
        private readonly route: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        title: Title,
    ) {
        title.setTitle(getPageTitle(null, 'Reset password'))
        this.token = this.route.snapshot.queryParamMap.get('token')
    }

    getErrorMessage = getErrorMessage

    async resetPassword() {
        this.passwordForm.markAllAsTouched()
        if (!(this.token && this.passwordForm.valid)) {
            return
        }
        const credentials = {...this.passwordForm.getRawValue(), token: this.token}
        const x = await this.auth.resetPassword(credentials)
        this.logs.add(x, 'Reset')
        this.resetResult = x.success
        // if (x.success) {
        //     this.resetSuccessful = true
        // } else {
        //     x.error
        // }
    }
}
