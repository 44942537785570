import {Component, input} from '@angular/core'
import {MatIconModule} from "@angular/material/icon"

@Component({
    selector: 'app-menu-selection',
    standalone: true,
    imports: [
        MatIconModule,
    ],
    templateUrl: './menu-selection.component.html',
    styleUrl: './menu-selection.component.scss'
})
export class MenuSelectionComponent {
    // @Input() steps!: string[]

    steps = input.required<readonly string[]>()
}
