<mat-card>
    <nav mat-tab-nav-bar [tabPanel]="tabPanel">
        <a mat-tab-link routerLink="existing" queryParamsHandling="merge" routerLinkActive="active"
           [ariaCurrentWhenActive]="'page'"> Edit </a>
        <a mat-tab-link routerLink="selected" queryParamsHandling="merge" routerLinkActive="active"
           [ariaCurrentWhenActive]="'page'" [disabled]="selectedObjects().length === 0"> Selected objects </a>
        <a mat-tab-link routerLink="names" queryParamsHandling="merge" routerLinkActive="active"
           [ariaCurrentWhenActive]="'page'"> Group from object names </a>
    </nav>
    <mat-tab-nav-panel #tabPanel>
        <router-outlet></router-outlet>
    </mat-tab-nav-panel>
    @if (waiting()) {
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }

</mat-card>
