<div class="content">

    <h3>Select limit for oldest data to fetch</h3>
    <!--TODO: use normal style for elements-->


    <table>
        <tr>
            <td> Relative:</td>
            <td>
                <input
                    [formControl]="form.controls.period.controls.interval"
                    class="limit-input"
                    max="99"
                    min="1"
                    type="number"
                >
                <select
                    [formControl]="form.controls.period.controls.period"
                    class="limit-input"
                >
                    @for (i of Interval; track i) {
                        <option [ngValue]="i">{{ i }}</option>
                    }
                </select>
            </td>
            <td>
                <button (click)="save('period')">Use</button>
            </td>
        </tr>
        <tr>
            <td> Absolute:</td>
            <td>
                <input
                    class="limit-input"
                    [max]="INPUT_DATE_MAX" [formControl]="form.controls.date.controls.date" type="date">
            </td>
            <td>
                <button (click)="save('date')">Use</button>
            </td>
        </tr>

        <tr>
            <td> No limit:</td>
            <td></td>
            <td>
                <button (click)="save(null)">Use</button>
            </td>
        </tr>

    </table>


</div>
