import { Component, output, inject } from '@angular/core'
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"

import {switchMap} from "rxjs"

import {DateLimit, DatePeriod, Interval} from "../api/limit-storage"
import {INPUT_DATE_MAX} from "../../constants"
import {VideDataService} from "../api/vide-data.service"


@Component({
    selector: 'app-select-limit',
    imports: [
        MatButtonModule,
        ReactiveFormsModule,
    ],
    templateUrl: './select-limit.component.html',
    styleUrl: './select-limit.component.scss',
    host: {
        '(click)': '$event.stopPropagation()',
        '(keydown)': '$event.stopPropagation()',
    }
})
export class SelectLimitComponent {
    private readonly fb = inject(FormBuilder);
    private readonly service = inject(VideDataService);


    readonly saved = output()

    protected readonly Interval = Interval
    protected readonly INPUT_DATE_MAX = INPUT_DATE_MAX

    private readonly controls = {
        period: this.fb.nonNullable.group({
            type: ['period' as const],
            interval: [1, Validators.required],
            period: ['months' as DatePeriod, Validators.required]
        }),
        date: this.fb.nonNullable.group({
            type: ['date' as const],
            date: ['', Validators.required]
        }),
    }
    protected form = this.fb.nonNullable.group(this.controls)

    readonly project = this.service.project$
    readonly limit$ = this.service.project$.pipe(
        switchMap(p => this.service.getLimit(p)),
    )

    constructor() {
        this.limit$.pipe(takeUntilDestroyed()).subscribe(limit => {
            if (limit) {
                this.form.patchValue({[limit.type]: limit})
            }
        })
    }

    save(tag: null | keyof typeof this.controls) {
        if (tag === null) {
            this.setLimit(null)
            return
        }
        this.form.markAllAsTouched()
        if (!this.form.controls[tag].valid) return
        const values = this.form.getRawValue()
        const limit = values[tag]
        this.setLimit(limit)
    }

    private setLimit(limit: DateLimit) {
        this.service.setLimit(limit)
        this.saved.emit()
    }
}
