@if (!smallScreen()) {
    <section class="proportion-slider">
        <mat-slider (dblclick)="onResetProportions()"
                    matTooltip="Control size of left and right panel. Double click to reset panel proportions">
            <input matSliderThumb [formControl]="proportionsSlider" min="0" max="100"/>
        </mat-slider>
    </section>
}

@if (proportion(); as prop) {
    <section>
        <div #left class="mat-elevation-z8 vide-project-panel">
            @if (prop.showLeft) {
                <app-object-select></app-object-select>
            }
        </div>
        <div #right class="mat-elevation-z8 vide-project-panel">
            @if (prop.showRight) {
                <app-plot></app-plot>
            }
        </div>
    </section>
}
