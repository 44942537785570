import { BehaviorSubject } from "rxjs"

export class Counter {
    private nrWaiting = new BehaviorSubject(0)
    waiting$ = this.nrWaiting.asObservable().pipe()

    increase() {
        setTimeout(() => {
            this.nrWaiting.next(this.nrWaiting.value + 1)
        })
    }

    decrease() {
        setTimeout(() => {
            this.nrWaiting.next(this.nrWaiting.value - 1)
        })
    }

}
