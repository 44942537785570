import {HttpInterceptorFn} from "@angular/common/http"
import {cachingInterceptor} from "./http-interceptors/caching.interceptor"
import {errorInterceptor} from "./http-interceptors/error.interceptor"
import {xsrcCookieInterceptor} from "./http-interceptors/xsrc-cookie.interceptor"
import {counterInterceptor} from "./http-interceptors/counter.interceptor"

export const interceptors: HttpInterceptorFn[] = [
    counterInterceptor,
    cachingInterceptor,
    errorInterceptor,
    xsrcCookieInterceptor,
]
