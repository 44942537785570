import {AfterViewInit, Component, OnDestroy, viewChild, ViewChild} from '@angular/core'
import {MatButtonModule} from "@angular/material/button"
import {MatCardModule} from "@angular/material/card"
import {MatDialog} from "@angular/material/dialog"
import {MatIconModule} from "@angular/material/icon"
import {MatMenuModule, MatMenuTrigger} from "@angular/material/menu"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner"
import {MatSort, MatSortModule} from "@angular/material/sort"
import {MatTableDataSource, MatTableModule} from "@angular/material/table"
import {MatTabsModule} from "@angular/material/tabs"
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop"

import {PlotlyComponent, PlotlyViaWindowModule} from "angular-plotly.js"

import {ComponentCanDeactivate} from "../../can-deactivate.guard"
import {ConfirmDialogComponent, ConfirmDialogData} from "../../dialogs/confirm-dialog/confirm-dialog.component"
import {GeoarkivetObject, GeoarkivetService,} from "./geoarkivet.service"
import {LogListComponent} from "../../log-list/log-list.component"
import {PLOT_CONFIG} from "../../../constants"
import {PlotlyMouseEvent} from "../../vide-types"
import {moveMenu} from "../../shared/mat-menu"

@Component({
    selector: 'app-geoarkivet',
    standalone: true,
    imports: [
        LogListComponent,

        MatButtonModule,
        MatCardModule,
        MatIconModule,
        MatMenuModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        PlotlyViaWindowModule,
    ],
    templateUrl: './geoarkivet.component.html',
    styleUrl: './geoarkivet.component.scss'
})
export class GeoarkivetComponent extends ComponentCanDeactivate implements OnDestroy, AfterViewInit {
    @ViewChild(PlotlyComponent) plotlyComponent?: PlotlyComponent
    private readonly sort = viewChild.required(MatSort)
    protected readonly dataSource = new MatTableDataSource<GeoarkivetObject>([])
    protected readonly figure = toSignal(this.dataService.figure$)
    protected readonly httpExtern = toSignal(this.dataService.httpStatus.extern)
    protected readonly httpStatus = this.dataService.httpStatus
    protected readonly logs = this.dataService.logs
    protected readonly plotlyStyle = PLOT_CONFIG.plotlyStyle
    protected readonly stations$ = this.dataService.stations$
    protected readonly trigger = viewChild(MatMenuTrigger)

    protected readonly add = this.dataService.add.bind(this.dataService)
    protected readonly getName = GeoarkivetService.getName
    protected readonly onPlotlyRelayout = this.dataService.plotlyRelayout.bind(this.dataService)
    protected readonly remove = this.dataService.remove.bind(this.dataService)

    protected readonly displayedColumns = [
        'name',
        'from',
        'to',
        'investigation',
        'imported',
        'action',
    ] as const

    constructor(
        private readonly dataService: GeoarkivetService,
        private readonly dialog: MatDialog,
    ) {
        super()
        this.stations$.pipe(takeUntilDestroyed()).subscribe(s => {
            this.dataSource.data = s
        })
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
            switch (sortHeaderId) {
                case 'name':
                    return GeoarkivetService.getName(data)
                case 'from':
                    return data.feature.properties.ProtocolData_ObservationDate_Min ?? ''
                case 'to':
                    return data.feature.properties.ProtocolData_ObservationDate_Max ?? ''
                case 'investigation':
                    return data.feature.properties.GroundInvestigationName
                case 'imported':
                    return data.external ? 1 : 0
                default:
                    console.warn(`Unhandled column ${sortHeaderId}`)
                    return ''
            }
        }
    }

    override canDeactivate(): boolean {
        return true
    }

    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort()
        this.dataService.plotlyHost = this
    }

    ngOnDestroy(): void {
        // not strictly necessary, but let's not keep the unloaded element in memory.
        this.dataService.plotlyHost = undefined
    }

    plotlyClick(event: PlotlyMouseEvent) {
        const custom = event.points[0]?.customdata as GeoarkivetObject | undefined
        if (typeof custom !== 'object') {
            console.error("Strange type of custom data")
            return
        }
        const trigger = this.trigger()

        if (trigger) {
            trigger.menuData = {element: custom,}
            trigger.openMenu()
        }
        setTimeout(moveMenu(event), 0)
    }

    showProperties(element: GeoarkivetObject) {
        console.log(element)
        const prop = Object.entries(element.feature.properties).map(([a, b]) => `${a}: ${b}`).join("\n")
        this.dialog.open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
            data: {
                header: `Properties for ${element.feature.properties.Name}`,
                positive_text: null,
                negative_text: null,
                textPre: prop

            }
        })
    }

}
