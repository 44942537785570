import {Component, Inject} from '@angular/core'
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms"
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef} from "@angular/material/dialog"
import {MatButtonModule} from "@angular/material/button"
import {MatCardModule} from "@angular/material/card"
import {MatDividerModule} from "@angular/material/divider"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatIconModule} from "@angular/material/icon"
import {MatInputModule} from "@angular/material/input"
import {MatOptionModule} from "@angular/material/core"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {MatSelectModule} from "@angular/material/select"
import {MatTooltipModule} from "@angular/material/tooltip"
import {toSignal} from "@angular/core/rxjs-interop"

import {finalize, map} from "rxjs"

import {License, OwnedUser} from "../../../api/api-types"
import {VideDataService} from "../../../api/vide-data.service"
import {getErrorMessage} from "../../../shared/vide-helper"
import {ConfirmDialogComponent, ConfirmDialogData} from "../../../dialogs/confirm-dialog/confirm-dialog.component"
import {LogContainer} from "../../../log-list/log-container"
import {LogListComponent} from "../../../log-list/log-list.component"
import {Ability, EXTENDED_PROJECT_ABILITIES, ExtendedProjectAbility} from "../../../ability"

export type EditUserComponentData = MatDialogConfig<OwnedUser>['data']
export type EditUserComponentResult = {
    name: string,
    comment: string | null,
    license: License | null,
    email: string | null,
}

@Component({
    selector: 'app-edit-user',
    standalone: true,
    imports: [
        LogListComponent,

        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatOptionModule,
        MatProgressBarModule,
        MatSelectModule,
        MatTooltipModule,
        ReactiveFormsModule,
    ],
    templateUrl: './edit-user.component.html',
    styleUrl: './edit-user.component.scss'
})
export class EditUserComponent {
    protected readonly licenses = toSignal(this.dataService.licenses$, {initialValue: []})
    protected readonly logs = new LogContainer()

    protected readonly form = this.formBuilder.nonNullable.group({
        name: ['', [Validators.required, Validators.maxLength(255)]],
        comment: ['' as null | string, Validators.maxLength(255)],
        ability: [null as null | ExtendedProjectAbility],
    })
    protected readonly addForm = this.formBuilder.nonNullable.group({
        email: ['', [Validators.required, Validators.email, Validators.maxLength(255)], [this.dataService.emailExistsValidator]],
        license: [null as License | null, Validators.required],
    })
    protected waiting: boolean = false

    constructor(
        private readonly dialog: MatDialog,
        private readonly dataService: VideDataService,
        private readonly formBuilder: FormBuilder,
        private readonly ref: MatDialogRef<EditUserComponent, EditUserComponentResult>,
        @Inject(MAT_DIALOG_DATA) public data: EditUserComponentData,
    ) {
        if (data) {
            this.form.patchValue(data)
        }
    }

    protected readonly loweringAbility = toSignal(this.form.controls.ability.valueChanges.pipe(
        map(change => {
            if (this.data?.ability) {
                const original = new Ability(this.data.ability)
                return original.gt(change)
            }
            // No original, then not lowering
            return false
        })
    ))

    protected readonly getErrorMessage = getErrorMessage

    saveEdit(data: OwnedUser) {
        this.form.markAllAsTouched()
        if (!this.form.valid) {
            console.log("Form is not valid")
            return
        }
        const values = this.form.getRawValue()
        this.dataService.updateUser({
            id: data.id,
            comment: values.comment,
            name: values.name,
            ability: values.ability === 'None' ? null : values.ability,
        }).subscribe(x => {
            this.logs.add(x, `Update ${data.email}`)
            if (x.success) {
                this.dataService.reloadUserData()
                this.ref.close()
            }
        })
    }

    addUser() {
        this.form.markAllAsTouched()
        if (!this.form.valid) {
            console.log("Form is not valid")
            return
        }
        this.addForm.markAllAsTouched()
        if (!this.addForm.valid) {
            console.log("Add form is not valid")
            return
        }
        const res = {
            ...this.form.getRawValue(),
            ...this.addForm.getRawValue(),
        }
        if (!(res && res.email && res.license)) {
            return
        }
        const values = {
            email: res.email,
            name: res.name,
            license: res.license,
            comment: res.comment,
            ability: res.ability === 'None' ? null : res.ability,
        }
        this.dialog.open<
            ConfirmDialogComponent,
            ConfirmDialogData,
            ConfirmDialogComponent['response']>(ConfirmDialogComponent, {
            data: {
                header: `User ${values.email} will be created, and an invitation email will be sent.`,
                positive_text: 'Proceed',
                negative_text: 'Cancel',
            },
        }).afterClosed().subscribe(result => {
            if (result) {
                this.waiting = true
                this.dataService.createUser(values).pipe(finalize(() => {
                    this.waiting = false
                })).subscribe({
                    next: x => {
                        this.logs.add(x, `Register '${values.email}'`)
                        if (x.success) {
                            this.dataService.reloadUserData()
                            this.ref.close(values)
                        }
                    },
                })
            }
        })
    }

    protected readonly EXTENDED_PROJECT_ABILITIES = EXTENDED_PROJECT_ABILITIES
}
