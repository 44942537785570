import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'byteFormat',
    standalone: true,
})
export class ByteFormatPipe implements PipeTransform {
    readonly units = 'kMGTPEZY'

    transform(value: number, decimals = 2, base: 1000 | 1024 = 1000) {
        const bytes = value
        return bytes >= 0 ? this.formatBytes(bytes, decimals, base) : undefined
    }

    private formatBytes(bytes: number, decimals: number, base: 1000 | 1024) {
        const i = Math.min(Math.floor(Math.log(bytes) / Math.log(base)), this.units.length)
        return parseFloat((bytes / Math.pow(base, i)).toFixed(decimals)) + ' ' + this.unit(i, base)
    }

    private unit(i: number, base: 1000 | 1024) {
        const infix = base === 1024 ? 'i' : ''
        const prefix = i > 0 ? (this.units[i - 1] + infix) : ''
        return (prefix + 'B').trim()
    }
}
