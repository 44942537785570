<div class="padded">
    <h1>Object select</h1>

    <p>
        Select/unselect single objects by clicking on them.

    <p>
    Use Box select or Lasso select in the plot toolbar to select
    multiple objects.

    <p>
    Selecting objects with box or lasso will replace previous selection.
</div>
