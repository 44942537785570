import {AfterViewInit, Component, computed, OnDestroy, ViewChild} from '@angular/core'
import {toSignal} from "@angular/core/rxjs-interop"
import {MatIcon} from '@angular/material/icon'
import {MatTooltip} from '@angular/material/tooltip'
import {MatButton} from '@angular/material/button'
import {MatSlider, MatSliderThumb} from '@angular/material/slider'
import {MatSlideToggle} from '@angular/material/slide-toggle'
import {ReactiveFormsModule} from '@angular/forms'
import {
    MatExpansionPanel,
    MatExpansionPanelActionRow,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle
} from '@angular/material/expansion'

import {NgSelectModule} from '@ng-select/ng-select'
import {PlotlyComponent, PlotlySharedModule} from "angular-plotly.js"
import {map} from "rxjs"

import {getNormalPositionSize, MAPBOX_MARKERS} from 'src/app/mapbox/mapbox-helper'
import {INPUT_DATE_MAX} from 'src/constants'
import {HasFigureData} from "../has-figure-data"
import {MEASUREMENT_TRANSFORM_KINDS} from '../plot-functions'
import {
    MAP_PLOT_KIND_ATTRIBUTES,
    MAP_PLOT_KIND_MEASUREMENT_VALUES,
    MAP_PLOT_KIND_STATISTICS,
    MapPlotDataService
} from './map-plot-data.service'
import {CanSaveData} from "../can-save-data"
import {InputComponent} from '../../forms/input/input.component'
import {DatetimeComponent} from "../../forms/datetime/datetime.component"
import {JsonPipe} from "@angular/common"

@Component({
    selector: 'app-map-plot',
    templateUrl: './map-plot.component.html',
    styleUrls: ['./map-plot.component.scss'],
    providers: [
        {provide: HasFigureData, useExisting: MapPlotComponent},
        {provide: CanSaveData, useExisting: MapPlotComponent},
    ],
    standalone: true,
    imports: [
        PlotlySharedModule,
        MatExpansionPanel,
        MatExpansionPanelHeader,
        MatExpansionPanelTitle,
        NgSelectModule,
        ReactiveFormsModule,
        MatSlideToggle,
        MatSlider,
        MatSliderThumb,
        InputComponent,
        MatExpansionPanelActionRow,
        MatButton,
        MatTooltip,
        MatIcon,
        DatetimeComponent,
        JsonPipe,
    ],
})
export class MapPlotComponent implements CanSaveData, HasFigureData, AfterViewInit, OnDestroy {
    readonly MAP_BACKGROUNDS = this.mapPlotDataService.MAP_BACKGROUNDS
    readonly INPUT_DATE_MAX = INPUT_DATE_MAX
    readonly MAPBOX_MARKERS = MAPBOX_MARKERS
    readonly MAP_PLOT_VALUE_DISPLAY = MEASUREMENT_TRANSFORM_KINDS

    @ViewChild(PlotlyComponent) plotlyComponent?: PlotlyComponent
    readonly mapPlotOptionsForm = this.mapPlotDataService.form

    readonly plotlyStyle = toSignal(this.mapPlotDataService.plotlyStyle$)
    readonly nrObjectsWithoutCoordinates = toSignal(this.mapPlotDataService.objectsWithoutCoordinates$.pipe(
        map(os => os.length)))
    readonly objectsWithoutCoordinates = toSignal(this.mapPlotDataService.objectsWithoutCoordinates$.pipe(
        map(os => os.map(o => o.name).join(", "))))
    readonly objectsWithCoordinates = toSignal(this.mapPlotDataService.objectsWithCoordinates$, {initialValue: []})
    readonly measureTypes = toSignal(this.mapPlotDataService.measureTypes$, {initialValue: []})
    readonly popupRef = toSignal(this.mapPlotDataService.popupRef$)
    readonly figure = toSignal(this.mapPlotDataService.figure$)

    transformationDisabled = this.mapPlotDataService.transformationDisabled.bind(this.mapPlotDataService)
    selectObjectsWithValue = this.mapPlotDataService.selectObjectsWithValue.bind(this.mapPlotDataService)
    plotlyRelayout = this.mapPlotDataService.plotlyRelayout.bind(this.mapPlotDataService)
    saveData = this.mapPlotDataService.saveData.bind(this.mapPlotDataService)

    readonly displayKinds = computed(() => {
        const kinds: { title: string, names: readonly string[] }[] = [{
            title: 'Value from range', names: MAP_PLOT_KIND_MEASUREMENT_VALUES,
        }, {
            title: 'Statistics for range', names: MAP_PLOT_KIND_STATISTICS,
        }, {
            title: 'Object attributes', names: MAP_PLOT_KIND_ATTRIBUTES,
        },
        ]
        return kinds as ReadonlyArray<{ title: string, names: ReadonlyArray<string> }>
    })


    constructor(
        private mapPlotDataService: MapPlotDataService,
    ) {
    }

    ngOnDestroy(): void {
        // not strictly necessary, but let's not keep the unloaded element in memory.
        this.mapPlotDataService.plotlyHost = undefined
    }

    ngAfterViewInit(): void {
        this.mapPlotDataService.plotlyHost = this
    }

    async getFigureData() {
        const opt = this.mapPlotDataService.plotlyOptions.config.toImage
        const objects = this.objectsWithCoordinates()
        const figure = this.figure()
        if (figure) {
            const x = getNormalPositionSize(objects, opt, true)
            const firstLayout = figure.layout
            const mapbox = {...x, style: figure.layout.mapbox?.style}
            const layout = {...firstLayout, mapbox}
            return {...figure, layout}
        } else {
            throw Error("Cannot get figure")
        }
    }

    resetPlotOptions() {
        this.mapPlotOptionsForm.controls.options.reset()
    }
}


