import {Component} from '@angular/core'
import {Title} from '@angular/platform-browser'
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop"

import {VideDataService} from 'src/app/api/vide-data.service'
import {getPageTitle} from 'src/app/shared/vide-helper'
import {MatCard} from "@angular/material/card"
import {MatTabLink, MatTabNav, MatTabNavPanel} from "@angular/material/tabs"
import {RouterLink, RouterLinkActive, RouterOutlet} from "@angular/router"
import {MatProgressBar} from "@angular/material/progress-bar"

@Component({
    selector: 'app-edit-groups-container',
    standalone: true,
    templateUrl: './edit-groups-container.component.html',
    styleUrls: ['./edit-groups-container.component.scss'],
    imports: [
        MatCard,
        MatTabNav,
        MatTabLink,
        RouterLink,
        RouterLinkActive,
        MatTabNavPanel,
        RouterOutlet,
        MatProgressBar
    ]
})
export class EditGroupsContainerComponent {
    readonly waiting = toSignal(this.dataService.projectWaiting$)
    readonly selectedObjects =  this.dataService.selectedObjects

    constructor(
        private dataService: VideDataService,
        private title: Title,
    ) {
        this.dataService.project$.pipe(takeUntilDestroyed()).subscribe(p => {
            this.title.setTitle(getPageTitle(p, 'Edit groups'))
        })
    }

}
