import { Component } from '@angular/core';

@Component({
  selector: 'app-constant-name',
  standalone: true,
  imports: [],
  templateUrl: './constant-name.component.html',
  styleUrl: './constant-name.component.scss'
})
export class ConstantNameComponent {

}
