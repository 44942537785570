import {Component} from '@angular/core'
import {FormControl, ReactiveFormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatIconModule} from "@angular/material/icon"

import {NgSelectComponent} from "@ng-select/ng-select"

import {PublicDataComponent} from "../public-data/public-data.component"
import {PlotInfoComponent} from "../plot-info/plot-info.component"
import {AbilityComponent} from "../ability/ability.component"
import {HelpService} from "../help.service"

@Component({
    selector: 'app-help-sidenav',
    standalone: true,
    imports: [
        AbilityComponent,
        MatButtonModule,
        MatIconModule,
        NgSelectComponent,
        PlotInfoComponent,
        PublicDataComponent,
        ReactiveFormsModule,
    ],
    templateUrl: './help-sidenav.component.html',
    styleUrl: './help-sidenav.component.scss'
})
export class HelpSidenavComponent {

    subject = new FormControl<HelpSidenavComponent['subjects'][number]>('Plots')

    protected readonly subjects = [
        'Public data',
        'User abilities',
        'Plots',
    ] as const

    constructor(
        private readonly help: HelpService,
    ) {
    }

    close() {
        this.help.close()
    }
}
