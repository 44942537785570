<mat-card>
    <mat-tab-group [(selectedIndex)]="selectedIndex">
        <mat-tab label="Map">
            <!--            @for (x of scan(); track $index) {-->
            <!--                <div>{{ x }}</div>-->
            <!--            }-->
            <!--            @if (figure(); as figure) {-->
            <!--                <plotly-plot-->
            <!--                    [data]="figure.data"-->
            <!--                    [layout]="figure.layout"-->
            <!--                    [config]="figure.config"-->
            <!--                    [useResizeHandler]="true"-->
            <!--                />-->
            <!--            }-->
            @for (x of messages; track $index) {
                <div>{{ x }}</div>
            }
        </mat-tab>
        <mat-tab label="Input">
            <p>Here you input new measurements</p>
        </mat-tab>
        <mat-tab label="Edit">
            <p>Here you can edit previous inputs</p>
        </mat-tab>
    </mat-tab-group>
</mat-card>


<mat-card>

    <app-debug> {{ message }}</app-debug>
    <app-debug> {{ selectedIndex }}</app-debug>
    <app-debug> {{ nrTabs() }}</app-debug>

    <div [style.height]="'50vh'"></div>
</mat-card>
