import {Component, computed, effect} from '@angular/core'
import {AbstractControl, FormBuilder, ReactiveFormsModule, ValidatorFn, Validators} from '@angular/forms'
import {toSignal} from "@angular/core/rxjs-interop"
import {MatCardModule} from "@angular/material/card"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatInputModule} from "@angular/material/input"
import {MatSlideToggleModule} from "@angular/material/slide-toggle"
import {MatButtonModule} from "@angular/material/button"

import {combineLatestWith, filter, firstValueFrom, map, startWith} from 'rxjs'

import {VideDataService} from 'src/app/api/vide-data.service'
import {ComponentCanDeactivate} from 'src/app/can-deactivate.guard'
import {LogContainer} from 'src/app/log-list/log-container'
import {filterNullAndUndefined, getErrorMessage, isNotNull} from 'src/app/shared/vide-helper'
import {ABILITY} from "../../ability"
import {Project, VideObject} from "../../api/api-types"
import {LogListComponent} from "../../log-list/log-list.component"

@Component({
    selector: 'app-edit-groups-names',
    standalone: true,
    templateUrl: './edit-groups-names.component.html',
    styleUrls: ['./edit-groups-names.component.scss'],
    imports: [
        LogListComponent,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatSlideToggleModule,
        ReactiveFormsModule,
    ]
})
export class EditGroupsNamesComponent extends ComponentCanDeactivate {
    readonly logs = new LogContainer()
    readonly project = this.dataService.project
    readonly groups = toSignal(this.dataService.groups$)

    readonly nameExistsValidator: ValidatorFn = (control: AbstractControl) => {
        const newName = (control.value as string).trim()
        const x = this.groups()?.find(g => g.name === newName)
        return x ? {nameExists: {global: x.global}} : null
    }
    readonly nameForm = this.formBuilder.nonNullable.group({
        name: ['', [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(255),
            this.nameExistsValidator,
        ],],
        objectNames: ['', [Validators.required]],
        global: [false],
    })
    readonly pastedObjects = toSignal(this.dataService.objects$.pipe(
        combineLatestWith(this.nameForm.controls.objectNames.valueChanges.pipe(startWith(''), filter(isNotNull))),
        map(([objects, vc]) => {
            return this.findMatchingObjects(vc, objects)
        }),
    ), {initialValue: []})
    readonly pastedObjectsList = computed(() => {
        return this.pastedObjects()?.map(o => o.name).join(', ')
    })

    getErrorMessage = getErrorMessage

    constructor(
        private readonly formBuilder: FormBuilder,
        private dataService: VideDataService,
    ) {
        super()
        effect(() => {
            const action = ABILITY.WRITE.availableFor(this.project()) ? 'enable' : 'disable'
            this.nameForm.controls.global[action]()
        })
    }

    reset() {
        this.nameForm.reset()
    }

    canDeactivate(): boolean {
        return !this.nameForm.dirty
    }

    async createPastedGroup(project: Project | null | undefined, objects: VideObject[]) {
        if (!project) {
            return
        }
        this.nameForm.markAllAsTouched()
        if (this.nameForm.valid) {
            if (objects.length > 0) {
                const values = this.nameForm.getRawValue()
                const groupName = values.name
                const oids = objects.map(o => o.id)
                const x = await firstValueFrom(this.dataService.createGroup(project, groupName, oids, values.global))
                this.logs.add(x, 'Create group')
                if (x.success) {
                    this.nameForm.markAsPristine()
                    this.dataService.reloadProjectData()
                }
            } else {
                this.logs.addPlainMessage("No objects matched")
            }
        }
    }

    private findMatchingObjects(vc: string, objects: readonly VideObject[]) {
        const objectNames = vc.split("\n").filter(s => s !== '').map(s => s.trim().toLocaleLowerCase())
        return filterNullAndUndefined(objectNames.map(n => objects.find(o => o.name.toLocaleLowerCase() === n)))
    }

}
