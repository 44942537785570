<h1 mat-dialog-title> Object statistics is outdated for some objects</h1>

<mat-dialog-content>
    <ul [style.column-width]="maxNameLength()">
        @for (o of outdatedObjects(); track o.id) {
            <li> {{ o.name }}</li>
        }
    </ul>

    <p>
        Request update to initiate update of all outdated statistics.

        For large amounts of data, this may take several minutes, but for a few
        objects it may take seconds.
    </p>
    <p>
        You may close this dialog and continue to use the app during the update.

        The project data will be reloaded when the update is complete.
    </p>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button (click)="dispatchStatistics()">Request update</button>
    <button mat-raised-button mat-dialog-close="true">Dismiss</button>
</mat-dialog-actions>
