<!-- Top row object/type selector -->
<mat-card>
    <mat-card-content>
        <article class="vide-columns-wide">
            <ng-select
                [items]="objects()"
                [formControl]="form.controls.object"
                placeholder="Object"
            >
                <ng-template ng-option-tmp ng-label-tmp let-item="item">
                    {{ getObjectFullName(item) }}
                </ng-template>
            </ng-select>
            <ng-select
                [items]="measureTypes()"
                bindLabel="name"
                [formControl]="form.controls.measureType"
                placeholder="Measure type"
            />
        </article>
    </mat-card-content>
</mat-card>

<mat-card>
    @if (selectedObject(); as object) {
        @if (project(); as project) {

            <mat-card-content>
                <article class="vide-columns-wide">
                    <section>
                        <h2> {{ object.name }}
                        </h2>
                        @if (object.owner; as owner) {
                            <p>Shared from {{ owner.project_name }} [{{ owner.project_owner }}]</p>
                        }
                        @if (object.original_information) {
                            <p> Contains original information </p>
                        }
                        <p>{{ object.comment }}</p>
                        <p>{{ object.directions }}</p>
                    </section>

                    @if (figure(); as figure) {
                        <section>
                            <plotly-plot [data]="figure.data" [layout]="figure.layout" [config]="figure.config"
                                         [style]="plotlyStyle" [useResizeHandler]="true"></plotly-plot>
                        </section>
                    }

                    @for (i of allFiles()?.images; track $index) {
                        <section>
                            <img
                                [src]="getFileUrl(project,i)"
                                [alt]="i.name"
                                [matTooltip]="i.name"
                                [style.width]="'100%'"
                            >
                        </section>
                    }


                    <section>
                        <table>
                            <tbody>
                                @for (row of attributes(); track row) {
                                    @if (row.value) {
                                        <tr>
                                            <td> {{ row.name }}</td>
                                            <td> {{ row.value }}</td>
                                        </tr>
                                    }
                                }
                            </tbody>
                        </table>
                    </section>
                    @if (triggers(); as triggers) {
                        <section>
                            <table>
                                <thead>
                                <tr>
                                    <th>Trigger</th>
                                    <th>Condition</th>
                                </tr>
                                </thead>
                                <tbody>
                                    @for (row of triggers; track row) {
                                        <tr>
                                            <td> {{ row.description }}</td>
                                            <td> {{ row.type }} {{ row.limit }}</td>
                                        </tr>

                                    }
                                </tbody>
                            </table>
                        </section>
                    }
                    @if (measurementComments(); as comments) {
                        @if (comments.length > 0) {
                            <section>
                                <h3> Measurement comments ({{ OBJECT_INFO.nrMeasurementComments }} latest) </h3>
                                <ul class="no-bullets">
                                    @for (m of comments; track m) {
                                        <li><b>{{ m.date }}</b> {{ m.comment }}</li>
                                    }
                                </ul>
                            </section>
                        }
                    }
                    @if (allFiles()?.documents; as docs) {
                        @if (docs.length > 0) {
                            <section>
                                <h3> Documents </h3>
                                <ul>
                                    @for (f of docs; track f) {
                                        <li><a [href]="getFileUrl(project,f)">{{ f.name }}</a></li>
                                    }
                                </ul>
                            </section>
                        }
                    }
                </article>
            </mat-card-content>

            <mat-card-actions>
                @if (notificationLink(); as href) {
                    <a mat-raised-button [routerLink]="href.link"
                       [queryParams]="href.param">Notifications</a>
                }
                @if (measurementLink(); as href) {
                    <a mat-raised-button [routerLink]="href.link"
                       [queryParams]="href.param"
                       queryParamsHandling="merge" [disabled]="!href.param.oids"
                    > Measurements </a>
                }
                @if (editLink(); as href) {
                    <a mat-raised-button [disabled]="!editable() || !ABILITY.WRITE.availableFor(project)" [routerLink]="href.link"
                       [queryParams]="href.param"> Edit object </a>
                }
                @if (object.owner; as owner) {
                    <button
                        mat-raised-button
                        matTooltip="Delete the object from this project; it will remain in {{owner.project_name}}"
                        (click)="deleteSharedObject(object)"
                        [disabled]="!ABILITY.WRITE.availableFor(project)"
                    >
                        Delete object
                    </button>
                } @else {
                    <span
                        [matTooltip]="object.original_information?'Objects with original information cannot be deleted':''"
                    >
                    <button
                        mat-raised-button
                        (click)="deleteObject(object)"
                        [disabled]="!ABILITY.ADMIN.availableFor(project) || object.original_information  || object.readonly"
                    >
                        Delete object
                    </button>
                    </span>
                }
                <button
                    mat-raised-button
                    (click)="copyObject(object)"
                    [matTooltip]="copyTooltip"
                    [disabled]="!ABILITY.WRITE.availableFor(project)"
                >
                    Copy object
                </button>

            </mat-card-actions>
        }
    }
</mat-card>

