import * as i0 from '@angular/core';
import { InjectionToken, inject, Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, shareReplay } from 'rxjs/operators';
import { NAVIGATOR } from '@ng-web-apis/common';
const GEOLOCATION = new InjectionToken('An abstraction over window.navigator.geolocation object', {
  factory: () => inject(NAVIGATOR).geolocation
});
const POSITION_OPTIONS = new InjectionToken('Token for an additional position options', {
  factory: () => ({})
});
const GEOLOCATION_SUPPORT = new InjectionToken('Is Geolocation API supported?', {
  factory: () => {
    return !!inject(GEOLOCATION);
  }
});
class GeolocationService extends Observable {
  constructor(geolocationRef, geolocationSupported, positionOptions) {
    let watchPositionId = 0;
    super(subscriber => {
      if (!geolocationSupported) {
        subscriber.error('Geolocation is not supported in your browser');
      }
      watchPositionId = geolocationRef.watchPosition(position => subscriber.next(position), positionError => subscriber.error(positionError), positionOptions);
    });
    return this.pipe(finalize(() => geolocationRef.clearWatch(watchPositionId)), shareReplay({
      bufferSize: 1,
      refCount: true
    }));
  }
}
GeolocationService.ɵfac = function GeolocationService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || GeolocationService)(i0.ɵɵinject(GEOLOCATION), i0.ɵɵinject(GEOLOCATION_SUPPORT), i0.ɵɵinject(POSITION_OPTIONS));
};
GeolocationService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: GeolocationService,
  factory: GeolocationService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GeolocationService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: Geolocation,
      decorators: [{
        type: Inject,
        args: [GEOLOCATION]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [GEOLOCATION_SUPPORT]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [POSITION_OPTIONS]
      }]
    }];
  }, null);
})();

/**
 * Public API Surface of geolocation
 */

/**
 * Generated bundle index. Do not edit.
 */

export { GEOLOCATION, GEOLOCATION_SUPPORT, GeolocationService, POSITION_OPTIONS };
