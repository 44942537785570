import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http'
import {FormControl} from "@angular/forms"
import {isDevMode} from "@angular/core"

import {catchError, map, Observable, of, switchMap, tap} from 'rxjs'
import * as S from "superstruct"
import MIMEType from "whatwg-mimetype"

import {environment} from 'src/environments/environment'
import {getHttpErrorMessage} from '../shared/vide-helper'
import {parseCsv} from "../csv/csv-parser"

interface PostResponseFail {
    success: false,
    error: string
}

interface PostResponseValue<T> {
    success: true,
    data: Readonly<T>
}

export type PostResponse<T> = PostResponseValue<T> | PostResponseFail


export class ApiHelper {
    private readonly urlBase = environment.apiUrl + 'v2/'
    private readonly noCacheHeader = new HttpHeaders({'Cache-Control': ['no-cache']})

    constructor(
        private http: HttpClient,
    ) {

    }

    getApiUrl(endpoint: string) {
        return this.urlBase + endpoint
    }

    getCSV(
        endpoint: string,
        params: HttpParams | undefined = undefined,
        headers: HttpHeaders | undefined = undefined,
    ) {
        params = (params ?? new HttpParams()).append('format', 'csv')
        return this.http.get(
            this.getApiUrl(endpoint),
            {
                headers,
                params,
                withCredentials: true,
                responseType: 'text',
                observe: 'response',
            },
        ).pipe(
            map(response => {
                const body = response.body
                if (!body) {
                    throw Error('No body')
                }
                const xx = MIMEType.parse(response.headers.get('content-type') ?? '')
                if (xx?.essence !== 'text/csv') {
                    throw Error(`Wrong mime type ${xx?.essence}`)
                }
                if (xx.parameters.get('header') !== 'present') {
                    throw Error('Missing csv header')
                }
                const ret = parseCsv(body, {eol: "\n", header: true})
                return ret
            })
        )
    }

    getNoContent(
        endpoint: string,
        params: HttpParams | undefined = undefined,
        headers: HttpHeaders | undefined = undefined,
    ) {
        return this.getPlain(endpoint, params, headers).pipe(
            map(x => {
                if (x !== null) {
                    throw Error("Unexpected value in response: " + x)
                }
                return {success: true as const, data: x}
            }),
        )
    }

    readonly validateHttpRequestControl = new FormControl(isDevMode(), {nonNullable: true})

    getWrapped<U extends S.Struct<any, any>, T = S.Infer<U>>(
        struct: U,
        endpoint: string,
        params: HttpParams | undefined = undefined,
        headers: HttpHeaders | undefined = undefined,
    ): Observable<T> {
        return this.getPlain(endpoint, params, headers).pipe(
            // map(x => (S.create(x, S.object({data: S.unknown()}))).data),
            map(x0 => {
                if (this.validateHttpRequestControl.value) {
                    const x = (S.create(x0, S.object({data: S.unknown()}))).data
                    const [error, value] = S.validate(x, struct)
                    if (error === undefined) {
                        return value
                    } else {
                        console.error(`Type error for endpoint GET '${endpoint}'`, x, error.message)
                        // console.error()
                        throw error
                    }
                } else {
                    return (x0 as { data: any }).data as T
                }
            }),
        )
    }

    /** We do not send files here, but if we did (From Laravel docs):
     *
     HTML forms do not support PUT, PATCH, or DELETE actions. So, when defining PUT, PATCH, or DELETE routes that
     are called from an HTML form, you will need to add a hidden _method field to the form. The value sent with the
     _method field will be used as the HTTP request method
     */
    patch(
        endpoint: string,
        body: Object | null = null,
        params?: HttpParams,
    ) {
        return this.csrf().pipe(
            switchMap(() => this.http.patch(
                this.getApiUrl(endpoint),
                body,
                {params, withCredentials: true}
            )),
            tap(u => {
                console.debug('Got response for PATCH ' + endpoint, u)
            }),
            map(x => {
                if (x !== null) {
                    throw Error("Unexpected value in response: " + x)
                }
                return {success: true as const, data: x}
            }),
            catchError(this.handleHttpError),
        )
    }

    put(
        endpoint: string,
        body: Object | null = null,
        params?: HttpParams,
    ) {
        return this.csrf().pipe(
            switchMap(() =>
                this.http.put(
                    this.getApiUrl(endpoint),
                    body,
                    {params, withCredentials: true}
                )),
            tap(u => {
                console.debug('Got response for PUT ' + endpoint, u)
            }),
            map(x => {
                if (x !== null) {
                    throw Error("Unexpected value in response: " + x)
                }
                return {success: true as const, data: x}
            }),
            catchError(this.handleHttpError),
        )
    }

    delete(
        endpoint: string,
        body: Object | null = null,
        params?: HttpParams,
    ) {
        return this.csrf().pipe(
            switchMap(() => this.http.delete(
                this.getApiUrl(endpoint),
                {params, withCredentials: true, body}
            )),
            tap(u => {
                console.debug('Got response for DELETE ' + endpoint, u)
            }),
            map(x => {
                if (x !== null) {
                    throw Error("Unexpected value in response: " + x)
                }
                return {success: true as const, data: x}
            }),
            catchError(this.handleHttpError),
        )

    }

    postNoContent(
        endpoint: string,
        body: Object | null = null,
        params?: HttpParams,
    ) {
        return this.postPlain(endpoint, body, params).pipe(
            map(x => {
                if (x !== null) {
                    throw Error("Unexpected value in response: " + x)
                }
                return {success: true as const, data: x}
            }),
            catchError(this.handleHttpError),
        )
    }

    postWrapped<U extends S.Struct<any, any>, T = S.Infer<U>>(
        struct: U,
        endpoint: string,
        body: Object | null = null,
        params?: HttpParams,
    ): Observable<PostResponse<T>> {
        return this.postPlain(endpoint, body, params).pipe(
            tap(u => {
                console.debug('Got response for NEW POST ' + endpoint, u)
            }),
            map(response => {
                const xx = S.create(response, S.object({data: S.unknown()})).data
                const [error, value] = S.validate(xx, struct)
                if (error === undefined) {
                    return {success: true as const, data: value}
                } else {
                    console.error(`Type error for endpoint POST '${endpoint}'`, xx, error.message)
                    throw error
                }
            }),
            catchError(this.handleHttpError),
        )
    }

    postWithProgress(
        endpoint: string,
        data: FormData | any,
    ) {
        return this.csrf().pipe(
            switchMap(() => this.http.post(
                this.getApiUrl(endpoint),
                data,
                {
                    reportProgress: true,
                    withCredentials: true,
                    observe: 'events',
                },
            )),
        )
    }

    private handleHttpError(error: any) {
        console.warn("Http error: ", error)
        if (error instanceof HttpErrorResponse) {
            const ret: PostResponseFail = {success: false, error: getHttpErrorMessage(error)}
            return of(ret)
        }
        throw error
    }

    csrf() {
        const headers = this.noCacheHeader
        return this.getNoContent('csrf-cookie', undefined, headers)
    }

    private getPlain(
        endpoint: string,
        params: HttpParams | undefined = undefined,
        headers: HttpHeaders | undefined = undefined,
    ) {
        return this.http.get(
            this.getApiUrl(endpoint),
            {
                headers,
                params,
                withCredentials: true,
            },
        )
    }

    private postPlain(
        endpoint: string,
        body: Object | null = null,
        params?: HttpParams,
    ) {
        return this.csrf().pipe(
            switchMap(() => this.http.post(
                this.getApiUrl(endpoint),
                body,
                {params, withCredentials: true},
            )),
        )
    }

}
