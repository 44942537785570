<p>
    Import new measurements from a diver. The file format is assumed to be similar to csv-files from DiverOffice and
    time is interpreted as local time.
</p>

<div class="vide-form-field">
    <div class="vide-columns-wide vide-form-field-row">
        <app-input
            type="file"
            [formControl]="form.controls.file"
            [multiple]="false"
            placeholder="Select file"
            accept=".csv"
        />
    </div>
</div>

@if (fileError(); as e) {
    <div class="error">
        {{ e }}
    </div>
}

@if (figure(); as figure) {
    <p>Click in the plot to set start and end of interval to use.</p>
    <plotly-plot
        [data]="figure.data"
        [layout]="figure.layout"
        [config]="figure.config"
        [useResizeHandler]="true"
        (plotlyClick)="plotlyClick($event)"
        (doubleClick)="doubleClick()"
    />
}

@if (annotations(); as ann) {
    @if (ann[0]; as begin) {
        <div>Begin: {{ begin.x }}</div>
    }
    @if (ann[1]; as end) {
        <div>End: {{ end.x }}</div>
    }
}

<p>
    Found {{ nrMeasurements() ?? 'no' }} measurements
</p>

@if (rawFileData(); as raw) {
    <mat-expansion-panel [expanded]="false">
        <mat-expansion-panel-header>
            <mat-panel-title>File header</mat-panel-title>
        </mat-expansion-panel-header>
        <div>
            @for (x of raw.head; track $index) {
                <code>
                    {{ x }} <br>
                </code>
            }
        </div>
    </mat-expansion-panel>
}


<div>
    <mat-form-field [style.width]="'25rem'">
        <mat-label>
            Type of measurements
        </mat-label>
        <mat-select [formControl]="form.controls.code">
            @for (item of DIVER_DATA_TYPES; track item) {
                <mat-option [value]="item">
                    {{ item }}
                </mat-option>
            }
        </mat-select>
    </mat-form-field>
</div>

<div>
    You can save the measurements to {{ diverObject().name }}, or continue with the following steps according to the
    type.
</div>
<button
    mat-raised-button
    [disabled]="!nrMeasurements() || saving.value"
    (click)="save(diverObject())"
>
    @if (saving.value) {
        Saving...
    } @else {
        Save to {{ diverObject().name }}
    }
</button>

<div class="stepper-navigate">
    <button mat-button matStepperPrevious type="button">Previous</button>
    <button mat-button matStepperNext type="button">Next</button>
</div>
