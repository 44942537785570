@if (waiting()) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}

@if (objects(); as objects) {
    <mat-card>
        <mat-card-header>
            <mat-card-title>
                <h2>Batch plot</h2>
            </mat-card-title>
            <mat-card-subtitle class="object-list">
                Save plots for objects
                @for (o of objects; track o; let last = $last) {
                    <span>
            {{ o.name }}@if (!last) {
                        <span>, </span>
                    }
        </span>
                }
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="batchForm">
                <mat-form-field>
                    <mat-label>Plot type</mat-label>
                    <mat-select formControlName="plotType">
                        @for (t of PLOT_TYPES; track t) {
                            <mat-option [value]="t">{{ t }}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Start date</mat-label>
                    <!-- fem år sedan! -->
                    <input matInput type="date" formControlName="startDate" [max]="INPUT_DATE_MAX">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>End date</mat-label>
                    <input matInput type="date" formControlName="endDate" [max]="INPUT_DATE_MAX">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Width</mat-label>
                    <input matInput type="number" formControlName="width">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Height</mat-label>
                    <input matInput type="number" formControlName="height">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>File name prefix</mat-label>
                    <input matInput type="text" formControlName="prefix">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>File name suffix</mat-label>
                    <input matInput type="text" formControlName="suffix">
                </mat-form-field>
                @if (batchForm.controls.measureType; as control) {
                    <mat-form-field>
                        <mat-label>Measure type</mat-label>
                        <mat-select formControlName="measureType">
                            @for (t of measureTypes(); track t) {
                                <mat-option [value]="t">
                                    {{ t.name }}
                                </mat-option>
                            }
                        </mat-select>
                        <mat-error>{{ getErrorMessage(control) }}</mat-error>
                    </mat-form-field>
                }
                <mat-form-field>
                    <mat-label>Value</mat-label>
                    <mat-select formControlName="displayValue">
                        @for (t of DISPLAY_KINDS; track t) {
                            <mat-option [value]="t">
                                {{ t }}
                            </mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </form>
            {{ nrPlots() }} plot(s) will be created.
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button [disabled]="busy" (click)="savePlots(objects)">Save plots</button>
            <button mat-raised-button [disabled]="busy || failedObjects.length===0"
                    (click)="savePlots(failedObjects.slice())"> Retry failed objects
            </button>
        </mat-card-actions>
    </mat-card>
}

@if (total) {
    <mat-progress-bar mode="buffer" [value]="100*done/total" [bufferValue]="100*fetched/total">
    </mat-progress-bar>
}

<mat-accordion multi="true">
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Objects without validated correlations ({{ nonValidatedObjects.length }})
            </mat-panel-title>
            <mat-panel-description>
                No plot will be generated for these objects
            </mat-panel-description>
        </mat-expansion-panel-header>
        @for (x of nonValidatedObjects; track x.id) {
            <p>{{ x.name }}</p>
        }
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Failed ({{ failedObjects.length }})
            </mat-panel-title>
            <mat-panel-description>
                These objects can be retried later
            </mat-panel-description>
        </mat-expansion-panel-header>
        @for (x of failedObjects; track x) {
            <p>{{ x.name }}</p>
        }
    </mat-expansion-panel>
    <mat-expansion-panel expanded>
        <mat-expansion-panel-header>Result ({{ actionLog.length }})</mat-expansion-panel-header>
        @for (x of actionLog; track $index) {
            <p>{{ x }}</p>
        }
    </mat-expansion-panel>
</mat-accordion>

<div class="debug">
    <!-- nrNotValidated: {{nrNotValidated|json}}<br> -->
    <!-- selectedType: {{selectedType | json}}<br> -->
    <!-- plotType: {{batchForm.value | json}}<br> -->
    <!-- inProgress: {{inProgress}}<br> -->
    <!-- running: {{running}}<br> -->
    <!-- waiting: {{waiting$ | async | json}}<br> -->
    <!--     total: {{total }}<br>-->
    <!--     fetched: {{fetched}}<br>-->
    <!--     done: {{done }}<br>-->
</div>

<plotly-plot [style]="{display: 'none'}" divId="real"></plotly-plot>
