import {FormBuilder} from "@angular/forms"
import {Injectable} from '@angular/core'
import {SelectionModel} from "@angular/cdk/collections"

import * as S from "superstruct"
import {combineLatest, firstValueFrom, map, startWith, switchMap} from "rxjs"

import {PlotlyScattermapboxData, VideFigure} from "../../vide-types"
import {AbstractMapboxService} from "../../mapbox/abstract-mapbox.service"
import {PLOT_COLOR} from "../../../constants"
import {ExternalObject, GoteborgStation, Position} from "../../api/api-types"
import {LogContainer} from "../../log-list/log-container"
import {HttpCounterService} from "../../shared/http-counter.service"

const ExternalObjectGoteborg = S.assign(ExternalObject, S.object({
    options: S.object({
        name: S.string(),
        // the rest are not used, but delivered... maybe we should stop them in the backend resource?
        updated: S.unknown(),
        // factor: S.unknown(),
    })
}))

export interface GoteborgObject {
    external: ExternalObject | null
    position: Position
    positionM: { x: number, y: number }
    station: GoteborgStation
    tainted: boolean
}

@Injectable({
    providedIn: 'root'
})
export class GoteborgService extends AbstractMapboxService {
    private readonly ExternalType = 'goteborg'
    protected readonly useColorbar: boolean = false
    readonly httpStatus = {
        vide: new SelectionModel<number>(true, [], true),
        extern: this.counter.waiting$,
    }
    readonly logs = new LogContainer('Save')
    readonly form = this.formBuilder.nonNullable.group({
        showInactive: [false],
    })

    readonly externalSelection$ = this.dataService.projectNotNull$.pipe(
        switchMap((p) => this.dataService.getExternalObjects(p, 'goteborg')),
        map(externals => S.create(externals, S.array(ExternalObjectGoteborg))),
    )
    readonly stations$ = combineLatest([
        this.dataService.getImportGoteborgStations(),
        this.dataService.getConverterToWgs(3007),
        this.externalSelection$,
        this.form.controls.showInactive.valueChanges.pipe(startWith(false)),
        this.httpStatus.vide.changed.pipe(startWith(false)),
    ]).pipe(
        map(([stations, converter, ex, showInactive,]) => {
            const active =
                showInactive ? stations : stations.filter(s => s.isActive)
            return active.map(station => {
                const external = ex.find(x => x.options.name === station.name) ?? null
                const positionM = {x: station.x, y: station.y}
                const position = converter(positionM)
                const tainted = this.httpStatus.vide.isSelected(station.id)
                const ret: GoteborgObject = {
                    external,
                    position,
                    positionM,
                    station,
                    tainted
                }
                return ret
            })
        }),
    )

    readonly figure$ =
        this.stations$.pipe(
            map(stations => {
                const data: PlotlyScattermapboxData[] = [{
                    customdata: stations as any[],
                    hovertemplate: stations.map(o => {
                        const p = o.station
                        const desc = []
                        {
                            let key: keyof typeof p
                            for (key in p) {
                                desc.push(`${key}: ${p[key]}`)
                            }
                        }
                        return `<em>%{text} </em> <br>` + desc.join("<br>") + "<extra></extra>"
                    }),
                    lat: stations.map(p => p.position.lat),
                    lon: stations.map(p => p.position.lon),
                    marker: {
                        color: stations.map(o =>
                            o.external ? PLOT_COLOR.object_selected : PLOT_COLOR.object_unselectd
                        ),
                    },
                    text: stations.map(GoteborgService.getName),
                    type: 'scattermapbox',
                }]
                const layout = this.getLayout(stations, {background: 'open-street-map'})
                const config = this.getMapConfig({filename: 'asdf'})
                const figure: VideFigure = {
                    config,
                    data,
                    layout,
                }
                return figure
            })
        )


    constructor(
        private formBuilder: FormBuilder,
        private readonly counter: HttpCounterService,
    ) {
        super()
    }


    async add(x: GoteborgObject) {
        const project = await firstValueFrom(this.dataService.projectNotNull$)
        const properties = {name: GoteborgService.getName(x)}
        this.httpStatus.vide.select(GoteborgService.getId(x))
        const res = await firstValueFrom(
            this.dataService.createExternalObject(project, this.ExternalType, properties)
        ).finally(() => {
            this.httpStatus.vide.deselect(GoteborgService.getId(x))
        })
        this.logs.add(res, `Add ${x.station.name}`)
        if (res.success) {
            this.dataService.reloadProjectData()
        }
    }

    async remove(x: GoteborgObject) {
        if (!x.external) return
        const project = await firstValueFrom(this.dataService.projectNotNull$)
        this.httpStatus.vide.select(GoteborgService.getId(x))
        const res = await firstValueFrom(
            this.dataService.deleteExternalSource(project, x.external)
        ).finally(() => {
            this.httpStatus.vide.deselect(GoteborgService.getId(x))
        })
        this.logs.add(res, `Remove ${x.station.name}`)
        if (res.success) {
            this.dataService.reloadProjectData()
        }
    }

    static getName(s: GoteborgObject) {
        return s.station.name
    }

    static getId(x: GoteborgObject) {
        return x.station.id
    }
}


