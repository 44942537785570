import {Injectable} from '@angular/core'
import {FormBuilder, Validators} from "@angular/forms"

import {
    BehaviorSubject,
    catchError,
    combineLatest,
    firstValueFrom,
    map,
    of,
    shareReplay,
    startWith,
    Subject,
    switchMap,
    tap
} from "rxjs"

import {VideObject} from "../../../api/api-types"
import {FileValidator} from "../../import-file/file-validator"
import {VideDataService} from "../../../api/vide-data.service"
import {PlotlyLayout, PlotlyMouseEvent} from "../../../vide-types"
import {
    annotationsToAnnotations,
    DiverService,
    figureClick,
    getFigure,
    getFilterFn,
    parseDiverOfficeData,
    readAsText
} from "../diver.service"
import {SlimObject} from "../diver.component"

@Injectable({
    providedIn: 'root'
})
export class ImportBaroService {
    readonly fileError = new Subject<string>()
    // We start off as valid.
    readonly completed = new BehaviorSubject(false)
    readonly selectedBaro = new BehaviorSubject<SlimObject | null>(null)
    readonly form = this.fb.nonNullable.group({
        object: [null as null | VideObject, Validators.required],
        file: [null as FileList | null, [Validators.required, FileValidator.nrFiles(1),]],
    })
    private readonly _baroAnnotations = new BehaviorSubject<NonNullable<PlotlyLayout['annotations']>>([])
    readonly baroAnnotations$ = this._baroAnnotations.pipe(
        map(annotationsToAnnotations),
    )
    private readonly selectedFile$ = this.form.controls.file.valueChanges.pipe(
        startWith(null),
        map(v => v?.item(0)),
        tap(x => {
            /**
             * When we select a file, the stage is not ready.
             * It has to be saved to become ready again.
             * If there is no file, we are ready.
             */
            this.completed.next(!x)
        }),
    )
    private readonly localBaroData$ = this.selectedFile$.pipe(
        switchMap(file => file ? readAsText(file).then(parseDiverOfficeData) : of(null)),
        catchError(err => {
            if (err instanceof Error) this.fileError.next(err.message)
            return of(null)
        }),
        map(data => data ? data.data : null),
        tap(_x => {
            this.resetBaroAnnotations()
        }),
        shareReplay({refCount: true, bufferSize: 1})
    )
    readonly baroFigure$ = combineLatest([
        this.localBaroData$,
        this.baroAnnotations$,
    ]).pipe(
        map(([x, annotations]) => {
            return x ? getFigure(x, 'pressure', annotations) : null
            // return x ? getFigure(x, 'baro-diver', annotations) : EMPTY_FIGURE
        }),
    )
    private readonly clippedBaroData$ = combineLatest([
        this.localBaroData$,
        this.baroAnnotations$,
    ]).pipe(
        map(([x, annotations]) => {
            if (!x) return null
            return x.filter(getFilterFn(annotations))
        }),
    )
    readonly baroNrMeasurements$ = this.clippedBaroData$.pipe(
        map(data => data?.length),
    )

    private resetBaroAnnotations() {
        this._baroAnnotations.next([])
    }

    baroDoubleClick() {
        this.resetBaroAnnotations()
    }

    baroFigureClick(event: PlotlyMouseEvent) {
        figureClick(this._baroAnnotations, event)
    }

    constructor(
        private readonly fb: FormBuilder,
        private readonly dataService: VideDataService,
        private readonly diverService: DiverService,
    ) {
    }

    async save(object: Pick<VideObject, 'id' | 'name'>) {
        const p = this.dataService.project()
        const dataStatus = await firstValueFrom(this.dataService.dataStatusStandard$)
        const measureType = await firstValueFrom(this.diverService.measureTypePressure$)
        const localData = await firstValueFrom(this.clippedBaroData$)
        if (!localData || !p || !dataStatus || !measureType) {
            throw new Error('Missing input values')
        }
        const x = await this.diverService.saveToObject(localData, {
            // errorCode: null,
            measureType,
            object,
            update: false,
        })
        // this.diverService.logs.add(x,`Save baro-data to ${object.name}`)
        if (x.success) {
            /**
             * On successful save, this stage is ready.
             */
            this.completed.next(true)
            this.selectedBaro.next(object)
        } else {
        }
    }

    async saveNew(name: string) {
        const objectResponse = await this.diverService.createNewObject(name)
        if (objectResponse.success) {
            await this.save(objectResponse.data)
        }
        return objectResponse.success
    }
}
