<h1>Testing area</h1>

<article [style.width]="'50%'">
    <h2> Test the coloring with material M2/M3 </h2>
    <div class="debug"> Debug</div>
    <div class="warning"> Warning</div>
    <div class="error"> Error</div>
    <div class="active"> Active</div>
    <div class="result"> Result</div>
    <div class="vide-editable-value dirty"> Dirty</div>
</article>

<article [style.width]="'50%'">
    <h2> Test the color sequences for plotly </h2>
    <table>
        <tbody>
            @for (q of (colorSequences | keyvalue); track q.key) {
                <tr>
                    <td style="text-align: end;"> {{ q.key }} ({{ q.value.length }}):</td>
                    <td style="display: flex;">
                        @for (c of getJunk(q.key); track c) {
                            <span [style.background-color]="c"
                                  style="width: 3em;height: 1em;flex: 1 1 ;">
            </span>
                        }
                    </td>
                </tr>
            }

        </tbody>
    </table>
</article>

