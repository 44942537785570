<header>
    <app-toolbar/>
</header>

<mat-sidenav-container>
    <mat-sidenav
        mode="side"
        position="end"
    >
        <app-help-sidenav></app-help-sidenav>
    </mat-sidenav>
    <mat-sidenav-content>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>

<footer class="mat-elevation-z8">
    &copy; 2024 Akvifär AB
</footer>
