import {AfterViewInit, Component, DestroyRef, Inject, viewChild} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogModule, MatDialogRef} from "@angular/material/dialog"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MatButtonModule} from "@angular/material/button"
import {MatTableDataSource, MatTableModule} from "@angular/material/table"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {MatSort, MatSortModule} from "@angular/material/sort"
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms"

import {combineLatestWith, concat, filter, map, switchMap, tap} from "rxjs"
import {NgSelectModule} from "@ng-select/ng-select"

import {Project, ProjectUser} from "../../../api/api-types"
import {LogListComponent} from "../../../log-list/log-list.component"
import {VideDataService} from "../../../api/vide-data.service"
import {InputComponent} from "../../../forms/input/input.component"
import {LogContainer} from "../../../log-list/log-container"
import {Ability, ExtendedProjectAbility} from "../../../ability"
import {Unpacked} from "../../../vide-types"
import {MatInputModule} from "@angular/material/input"
import {isDefined, isNotNull} from "../../../shared/vide-helper"
import {MatSelectModule} from "@angular/material/select"
import {APP_NAME} from "../../../../constants"
import {MatDividerModule} from "@angular/material/divider"


export type EditProjectUsersComponentData = MatDialogConfig<{ project: Project, }>['data']
export type EditProjectUsersComponentResult = boolean
type UserFormGroup = ReturnType<EditProjectUsersComponent['getFormGroup']>

interface LocalUser extends Omit<ProjectUser, 'ability' | 'license_name'> {
    ability: ExtendedProjectAbility
}

interface RequestResult {
    id: number,
    result: Unpacked<ReturnType<VideDataService['setProjectUser']>>
}

@Component({
    selector: 'app-edit-project-users',
    standalone: true,
    imports: [
        InputComponent,
        LogListComponent,
        MatButtonModule,
        MatDialogModule,
        MatDividerModule,
        MatInputModule,
        MatProgressBarModule,
        MatSelectModule,
        MatSortModule,
        MatTableModule,
        NgSelectModule,
        ReactiveFormsModule,
    ],
    templateUrl: './edit-project-users.component.html',
    styleUrl: './edit-project-users.component.scss'
})
export class EditProjectUsersComponent implements AfterViewInit {
    private readonly sort = viewChild(MatSort)
    protected readonly newUserForm = this.formBuilder.nonNullable.control('', [Validators.required, Validators.email])

    protected readonly users$ = this.dataService.projects$.pipe(
        map(ps => ps.find(p => p.id === this.data?.project.id)),
        // tap(x => {        }),
        filter(isDefined),
        switchMap(p => this.dataService.getProjectUsers(p)),
        combineLatestWith(this.dataService.knownUsers$),
        map(([projectUsers, knownUsers]) => {
            const userControl = this.form
            userControl.clear()
            const newUsers = knownUsers.map(user => {
                const pUser = projectUsers.find(u => u.id === user.id)
                const form = this.getFormGroup(pUser ?? {...user, ability: 'None', comment: null})
                userControl.push(form)
                return {user, pUser, form}
            })
            projectUsers.forEach(pUser => {
                if (!knownUsers.find(k => k.id === pUser.id)) {
                    console.error('User missing from known users!!! ', pUser)
                }
            })
            for (let _ of knownUsers.map(k => {
                const idx = projectUsers.find(u => u.id === k.id)
                // just return users not already in projectUsers
                return idx ? null : {...k, comment: null, ability: 'None' as const}
            }).filter(isNotNull)) {
            }
            userControl.markAsPristine()
            return newUsers
        }),
    )
    protected readonly logs = new LogContainer()
    protected readonly form = this.formBuilder.array([] as UserFormGroup[])
    protected readonly dataSource = new MatTableDataSource<Unpacked<typeof this.users$>>()
    protected readonly columnsToDisplay = [
        'name',
        'email',
        'access',
        'comment',
        // 'test',
    ] as const

    protected waiting = 0
    protected someEdit = false
    protected completed = 0
    private success = 0

    constructor(
        private readonly destroyRef: DestroyRef,
        private readonly dataService: VideDataService,
        private readonly formBuilder: FormBuilder,
        private readonly ref: MatDialogRef<EditProjectUsersComponent, EditProjectUsersComponentResult>,
        @Inject(MAT_DIALOG_DATA) public data: EditProjectUsersComponentData,
    ) {
        this.users$.pipe(takeUntilDestroyed()).subscribe(all => {
            this.dataSource.data = all
        })
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
            switch (sortHeaderId) {
                case 'access':
                    if (data.user.ability === null) {
                        return -1
                    }
                    switch (data.form.controls.ability.value) {
                        case 'Admin':
                            return 40
                        case 'Write':
                            return 30
                        case 'Measure':
                            return 20
                        case 'Read':
                            return 10
                        case 'None':
                            return 0
                        default:
                            throw new Error("Impossible")
                    }
                // const tValue = data.form.controls.ability.value
                // return tValue === 'None' ? null : tValue
                default:
                    return (data.user as any)[sortHeaderId]
            }
        }
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort() ?? null
    }

    private getFormGroup(u: LocalUser) {
        console.log(u)
        const formGroup = this.formBuilder.nonNullable.group({
            // user: [u],
            id: [u.id],
            comment: [u.comment],
            ability: [u.ability],
        })
        console.log(formGroup.value)
        return formGroup
    }

    save() {
        const ctrl = this.form
        ctrl.markAllAsTouched()
        const p = this.data?.project
        if (!(p && ctrl.valid)) {
            console.log("form invalid")
            return
        }
        if (ctrl.pristine) {
            this.logs.addPlainMessage("No changes to save")
            return
        }
        const changedControls = ctrl.controls.filter(c => !c.pristine)
        console.warn(changedControls)
        this.waiting = changedControls.length
        const requests = changedControls.map(cntrl => {
            const change = cntrl.getRawValue()
            let request
            if (change.ability === 'None') {
                request = this.dataService.removeProjectUser(p, change)
            } else {
                request = this.dataService.setProjectUser(p, {...change, ability: change.ability})
            }

            return request.pipe(
                map(x => ({result: x, id: change.id})),
                tap(x => {
                    this.completed++
                    if (x.result.success) {
                        this.success++
                        cntrl.markAsPristine()
                    } else {
                        this.logs.add(x.result, `Save update of ${x.id}`)
                    }
                })
            )
        })
        concat(...requests).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
            next: x => {
                console.log(x)
            },
            complete: () => {
                if (this.success > 0) {
                    this.dataService.reloadProjectData()
                }
                if (this.success === this.waiting) {
                    console.warn('All successfule')
                    this.dataService.reloadUserData()
                    this.ref.close(true)
                }
            }
        })
    }

    protected readonly availableAbilities = Ability.availableAbilities
    protected readonly APP_NAME = APP_NAME

    addEmail() {
        this.newUserForm.markAllAsTouched()
        const p = this.data?.project
        if (!(p && this.newUserForm.valid)) {
            console.log("form invalid")
            return
        }
        if (this.newUserForm.pristine) {
            this.logs.addPlainMessage("No changes to save")
            return
        }
        const email = this.newUserForm.value

        this.dataService.newProjectUser(p, {
            email,
            ability: 'Read',
            comment: null
        }).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(r => {
            this.logs.add(r, `New user ${email}`)
            if (r.success) {
                this.dataService.reloadUserData()
                this.dataService.reloadProjectData()
                this.newUserForm.reset()
            }
        })

    }
}

