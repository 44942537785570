import {enableProdMode} from '@angular/core'
import {bootstrapApplication} from '@angular/platform-browser'

import {environment} from './environments/environment'
import {AppComponent} from './app/app.component'
import {appConfig} from "./app/app.config"

if (environment.production) {
    enableProdMode()
    // disableConsoleMessages(['debug'])
}

bootstrapApplication(AppComponent, appConfig)
    // .then(moduleRef => {
    //   const applicationRef = moduleRef.injector.get(ApplicationRef);
    //   const componentRef = applicationRef.components[0];
    //   // allows to run `ng.profiler.timeChangeDetection();`
    //   enableDebugTools(componentRef);
    // })
    .catch(err => console.error(err))
