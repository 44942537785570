<div class="vide-columns42" style="display: inline-block">
    <app-input
        style="display: inline-block"
        type="date"
        [min]="INPUT_DATE_MIN"
        [max]="INPUT_DATE_MAX"
        (blur)="_onTouched()"
        [formControl]="form.controls.date"
        data-testid="date"
    />
    <app-input
        style="display: inline-block"
        type="time"
        [step]="useSeconds()?1:undefined"
        matTooltip="optional"
        (blur)="_onTouched()"
        [formControl]="form.controls.time"
        data-testid="time"
    />
</div>
