import {inject} from "@angular/core"

import {PlotlyComponent, PlotlyService} from "angular-plotly.js"
import {Icons, ModeBarButton} from "plotly.js-dist-min"
import {BehaviorSubject, defer, of, retry, Subscription} from "rxjs"
import {MapboxOptions} from "mapbox-gl"

import {PLOT_CONFIG} from "../../constants"
import {PlotlyConfig, PlotlyLayout, PlotlyRelayoutEvent, VideObjectWithPosition,} from "../vide-types"
import {
    getNormalPosition,
    MAP_BACKGROUNDS_APIKEY,
    MAP_BACKGROUNDS_PUBLIC,
    MapBackground,
    MapPosition
} from "./mapbox-helper"
import {VideObject} from "../api/api-types"
import {VideDataService} from "../api/vide-data.service"

/**
 * Use
 *
 *  `@ViewChild(PlotlyComponent) plotlyComponent?: PlotlyComponent` and
 *  `ngAfterViewInit(): void {
 *         this.dataService.plotlyHost = this
 *     }`
 * and use `this.forceRelayout$` to update figure layout
 */
export abstract class AbstractMapboxService {
    private readonly sguStyle: MapboxOptions['style'] = {
        name: 'SGU jordlager (not visible in small scale)',
        'version': 8,
        'sources': {
            'raster-tiles': {
                'type': 'raster',
                'tiles': [
                    // 'https://stamen-tiles.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg',
                    'https://maps3.sgu.se/geoserver/jord/ows?' +
                    'bbox={bbox-epsg-3857}&' +
                    'format=image/png&' +
                    'service=WMS&' +
                    // 'version=1.1.1&' +
                    'version=1.3.0&' +
                    'request=GetMap&' +
                    'srs=EPSG:3857&' +
                    'transparent=true&' +
                    'width=256&' +
                    'height=256&' +
                    'layers=jord:SE.GOV.SGU.JORD.GRUNDLAGER.25K'
                ],
                'tileSize': 256,
                'attribution': '<a target="_blank" rel="noopener" href="https://sgu.se">Sveriges geologiska undersökning</a>',
            }
        },
        'layers': [
            {
                'id': 'simple-tiles',
                'type': 'raster',
                'source': 'raster-tiles',
                'minzoom': 0,
                'maxzoom': 22
            }
        ]
    }

    readonly MAP_BACKGROUNDS = [
        {
            title: 'Normal backgrounds',
            names: MAP_BACKGROUNDS_APIKEY,
        },
        {
            title: 'Background where label will not work',
            names: MAP_BACKGROUNDS_PUBLIC,
        },
        {
            title: 'Experimental backgrounds, expect some problems',
            names: [
                this.sguStyle,
                // Doesn't work, unclear why
                // 'mapbox://styles/micce/clnu7hh4m00oh01qyc9pmczvc',
            ],
        }

    ] as const

    private plotlyElement?: HTMLElement
    private mapPosition?: MapPosition
    private elementFetcher?: Subscription

    protected readonly forceRelayout$ = new BehaviorSubject<void>(undefined)
    protected abstract readonly useColorbar: boolean
    private plotlyService: PlotlyService
    protected dataService: VideDataService

    set plotlyHost(host: { plotlyComponent?: PlotlyComponent } | undefined) {
        // TODO: maybe set the div-id here and use const div = this.plotlyService.getInstanceByDivId('my-div'); or something?
        if (host) {
            this.elementFetcher = this.fetchPlotlyElement(host).subscribe({
                next: e => {
                    // console.warn(e)
                    this.plotlyElement = e
                    this.forceRelayout$.next()
                },
                error: err => {
                    console.warn(`${err}`)
                }
            })
        } else {
            this.elementFetcher?.unsubscribe()
            this.plotlyElement = undefined
        }
    }

    private zoomAutoscaleButton: ModeBarButton = {
        name: 'zoomAutoscale',
        click: this.zoomAutoscale.bind(this),
        icon: Icons.autoscale,
        // icon: 'asdf',
        title: 'Autoscale zoom',
    }

    protected constructor() {
        this.plotlyService = inject(PlotlyService)
        this.dataService = inject(VideDataService)
    }

    protected zoomAutoscale() {
        this.clearPosition()
        this.forceRelayout$.next()
    }

    protected clearPosition() {
        this.mapPosition = undefined
    }

    protected getMapConfig(options?: { filename: string }): PlotlyConfig {
        return {
            showEditInChartStudio: true, // icon in modebar
            plotlyServerURL: "https://chart-studio.plotly.com",
            modeBarButtonsToRemove: [
                'toImage',
                'select2d',
                'lasso2d',
                'resetScale2d',
                'resetViewMapbox',
            ],
            modeBarButtonsToAdd: [
                this.zoomAutoscaleButton,
                this.dataService.plotlySnapshotButton,
            ],
            mapboxAccessToken: PLOT_CONFIG.mapboxAccessToken,
            toImageButtonOptions: {filename: options?.filename},
            scrollZoom: true,
        }
    }

    // protected getLayout(os: Pick<VideObjectV2, 'lat' | 'lon' | 'ew' | 'ns'>[], options: { background: MapBackground }): PlotlyLayout {
    protected getLayout(objects: readonly Pick<VideObject, 'position' | 'positionM'>[], options: {
        background: MapBackground
    }): PlotlyLayout {
        const position = this.mapPosition ?? getNormalPosition(objects, this.plotlyElement, this.useColorbar)
        return {
            mapbox: {
                style: options.background,
                center: position.center,
                zoom: position.zoom,
            },
            margin: {r: 0, t: 0, b: 0, l: 0},
            showlegend: false,
            autosize: true,
        }
    }

    plotlyRelayout(event: PlotlyRelayoutEvent) {
        if (event['mapbox.center'] && event['mapbox.zoom']) {
            this.mapPosition = {
                center: event['mapbox.center'],
                zoom: event['mapbox.zoom'],
            }
        }
    }

    /**
     * Zoom to the given objects
     * @param objects
     */
    protected zoomToObjects(objects: VideObjectWithPosition[]) {
        console.warn(this)
        this.mapPosition = getNormalPosition(objects, this.plotlyElement, this.useColorbar)
        this.forceRelayout$.next()
        return this.mapPosition
    }

    private fetchPlotlyElement(host: { plotlyComponent?: PlotlyComponent }) {
        return defer(() => {
            let instance = host.plotlyComponent?.plotlyInstance
            // console.warn(`Locating element:`, instance)
            if (!instance) throw new Error("Instance not found")
            return of(instance)
        }).pipe(
            retry({count: 5, delay: 1500}),
            // catchError(err => of("Giving up, element not found"))
        )

    }

}
