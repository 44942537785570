import {Component, Inject, OnInit} from '@angular/core'
import {FormControl, ReactiveFormsModule} from '@angular/forms'
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog'
import {LowerCasePipe} from "@angular/common"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatSelectModule} from "@angular/material/select"

import {NgSelectModule} from "@ng-select/ng-select"
import {MatButton} from "@angular/material/button"


/**
 * Dialog that will let you choose from a list of objects of type T.
 * The objects should have a name property.
 * Optionally, there will be a search field in the selection dropdown.
 */
@Component({
    standalone: true,
    selector: 'app-object-select-dialog',
    templateUrl: './object-select-dialog.component.html',
    styleUrls: ['./object-select-dialog.component.scss'],
    imports: [
        LowerCasePipe,
        MatDialogModule,
        MatFormFieldModule,
        MatSelectModule,
        NgSelectModule,
        ReactiveFormsModule,
        MatButton,
    ]
})
export class ObjectSelectDialogComponent<T extends { name: string, id: number }> implements OnInit {
    result?: T
    objectControl = new FormControl<T | null>(null)

    constructor(
        private dialogRef: MatDialogRef<ObjectSelectDialogComponent<T>, typeof this.result>,
        @Inject(MAT_DIALOG_DATA) public data: {
            objects: readonly T[],
            name: string,
            search: boolean,
            /** Use a confirmation button with this text. Else close on first select.*/
            confirmText?: string,
        },
    ) {
    }

    ngOnInit(): void {
        if (this.data && this.data.confirmText === undefined) {
            const subscription = this.objectControl.valueChanges.subscribe(o => {
                if (o) {
                    subscription.unsubscribe()
                    this.dialogRef.close(o)
                }
            })
        }
    }

}
