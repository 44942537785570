import {Component} from '@angular/core'
import {toSignal} from "@angular/core/rxjs-interop"
import {Title} from "@angular/platform-browser"
import {HttpParams, HttpRequest} from "@angular/common/http"
import {MatCard, MatCardActions, MatCardContent, MatCardHeader, MatCardTitle} from "@angular/material/card"
import {MatAnchor} from "@angular/material/button"

import {compareVersions, validate} from "compare-versions"

import {VideDataService} from "../api/vide-data.service"
import {APP_NAME, JIRA_ISSUES} from "../../constants"
import {environment} from "../../environments/environment"
import {CHANGELOG} from "../../CHANGELOG"
import {getPageTitle} from "../shared/vide-helper"
import {ChangelogComponent} from "../changelog/changelog.component"

@Component({
    standalone: true,
    selector: 'app-about',
    templateUrl: './about.component.html',
    imports: [
        MatCard,
        MatCardHeader,
        MatCardContent,
        MatCardTitle,
        MatCardActions,
        ChangelogComponent,
        MatAnchor
    ],
    styleUrls: ['./about.component.scss']
})
export class AboutComponent {

    readonly frontendVersion = environment.appVersion
    readonly CHANGES = CHANGELOG
        .filter(c => validate(c.version))
        .sort((a, b) => compareVersions(b.version, a.version))
    readonly APP_NAME = APP_NAME

    readonly version = toSignal(this.dataService.version$)
    readonly userAdminAbility = toSignal(this.dataService.userAdminAbility$)

    constructor(
        private dataService: VideDataService,
        titleService: Title,
    ) {
        titleService.setTitle(getPageTitle(null, 'about'))
    }

    bugLink() {
        const x = JIRA_ISSUES
        const fromObject = {
            pid: x.pid,
            issuetype: x.issuetype,
            description: 'version: ' + environment.appVersion,
            priority: 3,
        }
        const params = new HttpParams({fromObject})
        const r = new HttpRequest('GET', x.url, null, {params})
        return [r.urlWithParams]
    }
}
