import {Component} from '@angular/core'
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms"
import {MatDialog, MatDialogModule, MatDialogRef} from "@angular/material/dialog"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatInputModule} from "@angular/material/input"
import {MatCheckboxModule} from "@angular/material/checkbox"
import {MatButtonModule} from "@angular/material/button"
import {MatTooltipModule} from "@angular/material/tooltip"

import {APP_NAME} from "../../../constants"
import {AuthService} from "../../auth/auth.service"
import {getErrorMessage} from "../../shared/vide-helper"
import {
    RequestResetLinkComponent,
    RequestResetLinkData,
    RequestResetLinkResult
} from "../request-reset-link/request-reset-link.component"
import {LogContainer} from "../../log-list/log-container"
import {LogListComponent} from "../../log-list/log-list.component"

export type LoginDialogComponentResult = boolean
export type LoginDialogComponentData = {}

@Component({
    standalone: true,
    selector: 'app-login-dialog',
    templateUrl: './login-dialog.component.html',
    imports: [
        LogListComponent,
        MatButtonModule,
        MatCheckboxModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatTooltipModule,
        ReactiveFormsModule,
    ],
    styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent {
    appName = APP_NAME
    readonly loginForm = this.formBuilder.nonNullable.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required]],
        remember: false,
    })
    // Local state
    protected resetRequested = false
    protected readonly logs = new LogContainer()
    protected readonly getErrorMessage = getErrorMessage

    constructor(
        private readonly dialog: MatDialog,
        private readonly formBuilder: FormBuilder,
        private readonly auth: AuthService,
        private readonly ref: MatDialogRef<LoginDialogComponent, LoginDialogComponentResult>,
    ) {
    }

    login() {
        this.loginForm.markAsTouched()
        if (this.loginForm.valid) {
            const credentials = this.loginForm.getRawValue()
            this.auth.login(credentials).then(x => {
                if (x.success) {
                    this.ref.close(true)
                } else {
                    this.logs.add(x, 'Login')
                }
            })
        }
    }

    resetPassword() {
        const email = this.loginForm.controls.email.value
        this.dialog.open<
            RequestResetLinkComponent,
            RequestResetLinkData,
            RequestResetLinkResult
        >(RequestResetLinkComponent, {data: {email}}).afterClosed().subscribe(res => {
            if (res) {
                this.resetRequested = res
            }
        })

    }
}
