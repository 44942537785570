import {Component, Input, viewChildren} from '@angular/core'
import {FormBuilder, ReactiveFormsModule} from "@angular/forms"
import {JsonPipe} from "@angular/common"
import {MatButtonModule} from "@angular/material/button"
import {MatCardModule} from "@angular/material/card"
import {MatDialog} from "@angular/material/dialog"
import {MatIconModule} from "@angular/material/icon"
import {MatMenuModule} from "@angular/material/menu"
import {MatTableModule} from "@angular/material/table"
import {Title} from "@angular/platform-browser"
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop"

import {combineLatest, combineLatestWith, filter, map, Subject, switchMap} from "rxjs"

import {ComponentCanDeactivate} from "../can-deactivate.guard"
import {ConfirmDialogComponent} from "../dialogs/confirm-dialog/confirm-dialog.component"
import {EditTriggerComponent, EditTriggerData} from "./edit-trigger/edit-trigger.component"
import {LogContainer} from "../log-list/log-container"
import {LogListComponent} from "../log-list/log-list.component"
import {Trigger} from "../api/api-types"
import {VideDataService} from "../api/vide-data.service"
import {equalIds, getPageTitle, isDefined} from "../shared/vide-helper"

@Component({
    selector: 'app-triggers',
    standalone: true,
    imports: [
        LogListComponent,

        JsonPipe,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        MatMenuModule,
        MatTableModule,
        ReactiveFormsModule,
    ],
    templateUrl: './triggers.component.html',
    styleUrl: './triggers.component.scss'
})
export class TriggersComponent extends ComponentCanDeactivate {
    private readonly components = viewChildren(ComponentCanDeactivate)
    private readonly _oid$ = new Subject<number>()
    private readonly object$ = this._oid$.pipe(
        combineLatestWith(this.dataService.objects$),
        map(([oid, objects]) => objects.find(o => o.id === oid)),
        filter(isDefined),
    )
    protected readonly object = toSignal(this.object$)
    protected readonly utility = toSignal(this.dataService.utility$)

    private readonly triggers$ = combineLatest([
        this.dataService.projectNotNull$,
        this.object$,
    ]).pipe(
        switchMap(([project, object]) =>
            this.dataService.getTriggers(project, object)),
    )
    triggers = toSignal(this.triggers$, {initialValue: []})
    protected readonly logs = new LogContainer()

    constructor(
        private readonly titleService: Title,
        private readonly dataService: VideDataService,
        private readonly fb: FormBuilder,
        private readonly dialog: MatDialog,
    ) {
        super()
        combineLatest([this.object$, this.dataService.project$]).pipe(takeUntilDestroyed()).subscribe(([o, p]) => {
            this.titleService.setTitle(getPageTitle(p, `${o.name} alarms`))
        })
    }

    @Input()
    set oid(value: string) {
        const oid = Number(value)
        if (Number.isFinite(oid)) {
            this._oid$.next(oid)
        } else {
            console.error(`Invalid number ${value}`)
        }
    }

    canDeactivate(): boolean {
        return !this.components()?.some(item => !item.canDeactivate())
    }

    protected readonly equalIds = equalIds
    protected readonly columnsToDisplay = [
        'description',
        'active',
        'unchecked',
        'measureType',
        'type',
        'limit',
        'comment',
        'recipients',
        'actions',
    ]

    addTrigger() {
        const pr = this.dataService.project()
        const ob = this.object()
        if (!(pr && ob)) {
            console.error('Missing values')
            return
        }
        this.dialog.open<
            EditTriggerComponent,
            EditTriggerData,
            EditTriggerComponent['result']
        >(EditTriggerComponent, {data: {object: ob}}).afterClosed().subscribe(values => {
            console.warn(values)
            if (!values) {
                return
            }
            this.dataService.createTrigger(pr, ob, values).subscribe(x => {
                this.logs.add(x, `Create trigger ${values.description}`)
                if (x.success) {
                    this.dataService.reloadProjectData()
                }
            })
        })
    }

    formatRecipients(trigger: Trigger) {
        return trigger.recipients.map((r: any) => r.email).join(', ')
    }

    edit(trigger: Trigger) {
        const pr = this.dataService.project()
        const ob = this.object()
        if (!(pr && ob)) {
            return
        }
        this.dialog.open<
            EditTriggerComponent,
            EditTriggerData,
            EditTriggerComponent['result']
        >(EditTriggerComponent, {data: {trigger, object: ob}}).afterClosed().subscribe(edited => {
            console.warn(edited)
            if (!edited) {
                return
            }
            this.dataService.updateTrigger(pr, ob, trigger, edited).subscribe(x => {
                this.logs.add(x, 'Edit trigger')
                if (x.success) {
                    this.dataService.reloadProjectData()
                }
            })
        })
    }

    delete(trigger: Trigger) {
        console.warn("delete ", trigger)
        const pr = this.dataService.project()
        const ob = this.object()
        if (!(pr && ob)) {
            console.error('Missing project or object, strange')
            return
        }
        this.dialog.open<
            ConfirmDialogComponent,
            ConfirmDialogComponent['data'],
            ConfirmDialogComponent['response']
        >(ConfirmDialogComponent, {
            data: {
                header: `Really delete trigger '${trigger.description}'?`,
                positive_text: 'Delete',
                negative_text: 'Cancel'
            }
        }).afterClosed().subscribe(answer => {
            if (answer) {
                this.dataService.deleteTrigger(pr, ob, trigger).subscribe(x => {
                    console.warn(x)
                    this.logs.add(x, `Delete ${trigger.description}`)
                    if (x) {
                        this.dataService.reloadProjectData()
                    }
                })
            }
        })
    }
}
