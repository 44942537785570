<mat-card>
    <mat-card-header>
        <mat-card-title>
            Edit existing groups
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>
        @if (project(); as project) {
            @if (groupControl; as control) {
                <mat-form-field>
                    <mat-label>Select group</mat-label>
                    <mat-select [formControl]="groupControl">
                        @for (g of groups(); track g.id) {
                            <mat-option [value]="g" [disabled]="g.global && !ABILITY.WRITE.availableFor(project)">
                                {{ g.name }} @if (g.global) {
                                <small>(global)</small>
                            }
                            </mat-option>
                        }
                    </mat-select>
                    <mat-error>{{ getErrorMessage(control) }}</mat-error>
                </mat-form-field>
            }
            <form [formGroup]="form">
                @if (form.controls.name; as control) {
                    <mat-form-field>
                        <mat-label>Name</mat-label>
                        <input matInput formControlName="name">
                        <mat-error>{{ getErrorMessage(control) }}</mat-error>
                    </mat-form-field>
                }
                <mat-slide-toggle formControlName="global" [matTooltip]="tooltipGlobal">
                    Global
                </mat-slide-toggle>
                <mat-slide-toggle formControlName="ordered" [matTooltip]="tooltipOrder">
                    Save group order
                </mat-slide-toggle>
                <mat-list cdkDropList (cdkDropListDropped)="drop($event)">
                    @for (o of editableList; track o.id; let idx = $index) {
                        <mat-list-item cdkDrag>
                            {{ o.name }}
                            <button mat-icon-button aria-label="delete" (click)="deleteFromGroup(idx)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </mat-list-item>
                    }
                </mat-list>
                @if (groupControl.value) {
                    <button mat-icon-button type="button" aria-label="Add"
                            (click)="addObjectToList()" matTooltip="Add object to group">
                        <mat-icon>add</mat-icon>
                        <!-- Add object -->
                    </button>
                }
            </form>
        }
    </mat-card-content>

    <mat-card-actions>
        <button mat-raised-button type="button" (click)="save()">Save</button>
        <button mat-raised-button type="button" (click)="resetGroup()">Reset values</button>
        <button mat-raised-button type="button" (click)="copyGroup()">Copy group</button>
        <button mat-raised-button type="button" (click)="delete()"> Delete</button>
    </mat-card-actions>
</mat-card>

<app-log-list [container]="logs"></app-log-list>
