<!-- Some other hamburger options -->
<!--    &#8801;-->
<!--    ☰-->
<!--    &#9776;-->

<span>
     &equiv;
     &#x21FE;  <!-- arrow -->
    @for (s of steps(); track $index) {
        {{ s }}
        @if (!$last) {
            &#x21FE;  <!-- arrow -->
        }
    }
</span>
