import {HttpEvent, HttpRequest} from '@angular/common/http'
import {Injectable} from '@angular/core'

import {Observable} from 'rxjs'

interface CacheEntry {
    url: string
    response: Observable<HttpEvent<any>>
}

@Injectable({
    providedIn: 'root',
})
export class UrlCacheService {

    private readonly cache = new Map<string, CacheEntry>()

    constructor() {
    }

    purge() {
        console.debug(`Purging url cache (size ${this.cache.size}), all request will be re-fetched on next request`)
        this.cache.clear()
    }

    delete(request: HttpRequest<any>) {
        const url = request.urlWithParams
        const status = this.cache.delete(url) ? 'success' : 'failure'
        // console.debug(`Clear cache entry for ${url}. ${status}`)
    }

    has(request: HttpRequest<any>) {
        const url = request.urlWithParams
        // console.debug(`Checking if ${url} is cached: ${exists}`)
        return this.cache.has(url)
    }

    get(request: HttpRequest<any>) {
        const cached = this.cache.get(request.urlWithParams)
        // console.debug(`Found entry for ${request.urlWithParams}: `, cached)
        return cached?.response
    }

    set(request: HttpRequest<any>, response: CacheEntry['response']) {
        const url = request.urlWithParams
        // console.debug('Caching result for ' + url)
        this.cache.set(url, {url, response})
        if (this.cache.size % 50 === 0) {
            console.warn('Cache size: ' + this.cache.size)
        }
    }

}
