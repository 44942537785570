@if (data) {
    <h1 mat-dialog-title> {{ data.name }}</h1>
    <mat-dialog-content>
        <ng-select
            [formControl]="objectControl"
            [items]="data.objects"
            [searchable]="data.search"
            notFoundText="No {{data.name}} found"
            bindLabel="name"
            [clearable]="false"
        />
    </mat-dialog-content>
    @if (data.confirmText) {
        <mat-dialog-actions>
            <button
                mat-raised-button
                [mat-dialog-close]="objectControl.value"
                [disabled]="objectControl.invalid"
            >{{ data.confirmText }}
            </button>
            <button
                mat-raised-button
                mat-dialog-close
            >Cancel
            </button>
        </mat-dialog-actions>
    }
} @else {
    No data
}
