import {Component, computed, Inject} from '@angular/core'
import {AbstractControl, AsyncValidatorFn, FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms"
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogModule, MatDialogRef} from "@angular/material/dialog"
import {toSignal} from "@angular/core/rxjs-interop"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatInputModule} from "@angular/material/input"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {KeyValuePipe} from "@angular/common"
import {MatButtonModule} from "@angular/material/button"

import {delay, map, of} from "rxjs"

import {INPUT_DATE_MAX, UNCERTAINTIES} from "../../../constants"
import {VideDataService} from "../../api/vide-data.service"
import {getErrorMessage} from "../../shared/vide-helper"
import {
    Aquifer,
    CoordinateDeterminationMethod,
    FilterType,
    ObjectStatus,
    ObjectType,
    PipeMaterial,
    SettlementPosition,
    TipType,
    VideObject
} from "../../api/api-types"
import {CoordinateTransformService} from "../../shared/coordinate-transform.service"

export type AddObjectComponentData = MatDialogConfig<{ model?: Partial<VideObject>, }>['data']

@Component({
    selector: 'app-add-object',
    standalone: true,
    templateUrl: './add-object.component.html',
    styleUrls: ['./add-object.component.scss'],
    imports: [
        KeyValuePipe,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressBarModule,
        ReactiveFormsModule,
    ]
})
export class AddObjectComponent {
    result?: Parameters<typeof this.dataService.createObject>[1]

    readonly UNCERTAINTY_OPTIONS = UNCERTAINTIES
    readonly INPUT_DATE_MAX = INPUT_DATE_MAX
    readonly project = this.dataService.project
    readonly objects = toSignal(this.dataService.objects$, {initialValue: []})
    readonly utility = toSignal(this.dataService.utility$)

    readonly groupedOptionsMap = computed(() => {
        const utility = this.utility()
        const map = new Map<string, ObjectType[]>()
        if (utility) {
            utility.object_type.forEach(o => {
                const name = o.object_category.name
                const entry = map.get(name) ?? []
                entry.push(o)
                map.set(name, entry)
            })
        }
        return map
    })

    readonly nameExistsValidator: AsyncValidatorFn = (control: AbstractControl) => {
        console.log(`running nameExistsValidator`)
        return of((control.value as string).trim()).pipe(
            /**
             * If the input changes before this delay is passed, angular will
             * unsubscribe to this and issue a new one, so only the last one will pass
             * to the actual getting and checking.
             */
            delay(500),
            map(name => {
                const exists = this.objects().filter(o => o.owner === undefined || !o.readonly).some(o => o.name == name)
                return exists ? {nameExists: true} : null
            }),
        )
    }

    readonly form = this.formBuilder.nonNullable.group({
            alias: [null as string | null],
            aquifer: [null as Aquifer | null],
            bottom_level: [null as number | null],
            comment: [null as string | null],
            coordinate_determination_method: [null as CoordinateDeterminationMethod | null],
            coordinate_quality: [null as number | null],
            correlation_base: [false],
            correlation_reference: [false],
            direction: [null as number | null],
            directions: [null as string | null],
            filter_length: [null as number | null],
            filter_type: [null as FilterType | null],
            ground_level: [null as number | null],
            inclination: [null as number | null],
            level_quality: [null as number | null],
            measurableDepth: [null as number | null],
            name: [null as string | null,
                [Validators.required, Validators.maxLength(255)], [this.nameExistsValidator]],
            not_reference_from: [null as string | null],
            object_status: [null as ObjectStatus | null, [Validators.required]],
            object_type: [null as ObjectType | null, [Validators.required]],
            pipe_material: [null as PipeMaterial | null],
            positionM: this.formBuilder.group({
                x: [null as number | null],
                y: [null as number | null],
            }),
            reference_level: [null as number | null],
            settlement_position: [null as SettlementPosition | null],
            source: [null as string | null],
            tip_type: [null as TipType | null],
            well_dimension: [null as number | null],
        }
    )

    numberFields: readonly { control: string & keyof VideObject, title: string, type: "number" }[] = [
        {control: "direction", title: "Bearing", type: "number"},
        {control: "inclination", title: "Inclination", type: "number"},
        {control: "bottom_level", title: "Bottom level", type: "number"},
        {control: "filter_length", title: "Filter length", type: "number"},
        {control: "ground_level", title: "Ground level", type: "number"},
        {control: "reference_level", title: "Reference level", type: "number"},
        {control: "measurableDepth", title: "Measurable depth", type: "number"},
        {control: "well_dimension", title: "Well inner dimension", type: "number"},
    ] as const

    getErrorMessage = getErrorMessage


    constructor(
        @Inject(MAT_DIALOG_DATA) public data: AddObjectComponentData,
        private readonly dataService: VideDataService,
        private readonly formBuilder: FormBuilder,
        private readonly ref: MatDialogRef<AddObjectComponent, AddObjectComponent['result']>,
        private readonly transform: CoordinateTransformService,
    ) {
        console.warn(this.data)
        const model = this.data?.model
        if (model) {
            this.form.patchValue({...model, positionM: {x: model.positionM?.x, y: model.positionM?.y}})
        }
    }

    save() {
        this.form.markAllAsTouched()
        const value = this.form.getRawValue()

        const p = this.project()
        if (!p) {
            return
        }
        if (this.form.valid && value.object_status && value.object_type && value.name) {
            let position = null
            // transform to lat/long
            const transform = this.transform.getTransformerToWgs(p.coordinate_system)
            if (value.positionM.x && value.positionM.y) {
                const startMetric = {x: value.positionM.x, y: value.positionM.y}
                position = transform(startMetric)
            }
            const newObject: typeof this.result = {
                ...value,
                position,
                name: value.name,
                // the required properties
                object_status: value.object_status,
                object_type: value.object_type,
                original_information: true,
            }
            this.ref.close(newObject)
        }
    }
}
