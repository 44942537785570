import {Component, ElementRef, viewChild} from '@angular/core'
import {MatButtonModule} from "@angular/material/button"
import {MatDialog} from "@angular/material/dialog"
import {MatExpansionModule} from "@angular/material/expansion"
import {MatProgressBar} from "@angular/material/progress-bar"
import {MatSelectModule} from "@angular/material/select"
import {MatStepperModule, MatStepperNext, MatStepperPrevious} from "@angular/material/stepper"
import {ReactiveFormsModule} from "@angular/forms"
import {toSignal} from "@angular/core/rxjs-interop"

import {NgSelectComponent} from "@ng-select/ng-select"
import {PlotlyViaWindowModule} from "angular-plotly.js"

import {ConfirmDialogComponent, ConfirmDialogData} from "../../../dialogs/confirm-dialog/confirm-dialog.component"
import {DIVER_DATA_TYPES, DiverService} from "../diver.service"
import {ImportDiverService} from "./import-diver.service"
import {InputComponent} from "../../../forms/input/input.component"
import {SlimObject} from "../diver.component"
import {BehaviorSubject} from "rxjs"

@Component({
    selector: 'app-import-diver',
    standalone: true,
    imports: [
        InputComponent,

        MatButtonModule,
        MatExpansionModule,
        MatProgressBar,
        MatSelectModule,
        MatStepperModule,
        NgSelectComponent,
        PlotlyViaWindowModule,
        ReactiveFormsModule,
    ],
    templateUrl: './import-diver.component.html',
    styleUrl: './import-diver.component.scss'
})
export class ImportDiverComponent {
    // properties
    protected readonly DIVER_DATA_TYPES = DIVER_DATA_TYPES
    protected readonly form = this.service.form
    private readonly _saving = new BehaviorSubject(false)
    // inputs
    // outputs
    // view children
    protected readonly nextButton = viewChild.required(MatStepperNext, {read: ElementRef})
    protected readonly prevButton = viewChild.required(MatStepperPrevious, {read: ElementRef})
    // signals
    protected readonly annotations = toSignal(this.service.diverAnnotations$)
    protected readonly figure = toSignal(this.service.diverFigure$)
    protected readonly fileData = toSignal(this.service.diverFileData$)
    protected readonly fileError = toSignal(this.service.fileError)
    protected readonly nrMeasurements = toSignal(this.service.diverNrMeasurements$)
    protected readonly object = toSignal(this.diverService.selectedObject$)
    protected readonly saving = toSignal(this._saving)

    constructor(
        private readonly service: ImportDiverService,
        private readonly dialog: MatDialog,
        private readonly diverService: DiverService,
    ) {
    }

    readonly plotlyClick = this.service.diverFigureClick.bind(this.service)
    readonly doubleClick = this.service.diverDoubleClick.bind(this.service)

    async save(object: SlimObject) {
        this._saving.next(true)
        const res = await this.service.save(object).finally(() => {
            this._saving.next(false)
        })
        if (res) {
            // If baro-diver, go back. Else go forward.
            const el = res === 'Air pressure (baro diver)'
                ? this.prevButton().nativeElement
                : this.nextButton().nativeElement
            if (el instanceof HTMLButtonElement) {
                el.click()
            }
        }
    }

    showHead() {
        const fileData = this.fileData()
        if (!fileData) return
        this.dialog.open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
            data: {
                header: `${fileData.filename} header `,
                textPre: fileData.head.join("\n"),
                negative_text: null,
                positive_text: null,

            }
        })
    }
}
