import {Directive, HostListener, inject, Injectable} from '@angular/core'
import {ActivatedRouteSnapshot, CanDeactivateFn, RouterStateSnapshot, UrlTree} from '@angular/router'

import {Observable} from 'rxjs'

/**
 * Extend a component from this class, and implement the `canDeactivate`
 * function. Then reload/close/etc. will be guarded.  If
 * `canDeactivate: [canDeactivateGuard]` is added in the routing config for the
 * component, in-app routing will also be guarded.
 *
 * If you add
 * ```
 *     providers:[
 *         {provide: ComponentCanDeactivate, useExisting: forwardRef(()=>EditObjectFragmentComponent)},
 *     ]
 * ```
 * to components, a host component can search for them by
 * ```
 *  @ViewChildren(ComponentCanDeactivate) components?: QueryList<ComponentCanDeactivate>
 * ```
 * Use
 * ```
 *  canDeactivate(): boolean {
 *     return !this.components?.some(item => !item.canDeactivate())
 * }
 * ```
 * to check if the child components can deactivate.
 */
@Directive()
export abstract class ComponentCanDeactivate {
    abstract canDeactivate(): boolean

    @HostListener('window:beforeunload', ['$event'])
    unloadBlaj($event: Event) {
        // see https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
        if (!this.canDeactivate()) {
            $event.preventDefault()  // the correct way
            $event.returnValue = true // the way that works...
        }
    }
}

// interface CanComponentDeactivate {
//     /**
//      * Return true if navigating away is ok (e.g. no unsaved changes in the component).
//      */
//     canDeactivate(): boolean
// }

/**
 * To use, extend ComponentCanDeactivate in component, and add
 * `canDeactivate: [canDeactivateGuard]` to the route config.
 * If the component is of another class, the routing continues.
 */
@Injectable({
    providedIn: 'root',
})
class CanDeactivateGuard {
    /** NB: this function may be called with component argument of any type, if specified in the route module. */
    canDeactivate(
        component: any,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const msg = 'Discard changes?'
        if ((component instanceof ComponentCanDeactivate) && !component.canDeactivate()) {
            return confirm(msg)
        } else {
            return true
        }
    }
}

export const canDeactivateGuard: CanDeactivateFn<CanDeactivateGuard> = (
    component: CanDeactivateGuard,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot) => {
    return inject(CanDeactivateGuard).canDeactivate(component)
}
