import * as S from 'superstruct'

import {ExternalObject, Position, PositionM} from "../../api/api-types"

const CRSProperties = S.object({
    name: S.string(),
})

const CRS = S.object({
    type: S.string(),
    properties: CRSProperties,
})

const Geometry = S.object({
    type: S.literal("Point"),
    coordinates: S.tuple([S.number(), S.number()]),
})
const Jordart = S.enums([
    "sand",
    "morän",
    "grus eller grövre",
    "jord saknas",
    "varvig lera",
    "lera, ej varvig",
    "torv",
    "moränlera",
    "silt, ej varvig",
    "silt, varvig/ej varvig",
    "lera, varvig/ej varvig",
])

const Akvifertyp = S.enums([
    "berg , öppet magasin",
    "jord , öppet magasin",
    "berg , slutet magasin",
    "jord , slutet magasin",
    "okänt om magsinet är jord eller berg , okänd typ av magasin",
    "berg , okänd typ av magasin",
    "jord , okänd typ av magasin",
    "kombinerat jord och berg , öppet magasin",
])

const TopografisktLage = S.enums([
    "inströmningsområde",
    "intermediärt område",
    "inströmningområde - vattendelarläge",
    "utströmningsområde"
])

/** @deprecated */
/** @deprecated */
const GrundvattenstationProperties = S.object({
    "omrade-_och_stationsnummer": S.string(),
    stationens_namn: S.string(),
    startdatum_for_matning: S.string(),//Date
    jordart: S.optional(Jordart),
    akvifertyp: S.optional(Akvifertyp),
    topografiskt_lage: S.optional(TopografisktLage),
    "referensniva_for_roroverkant_m.o.h.": S.optional(S.string()),
    rorhojd_ovan_mark_m: S.optional(S.string()),
    total_rorlangd_m: S.optional(S.string()),
    kommunkod: S.string(),
    eucd_far_grundvattenforekomst: S.optional(S.string()),
    slutdatum_for_matning: S.optional(S.string()),//Date
    nivamatningskvalitet: S.optional(S.string()),
})
/** @deprecated */
type GrundvattenstationProperties = S.Infer<typeof GrundvattenstationProperties>

/** @deprecated */
const Grundvattenstation = S.object({
    type: S.literal("Feature"),
    geometry: S.nullable(Geometry),
    properties: GrundvattenstationProperties,
})
/** @deprecated */
export const SguGrundvattenstationer = S.object({
        type: S.string(),
        crs: CRS,
        features: S.array(Grundvattenstation),
    }
)

export function sguGetPlatsbeteckning(o: Pick<SguObject, 'properties'>): string {
    return o.properties.platsbeteckning
    // return o.properties['omrade-_och_stationsnummer']
}

export function sguGetObjectName(o: Pick<SguObject, 'properties'>): string {
    return o.properties.obsplatsnamn
    // return o.properties.stationens_namn
}

export interface SguObject {
    id: string
    external: ExternalObject | null
    position: Position | null
    /** EPSG:3006 */
    positionM: PositionM | null
    properties: OgcGrundvattenstationProperties
    tainted: boolean
}

const OgcGrundvattenstationProperties = S.type({
    rowid: S.integer(),
    platsbeteckning: S.string(),
    obsplatsnamn: S.string(),
    fdat: S.string(),
    tdat: S.nullable(S.string()),
    lan: S.string(),
    // akvifer: S.string(),
    // akvifer_tx: S.enums([
    //     'jord , ',
    //     'jord , öppet magasin',
    //     'jord , slutet magasin',
    //     'jord , okänd typ av magasin',
    //     'berg , ',
    //     'berg , öppet magasin',
    //     'berg , slutet magasin',
    //     'berg , okänd typ av magasin',
    //     'okänt om magsinet är jord eller berg , okänd typ av magasin',
    //     'kombinerat jord och berg , öppet magasin',
    //     ' , ',
    // ]),

    ////////////////////////////////////////
    // OLD
    // "omrade-_och_stationsnummer": S.string(),
    // stationens_namn: S.string(),
    // startdatum_for_matning: S.string(),//Date
    // jordart: S.optional(Jordart),
    // akvifertyp: S.optional(Akvifertyp),
    // topografiskt_lage: S.optional(TopografisktLage),
    // "referensniva_for_roroverkant_m.o.h.": S.optional(S.string()),
    // rorhojd_ovan_mark_m: S.optional(S.string()),
    // total_rorlangd_m: S.optional(S.string()),
    // kommunkod: S.string(),
    // eucd_far_grundvattenforekomst: S.optional(S.string()),
    // slutdatum_for_matning: S.optional(S.string()),//Date
    // nivamatningskvalitet: S.optional(S.string()),
    /**
     "rowid": 13,
     "platsbeteckning": "1000_2",
     "obsplatsnamn": "Ringarum_2",
     "provplatsid": "00116881",
     "fdat": "1996-04-29Z",
     "tdat": null,
     "refniva": 75,
     "hojdmetod": "okänd höjdbestämningsmetod",
     "hojdsystem": "okant",
     "rorhojd": 0.01,
     "rorlangd": 3,
     "akvifer": "JÖ",
     "akvifer_tx": "jord , öppet magasin",
     "jordart": "MN",
     "jordart_tx": "morän",
     "genes_jord": "moran_moranlera_torv",
     "genes_jord_tx": "om jordarten är morän eller moränlera eller torv",
     "jord_ovan_jord": null,
     "jord_ovan_jord_tx": null,
     "jorddjup": null,
     "tecken_jorddjup": null,
     "idiam": 50,
     "brunnsmtrl": "polyeten",
     "brunnsmtrl_tx": "polyeten",
     "borrhalslutning": null,
     "sillangd": 0.5,
     "geohylag": "I",
     "geohylag_tx": "inströmningsområde",
     "kommunkod": "0563",
     "kommun": "Valdemarsvik",
     "lanskod": "05",
     "lan": "Östergötlands län",
     "eucd_gwb": "",
     "n": 6464886,
     "e": 582975,
     "symbol_magasin": "moran_oppen",
     "symbol_paverkan": null,
     "stationsanmarkning": null,
     "kommentar": null
     */
})
type OgcGrundvattenstationProperties = S.Infer<typeof OgcGrundvattenstationProperties>
const OgcGrundvattenstation = S.object({
    type: S.literal("Feature"),
    id: S.string(),
    geometry: S.nullable(Geometry),
    geometry_name: S.literal('geom'),
    properties: OgcGrundvattenstationProperties,
})

export const SguOgcGrundvattenstationer = S.object({
        type: S.literal('FeatureCollection'),
        crs: CRS,
        // features: S.any(),
        features: S.array(OgcGrundvattenstation),
        totalFeatures: S.integer(),
        numberMatched: S.integer(),
        numberReturned: S.integer(),
        timeStamp: S.string(),
        // "2024-11-15T10:50:24.910Z",
    }
)
