import {Injectable} from '@angular/core'

import {BehaviorSubject, combineLatest, map, Subject} from "rxjs"

import {AbstractMapboxService} from "../mapbox/abstract-mapbox.service"
import {Mutable, objectWithPosition, PlotlyData, VideFigure, VideObjectWithPosition} from "../vide-types"
import {Position, PositionM, VideObject} from "../api/api-types"
import {getMapPlotTextFont, PLOT_CONFIG} from "../../constants"

@Injectable({
    providedIn: 'root'
})
export class EditObjectFragmentService extends AbstractMapboxService {
    protected override useColorbar = false
    private readonly _object = new Subject<VideObject>()
    readonly placedPosition = new BehaviorSubject<{ position: Position, positionM: PositionM } | null>(null)

    figure$ = combineLatest([
        this._object,
        this.dataService.objects$,
        this.placedPosition,
        this.forceRelayout$,
    ]).pipe(
        map(([o, objects, placedPosition]) => {
            return this.locateObjectGetFigure(o, objects, placedPosition)
        }),
    )

    set object(o: VideObject) {
        this._object.next(o)
    }

    constructor() {
        super()
    }

    locateObjectGetFigure(
        object: VideObject,
        objects: VideObject[],
        placedPosition: { position: Position, positionM: PositionM } | null,
    ): VideFigure {
        const traces: PlotlyData[] = []
        const objectsToPlot: Mutable<Parameters<EditObjectFragmentService['getLayout']>[0]> = []
        if (objectWithPosition(object)) {
            traces.push(getMainData(object))
            objectsToPlot.push(object)
        } else {
            traces.push(getObjectData(objects))
            objectsToPlot.push(...objects)
        }
        if (placedPosition) {
            objectsToPlot.push(placedPosition)
            traces.push(getPositionedTrace(placedPosition.position))
        }
        const mapbox = this.getLayout(objectsToPlot, {background: 'satellite-streets'}).mapbox

        return {
            data: traces,
            layout: {
                mapbox,
                margin: {r: 0, l: 0, t: 0, b: 0},
            },
            config: {
                mapboxAccessToken: PLOT_CONFIG.mapboxAccessToken,
                showEditInChartStudio: true,
                scrollZoom: true,
            },
        }
    }

}

function getPositionedTrace(placedPosition: Position): PlotlyData {
    const textfont = getMapPlotTextFont('satellite-streets')
    return {
        type: 'scattermapbox',
        lat: [placedPosition.lat],
        lon: [placedPosition.lon],
        text: 'new Position',
        marker: {symbol: 'circle-stroked', size: 10,},
        mode: 'text+markers',
        hoverinfo: "none",
        textposition: 'bottom right',
        textfont,
        showlegend: false,
    }
}

function getObjectData(os: VideObject[]): PlotlyData {
    const lat = Array<number>()
    const lon = Array<number>()
    const text = Array<string>()
    os.filter(objectWithPosition).forEach(o => {
        lat.push(o.position.lat)
        lon.push(o.position.lon)
        text.push(o.name)
    })
    const textfont = getMapPlotTextFont('satellite-streets')
    return {
        type: 'scattermapbox',
        lat,
        lon,
        text,
        marker: {
            size: 8,
            color: textfont.color,
            // color: options.textColor,
        },
        mode: 'text+markers',
        textposition: 'bottom right',
        hoverinfo: 'text',
        textfont,
        // textfont: {color: options.textColor},
        showlegend: false,
    }
}

function getMainData(object: VideObjectWithPosition): PlotlyData {
    const textfont = getMapPlotTextFont('satellite-streets')
    textfont.size = 20
    return {
        type: 'scattermapbox',
        lat: [object.position.lat],
        lon: [object.position.lon],
        text: [object.name],
        marker: {
            // symbol: 'triangle-stroked',
            size: 20,
            color: 'red',
        },
        mode: 'text+markers',
        textposition: 'bottom right',
        hoverinfo: 'text',
        // textfont: {color: options.textColor, size: 20},
        textfont,
        showlegend: false,
    }
}

