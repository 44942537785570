<h1 mat-dialog-title> {{ appName }} login </h1>

<mat-dialog-content>
    <form>
        @if (loginForm.controls.email; as control) {
            <div>
                <mat-form-field>
                    <mat-label>Username (email)</mat-label>
                    <input matInput type="email" [formControl]="control" email required autocomplete="email username">
                    <mat-error>{{ getErrorMessage(control) }}</mat-error>
                </mat-form-field>
            </div>
        }
        @if (loginForm.controls.password; as control) {
            <div>
                <mat-form-field>
                    <mat-label>Password</mat-label>
                    <input matInput type="password" [formControl]="control" required autocomplete="current-password">
                    <mat-error>{{ getErrorMessage(control) }}</mat-error>
                </mat-form-field>
            </div>
        }
        @if (loginForm.controls.remember; as control) {
            <div>
                <mat-checkbox [formControl]="control">Keep logged in</mat-checkbox>
            </div>
        }
    </form>

    <!--    @if (loginError) {-->
    <!--        <div class="error">Login failed</div>-->
    <!--    }-->
    <app-log-list [container]="logs"/>
    <!--    @if (requestError) {-->
    <!--        <div class="error">Error sending reset request</div>-->
    <!--    }-->
    @if (resetRequested) {
        <div>Reset link requested. If the email is correct, a reset message will be sent. Check the
            spam folder if you cannot find it. Follow the link in the email to reset the password, it is valid for 60
            minutes.
        </div>
    }
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button (click)="login()">Login</button>
    <span class="toolbar-spacer"></span>
    <button mat-raised-button matTooltip="Send reset email" (click)="resetPassword()">Reset password</button>
</mat-dialog-actions>
