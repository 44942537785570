import {
    HttpErrorResponse,
    HttpEventType,
    HttpHandlerFn,
    HttpInterceptorFn,
    HttpRequest,
    HttpResponse
} from '@angular/common/http'
import {inject} from "@angular/core"

import {catchError, of, share, tap} from "rxjs"

import {UrlCacheService} from "../shared/url-cache.service"

export const cachingInterceptor: HttpInterceptorFn = (request, next) => {
    const cache = inject(UrlCacheService)


    function cacheResponse(request: HttpRequest<unknown>, next: HttpHandlerFn) {
        const response = next(request).pipe(
            tap(response => {
                if (response instanceof HttpResponse) {
                    // console.debug(`Got http response for ${request.method} ${request.urlWithParams}`, response)
                    cache.set(request, of(response))
                }
            }),
            catchError((response, _y) => {
                // Delete error responses from the cache, they should be retried when requested again.
                if (response instanceof HttpErrorResponse) {
                    cache.delete(request)
                }
                throw response
            }),
            share(),
        )

        cache.set(request, response)
        // console.debug(`Returning new request for ${request.urlWithParams}`)
        return response
    }

    function handleCacheable(request: HttpRequest<unknown>, next: HttpHandlerFn) {
        // New use: just re-fetch this one, and keep the rest.
        if (noCache(request)) {
            cache.delete(request)
        }

        return cache.get(request) ?? cacheResponse(request, next)
    }

    function handleNonCacheable(request: HttpRequest<unknown>, next: HttpHandlerFn) {
        const purge = isDataChange(request)
        if (purge) {
            console.debug(`Got ${request.method} request for ${request.urlWithParams}, will purge cache when receiving a response`)
        }
        return next(request).pipe(
            tap(x => {
                // console.warn("Http response ", x)
                if (x.type === HttpEventType.Response)
                    if (purge && httpStatusValid(x)) {
                        console.debug(`Got a response for ${request.method} ${x.url}, purging cache`, x)
                        cache.purge()
                    }
            }),
        )
    }

    return isCacheable(request)
        ? handleCacheable(request, next)
        : handleNonCacheable(request, next)


}

function httpStatusValid<T>(response: HttpResponse<T>) {
    return response.status >= 200 && response.status < 300
}

function isCacheable(request: HttpRequest<any>) {
    // Maybe have some limiting on which url are to be cached?
    return request.method === 'GET'
}

function isDataChange(request: HttpRequest<any>) {
    return ['PUT', 'POST', 'PATCH', 'DELETE'].includes(request.method)
}

function noCache(request: HttpRequest<unknown>) {
    return request.headers.getAll('cache-control')?.includes('no-cache')
}
