import {AfterViewInit, Component, Inject} from '@angular/core'
import {FormBuilder, ReactiveFormsModule} from '@angular/forms'
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog'
import {KeyValuePipe} from "@angular/common"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatInputModule} from "@angular/material/input"
import {MatButtonModule} from "@angular/material/button"

import {INPUT_DATE_MAX} from "../../../constants"
import {Aquifer, ObjectStatus, ObjectType} from "../../api/api-types"

export interface EditObjectBulkData {
    objectStatuses: readonly ObjectStatus[]
    aquifers: readonly Aquifer[]
    objectTypes: readonly ObjectType[]
}

interface EditObjectsProperties {
    comment?: string
    not_reference_from?: string
    aquifer?: Aquifer
    object_status?: ObjectStatus
    object_type?: ObjectType
    correlation_base?: boolean
    correlation_reference?: boolean
}

@Component({
    standalone: true,
    selector: 'app-edit-objects-bulk',
    templateUrl: './edit-objects-bulk.component.html',
    styleUrls: ['./edit-objects-bulk.component.scss'],
    imports: [
        KeyValuePipe,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
    ]
})
export class EditObjectsBulkComponent implements AfterViewInit {
    readonly INPUT_DATE_MAX = INPUT_DATE_MAX
    result?: EditObjectsProperties
    readonly form = this.formBuilder.nonNullable.group({
        comment: [''],
        not_reference_from: [null as string | null],
        aquifer: [null as Aquifer | null],
        object_status: [null as ObjectStatus | null],
        object_type: [null as ObjectType | null],
        correlation_base: [null as boolean | null],
        correlation_reference: [null as boolean | null],
    })
    readonly groupedOptions: Map<string, ObjectType[]>

    constructor(
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<EditObjectsBulkComponent, EditObjectsProperties>,
        @Inject(MAT_DIALOG_DATA) public data: EditObjectBulkData,
    ) {
        this.groupedOptions = new Map<string, ObjectType[]>()
    }

    ngAfterViewInit(): void {
        {
            const map = this.groupedOptions
            this.data.objectTypes.forEach(o => {
                const name = o.object_category.name
                const entry = map.get(name) ?? []
                entry.push(o)
                map.set(name, entry)
            })
        }
    }


    onApply() {
        const value = this.form.getRawValue()
        const ret: any = {}
        let key: keyof typeof value
        for (key in value) {
            if (value[key] !== null) {
                ret[key] = value[key]
            }
        }
        if (ret.comment === '') {
            delete ret.comment
        }

        this.dialogRef.close(ret as EditObjectsProperties)
    }

}
