import {AfterViewInit, Component, OnDestroy, viewChild, ViewChild} from '@angular/core'
import {MatButtonModule} from "@angular/material/button"
import {MatCardModule} from "@angular/material/card"
import {MatCheckboxModule} from "@angular/material/checkbox"
import {MatIconModule} from "@angular/material/icon"
import {MatMenuModule, MatMenuTrigger} from "@angular/material/menu"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner"
import {MatSort, MatSortModule} from "@angular/material/sort"
import {MatTableDataSource, MatTableModule} from "@angular/material/table"
import {MatTabsModule} from "@angular/material/tabs"
import {ReactiveFormsModule} from "@angular/forms"
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop"

import {NgSelectModule} from "@ng-select/ng-select"
import {PlotlyComponent, PlotlyViaWindowModule} from 'angular-plotly.js'
import {map} from "rxjs"

import {ComponentCanDeactivate} from 'src/app/can-deactivate.guard'
import {LogListComponent} from "../../log-list/log-list.component"
import {PLOT_CONFIG,} from 'src/constants'
import {PlotlyMouseEvent} from 'src/app/vide-types'
import {SguService} from './sgu.service'
import {moveMenu} from "../../shared/mat-menu"
import {sguGetObjectName, SguObject} from './sgu-types'

@Component({
    selector: 'app-sgu',
    standalone: true,
    templateUrl: './sgu.component.html',
    imports: [
        LogListComponent,

        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatIconModule,
        MatMenuModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        NgSelectModule,
        PlotlyViaWindowModule,
        ReactiveFormsModule,
    ],
    styleUrls: ['./sgu.component.scss']
})
export class SguComponent extends ComponentCanDeactivate implements AfterViewInit, OnDestroy {
    @ViewChild(PlotlyComponent) plotlyComponent?: PlotlyComponent
    private readonly sort = viewChild.required(MatSort)
    protected readonly dataSource = new MatTableDataSource<SguObject>([])
    protected readonly SWEDISH_LAN = SguService.SGU_SWEDISH_LAN
    protected readonly figure = toSignal(this.dataService.figure$)
    protected readonly form = this.dataService.form
    protected readonly httpStatus = this.dataService.httpStatus
    protected readonly logs = this.dataService.logs
    protected readonly plotlyStyle = PLOT_CONFIG.plotlyStyle
    protected readonly stationsToShow$ = this.dataService.stationsToShow$
    protected readonly trigger = viewChild(MatMenuTrigger)
    protected readonly objectWithoutCoordinatesExists = toSignal(this.dataService.stationsToShow$.pipe(
        map(objects => objects.some(o => o.position === null))
    ))

    protected readonly add = this.dataService.add.bind(this.dataService)
    protected readonly onPlotlyRelayout = this.dataService.plotlyRelayout.bind(this.dataService)
    protected readonly remove = this.dataService.remove.bind(this.dataService)
    protected readonly sguGetObjectName = sguGetObjectName

    protected readonly displayedColumns = [
        'name',
        'active',
        'from',
        'to',
        'imported',
        'action',
    ] as const

    constructor(
        private dataService: SguService,
    ) {
        super()
        this.stationsToShow$.pipe(takeUntilDestroyed()).subscribe(s => {
            this.dataSource.data = s
        })
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
            switch (sortHeaderId) {
                case 'name':
                    return sguGetObjectName(data)
                case 'from':
                    return data.properties.startdatum_for_matning
                case 'to':
                    return data.properties.slutdatum_for_matning ?? ''
                case 'active':
                    return data.properties.slutdatum_for_matning ? 1 : 0
                case 'imported':
                    return data.external ? 1 : 0
                default:
                    console.warn(`Unhandled column ${sortHeaderId}`)
                    return ''
            }
        }
    }


    override canDeactivate() {
        return true
    }

    ngOnDestroy(): void {
        // not strictly necessary, but let's not keep the unloaded element in memory.
        this.dataService.plotlyHost = undefined
    }

    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort()
        this.dataService.plotlyHost = this
    }

    plotlyClick(event: PlotlyMouseEvent) {
        const custom = event.points[0]?.customdata as SguObject | undefined
        if (typeof custom !== 'object') {
            console.error("Strange type of custom data")
            return
        }
        const trigger = this.trigger()
        if (trigger) {
            trigger.menuData = {element: custom,}
            trigger.openMenu()
        }
        setTimeout(moveMenu(event), 0)
    }

}
