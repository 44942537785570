<section>
    @if (plot(); as p) {
        <plotly-plot
            [config]="p.config"
            [data]="p.data"
            [layout]="p.layout"
            [style]="plotlyStyle"
        />
    }

    @if (data) {
        <div class="description">
            @if (!data.measureType) {
                <div class="warning">
                    No measurements
                </div>
            }

            <h2> {{ data.object.name }} </h2>
            <p>
                <button (click)="toggleRange()">
                    {{ fullRange$.value ? 'Last year' : 'All data' }}
                </button>
            </p>

            <dl>
                @for (x of description | keyvalue; track x.key) {
                    <div>
                        <dt>{{ x.key }}</dt>
                        <dd>{{ x.value }}</dd>
                    </div>
                }
            </dl>
        </div>
    }
</section>
