export interface Change extends ActualChanges {
    version: string
    date: string
    general: string
}

type ActualChanges = {
    [Key in typeof CHANGE_TYPES[number]]: readonly string[]
}
export const CHANGE_TYPES = ['added', 'fixed', "changed", "removed"] as const

export const CHANGELOG: readonly Change[] = [
    {
        version: 'UPCOMING',
        date: '',
        general: '',
        added: [],
        fixed: [],
        changed: [],
        removed: []
    }, {
        version: 'UPCOMING',
        date: '',
        general: '',
        added: [],
        fixed: [],
        changed: [],
        removed: []
    }, {
        version: '1.54.0',
        date: '2024-10-22',
        general: '',
        added: ['Log scales in timeline plots'],
        fixed: [],
        changed: [],
        removed: []
    }, {
        version: '1.52.2',
        date: '2024-10-16',
        general: '',
        added: ['Set coordinates from map in Edit Object'],
        fixed: [],
        changed: [],
        removed: []
    },
    {
        version: '1.51.1',
        date: '2024-10-10',
        general: '',
        added: [],
        fixed: [],
        changed: ['Unoson imports are now attached to a new or existing object, and can be de-attached while keeping the object.'],
        removed: []
    },
    {
        version: '1.50.5-4',
        date: '2024-09-24',
        general: '',
        added: [],
        fixed: ['Enable editing of measurements in external objects (e.g., Unoson)'],
        changed: [],
        removed: []
    },
    {
        version: '1.50.0',
        date: '2024-09-13',
        general: '',
        added: [],
        fixed: [],
        changed: ['Users now have a fixed ability, which is the max they are allowed, set by the license owner.'],
        removed: []
    },
    {
        version: '1.49.4',
        date: '2024-09-10',
        general: '',
        added: [],
        fixed: ['Scroll to zoom enabled in maps'],
        changed: [],
        removed: []
    },
    {
        version: '1.49.2',
        date: '2024-09-05',
        general: '',
        added: [],
        fixed: [],
        changed: ['Saved files have a timestamp in the name'],
        removed: []
    },
    {
        version: 'UPCOMING',
        // version: '1.40.0',
        date: '',
        general: '',
        added: ['Import and compensation for diver measurements'],
        fixed: [],
        changed: [],
        removed: []
    },
    {
        version: '1.45.1',
        date: '2024-08-13',
        general: '',
        added: ['Unoson added as source of external object'],
        fixed: [],
        changed: [],
        removed: []
    },
    {
        version: '1.39.0',
        date: '2024-05-15',
        general: '',
        added: ['Notifications are sent when new measurements exceed limits set in a notification trigger.'],
        fixed: [],
        changed: [],
        removed: []
    },
    {
        version: '1.38.0',
        date: '2024-04-18',
        general: '',
        added: ['User preference for default timeline zoom'],
        fixed: ['Correct displayed fill rate value in map plot'],
        changed: [],
        removed: []
    },
    {
        version: '1.37.0',
        date: '2024-04-11',
        general: '',
        added: ['Support for Göteborg stads groundwater'],
        fixed: [],
        changed: [],
        removed: []
    },
    {
        version: '1.35.0',
        date: '2024-04-2',
        general: '',
        added: ['Timeline plot can wrap years, to compare yearly changes'],
        fixed: [],
        changed: [],
        removed: []
    },
    {
        version: '1.34.0',
        date: '2024-03-26',
        general: '',
        added: ['Support for Stockholm stad Geoarkivet as autoupdating external source'],
        fixed: [],
        changed: [],
        removed: []
    },
    {
        version: '1.33.4',
        date: '2024-03-25',
        general: '',
        added: [],
        fixed: ['Remove unnecessary measurement fetching that slowed the app for large selections.'],
        changed: [],
        removed: []
    },
    {
        version: '1.32.1',
        date: '2024-03-20',
        general: '',
        added: [`Measurement fetching can be limited (per project setting).
            Especially useful for object with over 100 000 measurements, e.g., SMHI water level.`],
        fixed: [],
        changed: [],
        removed: []
    },
    {
        version: '1.32.0',
        date: '2024-03-13',
        general: '',
        added: ['Project admin can delete objects and measurements'],
        fixed: [],
        changed: [],
        removed: []
    },
    {
        version: '1.31.0',
        date: '2024-03-06',
        general: '',
        added: ['Added SMHI water level stations for import'],
        fixed: [],
        changed: [],
        removed: []
    },
    {
        version: '1.30.0',
        date: '2024-02-15',
        general: '',
        added: ['Various attributes can be plotted as a horizontal line in timeline plot'],
        fixed: [],
        changed: [],
        removed: []
    },
    {
        version: '1.29.1',
        date: '2024-02-02',
        general: '',
        added: [],
        fixed: ['Calculate time weighted average with non-affected data.'],
        changed: [],
        removed: []
    },
    {
        version: '1.29.0',
        date: '2024-02-01',
        general: '',
        added: ['Correlation status can be show with color and symbol in timeline plots.'],
        fixed: [],
        changed: [],
        removed: []
    },
    {
        version: '1.28.4',
        date: '2024-01-29',
        general: '',
        added: [],
        fixed: [],
        changed: ['Use colors from timeline plot in select map only when timeline is shown.'],
        removed: []
    },
    {
        version: '1.24.0',
        date: '2023-12-19',
        general: '',
        added: [
            'Object sharing can be writeable and can be dropped from the owning project.',
            'Export of plot data from timeline and map plot.',
        ],
        fixed: [],
        changed: [],
        removed: []
    },
    {
        version: '1.23.0',
        date: '2023-12-14',
        general: '',
        added: [],
        fixed: [],
        changed: ['Only bulk edit from view objects. Links to edit object page.'],
        removed: []
    },
    {
        version: '1.22.0',
        date: '2023-12-11',
        general: '',
        added: ['Objects can be shared between projects. Available from the import page.'],
        fixed: [],
        changed: [],
        removed: []
    },
    {
        version: '1.19.0',
        date: '2023-11-28',
        general: '',
        added: [
            `Object can be marked as containing original information, disabling overwrite on import. Explicit editing is
            still possible. Existing objects will not be marked, but new objects created from the view objects page will
            be. Imported objects can also marked.`
        ],
        fixed: [],
        changed: [],
        removed: []
    },
    {
        version: '1.18.3',
        date: '2023-12-12',
        general: '',
        added: [],
        fixed: ['Correlation plot was not working with WebGL, reverted.'],
        changed: [],
        removed: []
    },
    {
        version: '1.18.0',
        date: '2023-11-24',
        general: '',
        added: [
            'Plots are using WebGL where available.'
        ],
        fixed: [],
        changed: [
            'Timeline plot changed what information is presented where, and the legend can now be placed below or to the right.',
        ],
        removed: []
    },
    {
        version: '1.16.1',
        date: '2023-11-20',
        general: 'Start of changelog',
        added: [],
        fixed: [],
        changed: [],
        removed: []
    },

] as const

