import {Component} from '@angular/core'
import {AsyncPipe, JsonPipe, KeyValuePipe} from "@angular/common"

import {COLOR_SEQUENCES} from "../shared/colors"
import {ApiCallService} from "../api/api-call.service"
import {VideDataService} from "../api/vide-data.service"
import {parseCsv} from "../csv/csv-parser"

// import Map from 'ol/Map'
// import View from 'ol/View'
// import {TileWMS} from 'ol/source'
// import {ScaleLine} from 'ol/control'
// import TileLayer from 'ol/layer/Tile'

@Component({
    selector: 'app-test',
    standalone: true,
    templateUrl: './test.component.html',
    styleUrls: ['./test.component.scss'],
    imports: [
        AsyncPipe,
        JsonPipe,
        KeyValuePipe
    ]
})
export class TestComponent {

    constructor(
        private readonly service: VideDataService,
        private readonly api: ApiCallService,
    ) {
        console.warn(parseCsv(['a,b,c', "\"a\nb\",c,d"].join("\n"), {eol: "\n"}))

        // const oid = 18055
        // this.service.pid = 4
        // combineLatest([
        //     this.service.projectNotNull$,
        //     this.service.objects$,
        //     this.service.utility$,
        // ]).pipe(
        //     switchMap(([p, os, u]) => {
        //         const o = os.find(o => o.id === oid)
        //         const mt = u.measure_type.find(t => t.id === 14)
        //         if (!(o && mt)) throw Error("not found")
        //         return this.service.getMeasurements(p, o, mt)
        //     }),
        // ).subscribe(x => {
        //     console.warn(x)
        // })
    }

    protected readonly colorSequences = COLOR_SEQUENCES

    getJunk(key: string) {
        const k = key as keyof typeof this.colorSequences
        // return [1,2,3]
        return [...this.colorSequences[k]]
    }

    // Test with https://openlayers.org/
    // ngAfterViewInit() {
    //     // this.map = new Map({
    //     //     layers: [
    //     //         new TileLayer({
    //     //             // source:new OSM(),
    //     //             source: new TileWMS({
    //     //                 url: 'https://maps3.sgu.se/geoserver/jord/ows',
    //     //                 params: {layers: 'jord:SE.GOV.SGU.JORD.GRUNDLAGER.25K'},
    //     //                 projection: 'EPSG:3857'
    //     //             }),
    //     //         }),
    //     //     ],
    //     //     target: 'map',
    //     //     view: new View({
    //     //         projection: 'EPSG:4326',
    //     //         // projection:'EPSG:3857',
    //     //         center: [18, 59.5],
    //     //         zoom: 15, maxZoom: 18,
    //     //     }),
    //     // })
    //     //
    //     // const c = new ScaleLine({
    //     //     // target: 'line',
    //     //     // bar:true,
    //     // })
    //     // this.map.addControl(c)
    // }

}
