import {Component, input, numberAttribute, viewChild} from '@angular/core'
import {Title} from "@angular/platform-browser"
import {takeUntilDestroyed, toObservable, toSignal} from "@angular/core/rxjs-interop"

import {combineLatest, filter, map, switchMap} from "rxjs"

import {ComponentCanDeactivate} from "../can-deactivate.guard"
import {EditObjectFragmentComponent} from "../edit-object-fragment/edit-object-fragment.component"
import {VideDataService} from "../api/vide-data.service"
import {getPageTitle, isDefined, isNotNull} from "../shared/vide-helper"

@Component({
    selector: 'app-edit-object',
    standalone: true,
    templateUrl: './edit-object.component.html',
    imports: [
        EditObjectFragmentComponent
    ],
    styleUrls: ['./edit-object.component.scss']
})
export class EditObjectComponent extends ComponentCanDeactivate {
    private readonly component = viewChild(ComponentCanDeactivate)
    protected readonly oid = input.required({transform: numberAttribute})
    private readonly object$ = combineLatest([
        toObservable(this.oid),
        this.dataService.objects$,
    ]).pipe(
        map(([oid, objects]) => objects.find(o => o.id === oid)),
        filter(isDefined),
    )
    protected readonly object = toSignal(this.object$)
    private readonly objectFiles$ = combineLatest([
        this.object$,
        this.dataService.project$.pipe(filter(isNotNull)),
    ]).pipe(
        switchMap(([o, p]) => this.dataService.getObjectFiles(p, o)),
    )
    protected readonly files = toSignal(this.objectFiles$, {initialValue: []})

    constructor(
        private readonly titleService: Title,
        private readonly dataService: VideDataService,
    ) {
        super()
        combineLatest([this.object$, this.dataService.project$]).pipe(takeUntilDestroyed()).subscribe(([o, p]) => {
            this.titleService.setTitle(getPageTitle(p, `Edit ${o.name}`))
        })
    }

    canDeactivate(): boolean {
        // Stop if child says stop
        return this.component()?.canDeactivate() ?? true
    }

}
