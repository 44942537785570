<mat-card>
    <mat-card-header>
        <mat-card-title> Objects from other projects that can be imported to {{ project()?.name }}
        </mat-card-title>
        <mat-card-subtitle>
            Objects will be shared as read-only. Admin on the owning project can edit it to be writeable.
        </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
        <div class="vide-columns-wide">
            <ng-select
                [items]="siblingProjects()"
                [bindLabel]="'name'"
                [clearable]="true"
                placeholder="Select source project"
                [formControl]="form.controls.projects"
                [multiple]="true"

            />
            <button mat-stroked-button (click)="selectAllProjects()">Select all projects</button>
            <ng-select
                [items]="objectCategories()"
                [formControl]="form.controls.objectCategory"
                placeholder="Object category"
                bindLabel="name"
            />
            <ng-select
                placeholder="Measure type"
                [items]="measureTypes()"
                [formControl]="form.controls.measureType"
                bindLabel="name"
            />
            <ng-select
                placeholder="Object status"
                [items]="objectStatuses()"
                [formControl]="form.controls.objectStatus"
                bindLabel="name"
            />

            <ng-select
                placeholder="Groups"
                [items]="siblingGroups()"
                [formControl]="form.controls.group"
            >
                <ng-template ng-label-tmp ng-option-tmp let-item="item">
                    {{ item.group.name }} [{{ item.project.name }}]
                </ng-template>
            </ng-select>

            <div>
                <mat-form-field [style.width]="'100%'">
                    <mat-hint>
                        Object names are separated by comma, semicolon or newline.
                    </mat-hint>
                    <mat-label>Search for object names</mat-label>
                    <textarea
                        matInput
                        [formControl]="form.controls.names"
                        rows="8"
                    ></textarea>
                </mat-form-field>
            </div>

            @if (heightSystemProblems(); as problems) {
                @if (problems.length > 0) {
                    <div class="warning">
                        Objects with different height systems selected.
                        Be aware that measurements with different
                        systems will appear in the project. The current project
                        uses {{ project()?.height_system?.name }}.
                        @for (p of problems; track p.object.id) {
                            <div>{{ p.object.name }} uses {{ p.project.height_system.name }}</div>
                        }
                    </div>
                }
            }

        </div>

    </mat-card-content>
</mat-card>

<mat-card>
    <mat-card-content>
        <mat-tab-group>
            <mat-tab label="List">
                <ng-template matTabContent>
                    <app-shared-objects-list/>
                </ng-template>
            </mat-tab>
            <mat-tab [label]="objectWithoutCoordinatesExists()?'Map (not all objects)':'Map'">
                <ng-template matTabContent>
                    <app-shared-objects-map/>
                </ng-template>
            </mat-tab>
        </mat-tab-group>

    </mat-card-content>
    <!-- <img mat-card-image> 	Card image. Stretches the image to the container width -->

    <mat-card-actions>
        <!-- Container for buttons at the bottom of the card (adds padding) -->
        <button mat-raised-button (click)="save()">Save changes</button>
        <button mat-raised-button (click)="reset()">Reset selection</button>
    </mat-card-actions>

    <!--    <mat-card-footer></mat-card-footer>-->

</mat-card>

<mat-card>
    @if (httpStatus.total > 0) {
        <mat-progress-bar
            [value]="100*httpStatus.fetched/httpStatus.total"
            [bufferValue]="100*httpStatus.requested/httpStatus.total"
            mode="buffer"
        />
    }
    <app-log-list [container]="logs"/>
</mat-card>
