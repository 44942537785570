import {FormBuilder, Validators} from "@angular/forms"
import {Injectable} from '@angular/core'
import {SelectionModel} from "@angular/cdk/collections"

import {combineLatest, first, firstValueFrom, map, of, startWith, switchMap} from "rxjs"

import {AbstractMapboxService} from "../../mapbox/abstract-mapbox.service"
import {ExternalObjectSmhi, SmhiApiService, SmhiStation, StationParameter} from "./smhi-api.service"
import {LogContainer} from "../../log-list/log-container"
import {PLOT_COLOR} from "../../../constants"
import {PlotlyConfig, PlotlyScattermapboxData, VideFigure} from "../../vide-types"
import {Position, PositionM} from 'src/app/api/api-types'
import {HttpCounterService} from "../../shared/http-counter.service"

export type SmhiObject = {
    external: ExternalObjectSmhi | null
    parameter: StationParameter
    position: Position | null
    positionM: PositionM | null
    station: SmhiStation
    tainted: boolean
}


@Injectable({
    providedIn: 'root'
})
export class SmhiService extends AbstractMapboxService {
    static getId(x: Pick<SmhiObject, 'station' | 'parameter'>) {
        return x.station.id
        // Maybe
        // return x.station.id + x.parameter.domain + x.parameter.parameter.key
    }

    static getName(x: SmhiObject) {
        return x.station.name
    }

    /**
     * Info about the SMHI data api: https://opendata.smhi.se/apidocs/
     */
    protected override useColorbar: boolean = false

    readonly httpStatus = {
        vide: new SelectionModel<number>(true, [], true),
        extern: this.counter.waiting$,
    }
    readonly logs = new LogContainer('Save')
    readonly form = this.formBuilder.nonNullable.group({
        parameter: [null as StationParameter | null, [Validators.required]],
        showInactive: [false],
    })

    readonly allExternalSmhiObjects$ = this.api.allExternalSmhiObjects$
    readonly parameters$ = this.api.parameters$

    readonly stationsToShow$ = combineLatest([
        this.form.valueChanges.pipe(startWith(true), map(() => this.form.getRawValue())),
        this.dataService.getConverterFromWgs(3006),
        this.allExternalSmhiObjects$,
        this.httpStatus.vide.changed.pipe(startWith(null)),
    ]).pipe(
        switchMap(([values, converter, externalObjects,]) => {
            const parameter = values.parameter
            if (parameter === null) return of([])
            return this.api.getStations(parameter).pipe(
                map(stations =>
                    values.showInactive ? stations : stations.filter(s => s.station.active)),
                map(stations => stations.map(s => {
                    const external = externalObjects.find(x =>
                        x.options.domain === parameter.domain
                        && x.options.parameter === parameter.parameter.key
                        && x.options.station === s.station.id
                    ) ?? null
                    const station = s.station
                    const position: Position = {
                        lat: station.latitude,
                        lon: station.longitude,
                    }
                    const positionM = converter(position)
                    const tainted = this.httpStatus.vide.isSelected(SmhiService.getId({station, parameter}))
                    const ret: SmhiObject = {
                        external,
                        parameter,
                        position,
                        positionM,
                        station,
                        tainted
                    }
                    return ret
                })),
            )
        }),
    )
    readonly figure$ = combineLatest([
        this.stationsToShow$,
        this.forceRelayout$,
    ]).pipe(
        map(([stations,]) => {
            const lat = stations.map(s => s.station.latitude)
            const lon = stations.map(s => s.station.longitude)
            const text = stations.map(s => s.station.name)
            // Strange type in @types/plotly, it can be any[]
            const customdata = stations as any[]
            const color = stations.map(s => s.external
                ? PLOT_COLOR.object_selected
                : PLOT_COLOR.object_unselectd)
            // noinspection SpellCheckingInspection
            const hovertemplate = stations.map(s => {
                const desc: string[] = []
                const p = {
                    ...s.station,
                    from: new Date(s.station.from ?? ''),
                    to: new Date(s.station.to ?? ''),
                    updated: new Date(s.station.updated ?? ''),
                }
                {
                    let key: keyof typeof p
                    for (key in p) {
                        const value = p[key]
                        if (typeof value !== 'object') {
                            desc.push(`${key}: ${value}`)
                        }
                    }
                }
                return `<em>%{text} </em> <br>` + desc.join("<br>") + "<extra></extra>"
            })
            // noinspection SpellCheckingInspection
            const data: PlotlyScattermapboxData[] = [{
                type: "scattermapbox",
                marker: {color},
                customdata, lat, lon, text, hovertemplate,
            }]
            const figure: VideFigure = {
                config: this.getMapConfig(),
                data: data,
                layout: this.getLayout(stations, {background: "open-street-map"}),
            }
            return figure
        })
    )

    constructor(
        private readonly api: SmhiApiService,
        private readonly formBuilder: FormBuilder,
        private readonly counter: HttpCounterService,
    ) {
        super()
        this.parameters$.pipe(first()).subscribe(p => {
            this.form.patchValue({parameter: p.at(0)})
        })
    }

    async remove(x: SmhiObject) {
        if (!x.external) return
        const project = await firstValueFrom(this.dataService.projectNotNull$)
        this.httpStatus.vide.select(SmhiService.getId(x))
        const res = await firstValueFrom(
            this.dataService.deleteExternalSource(project, x.external)
        ).finally(() => {
            this.httpStatus.vide.deselect(SmhiService.getId(x))
        })
        this.logs.add(res, `Remove ${x.station.name}`)
        if (res.success) {
            this.dataService.reloadProjectData()
        }
    }

    async add(x: SmhiObject) {
        const project = await firstValueFrom(this.dataService.projectNotNull$)
        const properties = {
            domain: x.parameter.domain,
            station: x.station.id,
            parameter: x.parameter.parameter.key,
        }
        this.httpStatus.vide.select(SmhiService.getId(x))
        const res = await firstValueFrom(
            this.dataService.createExternalObject(project, 'smhi', properties)
        ).finally(() => {
            this.httpStatus.vide.deselect(SmhiService.getId(x))
        })
        this.logs.add(res, `Add ${x.station.name}`)
        if (res.success) {
            this.dataService.reloadProjectData()
        }
    }

    protected override getMapConfig(toImageButtonOptions?: { filename: string }): PlotlyConfig {
        const baseConfig = super.getMapConfig(toImageButtonOptions)
        baseConfig.modeBarButtonsToRemove = []
        return baseConfig
    }
}
