<p>
    Select an existing object or create a new one. It will be used to load and save diver data in the following steps.
</p>

<mat-tab-group>
    <mat-tab label="Existing">
        @if (form.controls.object; as cntrl) {
            <div class="vide-columns-wide vide-form-field vide-form-field-row">
                <ng-select
                    [items]="objects()"
                    bindLabel="name"
                    [formControl]="cntrl"
                    placeholder="Diver object"
                    [clearable]="false"
                />
            </div>
            @if (cntrl.invalid && cntrl.dirty || !cntrl.untouched) {
                <div class="error">{{ getErrorMessage(cntrl) }}</div>
            }
        }

    </mat-tab>
    <mat-tab label="Create new">
        @let cntrl = form.controls.name ;
        <mat-form-field>
            <mat-label>Object name</mat-label>
            <input matInput [formControl]="cntrl"/>
            <mat-error>{{ getErrorMessage(cntrl) }}</mat-error>
        </mat-form-field>
        <button mat-raised-button (click)="newObject()" [disabled]="cntrl.invalid">Create new object</button>
    </mat-tab>
</mat-tab-group>


<div class="stepper-navigate">
    <button mat-button matStepperPrevious type="button">Previous</button>
    <button mat-button matStepperNext type="button" (click)="localNext()" >Next</button>
</div>
