import {Component} from '@angular/core'
import {MatDialog} from '@angular/material/dialog'
import {Router, RouterLink} from '@angular/router'
import {toSignal} from "@angular/core/rxjs-interop"
import {MatListModule} from "@angular/material/list"
import {MatTooltipModule} from "@angular/material/tooltip"
import {MatButtonModule} from "@angular/material/button"

import {VIDE_ROUTES2, VideDataService} from '../api/vide-data.service'
import {ObjectSelectDialogComponent} from '../dialogs/object-select-dialog/object-select-dialog.component'
import {Project} from "../api/api-types"

@Component({
    selector: 'app-welcome',
    standalone: true,
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss'],
    imports: [
        MatButtonModule,
        MatListModule,
        MatTooltipModule,
        RouterLink,
    ]
})
export class WelcomeComponent {
    readonly projects = toSignal(this.dataServiceV2.projects$)
    readonly smallScreen = this.dataServiceV2.smallScreen

    constructor(
        private router: Router,
        private dataServiceV2: VideDataService,
        private dialog: MatDialog,
    ) {
    }

    selectProject(p: Project) {
        const link = this.dataServiceV2.videLink(VIDE_ROUTES2.project.project, {project: p})
        this.router.navigate(link).then(r => {
            if (!r) {
                console.error("Error navigating to project")
            }
        })
    }


    onMeasurements(ps: readonly Project[]) {
        console.log(`press button!`)
        const ref = this.dialog.open<
            ObjectSelectDialogComponent<Project>,
            ObjectSelectDialogComponent<Project>['data'],
            ObjectSelectDialogComponent<Project>['result']
        >(ObjectSelectDialogComponent, {
            data: {
                objects: ps,
                search: false,
                name: 'Select project',
            },
        })
        ref.afterClosed().subscribe(p => {
            console.debug(`got `, p)
            if (p) {
                const l = this.dataServiceV2.videLink(VIDE_ROUTES2.fieldApp, {project: p})
                this.router.navigate(l).then(value => {
                    if (!value) {
                        console.error("error navigating")
                    }
                })
            }
        })
    }

    projectLink(p: Project) {
        return this.dataServiceV2.videLink(VIDE_ROUTES2.project.project, {project: p})
    }
}
