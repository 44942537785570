import {assertNever, dateToString} from "../shared/vide-helper"
import * as S from 'superstruct'
import {BehaviorSubject, map} from "rxjs"
import {Project} from "./api-types"

export const Interval = [
    'days',
    'weeks',
    'months',
    'years',
] as const

const DatePeriod = S.enums(Interval)
const DateLimit = S.nullable(S.union([
    S.object({type: S.enums(['date']), date: S.string()}),
    S.object({type: S.enums(['period']), interval: S.integer(), period: DatePeriod})]))

export type DateLimit = S.Infer<typeof DateLimit>
export type DatePeriod = S.Infer<typeof DatePeriod>

//
// @Injectable({
//     providedIn: 'root'
// })

export class LimitStorage {
    private readonly reload = new BehaviorSubject<void>(undefined)

    private getStorageKey(project: Project) {
        return `project-${project.id}-limit`
    }


    constructor() {
    }

    getLimit(project: Project | null) {
        return this.reload.pipe(
            map(() => {
                if (!project) {
                    return null
                }
                const key = this.getStorageKey(project)
                const value = localStorage.getItem(key)
                const value2 = value ? JSON.parse(value) : null
                if (!value2) {
                    return null
                }
                const [error, limit] = S.validate(value2, DateLimit)
                if (error) {
                    console.warn("Error parsing limit, resetting", value2, error)
                    this.setLimit(project, null)
                    return null
                }
                return limit
            }),
        )
    }

    getLimitAsDateString(project: Project | null) {
        return this.getLimit(project).pipe(
            map(limit => {
                let stringLimit
                if (limit) {
                    // maybe return the date string?
                    if (limit.type === 'period') {
                        let start = new Date()
                        const period = limit.period
                        switch (period) {
                            case "days":
                                start.setDate(start.getDate() - limit.interval)
                                break
                            case "weeks":
                                start.setDate(start.getDate() - limit.interval * 7)
                                break
                            case "months":
                                start.setMonth(start.getMonth() - limit.interval)
                                break
                            case "years":
                                start.setFullYear(start.getFullYear() - limit.interval)
                                break
                            default:
                                assertNever(period)
                        }
                        stringLimit = dateToString(start)
                    } else {
                        stringLimit = limit.date
                    }
                }
                return stringLimit
            }),
        )
    }

    getDescription(project: Project | null) {
        return this.getLimit(project).pipe(
            map(limit => {
                if (!limit) return "No limit set"
                const type = limit.type
                switch (type) {
                    case "date":
                        return `From ${limit.date}`
                    case "period":
                        return `From ${limit.interval} ${limit.period} ago`
                    default:
                        assertNever(type)
                }
            })
        )
    }

    setLimit(project: Project, value: DateLimit) {
        const key = this.getStorageKey(project)
        console.log(key)
        if (key) {
            localStorage.setItem(key, JSON.stringify(value))
        }
        this.reload.next()
    }

}
