<table mat-table [dataSource]="annotations$">

    <ng-container matColumnDef="first_date">
        <th mat-header-cell *matHeaderCellDef> First value (local time)</th>
        <td mat-cell *matCellDef="let element"> {{ element.first_date }}</td>
    </ng-container>
    <ng-container matColumnDef="updated_at">
        <th mat-header-cell *matHeaderCellDef> Updated</th>
        <td mat-cell *matCellDef="let element">
            {{ element.updated_at |date : DATETIME_FORMAT }}
        </td>
    </ng-container>
    <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef> Updated</th>
        <td mat-cell *matCellDef="let element">
            {{ element.updated_at |date : DATETIME_FORMAT }}
        </td>
    </ng-container>
    <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef> Reference measurement</th>
        <td mat-cell *matCellDef="let element">
            @if (element.reference_measurement; as ref) {
                {{ ref.object.name }}: {{ ref.resulting_value }} &commat; {{ ref.measuretime }}
            }
<!--            {{ element |json }}-->
        </td>
    </ng-container>
    <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="delete(element)">
                <mat-icon>delete</mat-icon>
            </button>
        </td>
    </ng-container>
    <ng-container matColumnDef="serial_number">
        <th mat-header-cell *matHeaderCellDef> Serial number of diver</th>
        <td mat-cell *matCellDef="let element"> {{ element.serial_number }}</td>
    </ng-container>
    <mat-text-column name="comment"/>
    <mat-text-column name="id"/>
    <mat-text-column name="object_id"/>
    <mat-text-column name="reference_measurement_id"/>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

@if (uncompensatedMeasurements(); as ms) {
    @if (ms.length > 0) {
        <button mat-raised-button type="button" (click)="deleteMeasurements(diverObject(), ms)">
            Delete {{ ms.length }} uncompensated measurements from {{ diverObject().name }}
        </button>
    }
}
@if (unreferencedMeasurements(); as ms) {
    @if (ms.length > 0) {
        <button mat-raised-button type="button" (click)="deleteMeasurements(diverObject(), ms)">
            Delete {{ ms.length }} unreferenced measurements from {{ diverObject().name }}
        </button>
    }
}

<div class="stepper-navigate">
    <button mat-button matStepperPrevious type="button">Previous</button>
    <button mat-button matStepperNext type="button" disabled>Next</button>
</div>
