import {Component, computed, DestroyRef, effect} from '@angular/core'
import {ActivatedRoute, Router, RouterLink} from '@angular/router'
import {toSignal} from "@angular/core/rxjs-interop"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {MatTooltipModule} from "@angular/material/tooltip"
import {MatExpansionModule} from "@angular/material/expansion"
import {MatButtonModule} from "@angular/material/button"
import {ReactiveFormsModule} from "@angular/forms"
import {MatTabsModule} from "@angular/material/tabs"

import {NgSelectModule} from "@ng-select/ng-select"

import {mixinNavigateTabs} from 'src/app/shared/mixin-navigate-tabs'
import {INPUT_DATE_MAX, VIDE_ROUTES} from "../../../constants"
import {DISPLAY_OPTIONS, ObjectSelectionDataService} from '../object-selection-data.service'
import {VideObject} from "../../api/api-types"
import {ObjectMapComponent} from "../object-map/object-map.component"
import {ObjectListComponent} from "../object-list/object-list.component"
import {ObjectStatisticsComponent} from "../object-statistics/object-statistics.component"
import {InputComponent} from "../../forms/input/input.component"
import {equalIds} from "../../shared/vide-helper"

const MixinBase =
    mixinNavigateTabs(
        class {
            constructor(public router: Router, public route: ActivatedRoute, public destroyRef: DestroyRef) {
            }
        },
        'select',
        0,
    )

@Component({
    selector: 'app-object-select',
    standalone: true,
    templateUrl: './object-select.component.html',
    styleUrls: ['./object-select.component.scss'],
    imports: [
        InputComponent,
        MatButtonModule,
        MatExpansionModule,
        MatProgressBarModule,
        MatTabsModule,
        MatTooltipModule,
        NgSelectModule,
        ObjectListComponent,
        ObjectMapComponent,
        ObjectStatisticsComponent,
        ReactiveFormsModule,
        RouterLink,
    ]
})
export class ObjectSelectComponent extends MixinBase {

    readonly DISPLAY_OPTIONS = DISPLAY_OPTIONS
    readonly VIDE_ROUTES = VIDE_ROUTES
    readonly INPUT_DATE_MAX = INPUT_DATE_MAX

    readonly filterAndSearchForm = this.dataService.filterAndSearchForm
    readonly displayOption = this.dataService.displayOption

    readonly objects = this.dataService.objects
    readonly sourceProjects = this.dataService.sourceProjects
    readonly project = this.dataService.project

    readonly selectedObjects = toSignal(this.dataService.selectedObjects$, {initialValue: []})
    readonly groups = toSignal(this.dataService.groups$, {initialValue: []})
    readonly filteredObjects = toSignal(this.dataService.filteredObjects$, {initialValue: []})
    readonly unmappedObjects = toSignal(this.dataService.unmappedObjectsToDisplay$, {initialValue: []})
    readonly projectWaiting = this.dataService.projectWaiting
    readonly selectedCategory = toSignal(this.filterAndSearchForm.controls.object_category.valueChanges)
    private readonly utility = toSignal(this.dataService.utility$)

    readonly aquifers = computed(() => this.utility()?.aquifer ?? [])
    readonly objectStatuses = computed(() => this.utility()?.object_status ?? [])
    readonly measureTypes = computed(() => this.utility()?.measure_type ?? [])
    readonly objectTypes = computed(() => this.utility()?.object_type ?? [],)
    readonly objectCategories = computed(() => this.utility()?.object_category ?? [])
    readonly filteredTypes = computed(() => {
        const category = this.selectedCategory()
        const filter = category
            ? (t: { object_category: { id: number } }) => t.object_category.id === category.id
            : () => true
        return this.objectTypes().filter(filter)
    })
    readonly filterSelections = computed(() => {
        return [
            {
                control: this.filterAndSearchForm.controls.object_category,
                items: this.objectCategories(),
                title: 'Object category'
            },
            {
                control: this.filterAndSearchForm.controls.object_type,
                items: this.filteredTypes(),
                title: 'Object type'
            },
            {
                control: this.filterAndSearchForm.controls.object_status,
                items: this.objectStatuses(),
                title: 'Object status'
            },
            {
                control: this.filterAndSearchForm.controls.measure_type,
                items: this.measureTypes(),
                title: 'Measure type'
            },
            {
                control: this.filterAndSearchForm.controls.group,
                items: this.groups(),
                title: 'Group'
            },
            {
                control: this.filterAndSearchForm.controls.aquifer,
                items: this.aquifers(),
                title: 'Aquifer'
            },
        ] as const
    })

    constructor(
        destroyRef: DestroyRef,
        route: ActivatedRoute,
        router: Router,
        private readonly dataService: ObjectSelectionDataService,
    ) {
        super(router, route, destroyRef)
        effect(() => {
            if (this.selectedCategory()) {
                this.filterAndSearchForm.patchValue({object_type: null})
            }
        })
    }

    selectObjects(os: VideObject[]) {
        this.dataService.selectionModel.select(...os.map(o => o.id))
    }

    deselectAllObjects() {
        this.dataService.selectionModel.clear()
    }

    mapViewTooltip(unmapped: VideObject[]) {
        return 'Show objects on a map–' +
            (unmapped.length > 0
                ? 'objects without coordinates: ' + unmapped.map(os => os.name).join(', ')
                : 'only objects with coordinates will be visible')
    }

    resetFilter() {
        this.filterAndSearchForm.reset()
    }
}
