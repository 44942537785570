import xxx from "../../package.json"
import { VideEnvironment } from "../app/vide-types"

const VERSION = xxx.version

export const environment: VideEnvironment = {
    production: true,
    apiUrl: '/api/',
    appVersion: VERSION + '-staging',
    outdatedReloads: 10,
    outdatedObjectWait: 5,
}
