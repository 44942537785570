import {Pipe, PipeTransform} from '@angular/core'

/** Splits at capital letters, removes all underscores, and make just the initial letter uppercase */
@Pipe({
    name: 'headerCase',
    standalone: true
})
export class HeaderCasePipe implements PipeTransform {

    transform(value: string): string {
        return HeaderCasePipe.headerCase(value)
    }

    public static headerCase(value: string) {
        const x = value.replace(/\p{Lu}/ug, ' $&')
        const y = x.toLocaleLowerCase().replace(/_/g, ' ').trim()
        const first = y.substring(0, 1).toLocaleUpperCase()
        const rest = y.substring(1)
        return first + rest
    }
}
