<div class="padded">
    <h1>User abilities</h1>

    <h2>License owner</h2>
    <ul>
        <li>Can create new users and invite them to the database.</li>
        <li>Can delete users.</li>
        <li>Set the user's ability. This limits what project ability the user can have, and is limited by what is
            included in the license.
        </li>
        <li>If the user's ability is lowered, the ability is lowered in existing projects as necessary.</li>
        <li>Handle connections to Unoson, if any.</li>
    </ul>

    <h2>Project abilities</h2>
    <p> The content is organised in projects. A user is granted access to a project with a project ability, not higher
        than the user's ability.
    </p>
    <h3>Read</h3>
    <ul>
        <li>Make all kinds of plots.</li>
        <li> View measurements and object data.</li>
        <li>Export data.</li>
    </ul>
    <h3>Measure</h3>
    <ul>
        <li> All capabilities of Read.</li>
        <li> Use the Field app to add measurements to the database.</li>
    </ul>
    <h3>Write</h3>
    <ul>
        <li> All capabilities of Measure.</li>
        <li> Change measurements, including accepting measurements with status Not checked.</li>
        <li> Change objects.</li>
        <li> Create objects.</li>
        <li> Import data from files (objects and measurements).</li>
        <li> Add and remove objects with public data (e.g., from SGU and SMHI).</li>
        <li> Add and remove objects from other projects where the users has Read ability.</li>
    </ul>
    <h3>Admin</h3>
    <ul>
        <li> All capabilities of Write.</li>
        <li> Remove measurements.</li>
        <li> Remove objects (not objects that contains original data, a property that can be changed).</li>
        <li> Create, edit and delete projects (and the contained objects and measurements).</li>
        <li> Add users to project, and set the users project ability (not exceeding the ability set by the license
            owner).
        </li>
        <li>Allow shared objects to be writeable by other projects (by default, they are read-only).</li>
    </ul>
</div>

