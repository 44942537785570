<header>
    <app-toolbar/>
</header>

<mat-sidenav-container>
    <mat-sidenav
        mode="side"
        position="end"
        [opened]="help.state()"
    >
        <app-help-sidenav/>
    </mat-sidenav>
    <mat-sidenav-content>
        <router-outlet/>
    </mat-sidenav-content>
</mat-sidenav-container>

<footer class="mat-elevation-z8">
    &copy; 2025 Akvifär AB
</footer>
