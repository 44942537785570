import {Component, ElementRef, viewChild} from '@angular/core'
import {MatButtonModule} from "@angular/material/button"
import {MatSelectModule} from "@angular/material/select"
import {MatStepperModule, MatStepperNext} from "@angular/material/stepper"
import {ReactiveFormsModule} from "@angular/forms"
import {toSignal} from "@angular/core/rxjs-interop"

import {PlotlyViaWindowModule} from "angular-plotly.js"

import {CompensateDiverService} from "./compensate-diver.service"
import {DiverService} from "../diver.service"
import {SlimObject} from "../diver.component"
import {MatProgressBar} from "@angular/material/progress-bar"
import {BehaviorSubject} from "rxjs"

@Component({
    selector: 'app-compensate-diver',
    standalone: true,
    imports: [
        MatButtonModule,
        MatSelectModule,
        MatStepperModule,
        PlotlyViaWindowModule,
        ReactiveFormsModule,
        MatProgressBar,
    ],
    templateUrl: './compensate-diver.component.html',
    styleUrl: './compensate-diver.component.scss'
})
export class CompensateDiverComponent {
    // properties
    private readonly _saving = new BehaviorSubject(false)
    // inputs
    // outputs
    // view children
    protected readonly nextButton = viewChild.required(MatStepperNext, {read: ElementRef})
    // signals
    protected readonly figure = toSignal(this.service.compensatedFigure$)
    protected readonly nrCompensated = toSignal(this.service.compensatedNrData$)
    protected readonly object = toSignal(this.diverService.selectedObject$)
    protected readonly saving = toSignal(this._saving)

    constructor(
        private readonly service: CompensateDiverService,
        private readonly diverService: DiverService,
    ) {
    }

    async save(object: SlimObject) {
        this._saving.next(true)
        const res = await this.service.save(object).finally(() => {
            this._saving.next(false)
        })
        if (res) {
            const el = this.nextButton().nativeElement
            if (el instanceof HTMLButtonElement) {
                el.click()
            }
        }
    }
}
