@if (projectWaiting()) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}

@if (filteredObjects(); as filteredObjects) {
    <!-- Some nice characters I found -->
        <!-- &#119966; 𝒞, ℳ, ℒ, 𝒮 -->
        <!-- Object display tabs -->
    <mat-tab-group
        class="mat-elevation-z8"
        headerPosition="above"
        [selectedIndex]="selectedIndex"
        (selectedIndexChange)="selectedIndexChange($event)"
        dynamicHeight
        animationDuration="0"
    >
        <mat-tab>
            <ng-template mat-tab-label>
                @if ((unmappedObjects()); as unmapped) {
                    <span [matTooltip]="mapViewTooltip(unmapped)">
              Map view @if (unmapped.length > 0) {
                        <sup>*</sup>
                    }
          </span>
                }
            </ng-template>
            <ng-template matTabContent>
                <app-object-map></app-object-map>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
        <span matTooltip="Show objects in a brief list">
          List view
        </span>
            </ng-template>
            <ng-template matTabContent>
                <app-object-list></app-object-list>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
        <span matTooltip="Show object statistics&mdash;only objects with measurements will show up here">
          Statistics view
        </span>
            </ng-template>
            <ng-template matTabContent>
                <app-object-statistics></app-object-statistics>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
    <!-- Select buttons -->
    <mat-expansion-panel expanded="true" class="mat-elevation-z8">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Objects
            </mat-panel-title>
            <mat-panel-description>
                {{ selectedObjects().length }} selected
            </mat-panel-description>
        </mat-expansion-panel-header>
        <button mat-raised-button (click)="selectObjects(filteredObjects)" [disabled]="filteredObjects.length===0">
            Select filtered
        </button>
        <button mat-raised-button (click)="deselectAllObjects()">
            Deselect all
        </button>
        <a mat-raised-button [routerLink]="videLink(VIDE_ROUTES2.groups)" queryParamsHandling="merge"
           title="Edit groups using the selected objects">
            Edit groups
        </a>
        <ng-select id="show" [items]="DISPLAY_OPTIONS" [formControl]="displayOption"
                   [clearable]="false"></ng-select>
    </mat-expansion-panel>
    <!-- Filter fields -->
    @if (filterAndSearchForm.controls; as controls) {
        <mat-expansion-panel class="vide-form-field" expanded="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Filter objects
                </mat-panel-title>
                <mat-panel-description>
                    {{ filteredObjects.length }} of {{ objects().length }}
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="vide-columns vide-form-field-row">
                <app-input [clearable]="true" placeholder="Object name"
                           [formControl]="controls.search"></app-input>
                <ng-select [formControl]="controls.source" placeholder="Source project"
                           [items]="sourceProjects()"></ng-select>
                <ng-select [formControl]="controls.has_non_checked_data" placeholder="Non-checked data">
                    <ng-option [value]="true">Has non-checked data</ng-option>
                    <ng-option [value]="false">No non-checked data</ng-option>
                </ng-select>
                <ng-select [formControl]="controls.is_selected" placeholder="Selected">
                    <ng-option [value]="true">Is selected</ng-option>
                    <ng-option [value]="false">Is not selected</ng-option>
                </ng-select>
                <ng-select [formControl]="controls.is_readonly" placeholder="Access">
                    <ng-option [value]="true">Is readonly</ng-option>
                    <ng-option [value]="false">Is writeable</ng-option>
                </ng-select>
                @for (row of filterSelections(); track row.title) {
                    <ng-select [formControl]="row.control" [items]="row.items"
                               [searchable]="true" [placeholder]="row.title" bindLabel="name"></ng-select>
                }
                @if (project()?.correlations_exists) {
                    <ng-select [formControl]="controls.has_correlation" placeholder="Correlation">
                        <ng-option [value]="true">Has correlation</ng-option>
                        <ng-option [value]="false">Has no correlation</ng-option>
                    </ng-select>
                    <ng-select [formControl]="controls.is_reference" placeholder="Reference">
                        <ng-option [value]="true">Is reference</ng-option>
                        <ng-option [value]="false">Is not reference</ng-option>
                    </ng-select>
                    <ng-select [formControl]="controls.correlation_base" placeholder="Correlation base">
                        <ng-option [value]="true">Marked as correlation base</ng-option>
                        <ng-option [value]="false">Not marked as correlation base</ng-option>
                    </ng-select>
                    <ng-select [formControl]="controls.correlation_reference" placeholder="Correlation reference">
                        <ng-option [value]="true">Marked as correlation reference</ng-option>
                        <ng-option [value]="false">Not marked as correlation reference</ng-option>
                    </ng-select>
                }
            </div>
            <div class="vide-form-field-row vide-field-combination">
                <ng-select [style.width]="'15rem'" [formControl]="controls.dateDirection" [clearable]="false">
                    <ng-option value="before">Has measurements before</ng-option>
                    <ng-option value="after">Has measurements after</ng-option>
                </ng-select>
                <app-input type="date" [formControl]="controls.date" [max]="INPUT_DATE_MAX"
                           placeholder=""></app-input>
                <div class="toolbar-spacer"></div>
                <button mat-raised-button (click)="resetFilter()">Reset filter</button>
            </div>
        </mat-expansion-panel>
    }
}
