import {AfterViewInit, Component, viewChild} from '@angular/core'
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator"
import {ReactiveFormsModule} from "@angular/forms"
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop"
import {MatTableDataSource, MatTableModule} from "@angular/material/table"
import {MatSortModule} from "@angular/material/sort"
import {MatCheckboxModule} from "@angular/material/checkbox"

import {map} from "rxjs"

import {SharedObjectsService} from "../shared-objects.service"
import {Unpacked} from "../../../vide-types"

@Component({
    selector: 'app-shared-objects-list',
    standalone: true,
    imports: [
        MatCheckboxModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        ReactiveFormsModule,
    ],
    templateUrl: './shared-objects-list.component.html',
    styleUrl: './shared-objects-list.component.scss'
})
export class SharedObjectsListComponent implements AfterViewInit {
    protected readonly pageSize = 15
    paginator = viewChild.required(MatPaginator)
    dataSource = new MatTableDataSource<Unpacked<typeof this.service.filteredShareableObjects$>>([])
    protected readonly selectionModel = this.service.selectionModel
    protected readonly form = this.service.form
    protected readonly shareableObjectIds = toSignal(this.service.filteredShareableObjects$.pipe(
        map(objects => objects.map(o => o.object.id))), {initialValue: []})
    columnsToDisplay = [
        'select',
        'object',
        'project',
        'noCoordinates',
    ]

    constructor(
        private readonly service: SharedObjectsService,
    ) {
        this.service.filteredShareableObjects$.pipe(takeUntilDestroyed()).subscribe(objects => {
            this.dataSource.data = objects
        })
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator()
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        return this.shareableObjectIds().every(id => this.selectionModel.isSelected(id))
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        const selectable = this.shareableObjectIds().map(id => id)
        this.isAllSelected()
            ? this.selectionModel.deselect(...selectable)
            : this.selectionModel.select(...selectable)
    }

    isSomeSelected() {
        return this.shareableObjectIds().some(id => this.selectionModel.isSelected(id))
    }
}
