import {Component, HostListener} from '@angular/core'
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {ActivatedRoute, Router} from "@angular/router"

import {VideDataService} from "../../api/vide-data.service"
import {BroadcastType} from "../../broadcast/broadcast-type"
import {BroadcastService} from "../../broadcast/broadcast.service"
import {ObjectSelectComponent} from "../object-select/object-select.component"

function throwError(msg: string): never {
    throw Error(msg)
}

@Component({
    selector: 'app-popup-select',
    standalone: true,
    templateUrl: './popup-select.component.html',
    imports: [
        ObjectSelectComponent
    ],
    styleUrls: ['./popup-select.component.scss']
})
export class PopupSelectComponent {
    private readonly tag: string

    @HostListener('window:beforeunload', ['$event'])
    closeEvent($event: Event) {
        // see https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
        const broadcasterClose = this.broadcastService.get(BroadcastType.ACTION, this.tag)
        broadcasterClose.send({action: 'close', target: window.name})
    }

    constructor(
        private readonly dataServiceV2: VideDataService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly broadcastService: BroadcastService,
    ) {
        console.warn('constructor')
        const snapshot = this.route.snapshot
        this.tag = snapshot.queryParamMap.get('tag') ?? throwError('Cannot work without tag url parameter')
        this.setupBroadcast()
    }

    private setupBroadcast() {
        const broadcaster = this.broadcastService.get(BroadcastType.OBJECT_IDS, this.tag)
        this.dataServiceV2.selectionModel.changed.pipe(
            takeUntilDestroyed(),
        ).subscribe(change => {
            const oids = change.source.isEmpty() ? null : change.source.selected.join(',')
            this.router.navigate([], {queryParams: {oids}, queryParamsHandling: 'merge'}).then(x => {
                broadcaster.send({added: change.added, removed: change.removed})
            })
        })
    }


}
