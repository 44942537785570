<mat-card>
    <mat-card-header>
        <mat-card-title>License admin</mat-card-title>
        <!--        <mat-card-subtitle>{{ l.comment }}</mat-card-subtitle>-->
    </mat-card-header>

    <mat-card-content class="vide-scrollable-content">
        @for (l of licenses(); track l.id) {
            <mat-card>
                <!--                <mat-card-header>-->
                <!--                    <mat-card-title>{{ l.name }}</mat-card-title>-->
                <!--                    <mat-card-subtitle>{{ l.comment }}</mat-card-subtitle>-->
                <!--                </mat-card-header>-->
                <mat-card-content>
                    <div class="vide-columns-wide">
                        <div>
                            <b>{{ l.name }}</b>
                            <p>{{ l.comment }}</p>
                        </div>

                        <div>
                            @if (l.owner) {
                                Owner: {{ l.owner }}
                            } @else {
                                No owner set
                            }
                        </div>
                        @if (l.unoson_sources; as x) {
                            @for (x of l.unoson_sources; track $index) {
                                <table>
                                    <caption>Unoson connection {{ x.name }}</caption>
                                    <thead>
                                    <tr>
                                        <th>Property</th>
                                        <th>Value</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        @for (row of x|keyvalue; track $index) {
                                            <tr>
                                                <td>{{ row.key }}</td>
                                                <td>{{ row.value }}</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            }
                        }
                        <table>
                            <caption>Capacity</caption>
                            <thead>
                            <tr>
                                <th>Ability</th>
                                <th>Capacity</th>
                                <th>Current use</th>
                            </tr>
                            </thead>
                            <tbody>
                                @for (x of abilities; track $index) {
                                    <tr>
                                        @if (x.name(); as name) {
                                            <td> {{ name }}</td>
                                            <td> {{ l.limit[name] }}</td>
                                            <td> {{ l.used[name] }}</td>
                                        }
                                    </tr>
                                }
                            </tbody>
                        </table>
                        <table>
                            <caption>Users</caption>
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Ability</th>
                            </tr>
                            </thead>
                            <tbody>
                                @for (x of l.users; track x) {
                                    <tr>
                                        <td> {{ x.name }}</td>
                                        <td> {{ x.email }}</td>
                                        <td> {{ x.ability }}</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>

                </mat-card-content>

                <mat-card-actions>
                    <button mat-icon-button (click)="editLicense(l)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </mat-card-actions>
            </mat-card>
        }
    </mat-card-content>
    <mat-card-actions>
        @if (userAbility()?.has('Vide admin')) {
            <button mat-fab (click)="addLicense()">
                <mat-icon>add</mat-icon>
            </button>
            <app-log-list [container]="logs"/>
        }
    </mat-card-actions>
    <mat-card-footer>
        <app-log-list [container]="logs"/>
    </mat-card-footer>
</mat-card>


<h1></h1>
<!--@if (userAbility()?.has('Vide admin')) {-->
<!--    <button mat-fab (click)="addLicense()">-->
<!--        <mat-icon>add</mat-icon>-->
<!--    </button>-->
<!--    <app-log-list [container]="logs"/>-->
<!--}-->



