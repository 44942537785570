@if (importInfo(); as info) {
    <mat-card>
        <mat-card-header>
            <mat-card-title> File import</mat-card-title>
            <mat-card-subtitle>
                Maximum allowed file size:
                <span matTooltip="{{info.maxFileSize}} bytes"> {{ info.maxFileSize | byteFormat }} </span>
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            @if (form.controls; as controls) {
                <div class="vide-form-field">
                    <div class="vide-columns-wide vide-form-field-row">
                        <!-- File type -->
                        <ng-select
                            [items]="info.types"
                            bindLabel="type"
                            [clearable]="false"
                            [formControl]="controls.type"
                        />
                        <!-- Coordinate system -->
                        <ng-select
                            [items]="coordinateSystems()"
                            [clearable]="false"
                            [formControl]="controls.coordinate_system"
                            [compareWith]="equalIds"
                        >
                            <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                {{ item.name }}
                                @if (item.id === project()?.coordinate_system?.id) {
                                    <span> (project default) </span>
                                }
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="vide-columns-wide vide-form-field-row">
                        <!-- File -->
                        <app-input type="file" [accept]="mimeType()" [formControl]="controls.file"
                                   placeholder="Select file" (cleared)="fileInputCleared()"/>
                    </div>
                    <div class="vide-form-field-row">
                        <!-- Error -->
                        @if ((controls.file.touched || controls.file.dirty) && controls.file.invalid) {
                            <mat-error>
                                {{ getErrorMessage(controls.file) }}
                            </mat-error>
                        }
                    </div>
                    <div class="vide-form-field-row">
                        <!-- Options -->
                        <mat-slide-toggle
                            [formControl]="controls.allowUpdate"
                            [matTooltip]="info.tooltips.allowUpdate"
                        >
                            {{ info.nameAllowUpdates }}
                        </mat-slide-toggle>
                        <mat-slide-toggle
                            [formControl]="controls.allowOldData"
                            [matTooltip]="info.tooltips.oldData"
                        >
                            {{ info.nameOldData }}
                        </mat-slide-toggle>
                        <mat-slide-toggle
                            [formControl]="controls.markAsOriginal"
                            [matTooltip]="info.tooltips.originalInformation"
                        >
                            {{ info.nameOriginalData }}
                        </mat-slide-toggle>
                    </div>
                </div>
            }
            @if (form.controls.type.value?.template; as file) {
                <div>
                    <a [href]="'assets/templates/' + file">Template file</a>
                </div>
            }
        </mat-card-content>

        <mat-card-actions>
            @if (ABILITY.WRITE.availableFor(project())) {
                <button mat-raised-button type="button" (click)="onSubmit()">
                    Submit
                </button>
            } @else {
                <div class="error">
                    Write permission required for file import
                </div>
            }
            <button mat-raised-button type="button" (click)="updateStatistics()" [matTooltip]="statisticsTooltip">
                Update statistics
            </button>
            @if (form.controls.type.value; as importType) {
                @if (importInfo(); as importInfo) {
                    <button
                        mat-raised-button
                        matTooltip="Detailed information for the selected file type"
                        (click)="showInfo(importInfo, importType)"
                    >
                        Help
                    </button>
                }
            }
        </mat-card-actions>

        <mat-card-footer>
            @if (progress) {
                <mat-progress-bar mode="determinate" [value]="progress"/>
            }
            @if (progress === 100 && !error && !importResult) {
                <div>
                    <mat-progress-bar mode="buffer"/>
                </div>
            }
        </mat-card-footer>
    </mat-card>

    <!-- In case of ERROR-->
    @if (error) {
        <mat-card class="error">
            <mat-card-header>
                <mat-card-title>
                    Error &mdash; nothing inserted
                </mat-card-title>
                <mat-card-subtitle>
                    {{ getHttpErrorMessage(error) }}
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <!-- Extra info (guessing) regarding some response codes -->
                <div>
                    @switch (error.status) {
                        @case (0) {
                            <div>
                                @if (progress === 100) {
                                    <span>
                  If the request timed out, try dividing the input in half to reduce processing time.
                </span>
                                }
                                @if (!progress) {
                                    <span> Maybe the file changed after you selected it? Then you have to select it again. </span>
                                }
                            </div>
                        }
                        @case (413) {
                            <div>
                                File too large. Maximum upload size is {{ info.maxFileSize | byteFormat }}.
                            </div>
                        }
                        @case (500) {
                            <div>
                                Maybe the server ran out of memory, please check the server logs for details.
                            </div>
                        }
                        @default {
                            <div>
                            </div>
                        }
                    }
                </div>
                <!-- Extra info in case we got some error result in the response -->
                <div>
                    @switch (importResult?.error) {
                        @case ('ERROR_XLSX') {
                            <div>
                                Error reading input file, maybe the file type is wrong?
                            </div>
                        }
                        @case ('ERROR_DUPLICATES') {
                            <div>
                                Duplicates in input data.
                                @if (!failedKeys) {
                                    <div>The duplicates are in the input data (not already in the database).</div>
                                }
                                <p>
                                    If you edit the input file, you must select it again.
                                </p>
                            </div>
                        }
                        @case ('ERROR_OLD_DATA') {
                            <div>
                                Old measurements uploaded. Maybe the year is wrong? If the data should be uploaded
                                anyway,
                                try {{ info.nameOldData }}.
                            </div>
                        }
                        @case ('ERROR_UNHANDLED_DATA') {
                            <div>
                                Some rows contains invalid data. If the data should overwrite existing entries,
                                try {{ info.nameAllowUpdates }}.
                            </div>
                        }
                        @case ('ERROR_SQL') {
                            <p> SQL error</p>
                        }
                        @case ('ERROR_UNKNOWN') {
                            <p> Unknown error</p>
                        }
                        @case ('ERROR_UNKNOWN_FILETYPE') {
                            <p> Unknown type of input data.</p>
                        }
                        @default {
                            <p>Unhandled error type {{ importResult?.error }}</p>
                        }
                    }
                </div>
                @if (failedKeys) {
                    <div>
                        <table>
                            <caption>Rows with error</caption>
                            <thead>
                            <tr>
                                @for (n of failedKeys; track n) {
                                    <th>{{ n }}</th>
                                }
                            </tr>
                            </thead>
                            <tbody>
                                @for (r of importResult?.failed_rows; track r) {
                                    <tr>
                                        @for (n of failedKeys; track n) {
                                            <td>{{ r[n] | json }}</td>
                                        }
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                }
            </mat-card-content>
        </mat-card>
    }
    <!-- Upload accepted -->
    @if (!error && importResult) {
        <mat-card class="result">
            <mat-card-header>
                <mat-card-title>
                    Upload accepted
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p>{{ importResult.message }}</p>
                <p>Rows read: {{ importResult.rows }} </p>
                <p>Create count: {{ importResult.create_count }}</p>
                <p>Update count: {{ importResult.update_count }}</p>
                @if ((importResult.update_count + importResult.create_count) > 1) {
                    <p>
                        Object data updated. The statistics will be outdated for these objects. Initiate statistics
                        update when
                        all
                        new data (objects and measurements) have been uploaded.
                    </p>
                }
                @if (importResult.object_change_warnings) {
                    <div>
                        @if (objects(); as os) {
                            Reference level or inclination changed for the following objects, maybe the objects measurements
                                must be
                                re-fetched from the source:
                            <ul>
                                @for (o of objectsInNameList(os, importResult.object_change_warnings); track o) {
                                    <li>
                                        {{ o.name }} Has correlation: {{ hasCorrelation(o) ? 'true' : 'false' }}
                                    </li>
                                }
                            </ul>
                        }
                    </div>
                }
            </mat-card-content>
        </mat-card>
    }
}
