import {Component, computed, effect} from '@angular/core'
import {toSignal} from "@angular/core/rxjs-interop"

import {map} from "rxjs"

import {VideDataService} from 'src/app/api/vide-data.service'
import {TIME_WEIGHT_MAX_DAYS} from 'src/constants'
import {TREND_NR_DAYS} from "../../plot/map-plot/map-plot-data.service"
import {joinWithEnding} from "../../shared/vide-helper"
import {DataStatus} from "../../api/api-types"
import {ConstantNameComponent} from "../../shared/constant-name/constant-name.component"

@Component({
    selector: 'app-plot-info',
    standalone: true,
    templateUrl: './plot-info.component.html',
    styleUrls: ['./plot-info.component.scss'],
    imports: [
        ConstantNameComponent,
    ],
})
export class PlotInfoComponent {
    private dataStatusMap: Map<string, string> = new Map()
    protected readonly dataStatuses = toSignal(this.dataService.utility$.pipe(map(u => u.data_status)), {initialValue: []})
    protected readonly dataStatusNames = computed(() => this.dataStatuses()?.map(s => s.name))

    constructor(
        private dataService: VideDataService,
    ) {
        effect(() => {
            const dataStatuses = this.dataStatuses()
            this.dataStatusMap.clear()
            dataStatuses.forEach(s => this.dataStatusMap.set(s.constant_name, s.name))
        })
    }

    dataStatusName(constant_name: DataStatus['constant_name']) {
        return this.dataStatusMap.get(constant_name)
    }

    protected readonly TIME_WEIGHT_MAX_DAYS = TIME_WEIGHT_MAX_DAYS
    protected readonly TREND_NR_DAYS = TREND_NR_DAYS
    protected readonly joinWithEnding = joinWithEnding
}
