import {ErrorHandler, isDevMode} from "@angular/core"
import {MatSnackBar} from "@angular/material/snack-bar"

export class VideErrorHandler extends ErrorHandler {
    constructor(private readonly snack: MatSnackBar) {
        super()
    }

    override handleError(error: any): void {
        if (isDevMode()) {
            this.snack.open(error, 'Dismiss', {duration: 5 * 1000})
        }
        super.handleError(error)
    }
}
