import {AbstractControl, FormBuilder, ReactiveFormsModule, ValidatorFn, Validators} from "@angular/forms"
import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogModule} from "@angular/material/dialog"
import {MatButtonModule} from "@angular/material/button"
import {MatInputModule} from "@angular/material/input"

import {VideObject} from "../../../api/api-types"
import {getErrorMessage} from "../../../shared/vide-helper"

@Component({
    selector: 'app-new-object-name-dialog',
    standalone: true,
    imports: [
        MatButtonModule,
        MatDialogModule,
        MatInputModule,
        ReactiveFormsModule
    ],
    templateUrl: './new-object-name-dialog.component.html',
    styleUrl: './new-object-name-dialog.component.scss'
})
export class NewObjectNameDialogComponent {
    private readonly objectNameValidator: ValidatorFn = (control: AbstractControl) => {
        const value = control.value
        if (typeof value !== 'string') {
            return null
        }
        const name = value.trim()
        return this.data?.objects.find(o => o.name === name) ? {nameExists: true} : null
    }

    result?: string
    control = this.fb.control('', [Validators.required, this.objectNameValidator])

    constructor(
        private readonly fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: {
            title: string,
            objects: readonly VideObject[],
            positiveText: string,
            suggestion?: string,
        },
    ) {
        this.control.setValue(data?.suggestion ?? null)
    }

    protected readonly getErrorMessage = getErrorMessage
}
