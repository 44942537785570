import {AbstractControl, AsyncValidatorFn, FormBuilder, FormControl, Validators} from '@angular/forms'
import {BreakpointObserver} from "@angular/cdk/layout"
import {DestroyRef, Injectable} from '@angular/core'
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop"

import {
    BehaviorSubject,
    combineLatest,
    combineLatestWith,
    debounceTime,
    delay,
    filter,
    finalize,
    first,
    firstValueFrom,
    forkJoin,
    map,
    Observable,
    of,
    shareReplay,
    startWith, switchAll,
    switchMap,
} from 'rxjs'
import {Icons, ModeBarButton} from "plotly.js-dist-min"
import {PlotlyService} from "angular-plotly.js"

import {FORM_DEBOUNCE_TIME, PLOT_CONFIG} from 'src/constants'
import {AuthService} from '../auth/auth.service'
import {CoordinateTransformService} from "../shared/coordinate-transform.service"
import {Counter} from '../shared/counter'
import {UrlCacheService} from '../shared/url-cache.service'
import {
    assertNever,
    compareName,
    dateToDateTimeString,
    dateToString,
    isDefined,
    isNotNull,
    mapFromArray,
    parseFloatOrThrow,
    parseIntOrThrow
} from '../shared/vide-helper'
import {
    ExtendedVideObjectV2,
    Nullable,
    Observed,
    omit,
    omitEndsWith,
    PlotlyConfig,
    PlotOption,
    Unpacked,
} from '../vide-types'
import {ApiCallService} from './api-call.service'
import {ABILITY} from "../ability"
import {
    Aquifer,
    CoordinateDeterminationMethod,
    CoordinateSystem,
    Correlation,
    DatabaseUtilities,
    DataCorrelationStatus,
    DataStatus,
    DeviationStatus,
    ErrorCode,
    ExtendedMeasurementResponse,
    FilterType,
    HeightSystem,
    Measurement,
    MeasurementResponse,
    MeasureType,
    MeasureUnit,
    ObjectCategory,
    ObjectStatus,
    ObjectType,
    Operator,
    PipeMaterial,
    Project,
    ProjectFull,
    ProjectWithLimit,
    SettlementPosition,
    Statistics,
    TipType,
    UserOptions,
    VideObject
} from "./api-types"
import {DateLimit, DatePeriod, LimitStorage} from "./limit-storage"
import {ObjectRouteSelectionService} from "./object-route-selection.service"

class Utility implements DatabaseUtilities {
    // Rename properties to the singular, so we can append _id to
    // get the associated id field.
    readonly aquifer: Aquifer[]
    readonly coordinate_determination_method: CoordinateDeterminationMethod[]
    readonly coordinate_system: CoordinateSystem[]
    readonly data_correlation_status: DataCorrelationStatus[]
    readonly data_status: DataStatus[]
    readonly deviation_status: DeviationStatus[]
    readonly error_code: ErrorCode[]
    readonly filter_type: FilterType[]
    readonly height_system: HeightSystem[]
    readonly measure_type: MeasureType[]
    readonly measure_unit: MeasureUnit[]
    readonly object_category: ObjectCategory[]
    readonly object_status: ObjectStatus[]
    readonly object_type: ObjectType[]
    readonly operator: Operator[]
    readonly pipe_material: PipeMaterial[]
    readonly settlement_position: SettlementPosition[]
    readonly tip_type: TipType[]

    getValueById<U extends keyof DatabaseUtilities>(id: number | null, tpe: U) {
        return id ? this.maps[tpe].get(id) ?? null : null
    }

    getValueByIdOrFail<T, U extends keyof DatabaseUtilities>(id: number, tpe: U) {
        let value = this.getValueById(id, tpe)
        if (value === null) {
            throw new Error("Cannot find value ")
        }
        return value
    }

    getValue<
        T extends { [W in U as `${U}_id`]: number | null },
        U extends keyof DatabaseUtilities
    >(m: T, tpe: U) {
        // by type of T and U
        const att = tpe + '_id' as keyof T
        const id = m[att] as number
        const ret = this.maps[tpe].get(id)
        return ret ?? null
    }

    // getByConstantName<
    //     U extends keyof DatabaseUtilities,
    //     T extends string
    //     // T extends U[number] extends infer V { constant_name: string } ?  V  : never
    //     // T extends { [W in U as `constant_name`]: number | null },
    // >(tpe: U, m: T) {
    //     // by type of T and U
    //     this[tpe].find(x=>x.constant_name===m)
    //     const att = tpe + '_id' as keyof T
    //     const id = m[att] as number
    //     const ret = this.maps[tpe].get(id)
    //     return ret ?? null
    // }

    getValueOrFail<
        T extends { [W in U as `${U}_id`]: number | null },
        U extends keyof DatabaseUtilities
    >(m: T, arg1: U) {
        const ret = this.getValue(m, arg1)
        if (ret === null) {
            throw new Error("Cannot find value ")
        }
        return ret
    }

    private readonly maps: { [K in keyof DatabaseUtilities]: Map<number, Unpacked<DatabaseUtilities[K]>> }

    constructor(source: DatabaseUtilities) {
        this.aquifer = source.aquifer
        this.coordinate_system = source.coordinate_system
        this.coordinate_determination_method = source.coordinate_determination_method
        this.data_correlation_status = source.data_correlation_status
        this.data_status = source.data_status
        this.deviation_status = source.deviation_status
        this.error_code = source.error_code
        this.filter_type = source.filter_type
        this.height_system = source.height_system
        this.measure_type = source.measure_type
        this.measure_unit = source.measure_unit
        this.object_category = source.object_category
        this.object_status = source.object_status
        this.object_type = source.object_type
        this.operator = source.operator
        this.pipe_material = source.pipe_material
        this.settlement_position = source.settlement_position
        this.tip_type = source.tip_type

        this.maps = {
            aquifer: mapFromArray(source.aquifer),
            coordinate_determination_method: mapFromArray(source.coordinate_determination_method),
            coordinate_system: mapFromArray(source.coordinate_system),
            data_correlation_status: mapFromArray(source.data_correlation_status),
            data_status: mapFromArray(source.data_status),
            deviation_status: mapFromArray(source.deviation_status),
            error_code: mapFromArray(source.error_code),
            filter_type: mapFromArray(source.filter_type),
            height_system: mapFromArray(source.height_system),
            measure_type: mapFromArray(source.measure_type),
            measure_unit: mapFromArray(source.measure_unit),
            object_category: mapFromArray(source.object_category),
            object_status: mapFromArray(source.object_status),
            object_type: mapFromArray(source.object_type),
            operator: mapFromArray(source.operator),
            pipe_material: mapFromArray(source.pipe_material),
            settlement_position: mapFromArray(source.settlement_position),
            tip_type: mapFromArray(source.tip_type),
        }
    }

}

type AdminAbility = "Vide admin" | "License owner" | "Project admin"

/**
 * Service responsible for loading project and object data.
 *
 * Set the `pid` parameter to fetch the project data. Use the `selectionModel`
 * property to select objects. Selected objects are available in the
 * `selectedObjects$` property.
 *
 * The observable properties (names end with $) fall into three categories:
 *
 * 1: Static properties. They do not change during app use. E.g., utility$
 *
 * 2: User properties. They depend on the current user. Call `reloadUserData()` to initiate update when a change has been
 *      saved. This is the majority of properties.
 *
 * 3: Project properties: They depend on the selected project. Call `reloadProjectData()` to initiate update when a
 *      change has been saved.
 *
 * The functions that return an observable will emit once and complete (they work as wrappers for `HttpClient.get()`)
 *
 */
@Injectable({
    providedIn: 'root',
})
export class VideDataService {
    readonly validateHttpRequestControl = this.apiCall.validateHttpRequestControl
    //////////////////////////////////////////////////////////////////////////////
    // Self
    //////////////////////////////////////////////////////////////////////////////
    /** The logged-in user */
    readonly user$ = this.auth.user$
    /** User options */
    readonly userOptions$ = this.user$.pipe(
        switchMap(() => this.apiCall.getSelfOptions()),
    )

    /** Update the options in the argument. Other options are left as is. */
    updateSelfOptions(options: Nullable<Partial<UserOptions>>) {
        return this.apiCall.getSelfOptions().pipe(
            switchMap(old => {
                return this.apiCall.updateSelfOptions({...old, ...options})
            }),
        )
    }

    updateSelfName = this.apiCall.updateSelfName.bind(this.apiCall)

    //////////////////////////////////////////////////////////////////////////////
    // Licenses NB: only for license owners, others will get nothing
    //////////////////////////////////////////////////////////////////////////////
    /** User property */
    readonly licenses$ = this.user$.pipe(
        filter(isNotNull),
        switchMap(_ => this.apiCall.getLicenses())
    )
    createLicense = this.apiCall.createLicense.bind(this.apiCall)
    updateLicense = this.apiCall.updateLicense.bind(this.apiCall)

    //////////////////////////////////////////////////////////////////////////////
    // Admin User
    //////////////////////////////////////////////////////////////////////////////
    /**
     * The users `owned` by the current user by owning their license.
     *
     * User property.
     */
    readonly users$ = this.user$.pipe(
        switchMap(() => this.apiCall.getOwnedUsers()),
        combineLatestWith(this.licenses$),
        map(([users, licenses]) => {
            return users.map(u => {
                const license = licenses.find(l => l.id === u.license_id)
                if (!license) {
                    throw Error(`License ${u.license_id} not found`)
                }
                return {
                    license,
                    ...u
                }
            })
        }),
    )

    /** User property */
    readonly knownUsers$ = this.user$.pipe(
        switchMap(() => {
            return this.apiCall.getKnownUsers()
        })
    )

    updateUser = this.apiCall.updateUser.bind(this.apiCall)
    deleteUser = this.apiCall.deleteUser.bind(this.apiCall)
    createUser = this.apiCall.createUser.bind(this.apiCall)

    readonly emailExistsValidator: AsyncValidatorFn = (control: AbstractControl) => {
        return of((control.value as string).trim()).pipe(
            /**
             * If the input changes before this delay is passed, angular will
             * unsubscribe to this and issue a new one, so only the last one will pass
             * to the actual getting and checking.
             */
            delay(500),
            switchMap(email => this.checkUserExists(email)),
            map(exists => {
                return exists ? {emailExists: true} : null
            }),
        )
    }

    checkUserExists(email: string) {
        const e = email.trim().toLocaleLowerCase()
        return this.knownUsers$.pipe(
            first(),
            map(users => {
                console.log(users)
                return users.some(u => u.email.toLocaleLowerCase() === e)
            }),
        )
    }

    //////////////////////////////////////////////////////////////////////////////
    // Plot options for user
    //////////////////////////////////////////////////////////////////////////////

    savePlotOptions = this.apiCall.savePlotOptions.bind(this.apiCall)
    deletePlotOptions = this.apiCall.deletePlotOptions.bind(this.apiCall)
    updatePlotOptions = this.apiCall.updatePlotOptions.bind(this.apiCall)

    /** User options */
    readonly plotOptions$ = this.user$.pipe(
        filter(isNotNull),
        switchMap(_ => this.apiCall.getPlotOptions().pipe(
            map(x => {
                const map1 = x.map(x => {
                    const ret: Readonly<PlotOption> = {id: x.id, name: x.name, options: JSON.parse(x.options)}
                    return ret
                })
                return map1 as ReadonlyArray<PlotOption>
            }),
        ))
    )

    /**
     * Some properties that are more general and common for most Plotly plots in the app.
     *
     * Applies both to style and config, so far.
     */
    readonly plotlyConfigForm = this.formBuilder.nonNullable.group({
        style: this.formBuilder.nonNullable.group({
            bigMap: [false],
        }),
        config: this.formBuilder.nonNullable.group({
            toImage: this.formBuilder.nonNullable.group({
                width: [850, Validators.min(100)],
                height: [500, Validators.min(100)],
                filename: ['VidePlot', Validators.required],
            }),
        }),
        layout: this.formBuilder.nonNullable.group({}),
        data: this.formBuilder.nonNullable.group({}),
        dateZoom: this.formBuilder.nonNullable.group({
            interval: [0, Validators.required],
            period: ['months' as DatePeriod, Validators.required]
        })
    })

    /**
     * Get the date range set in user config, as period ending today. If not set, return undefined.
     */
    getPlotlyDefaultXRange() {
        const values = this.plotlyConfigForm.controls.dateZoom.getRawValue()
        const value = values.interval
        if (value === 0) {
            return undefined
        }
        const start = new Date()
        const nowAsString = dateToString(start)
        const period = values.period
        switch (period) {
            case "days":
                start.setDate(start.getDate() - value)
                break
            case "weeks":
                start.setDate(start.getDate() - value * 7)
                break
            case "months":
                start.setMonth(start.getMonth() - value)
                break
            case "years":
                start.setFullYear(start.getFullYear() - value)
                break
            default:
                assertNever(period)
        }
        return [dateToString(start), nowAsString]
    }

    get plotlyOptions() {
        return this.plotlyConfigForm.getRawValue()
    }

    set plotlyOptions(value: ReturnType<typeof this.plotlyConfigForm.getRawValue>) {
        this.plotlyConfigForm.patchValue(value)
    }

    get popupRef() {
        return this._popupRef.value
    }

    set popupRef(value: Window | null) {
        this._popupRef.next(value)
    }

    private readonly _popupRef = new BehaviorSubject<Window | null>(null)
    readonly popupRef$ = this._popupRef.asObservable()

    /** @deprecated */
    readonly bigMapControl = new FormControl(false, {nonNullable: true})

    readonly plotlyStyle$ = this.bigMapControl.valueChanges.pipe(
        startWith(this.bigMapControl.value),
        map(bigMap => bigMap ? PLOT_CONFIG.plotlyStyleBig : PLOT_CONFIG.plotlyStyle),
        shareReplay(1),
    )

    /** Admin property */
    /** @deprecated Why? Refactor to a better way. Maybe a separate config service? */
    readonly plotlyConfig$ = this.plotlyConfigForm.controls.config.valueChanges.pipe(
        startWith(true),
        map(_v => {
            const value = this.plotlyConfigForm.controls.config.getRawValue()
            const ret: PlotlyConfig = {
                // showLink: true, // text link on plot
                showEditInChartStudio: true, // icon in modebar
                plotlyServerURL: "https://chart-studio.plotly.com",
                // showLink: true,
                // linkText: 'This text is custom!' + imageSize?.width + 'x' + imageSize?.height,
                modeBarButtonsToRemove: [
                    // 'pan2d',
                    // 'zoom2d',
                    'toImage',
                    'select2d',
                    'lasso2d',
                    'resetScale2d',
                ],
                modeBarButtonsToAdd: [
                    'toggleSpikelines',
                    // 'zoom2d',
                    this.plotlySnapshotButton,
                ],
                // Skip the size options, we use the default, the current plot size. The size option is for the manual save button.
                toImageButtonOptions: {filename: value.toImage.filename},
            }
            return ret
        }),
    )

    readonly plotlyToImage$ = this.plotlyConfigForm.controls.config.valueChanges.pipe(
        startWith(true),
        debounceTime(FORM_DEBOUNCE_TIME),
        map(_ => {
            const value = this.plotlyConfigForm.controls.config.getRawValue()
            return {
                toImageOptions: value.toImage,
            }
        }),
    )

    private readonly projectCounter = new Counter()
    /** The number of outstanding http calls related to projects, objects or groups. */
    readonly projectWaiting$ = this.projectCounter.waiting$

    private readonly _selectedPid$ = new BehaviorSubject<number | null>(null) // change to ReplaySubject?
    // TODO: change the operations so we can set pid to undefined, and get project=undefined pushed out, i.e. unset project in the app.
    private readonly selectedPid$ = this._selectedPid$.pipe()

    /**
     * Load a new project by setting this property to the project id. Setting
     * to the current value does nothing.
     *
     * @param pid project id
     */
    set pid(pid: number | null) {
        const oldPid = this._selectedPid$.value
        if (pid !== oldPid) {
            if (oldPid) {
                // Clear if we change project, but not if there was not one before, i.e., first load or reload
                this.selectionModel.clear()
            }
            this._selectedPid$.next(pid)
        } else {
            console.debug(`Setting pid to same value or undefined, ${pid}, no action taken`)
        }
    }

    get pid() {
        return this._selectedPid$.value
    }

    /**
     * Get the utility object. Will not emit until there is a logged-in user,
     * and then complete after emitting once. This is a shared observable.
     *
     * Static property
     */
    readonly utility$ = this.user$.pipe(
        switchMap((_u) => {
            return this.apiCall.getUtilities()
        }),
        map(array => new Utility(array)),
        // map(array => new Utility(array) as Immutable<Utility>),
        // map(x => x),
        first(),
        shareReplay(1),
    )

    /////////////////////////////////////////////////////////
    // General stuff
    /////////////////////////////////////////////////////////

    readonly dataStatusStandard$ = this.utility$.pipe(
        map(u => {
            const type = u.data_status.find(t => t.constant_name === 'data_status_standard')
            if (!type) {
                throw new Error('No data status found')
            }
            return type
        }),
    )
    readonly measureTypeDiverUncompensated$ = this.utility$.pipe(
        map(u => {
            const type = u.measure_type
                .find(t => t.constant_name === 'measure_type_diver_uncompensated')
            if (!type) {
                throw new Error('No measure type found')
            }
            return type
        }),
    )
    readonly measureTypeDiverUnreferenced$ = this.utility$.pipe(
        map(u => {
            const type = u.measure_type
                .find(t => t.constant_name === 'measure_type_diver_unreferenced')
            if (!type) {
                throw new Error('No measure type found')
            }
            return type
        }),
    )
    readonly objectTypeWell$ = this.utility$.pipe(
        map(u => {
            const type = u.object_type
                .find(t => t.name === 'Observation well')
            if (!type) {
                throw new Error('No object type found')
            }
            return type
        }),
    )
    readonly objectStatusActive$ = this.utility$.pipe(
        map(u => {
            const type = u.object_status
                .find(t => t.constant_name === 'object_status_active')
            if (!type) {
                throw new Error('No object status found')
            }
            return type
        }),
    )


    /**
     * Vide version information. Will not emit until there is a logged-in user,
     * and then complete after emitting once.
     *
     * Static property
     */
    readonly version$ = this.user$.pipe(
        switchMap(() => this.apiCall.getVersion()),
    )

    plotlySnapshotButton: ModeBarButton = {
        name: 'snapshot',
        click: this.snapshot.bind(this),
        icon: Icons.camera,
        // icon: 'asdf',
        title: 'Snapshot',
    }

    private async snapshot(gd: any) {
        const pl = await this.plotlyService.getPlotly()
        if (!pl) {
            return Promise.reject("No plotly found")
        }
        const filename = this.plotlyConfigForm.controls.config.controls.toImage.controls.filename.value
            + '-' + dateToDateTimeString()
        const y = await pl.downloadImage(gd, {filename})
        console.log(`Saved ${y}`)
    }


    constructor(
        private readonly apiCall: ApiCallService,
        private readonly auth: AuthService,
        private readonly urlCache: UrlCacheService,
        private readonly formBuilder: FormBuilder,
        private readonly transformer: CoordinateTransformService,
        private readonly destroyRef: DestroyRef,
        readonly selectionModel: ObjectRouteSelectionService,
        private readonly breakpointObserver: BreakpointObserver,
        private readonly plotlyService: PlotlyService,
    ) {
        // Use options from user,
        this.subscribeToUserOptions()
        this.updateStatisticsOnSelectObject()
    }

    private updateStatisticsOnSelectObject() {
        combineLatest([
            this.freshOutdated$,
            this.selectionModel.changed,
        ]).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async ([outdated, change]) => {
            const intersection = change.added.filter(id => outdated.object_ids.includes(id))
            // console.warn(intersection)
            let anySuccess = false
            for (let index = 0; index < intersection.length; index++) {
                const id = intersection[index]!
                console.warn(`Updating statistics for ${id}`)
                const ret = await firstValueFrom(this.apiCall.updateStatisticsForObject(id))
                anySuccess = anySuccess || ret.success
            }
            if (anySuccess) {
                console.log(`Updated statistics for object(s), reloading`)
                this.reloadProjectData()
            }
        })
    }

    private subscribeToUserOptions() {
        this.user$.pipe(
            switchMap(() => this.apiCall.getSelfOptions()),
            takeUntilDestroyed(),
        ).subscribe(options => {
            this.plotlyConfigForm.patchValue({
                config: {toImage: options ?? undefined},
                dateZoom: options?.dateZoom,
            })
        })
    }

    reloadProjectData() {
        this.urlCache.purge()
        this._selectedPid$.next(this._selectedPid$.value)
    }

    reloadUserData() {
        this.urlCache.purge()
        this.auth.reloadUser()
    }

    //////////////////////////////////////////////////////////////////////////////
    // Import stuff
    //////////////////////////////////////////////////////////////////////////////
    /**
     * Info about available file import types.
     *
     * Static property
     */
    readonly importInfo$ = this.user$.pipe(
        switchMap(() => this.apiCall.getImportInfo())
    )
    getImportGoteborgStations = this.apiCall.getImportGoteborgStations.bind(this.apiCall)
    getImportUnosonLocations = this.apiCall.getImportUnosonLocations.bind(this.apiCall)
    //////////////////////////////////////////////////////////////////////////////
    // External sources: SGU, SMHI, ???
    //////////////////////////////////////////////////////////////////////////////
    createExternalObject = this.apiCall.createExternalObject.bind(this.apiCall)
    getExternalObjects = this.apiCall.getExternalObjects.bind(this.apiCall)
    deleteExternalSource = this.apiCall.deleteExternalSource.bind(this.apiCall)
    // //////////////////////////////////////////////////////////////////////////////
    // // Unoson sources
    // //////////////////////////////////////////////////////////////////////////////
    // createUnoson = this.apiCall.createUnoson.bind(this.apiCall)
    // // getUnoson = this.apiCall.getUnoson.bind(this.apiCall)
    // // deleteUnoson = this.apiCall.deleteUnoson.bind(this.apiCall)

    //////////////////////////////////////////////////////////////////////////////
    // Coordinate transform
    //////////////////////////////////////////////////////////////////////////////
    /** Converter from EPSG:3006 to WGS84. Static property. */
    readonly converter3006$ = this.utility$.pipe(
        map(u => {
            const c = u.coordinate_system.find(c => c.id === 3006)
            if (!c) throw Error("Cannot find coordinate system EPSG:3006")
            return this.transformer.getWgsConverter(c)
        }),
    )

    /**
     * Converter from EPSG to WGS84. Static property.
     * @deprecated Confusing coordinates
     */
    getConverter(epsg: number) {
        return this.utility$.pipe(
            map(u => {
                const c = u.coordinate_system.find(c => c.id === epsg)
                if (!c) throw Error(`Cannot find coordinate system EPSG ${epsg}`)
                return this.transformer.getWgsConverter(c)
            }),
        )
    }

    /**
     * Converter from EPSG to WGS84. Static property.
     */
    getConverterToWgs(epsg: number) {
        return this.utility$.pipe(
            map(u => {
                const c = u.coordinate_system.find(c => c.id === epsg)
                if (!c) throw Error(`Cannot find coordinate system EPSG ${epsg}`)
                return this.transformer.getTransformerToWgs(c)
            }),
        )
    }

    /**
     * Converter from EPSG to WGS84. Static property.
     */
    getConverterFromWgs(epsg: number) {
        return this.utility$.pipe(
            map(u => {
                const c = u.coordinate_system.find(c => c.id === epsg)
                if (!c) throw Error(`Cannot find coordinate system EPSG ${epsg}`)
                return this.transformer.getTransformerFromWgs(c)
            }),
        )
    }


    //////////////////////////////////////////////////////////////////////////////
    // Project users
    //////////////////////////////////////////////////////////////////////////////
    getProjectUsers = this.apiCall.getProjectUsers.bind(this.apiCall)
    setProjectUser = this.apiCall.setProjectUser.bind(this.apiCall)
    newProjectUser = this.apiCall.newProjectUser.bind(this.apiCall)
    removeProjectUser = this.apiCall.removeProjectUser.bind(this.apiCall)

    //////////////////////////////////////////////////////////////////////////////
    // Projects
    //////////////////////////////////////////////////////////////////////////////

    /**
     * Projects the user has access to as read, measure, write or admin.
     *
     * User property
     */
    readonly projects$ = this.user$.pipe(
        filter(isNotNull),
        combineLatestWith(this.utility$),
        switchMap(([_u, utility]) => {
            this.projectCounter.increase()
            return this.apiCall.getUsersProjects().pipe(
                finalize(() => this.projectCounter.decrease()),
                map(x => {
                    return x
                        .map(p => {
                            return {
                                ...p,
                                height_system: utility.getValueOrFail(p, 'height_system'),
                                coordinate_system: utility.getValueOrFail(p, 'coordinate_system')
                            }
                        })
                        .sort((a, b) => a.name.localeCompare(b.name))
                })
            )
        })
    )
    /**
     * Projects where user is the license owner
     *
     * User property
     */
    readonly ownedProjects$ = this.user$.pipe(
        filter(isNotNull),
        combineLatestWith(this.utility$),
        switchMap(([_u, utility]) => {
            this.projectCounter.increase()
            return this.apiCall.getOwnedProjects().pipe(
                finalize(() => this.projectCounter.decrease()),
                map(x => {
                    return x
                        .map(p => {
                            return {
                                ...p,
                                height_system: utility.getValueOrFail(p, 'height_system'),
                                coordinate_system: utility.getValueOrFail(p, 'coordinate_system')
                            }
                        })
                        .sort((a, b) => a.name.localeCompare(b.name))
                })
            )
        })
    )
    /**
     * Projects you own or admin
     *
     * User property
     */
    readonly editableProjects$ =
        combineLatest([
            this.projects$,
            this.ownedProjects$
        ]).pipe(
            // Small wait, so both inputs have updated when reloaded. Is there a better way?
            debounceTime(100),
            map(([projects, ownedProjects]) => {
                const adminP = projects.filter(p => p.ability === 'Admin')
                const allP = [...adminP, ...ownedProjects]
                const map = new Map<number, Project>()
                allP.forEach(p => map.set(p.id, p))

                return Array.from(map.values()).sort(compareName)
            })
        )

    private readonly limit: LimitStorage = new LimitStorage()

    setLimit(limit: DateLimit) {
        const p = this.project()
        if (!p) {
            console.error("No project")
            return
        }
        this.limit.setLimit(p, limit)
    }

    getLimit = this.limit.getLimit.bind(this.limit)
    getLimitDescription = this.limit.getDescription.bind(this.limit)

    /** Currently selected project  */
    readonly project$ = this.selectedPid$.pipe(
        combineLatestWith(this.projects$),
        map(([pid, ps]) => {
            if (!pid) return null
            // console.warn(`Search for selected project, ${pid}`, ps)
            const projectttt = ps.find(p => p.id === pid)
            if (projectttt === undefined) {
                console.error(`Project ${pid} not found`)
                throw new Error("Project not found")
            }
            // console.warn(`found project `, p)
            return projectttt
        }),
        switchMap(project => {
            return this.limit.getLimitAsDateString(project).pipe(
                map(limit => {
                    const dateLimit = limit ?? null
                    return project ? {...project, dateLimit} : null
                })
            )
        }),
        // tap(x=>{}),
        // combineLatestWith(this.limit.limit$),
        // map(([project, limit]) => {
        //
        //     return limit ? {...project, limit} : project
        // }),
        shareReplay(1),
    )
    readonly projectNotNull$ = this.project$.pipe(filter(isNotNull))

    updateProject = this.apiCall.updateProject.bind(this.apiCall)
    createProject = this.apiCall.createProject.bind(this.apiCall)
    deleteProject = this.apiCall.deleteProject.bind(this.apiCall)

    /** User property */
    readonly userAdminAbility$ = combineLatest([
        this.user$,
        this.licenses$,
        this.projects$,
    ]).pipe(
        map(([user, licenses, projects]) => {
            const ability = new Set<AdminAbility>()
            if (licenses.length > 0) ability.add("License owner")
            if (projects.some(p => p.ability === ABILITY.ADMIN.name())) ability.add("Project admin")
            if (user.vide_admin) ability.add("Vide admin")
            return ability
        }),
    )


    //////////////////////////////////////////////////////////////////////////////
    // Import file
    //////////////////////////////////////////////////////////////////////////////

    /**
     * File upload. This request reports events to monitor the upload. It
     * does not catch any errors.
     *
     * Note that the returned Observable must be subscribed to.
     *
     */
    importFile = this.apiCall.importFile.bind(this.apiCall)

    //////////////////////////////////////////////////////////////////////////////
    // Objects
    //////////////////////////////////////////////////////////////////////////////
    private getStatisticsMap(project: ProjectFull) {
        return this.apiCall.getStatistics(project.id).pipe(
            combineLatestWith(this.utility$),
            map(([statistics, utility]) => {
                return makeStatisticsMap(statistics, utility)
            })
        )
    }

    private getObjectsNoStatistics(project: ProjectFull) {
        return this.apiCall.getObjects(project.id).pipe(
            combineLatestWith(this.utility$),
            map(([objects, utility]) => {
                const converter =
                    project.coordinate_system.unit === 'metre'
                        ? this.transformer.getTransformerFromWgs(project.coordinate_system)
                        : null
                return objects.map(o => {
                    let positionM = null
                    let position = null
                    if (o.latitude !== null && o.longitude !== null) {
                        position = {
                            lat: o.latitude,
                            lon: o.longitude,
                        }
                        positionM = converter ? converter(position) : null
                    }

                    return {
                        ...omitEndsWith(omit(o, 'latitude', 'longitude'), '_id'),
                        project_id: o.project_id,
                        aquifer: utility.getValue(o, 'aquifer'),
                        position,
                        positionM,
                        object_status: utility.getValueOrFail(o, 'object_status'),
                        object_type: utility.getValueOrFail(o, 'object_type'),
                        coordinate_determination_method: utility.getValue(o, 'coordinate_determination_method'),
                        filter_type: utility.getValue(o, 'filter_type'),
                        pipe_material: utility.getValue(o, 'pipe_material'),
                        settlement_position: utility.getValue(o, 'settlement_position'),
                        tip_type: utility.getValue(o, 'tip_type'),
                    }
                })
            }),
        )
    }

    getObjectsWithStatistics(project: ProjectFull) {
        const o$ = this.getObjectsNoStatistics(project)
        const s$ = this.getStatisticsMap(project)
        return forkJoin([o$, s$]).pipe(
            map(([objects, sMap]) => {
                return objects.map(o => {
                    const ret: VideObject = {
                        ...o,
                        statistics: sMap.get(o.id) ?? [],
                    }
                    return ret
                })
            }),
        )
    }

    /** All objects in the selected project. */
    readonly objects$ = this.projectNotNull$.pipe(
        switchMap(p => this.getObjectsWithStatistics(p)),
        shareReplay({bufferSize: 1, refCount: true}),
    )

    /**
     * Currently selected objects.  Use `selectionModel` to alter the selection.
     * This observable is shared.
     * Project property.
     */
    readonly selectedObjects$ = combineLatest([
        this.objects$,
        this.selectionModel.changed.pipe(startWith(true)),
    ]).pipe(
        map(([os]) => {
            return os.filter(o => this.selectionModel.isSelected(o.id))
        }),
        shareReplay({bufferSize: 1, refCount: true}),
    )

    /**
     * Returns a project dependent observable.
     */
    getExtendedObject(project: Project, object: VideObject): Observable<ExtendedVideObjectV2> {
        return combineLatest([
            this.apiCall.getObject(project, object.id),
            this.objects$,
            this.utility$,
        ]).pipe(
            // tap(x => { console.warn('transforming object ', x) }),
            map(([o, allObjects, utility]) => {
                const correlations = o.correlations.map(c => {
                    const ref_object = allObjects.find(o => o.id === c.ref_object_id)
                    const ref_measure_type = utility.measure_type.find(m => m.id === c.ref_measure_type_id)
                    if (!ref_object || !ref_measure_type) {
                        throw new Error("Objects not found")
                    } else {
                        // TODO: some kind of S.assert here? Or S.mask?
                        const ret: Correlation = {
                            ...c,
                            object: object,
                            ref_object,
                            measure_type: utility.getValueOrFail(c, 'measure_type'),
                            ref_measure_type,
                        }
                        return ret
                    }
                })
                const ret: ExtendedVideObjectV2 = {
                    ...object,
                    correlations,
                    validated_correlations: o.validated_correlations,
                    statistics: o.statistics,
                }
                return ret
            }),
            // tap(x => {            }),
        )
    }

    // getObjects = this.apiCall.getObjects.bind(this.apiCall)
    updateObject = this.apiCall.updateObject.bind(this.apiCall)
    createObject = this.apiCall.createObject.bind(this.apiCall)
    deleteObject = this.apiCall.deleteObject.bind(this.apiCall)

    //////////////////////////////////////////////////////////////////////////////
    // Shared objects
    //////////////////////////////////////////////////////////////////////////////
    getLentObjects = this.apiCall.getLentObjects.bind(this.apiCall)
    /** Store object as lent to project */
    setSharedObject = this.apiCall.setSharedObject.bind(this.apiCall)
    // /**     @deprecated     */
    // updateSharedObjects = this.apiCall.updateSharedObjects.bind(this.apiCall)
    /** Remove object as lent to project */
    deleteSharedObject = this.apiCall.deleteSharedObject.bind(this.apiCall)

    //////////////////////////////////////////////////////////////////////////////
    // Object files
    //////////////////////////////////////////////////////////////////////////////

    createFile = this.apiCall.createFile.bind(this.apiCall)
    getObjectFiles = this.apiCall.getObjectFiles.bind(this.apiCall)
    deleteFile = this.apiCall.deleteFile.bind(this.apiCall)
    getFileUrl = this.apiCall.getFileUrl.bind(this.apiCall)

    //////////////////////////////////////////////////////////////////////////////
    // Measurements
    //////////////////////////////////////////////////////////////////////////////
    getMeasurement = this.apiCall.getMeasurement.bind(this.apiCall)

    /** Get all existing measurements for the object of this measure type. Will emit once and complete. */
    getExtendedMeasurements(project: ProjectWithLimit, object: VideObject, measureType: MeasureType) {
        return this.getMeasurements(project, object, measureType).pipe(
            map(x => {
                const ret: ExtendedMeasurementResponse = {
                    ...x,
                    object: object,
                    project,
                }
                return ret
            }),
        )
    }

    /**
     * Get all existing measurements for the object of this measure type.
     * Using the old JSON format
     *
     * Will emit once and complete.
     */
    getMeasurementsJson(project: ProjectWithLimit, object: VideObject, measureType: MeasureType) {
        return this.apiCall.getMeasurementsJson(project, object, measureType).pipe(
            combineLatestWith(this.utility$),
            map(([x, utility]) => {
                const xxx = x.measurements.map(m => {
                    const ret: Measurement = {
                        ...m,
                        data_correlation_status: utility.getValue(m, 'data_correlation_status'),
                        data_status: utility.getValueOrFail(m, 'data_status'),
                        error_code: utility.getValue(m, 'error_code'),
                        measure_type: utility.getValueOrFail(m, 'measure_type'),
                        // operator: utility.getValue(m, 'operator'),
                    }
                    return ret
                })
                const ret: MeasurementResponse = {
                    measure_type: x.measure_type,
                    measurements: xxx,
                }
                return ret
            }),
            // tap(x => {            }),
        )
    }

    /**
     * Get all existing measurements for the object of this measure type.
     * Using CSV format
     *
     * Will emit once and complete.
     */
    getMeasurements(project: ProjectWithLimit, object: Pick<VideObject, 'id'>, measureType: MeasureType) {
        function getNullableInt(
            row: Unpacked<ReturnType<ApiCallService['getMeasurementsCsv']>>,
            key: string
        ) {
            const id = row[key]
            if (id && id !== '') {
                return parseIntOrThrow(id)
            }
            return null
        }

        function getNullableFloat(
            row: Unpacked<ReturnType<ApiCallService['getMeasurementsCsv']>>,
            key: string
        ) {
            const id = row[key]
            if (id && id !== '') {
                return parseFloatOrThrow(id)
            }
            return null
        }

        function getString(row: Unpacked<ReturnType<ApiCallService['getMeasurementsCsv']>>, key: string, nullable: false): string
        function getString(row: Unpacked<ReturnType<ApiCallService['getMeasurementsCsv']>>, key: string, nullable: true): string | null
        function getString(row: Unpacked<ReturnType<ApiCallService['getMeasurementsCsv']>>, key: string, nullable: boolean) {
            const id = row[key]
            if (nullable) {
                if (id && id !== '') {
                    return id
                }
                return null
            } else {
                if (!id) {
                    throw new Error(`Missing value for ${key}`)
                }
                return id
            }
        }

        return this.apiCall.getMeasurementsCsv(project, object, measureType).pipe(
            combineLatestWith(this.utility$),
            map(([response, utility]) => {
                function getNullableUtility<U extends keyof DatabaseUtilities>(
                    row: Unpacked<ReturnType<ApiCallService['getMeasurementsCsv']>>,
                    key: U
                ) {
                    const id = row[key + '_id']
                    if (id === '') {
                        return null
                    }
                    return utility.getValueById(parseIntOrThrow(id), key)
                }

                function getUtility<U extends keyof DatabaseUtilities>(
                    row: Unpacked<ReturnType<ApiCallService['getMeasurementsCsv']>>,
                    key: U,
                ) {
                    const id = row[key + '_id']
                    return utility.getValueByIdOrFail(parseIntOrThrow(id), key)
                }

                const xxx = response.map(row => {
                    const ret: Measurement = {
                        comment: getString(row, 'comment', true),
                        data_correlation_status: getNullableUtility(row, 'data_correlation_status'),
                        data_status: getUtility(row, 'data_status'),
                        // diver_installation_id: getNullableInt(row, 'diver_installation_id'),
                        error_code: getNullableUtility(row, 'error_code'),
                        id: parseIntOrThrow(row['id']),
                        last_changed_by: getString(row, 'last_changed_by', true),
                        measure_type: measureType,
                        measured_value: getNullableFloat(row, 'measured_value'),
                        measuretime: getString(row, 'measuretime', false),
                        resulting_value: getNullableFloat(row, 'resulting_value'),
                        source: getString(row, 'source', true),
                        time_changed: getString(row, 'time_changed', false),
                        time_created: getString(row, 'time_created', false),
                    }
                    return ret
                })
                const ret: MeasurementResponse = {
                    measure_type: measureType,
                    measurements: xxx,
                }
                return ret
            }),
            // tap(x => {            }),
        )
    }

    updateMeasurements = this.apiCall.updateMeasurements.bind(this.apiCall)
    updateMeasurementField = this.apiCall.updateMeasurementField.bind(this.apiCall)
    createFieldMeasurement = this.apiCall.createFieldMeasurement.bind(this.apiCall)
    // /** @deprecated */
    // createMeasurementsOLD = this.apiCall.createMeasurementsOLD.bind(this.apiCall)
    createMeasurements = this.apiCall.createMeasurements.bind(this.apiCall)
    // deleteMeasurement = this.apiCall.deleteMeasurement.bind(this.apiCall)
    deleteMeasurements = this.apiCall.deleteMeasurements.bind(this.apiCall)

    //////////////////////////////////////////////////////////////////////////////
    // Diver stuff
    //////////////////////////////////////////////////////////////////////////////
    createDiverAnnotations = this.apiCall.createDiverAnnotations.bind(this.apiCall)
    updateDiverAnnotation = this.apiCall.updateDiverAnnotation.bind(this.apiCall)
    deleteDiverAnnotation = this.apiCall.deleteDiverAnnotation.bind(this.apiCall)

    getDiverAnnotations(project: Project, object: Pick<VideObject, "id">) {
        return this.apiCall.getDiverAnnotations(project, object).pipe(
            map(annotations => annotations.map(a => {
                if (a.reference_measurement_id) {
                    return this.getMeasurement(project, a.reference_measurement_id).pipe(map(m => ({
                        ...a,
                        reference_measurement: m,
                    })))
                }
                return of(a)
            })),
           switchMap(x=>forkJoin(x)),
        )
    }

    //////////////////////////////////////////////////////////////////////////////
    // Statistics
    //////////////////////////////////////////////////////////////////////////////

    /**
     * Objects where there is valid data that is newer than the statistics row.
     *
     * NB: this is not a shared observable. If subscribed to, it will fetch the data again (and if there has been a
     * recent post/patch, it will thus re-fetch from the source, as the local cache has been purged).
     *
     * Project property.
     */
    private readonly outdatedStatistics$ = combineLatest([
        this.project$.pipe(filter(isDefined),),
        this.objects$,
    ]).pipe(
        // tap(x => { console.warn(x) }),
        switchMap(([project, objects]) =>
            this.apiCall.getOutdatedStatisticObjectIds(project).pipe(
                // tap(x => { console.log(`Outdated statistics (${x.object_ids.length}):`, x) }),
                map(({object_ids: oids}) => {
                    return objects.filter(o => oids.includes(o.id))
                    // return objects.filter(o => oids.includes(o.id)) as ReadonlyArray<VideObjectV2>
                }),
                // tap(x => { console.log(`Outdated statistics (${x.length}):`, x) }),
            )),
        // tap(x => { console.log(`Outdated statistics (${x.length}):`, x) }),
    )
    readonly freshOutdated$ = this.project$.pipe(
        filter(isDefined),
        switchMap(p => this.apiCall.getOutdatedStatisticObjectIds(p, true)),
    )

    updateStatisticsCurrentProject() {
        if (this.pid) return this.apiCall.updateStatisticsProjectId(this.pid)
        throw new Error("No project id")
    }

    //////////////////////////////////////////////////////////////////////////////////
    // Links
    //////////////////////////////////////////////////////////////////////////////////


    /**
     *
     * @param target
     * @param options {project,object} project is not necessary to use the current project
     */
    videLink(
        target: keyof typeof VIDE_ROUTES2,
        options?: {
            object?: VideObject,
            project?: Project
        }): string[] {
        try {
            const targetArray = VIDE_ROUTES2[target]
            return targetArray.map(element => {
                if (element === projectSymbol) {
                    const pid = options?.project?.id ?? this.pid
                    if (!pid) throw Error("pid not found")
                    return pid.toString()
                } else if (element === objectSymbol) {
                    const oid = options?.object?.id
                    if (!oid) throw Error("object missing")
                    return oid.toString()
                } else {
                    return element
                }
            })
        } catch (e) {
            console.error(e)
            return ['']
        }
    }

    //////////////////////////////////////////////////////////////////////////////
    // Groups
    //////////////////////////////////////////////////////////////////////////////
    readonly groups$ = this.project$.pipe(
        switchMap(p => {
            if (!p) return []
            this.projectCounter.increase()
            return this.apiCall.getGroups(p).pipe(
                finalize(() => {
                    this.projectCounter.decrease()
                }))
        }),
    )

    getGroups = this.apiCall.getGroups.bind(this.apiCall)
    createGroup = this.apiCall.createGroup.bind(this.apiCall)
    deleteGroup = this.apiCall.deleteGroup.bind(this.apiCall)
    updateGroup = this.apiCall.updateGroup.bind(this.apiCall)

    //////////////////////////////////////////////////////////////////////////////////
    // Triggers
    //////////////////////////////////////////////////////////////////////////////////
    getTriggers(project: Project, object: VideObject) {
        return this.apiCall.getTriggers(project, object).pipe(
            combineLatestWith(this.utility$),
            map(([triggers, utility]) =>
                triggers.map(t => {
                    return {
                        ...omit(t, 'measure_type_id'),
                        x: t.limit,
                        measureType: utility.getValueOrFail(t, 'measure_type')
                    }
                })),
        )
    }

    createTrigger = this.apiCall.createTrigger.bind(this.apiCall)
    updateTrigger = this.apiCall.updateTrigger.bind(this.apiCall)
    deleteTrigger = this.apiCall.deleteTrigger.bind(this.apiCall)

    //////////////////////////////////////////////////////////////////////////////////
    // Signals
    //////////////////////////////////////////////////////////////////////////////////
    /**
     * These should be the only signals created in this service, as it creates a subscription on the observable, and
     * will not let go.
     * But these will be necessary everywhere, and the app cannot do just anything without them.
     */
    readonly project = toSignal(this.project$)
    readonly selectedObjects = toSignal(this.selectedObjects$, {initialValue: []})
    readonly user = toSignal(this.auth.user$)
    readonly outdatedStatistics = toSignal(this.outdatedStatistics$, {initialValue: []})
    readonly smallScreen = toSignal(this.breakpointObserver.observe(['screen and (max-width: 960px)']).pipe(
        map(x => x.matches),
    ), {initialValue: false})

}

const projectSymbol = Symbol('projectId')
const objectSymbol = Symbol('objectId')
const VIDE_ROUTES2 = {
    root: ['/'],
    help: ['help'],
    project: ['/project', projectSymbol],
    correlation: ['/project', projectSymbol, 'correlations'],
    popupSelect: ['/project', projectSymbol, 'popup-select'],
    objects: ['/project', projectSymbol, 'objects'],
    measurements: ['/project', projectSymbol, 'measurements'],
    importFile: ['/project', projectSymbol, 'import', 'file'],
    importDiver: ['/project', projectSymbol, 'import', 'diver'],
    importShares: ['/project', projectSymbol, 'import', 'shared'],
    importShares2: ['/project', projectSymbol, 'import', 'shared2'],
    importSgu: ['/project', projectSymbol, 'import', 'sgu'],
    importSmhi: ['/project', projectSymbol, 'import', 'smhi'],
    importGeoarkivet: ['/project', projectSymbol, 'import', 'geoarkivet'],
    importGoteborg: ['/project', projectSymbol, 'import', 'goteborg'],
    unoson: ['/project', projectSymbol, 'import', 'unoson'],
    batchPlot: ['/project', projectSymbol, 'batch-plot'],
    fieldApp: ['/project', projectSymbol, 'field-app'],
    groups: ['/project', projectSymbol, 'groups'],
    events: ['/project', projectSymbol, 'events'],
    editObject: ['/project', projectSymbol, 'object', objectSymbol, 'edit'],
    objectTriggers: ['/project', projectSymbol, 'object', objectSymbol, 'triggers'],
    adminProjects: ['/admin', 'projects'],
    adminUsers: ['/admin', 'users'],
    adminLicenses: ['/admin', 'licenses'],
    about: ['/about'],
    preferences: ['/preferences'],
} as const

function makeStatisticsMap(
    statistics: Observed<ReturnType<ApiCallService['getStatistics']>>,
    utility: Utility,
) {
    const map = new Map<number, Statistics[]>()
    statistics.forEach(s => {
        const ret: Statistics = {
            ...s,
            measure_type: utility.getValueOrFail(s, 'measure_type'),
        }
        const list = map.get(s.object_id) ?? new Array<Statistics>()
        if (!map.has(s.object_id)) {
            map.set(s.object_id, list)
        }
        list.push(ret)
    })
    return map

}

