@if (projectWaiting()) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}

    @if (project()?.coordinate_system; as cs) {
<table mat-table matSort recycleRows [dataSource]="dataSource2()">
    <ng-template matNoDataRow> No objects</ng-template>
    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
            />
        </th>
        <td mat-cell *matCellDef="let row">
            @if (!row.readonly) {
                <mat-checkbox (click)="$event.stopPropagation()"
                              (change)="$event ? selection.toggle(row.id) : null"
                              [checked]="selection.isSelected(row.id)"
                />
            }
        </td>
    </ng-container>
        @if (cs.unit === 'metre') {
            <ng-container matColumnDef="ew">
                <th mat-header-cell *matHeaderCellDef matTooltip="East-west-coordinate {{cs.name}}">E/W</th>
                <td mat-cell *matCellDef="let row">
                    @if (getCellStatus(row, 'ew'); as status) {
                        <span class="vide-editable-value" [class.dirty]="status.dirty">{{ status.value }}</span>
                    }
                </td>
            </ng-container>
            <ng-container matColumnDef="ns">
                <th mat-header-cell *matHeaderCellDef matTooltip="North-south-coordinate {{cs.name}}">N/S</th>
                <td mat-cell *matCellDef="let row">
                    @if (getCellStatus(row, 'ns'); as status) {
                        <span class="vide-editable-value" [class.dirty]="status.dirty">{{ status.value }}</span>
                    }
                </td>
            </ng-container>
        } @else {
            <ng-container matColumnDef="ew">
                <th mat-header-cell *matHeaderCellDef matTooltip="Latitude">Lat</th>
                <td mat-cell *matCellDef="let row">
                    {{ row.position?.lat }}
                </td>
            </ng-container>
            <ng-container matColumnDef="ns">
                <th mat-header-cell *matHeaderCellDef matTooltip="Longitude">Lon</th>
                <td mat-cell *matCellDef="let row">
                    {{ row.position?.lon }}
                </td>
            </ng-container>
        }
    @for (def of objectColumnsFirst.concat(objectColumnsLast).concat(correlationColumns); track def) {
        <ng-container [matColumnDef]="def">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                [matTooltip]="getTooltip(def)"> {{ def | headerCase }}
            </th>
            <td mat-cell *matCellDef="let row">
                @if (getCellStatus(row, def); as status) {
                    <span class="vide-editable-value" [class.dirty]="status.dirty">{{ status.value }}</span>
                }
            </td>
        </ng-container>
    }
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay()"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay()"></tr>
</table>
    }


@if (pageSize(); as pageSize) {
    <mat-paginator
        showFirstLastButtons
        [length]="pageSize.length"
        [pageSizeOptions]="pageSize.options"
        (page)="page($event)"
        [pageSize]="10"
    />
}

<mat-toolbar>
    @if (ABILITY.WRITE.availableFor(project())) {
        <button mat-raised-button [disabled]="canDeactivate()" (click)="save()">Save changes</button>
        <button mat-raised-button [disabled]="canDeactivate()" (click)="resetEdits()">Reset</button>
        <button mat-raised-button [disabled]="selection.selected.length<1" (click)="editSelected()">
            Edit selected rows
        </button>
        <button mat-raised-button (click)="addObject()">Add object</button>
        <span class="toolbar-spacer"></span>
    }

    <button mat-raised-button
            matTooltip="Export objets as stored. Unsasved edits (highlighted in the table) are not exported."
            (click)="exportData(objects())"
    >
        Export
    </button>
</mat-toolbar>

<app-log-list [container]="log"/>
