import {Injectable, NgZone, OnDestroy} from '@angular/core'

import {BroadcastChannelWrapper} from "./broadcast-channel-wrapper"
import {BroadcastType} from "./broadcast-type"

export interface BroadcastInterfaceObjectIds {
    added: number[]
    removed: number[]
}

export interface BroadcastInterfaceAction {
    action: 'close' | 'test'
    target: string
}

export interface BroadcastInterfaceQueryParams {
    [key: string]: string
}

export type BroadcastInterfaceString = string

@Injectable({
    providedIn: 'root'
})
export class BroadcastService implements OnDestroy {

    private readonly channels = new Map<string, BroadcastChannelWrapper<any>>

    constructor(private readonly ngZone: NgZone) {
    }

    get(x: BroadcastType.STRING, tag: string): BroadcastChannelWrapper<BroadcastInterfaceString>
    get(x: BroadcastType.OBJECT_IDS, tag: string): BroadcastChannelWrapper<BroadcastInterfaceObjectIds>
    get(x: BroadcastType.ACTION, tag: string): BroadcastChannelWrapper<BroadcastInterfaceAction>
    get(x: BroadcastType.QUERY_PARAMS, tag: string): BroadcastChannelWrapper<BroadcastInterfaceQueryParams>
    get(x: BroadcastType.ANY, tag: string): BroadcastChannelWrapper<any>
    get(type: BroadcastType, tag: string): BroadcastChannelWrapper<any> {
        const key = `${type}-${tag}`
        if (!this.channels.has(key)) {
            this.channels.set(key, new BroadcastChannelWrapper(this.ngZone, key))
        }
        return this.channels.get(key)!
    }

    ngOnDestroy(): void {
        Object.entries(this.channels).forEach(([key, channel]) => {
            channel.close()
        })
    }

    destroy(b: BroadcastChannelWrapper<any>) {
        b.close()
        this.channels.delete(b.tag)
    }
}
