import { Component } from '@angular/core';
import {MenuSelectionComponent} from "../menu-selection/menu-selection.component"

@Component({
  selector: 'app-public-data',
  standalone: true,
    imports: [
        MenuSelectionComponent
    ],
  templateUrl: './public-data.component.html',
  styleUrl: './public-data.component.scss'
})
export class PublicDataComponent {

}
