import {AfterViewInit, Component, viewChild} from '@angular/core'
import {MatButtonModule} from "@angular/material/button"
import {MatCardModule} from "@angular/material/card"
import {MatDialog} from "@angular/material/dialog"
import {MatIconModule} from "@angular/material/icon"
import {MatSort, MatSortModule} from "@angular/material/sort"
import {MatTableDataSource, MatTableModule} from "@angular/material/table"
import {MatTooltipModule} from "@angular/material/tooltip"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"

import {ComponentCanDeactivate} from "../../can-deactivate.guard"
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
    ConfirmDialogResult
} from "../../dialogs/confirm-dialog/confirm-dialog.component"
import {FailedJob} from "../../api/api-types"
import {LogContainer} from "../../log-list/log-container"
import {SortedTextColumnComponent} from "../../shared/sorted-text-column/sorted-text-column.component"
import {Unpacked} from "../../vide-types"
import {VideDataService} from "../../api/vide-data.service"
import {firstValueFrom} from "rxjs"

@Component({
    selector: 'app-failed-jobs',
    imports: [
        SortedTextColumnComponent,

        MatButtonModule,
        MatCardModule,
        MatIconModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
    ],
    templateUrl: './failed-jobs.component.html',
    styleUrl: './failed-jobs.component.scss'
})
export class FailedJobsComponent extends ComponentCanDeactivate implements AfterViewInit {
    protected readonly dataSource = new MatTableDataSource<Unpacked<typeof this.dataService.failedJobs$>>()

    protected readonly logs = new LogContainer()
    private readonly sort = viewChild.required(MatSort)

    override canDeactivate(): boolean {
        // Nothing here to keep actually
        return true
    }

    columnsToDisplay = [
        'id',
        'uuid',
        'connection',
        'queue',
        'jobName',
        'displayPayload',
        'exception',
        'failed_at',
        'retry',
        'delete',
    ]

    constructor(
        private readonly dataService: VideDataService,
        private readonly dialog: MatDialog,
    ) {
        super()
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
            switch (sortHeaderId) {
                // case 'license':
                //     return data[sortHeaderId].name
                default:
                    return (data as any)[sortHeaderId] ?? ''

            }
        }
        this.dataService.failedJobs$.pipe(takeUntilDestroyed()).subscribe(jobs => {
            this.dataSource.data = jobs
        })
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort() ?? null
    }

    displayPayload(job: FailedJob) {
        this.dialog.open<ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogResult
        >(ConfirmDialogComponent, {
            data:
                {
                    header: `Payload for job ${job.id}`,
                    textPre: JSON.stringify(job.payload, null, 2),
                    negative_text: null,
                    positive_text: null,
                }
        })
    }

    async deleteJob(job: FailedJob) {
        const res = await firstValueFrom(this.dataService.deleteFailedJob(job))
        if (res.success) this.dataService.reloadUserData()
        this.logs.add(res, `Delete failed job ${job.id}`)
    }

    displayException(job: FailedJob) {
        this.dialog.open<ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogResult
        >(ConfirmDialogComponent, {
            data:
                {
                    header: `Exception for job ${job.id}`,
                    textPre: job.exception,
                    negative_text: null,
                    positive_text: null,
                }
        })

    }

    async retryJob(job: FailedJob) {
        const res = await firstValueFrom(this.dataService.retryFailedJob(job))
        if (res.success) this.dataService.reloadUserData()
        this.logs.add(res, `Retry failed job ${job.id}`)

    }
}
