import {AbstractControl, FormBuilder, ReactiveFormsModule, ValidatorFn, Validators} from "@angular/forms"
import {AfterViewInit, Component, ElementRef, isDevMode, viewChild} from '@angular/core'
import {MatButtonModule} from "@angular/material/button"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatInputModule} from "@angular/material/input"
import {MatStepperModule, MatStepperNext} from "@angular/material/stepper"
import {MatTabsModule} from "@angular/material/tabs"
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop"

import {NgSelectModule} from "@ng-select/ng-select"
import {first, map, startWith} from "rxjs"

import {DiverService} from "../diver.service"
import {SlimObject,} from "../diver.component"
import {VideDataService} from "../../../api/vide-data.service"
import {getErrorMessage} from "../../../shared/vide-helper"
import {InputComponent} from "../../../forms/input/input.component"

@Component({
    selector: 'app-select-diver-object',
    standalone: true,
    imports: [
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatStepperModule,
        MatTabsModule,
        NgSelectModule,
        ReactiveFormsModule,
        InputComponent,
    ],
    templateUrl: './select-diver-object.component.html',
    styleUrl: './select-diver-object.component.scss',
})
export class SelectDiverObjectComponent implements AfterViewInit {
    // inputs
    // outputs
    // view children
    protected readonly nextButton = viewChild.required(MatStepperNext, {read: ElementRef})
    // signals
    protected readonly objects = toSignal(this.dataService.objects$, {initialValue: []})
    // properties
    private readonly objectNameValidator: ValidatorFn = (control: AbstractControl) => {
        const value = control.value
        if (typeof value !== 'string') {
            return null
        }
        const name = value.trim()
        return this.objects().find(o => o.name === name) ? {nameExists: true} : null
    }
    protected readonly form = this.fb.nonNullable.group({
        object: [null as null | SlimObject, Validators.required],
        name: ['', [Validators.required, this.objectNameValidator, Validators.minLength(3), Validators.maxLength(255)]],
    })
    private readonly selectedObject$ = this.form.controls.object.valueChanges.pipe(
        startWith(null),
        map(() => this.form.controls.object.value),
    )

    constructor(
        private readonly dataService: VideDataService,
        private readonly diverService: DiverService,
        private readonly fb: FormBuilder,
    ) {
        this.selectedObject$.pipe(takeUntilDestroyed()).subscribe(object => {
            this.diverService.setObject(object)
            if (object) this.nextStep()
        })
    }

    protected async newObject() {
        const control = this.form.controls.name
        control.markAllAsTouched()
        if (control.invalid) return
        const res = await this.diverService.createNewObject(control.value)
        if (res.success) {
            this.form.patchValue({object: res.data})
        }
    }

    protected readonly getErrorMessage = getErrorMessage

    localNext() {
        this.form.markAllAsTouched()
    }

    private nextStep() {
        const el = this.nextButton().nativeElement
        if (el instanceof HTMLButtonElement) {
            setTimeout(() => {
                el.click()
            }, 10)
        }

    }

    ngAfterViewInit(): void {
        if (isDevMode()) {
            this.dataService.objects$.pipe(first()).subscribe(objects => {
                console.warn("dev action in place")
                this.form.patchValue({object: objects.find(o => o.name === 'qwer11')})
            })
        }
    }
}
