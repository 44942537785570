import { Component, Input } from '@angular/core'

import { LogContainer } from './log-container'
import { MatList, MatListItem } from '@angular/material/list';
import { MatCard } from '@angular/material/card';

export interface LogMessage {
    error: boolean
    message: string
}


@Component({
    selector: 'app-log-list',
    templateUrl: './log-list.component.html',
    styleUrls: ['./log-list.component.scss'],
    standalone: true,
    imports: [
        MatCard,
        MatList,
        MatListItem,
    ],
})
export class LogListComponent {
    @Input() container: LogContainer | null = null

    constructor() {
    }

}
