import {Routes} from '@angular/router'

import {canDeactivateGuard} from "./can-deactivate.guard"
import {AdminLicensesComponent} from "./admin/licenses/admin-licenses.component"
import {ResetPasswordComponent} from "./auth/reset-password/reset-password.component"
import {WelcomeComponent} from "./welcome/welcome.component"
import {PreferencesComponent} from "./preferences/preferences.component"
import {AboutComponent} from "./about/about.component"
import {TestComponent} from "./test/test.component"
import {ProjectContainerComponent} from "./project-container/project-container.component"
import {ProjectComponent} from "./project/project.component"
import {PopupSelectComponent} from "./object-select/popup-select/popup-select.component"
import {TriggersComponent} from "./triggers/triggers.component"
import {EditObjectComponent} from "./edit-object/edit-object.component"
import {BatchPlotComponent} from "./plot/batch-plot/batch-plot.component"
import {FieldMeasurementsComponent} from "./field-measurements/field-measurements.component"
import {ViewMeasurementsComponent} from "./view-measurements/view-measurements.component"
import {ViewObjectsComponent} from "./view-objects/view-objects.component"
import {EditGroupsContainerComponent} from "./edit-groups/edit-groups-container/edit-groups-container.component"
import {EditGroupsExistingComponent} from "./edit-groups/edit-groups-existing/edit-groups-existing.component"
import {EditGroupsNamesComponent} from "./edit-groups/edit-groups-names/edit-groups-names.component"
import {EditGroupsObjectsComponent} from "./edit-groups/edit-groups-objects/edit-groups-objects.component"
import {ImportFileComponent} from "./import/import-file/import-file.component"
import {DiverComponent} from "./import/diver/diver.component"
import {SguComponent} from "./import/sgu/sgu.component"
import {SmhiComponent} from "./import/smhi/smhi.component"
import {GeoarkivetComponent} from "./import/geoarkivet/geoarkivet.component"
import {GoteborgComponent} from "./import/goteborg/goteborg.component"
import {PageNotFoundComponent} from "./page-not-found/page-not-found.component"
import {CorrelationAnalysisComponent} from "./correlation-analysis/correlation-analysis.component"
import {SetPasswordComponent} from "./auth/set-password/set-password.component"
import {UnosonComponent} from "./import/unoson/unoson.component"
import {AdminUsersComponent} from "./admin/admin-users/admin-users.component"
import {AdminProjectsComponent} from "./admin/admin-projects/admin-projects.component"
import {SharedObjectsComponent} from "./import/shared-objects/shared-objects.component"
import {FailedJobsComponent} from "./admin/failed-jobs/failed-jobs.component"
import {FieldComponent} from "./field/field.component"

const VIDE_ROUTES = {
    project: {
        _base: 'project',
        correlations: 'correlations',
        popupSelect: 'popup-select',
        objects: 'objects',
        measurements: 'measurements',
        import: 'import',
        batchPlot: 'batch-plot',
        fieldApp: 'field-app',
        fieldApp2: 'field-app2',
        groups: 'groups',
        // editObject: 'edit-object',
        events: 'events',
        object: {
            _base: 'object',
            triggers: 'triggers',
            edit: 'edit',
        }
    },
    about: 'about',
    preferences: 'preferences',
} as const

// TODO: make VIDE_ROUTES2 contain this info, and transform it to the Routes object!
export const oldRoutes: Routes = [
    {
        path: '',
        // component: VideContainerComponent,
        // canActivate: [AuthGuard],
        children: [
            {path: '', component: WelcomeComponent},
            // {path: 'help', component: HelpComponent,},
            // {path: 'help/public-data', component: PublicDataComponent},
            // {path: 'help/ability', component: AbilityComponent},
            // {path: 'help/plots', component: PlotInfoComponent},
            {
                path: 'admin',
                children: [
                    {path: 'licenses', component: AdminLicensesComponent, canDeactivate: [canDeactivateGuard]},
                    {path: 'users', component: AdminUsersComponent, canDeactivate: [canDeactivateGuard]},
                    {path: 'projects', component: AdminProjectsComponent, canDeactivate: [canDeactivateGuard]},
                    {path: 'failed-jobs', component: FailedJobsComponent, canDeactivate: [canDeactivateGuard]},
                ],
            },
            {path: 'reset-password', component: ResetPasswordComponent},
            {path: 'set-password', component: SetPasswordComponent},
            {
                path: VIDE_ROUTES.preferences,
                component: PreferencesComponent,
                canDeactivate: [canDeactivateGuard]
            },
            {path: VIDE_ROUTES.about, component: AboutComponent},
            {path: 'test', component: TestComponent},
            {
                path: VIDE_ROUTES.project._base + '/:pid',
                component: ProjectContainerComponent,
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        component: ProjectComponent,
                    },
                    {path: VIDE_ROUTES.project.correlations, component: CorrelationAnalysisComponent},
                    {
                        path: VIDE_ROUTES.project.popupSelect,
                        component: PopupSelectComponent,
                    },
                    {
                        path: VIDE_ROUTES.project.object._base + '/:oid',
                        children: [
                            {
                                path: VIDE_ROUTES.project.object.triggers,
                                component: TriggersComponent,
                                canDeactivate: [canDeactivateGuard],
                            },
                            {
                                path: VIDE_ROUTES.project.object.edit,
                                component: EditObjectComponent,
                                canDeactivate: [canDeactivateGuard],
                            }
                        ],
                    },
                    {
                        path: VIDE_ROUTES.project.batchPlot,
                        component: BatchPlotComponent
                    },
                    {
                        path: VIDE_ROUTES.project.fieldApp,
                        component: FieldMeasurementsComponent,
                        canDeactivate: [canDeactivateGuard],
                    }, {
                        path: VIDE_ROUTES.project.fieldApp2,
                        component: FieldComponent,
                        canDeactivate: [canDeactivateGuard],
                    },
                    {
                        path: VIDE_ROUTES.project.measurements,
                        component: ViewMeasurementsComponent,
                        canDeactivate: [canDeactivateGuard]
                    },
                    {
                        path: VIDE_ROUTES.project.objects,
                        component: ViewObjectsComponent,
                        canDeactivate: [canDeactivateGuard],
                    },
                    {
                        path: 'groups', component: EditGroupsContainerComponent,
                        children: [
                            {path: '', redirectTo: 'existing', pathMatch: 'full'},
                            {
                                path: 'existing',
                                component: EditGroupsExistingComponent,
                                canDeactivate: [canDeactivateGuard]
                            },
                            {
                                path: 'names',
                                component: EditGroupsNamesComponent,
                                canDeactivate: [canDeactivateGuard]
                            },
                            {
                                path: 'selected',
                                component: EditGroupsObjectsComponent,
                                canDeactivate: [canDeactivateGuard]
                            },
                        ],
                    },

                    {
                        path: VIDE_ROUTES.project.import,
                        children: [
                            {path: 'file', component: ImportFileComponent, canDeactivate: [canDeactivateGuard]},
                            {path: 'diver', component: DiverComponent, canDeactivate: [canDeactivateGuard]},
                            {path: 'shared2', component: SharedObjectsComponent, canDeactivate: [canDeactivateGuard]},
                            {path: 'sgu', component: SguComponent, canDeactivate: [canDeactivateGuard]},
                            {path: 'smhi', component: SmhiComponent, canDeactivate: [canDeactivateGuard]},
                            {
                                path: 'geoarkivet',
                                component: GeoarkivetComponent,
                                canDeactivate: [canDeactivateGuard]
                            },
                            {
                                path: 'goteborg',
                                component: GoteborgComponent,
                                canDeactivate: [canDeactivateGuard]
                            },
                            {
                                path: 'unoson',
                                component: UnosonComponent,
                                canDeactivate: [canDeactivateGuard]
                            }
                        ],
                    },
                ]
            },

        ],
    },
    {path: '**', component: PageNotFoundComponent},
]

// export const routes: Routes = []
