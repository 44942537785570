import {Component, Inject} from '@angular/core'
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms"
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogModule, MatDialogRef} from "@angular/material/dialog"
import {MatButtonModule} from "@angular/material/button"
import {MatCardModule} from "@angular/material/card"
import {MatDividerModule} from "@angular/material/divider"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatIconModule} from "@angular/material/icon"
import {MatInputModule} from "@angular/material/input"
import {MatTooltipModule} from "@angular/material/tooltip"
import {toSignal} from "@angular/core/rxjs-interop"

import {License, UnosonCredential} from "../../../api/api-types"
import {VideDataService} from "../../../api/vide-data.service"
import {getErrorMessage} from "../../../shared/vide-helper"
import {LogListComponent} from "../../../log-list/log-list.component"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {LogContainer} from "../../../log-list/log-container"

export type AddLicenseComponentData = MatDialogConfig<License>['data']
export type AddLicenseComponentResult = boolean

// export type AddLicenseComponentResult = Parameters<VideDataService['createLicense']>['0']

@Component({
    selector: 'app-add-license',
    standalone: true,
    imports: [
        LogListComponent,

        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressBarModule,
        MatTooltipModule,
        ReactiveFormsModule,
    ],
    templateUrl: './add-license.component.html',
    styleUrl: './add-license.component.scss'
})
export class AddLicenseComponent {
    protected readonly users = toSignal(this.dataService.users$)
    protected readonly userAbility = toSignal(this.dataService.userAdminAbility$)
    protected readonly logs = new LogContainer()

    protected readonly form = this.formBuilder.nonNullable.group({
        capacity: this.formBuilder.nonNullable.group({
            read: [0, [Validators.required, Validators.min(0)]],
            measure: [0, [Validators.required, Validators.min(0)]],
            write: [0, [Validators.required, Validators.min(0)]],
            admin: [0, [Validators.required, Validators.min(1)]],
        }),
        comment: ['' as null | string],
        name: ['', [Validators.required, Validators.minLength(4)]],
        ownerEmail: [null as null | string],
        unosonCredentials: this.formBuilder.nonNullable.array<ReturnType<AddLicenseComponent['getCredentialForm']>>([]),
    })
    protected waiting = false

    private getCredentialForm(credential?: UnosonCredential) {
        const group = this.formBuilder.nonNullable.group({
            name: ['', Validators.required],
            client_id: ['', Validators.required],
            client_secret: ['', Validators.required],
            username: ['', Validators.required],
            password: ['', Validators.required],
        })
        if (credential) {
            group.patchValue(credential)
        }
        return group
    }

    constructor(
        private readonly dataService: VideDataService,
        private readonly formBuilder: FormBuilder,
        private readonly ref: MatDialogRef<AddLicenseComponent, AddLicenseComponentResult>,
        @Inject(MAT_DIALOG_DATA) public data: AddLicenseComponentData,
    ) {
        if (data) {
            this.form.patchValue(data)
            this.form.patchValue({ownerEmail: data.owner})
            this.form.patchValue({
                capacity: {
                    admin: data.limit.Admin,
                    write: data.limit.Write,
                    measure: data.limit.Measure,
                    read: data.limit.Read,
                }
            })
            data.unoson_sources.forEach(source => {
                const uForm = this.getCredentialForm(source)
                this.form.controls.unosonCredentials.push(uForm)
            })
        }
    }

    save(data: License) {
        this.form.markAllAsTouched()
        if (!this.form.valid) {
            console.log("Form is not valid")
            return
        }
        const values = this.form.getRawValue()

        this.dataService.updateLicense(data, values).subscribe({
            next: res => {
                console.log(res)
                this.logs.add(res, `Update license '${values.name}'`)
                if (res.success) {
                    this.dataService.reloadUserData()
                    this.ref.close(true)
                }
            },
            complete: () => {
                console.log('Complete')
            }
        })
    }

    protected readonly getErrorMessage = getErrorMessage

    addConnection() {
        this.form.controls.unosonCredentials.push(this.getCredentialForm())
    }

    deleteCredential(index: number) {
        this.form.controls.unosonCredentials.removeAt(index)
    }

    add() {
        this.form.markAllAsTouched()
        if (!this.form.valid) {
            console.log("Form is not valid")
            return
        }
        const values = this.form.getRawValue()

        this.waiting = true
        this.dataService.createLicense(values).subscribe({
            next: res => {
                console.log(res)
                this.logs.add(res, `Add license '${values.name}'`)
                if (res.success) {
                    this.dataService.reloadUserData()
                    this.ref.close(true)
                }
            },
            complete: () => {
                console.log('Complete')
            }
        })
    }
}
