<h1 mat-dialog-title>Add object</h1>

@if (utility(); as utility) {
    <mat-dialog-content>
        @if (project(); as project) {
            Add a new object to
            <em>{{ project.name }}</em>.
                Coordinate system {{ project.coordinate_system.name }}, height system {{ project.height_system.name }}
        }
        <form [formGroup]="form">
            <mat-form-field>
                <mat-label>Name</mat-label>
                <input matInput type="text" formControlName="name">
                @if (form.controls.name.pending) {
                    <mat-hint>
                        Checking...
                        <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
                    </mat-hint>
                }
                <mat-error>{{ getErrorMessage(form.controls.name) }}</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Alias</mat-label>
                <input matInput type="text" formControlName="alias">
                <mat-error>{{ getErrorMessage(form.controls.alias) }}</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Object status</mat-label>
                <select matNativeControl formControlName="object_status">
                    @for (s of utility.object_status; track s) {
                        <option [ngValue]="s"> {{ s.name }}</option>
                    }
                </select>
                <mat-error>{{ getErrorMessage(form.controls.object_status) }}</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Object type</mat-label>
                <select matNativeControl formControlName="object_type">
                    @for (item of groupedOptionsMap() | keyvalue; track item) {
                        <optgroup [label]="item.key">
                            @for (t of item.value; track t) {
                                <option [ngValue]="t"> {{ t.name }}</option>
                            }
                        </optgroup>
                    }
                </select>
                <mat-error>{{ getErrorMessage(form.controls.object_type) }}</mat-error>
            </mat-form-field>
            <span formGroupName="positionM">
                    <mat-form-field>
                        <mat-label>E/W</mat-label>
                        <input type="number" matNativeControl formControlName="x">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>N/S</mat-label>
                        <input type="number" matNativeControl formControlName="y">
                    </mat-form-field>
                </span>
            <mat-form-field>
                <mat-label>Aquifer</mat-label>
                <select matNativeControl formControlName="aquifer">
                    @for (a of utility.aquifer; track a) {
                        <option [ngValue]="a"> {{ a.name }}</option>
                    }
                </select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Filter type</mat-label>
                <select matNativeControl formControlName="filter_type">
                    @for (a of utility.filter_type; track a) {
                        <option [ngValue]="a"> {{ a.name }}</option>
                    }
                </select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Pipe material</mat-label>
                <select matNativeControl formControlName="pipe_material">
                    @for (a of utility.pipe_material; track a) {
                        <option [ngValue]="a"> {{ a.name }}</option>
                    }
                </select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Settlement position</mat-label>
                <select matNativeControl formControlName="settlement_position">
                    @for (a of utility.settlement_position; track a) {
                        <option [ngValue]="a"> {{ a.name }}</option>
                    }
                </select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Tip type</mat-label>
                <select matNativeControl formControlName="tip_type">
                    @for (a of utility.tip_type; track a) {
                        <option [ngValue]="a"> {{ a.name }}</option>
                    }
                </select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Coordinate determination method</mat-label>
                <select matNativeControl formControlName="coordinate_determination_method">
                    @for (a of utility.coordinate_determination_method; track a) {
                        <option [ngValue]="a"> {{ a.name }}</option>
                    }
                </select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Coordinate precision</mat-label>
                <select matNativeControl formControlName="coordinate_quality">
                    @for (a of UNCERTAINTY_OPTIONS; track a) {
                        <option [value]="a"> {{ a }} m</option>
                    }
                </select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Level precision</mat-label>
                <select matNativeControl formControlName="level_quality">
                    @for (a of UNCERTAINTY_OPTIONS; track a) {
                        <option [value]="a"> {{ a }} m</option>
                    }
                </select>
            </mat-form-field>
            @for (def of numberFields; track def) {
                <mat-form-field>
                    <mat-label> {{ def.title }}</mat-label>
                    <input matInput type="number" [formControlName]="def.control">
                </mat-form-field>
            }
            <mat-form-field>
                <mat-label> Correlation base</mat-label>
                <select matNativeControl formControlName="correlation_base">
                    @for (t of [true, false]; track t) {
                        <option [value]="t"> {{ t }}</option>
                    }
                </select>
            </mat-form-field>
            <mat-form-field>
                <mat-label> Correlation reference</mat-label>
                <select matNativeControl formControlName="correlation_reference">
                    @for (t of [true, false]; track t) {
                        <option [value]="t"> {{ t }}</option>
                    }
                </select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Not used as reference after</mat-label>
                <input matInput type="date" formControlName="not_reference_from" [max]="INPUT_DATE_MAX">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Comment</mat-label>
                <textarea matInput formControlName="comment"></textarea>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Source</mat-label>
                <textarea matInput formControlName="source"></textarea>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Directions</mat-label>
                <textarea matInput formControlName="directions"></textarea>
            </mat-form-field>
        </form>
    </mat-dialog-content>
}

<mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="false">Cancel</button>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button mat-raised-button (click)="save()">Save</button>
</mat-dialog-actions>
