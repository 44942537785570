<mat-card>
    <mat-card-header>
        <mat-card-title>Handle diver data</mat-card-title>
        <mat-card-subtitle></mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <p>
            All steps, except selection a diver object, are optional and can be performed independently of each other.
            The order indicates a common workflow.
        </p>
        <p>
            Saving is optional for the all steps. After saving, it is possible to leave the process and
            continue at a later time.
        </p>
    </mat-card-content>
</mat-card>

<mat-stepper [linear]="true" orientation="vertical">

    <mat-step
        [completed]="selectedObject()"
        label="Select object"
    >
        <ng-template matStepContent>
            <app-select-diver-object/>
        </ng-template>
    </mat-step>

    <mat-step label="Import diver data" optional>
        <ng-template matStepContent>
            <app-import-diver/>
        </ng-template>
    </mat-step>

    <mat-step label="Barometer measurements" optional>
        <ng-template matStepContent>
            <app-barometer-measurements/>
        </ng-template>
    </mat-step>

    <mat-step optional>
        <ng-template matStepLabel>
            <div>
                Compensate measurements
            </div>
            @if (service.nrUncompensated; as nr) {
                <small>
                    Input measurements:
                    {{ nr.database ?? 'no' }}  from database,
                    {{ nr.file ?? 'no' }}  from file.
                </small>
            }
        </ng-template>
        <!-- Must be eager loaded to calculate nrUncompensated for the label -->
        <app-compensate-diver/>
    </mat-step>

    <mat-step optional>
        <ng-template matStepLabel>
            <div>
                Reference measurements
            </div>
            @if (service.nrUnreferenced; as nr) {
                <small>
                    Input measurements:
                    {{ nr.database ?? 'no' }} from database,
                    {{ nr.file ?? 'no' }} from file,
                    {{ nr.local ?? 'no' }} from compensation step.
                </small>
            }
        </ng-template>
        <!-- Must be eager loaded to calculate nrUnreferenced for the label -->
        <app-reference-diver/>
    </mat-step>

    <mat-step label="Miscellaneous" optional>
        <ng-template matStepContent>
            <app-existing-instances/>
        </ng-template>
    </mat-step>

</mat-stepper>

<!--@if (httpStatus.waiting > 0) {-->
<!--    <mat-progress-bar-->
<!--        mode="indeterminate"-->
<!--    />-->
<!--}-->

<mat-card>
    <app-log-list [container]="logs"/>
</mat-card>
