<h1 mat-dialog-title> Name for this option set </h1>

<mat-dialog-content>

  <mat-form-field>
    <input matInput type="text" [formControl]="control">
    <mat-error> {{getErrorMessage(control)}} </mat-error>
  </mat-form-field>

  @if (error) {
    <div class="warning">
      Error: {{error}}
    </div>
  }

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close>Cancel</button>
  <button mat-raised-button (click)="save()">Save</button>
</mat-dialog-actions>