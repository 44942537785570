import {Component, Inject} from '@angular/core'
import {
    MAT_DIALOG_DATA,
    MatDialogActions,
    MatDialogClose,
    MatDialogConfig,
    MatDialogContent,
    MatDialogTitle
} from '@angular/material/dialog'
import {MatButton} from "@angular/material/button"

export type ConfirmDialogResult = boolean
export type ConfirmDialogData = MatDialogConfig<{
    header: string,
    text?: string,
    textList?: string[],
    positive_text?: string,
    negative_text?: string
}>['data']
export type ConformDialogResult = boolean

@Component({
    standalone: true,
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    imports: [
        MatDialogClose,
        MatDialogContent,
        MatDialogActions,
        MatButton,
        MatDialogTitle
    ]
})
export class ConfirmDialogComponent {
    response: ConfirmDialogResult = false

    constructor(
        @Inject(MAT_DIALOG_DATA) readonly data: ConfirmDialogData,
    ) {
    }

}
