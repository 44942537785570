<h1 mat-dialog-title>Edit trigger parameters</h1>
<mat-dialog-content>

    <mat-form-field>
        <input matInput [formControl]="form.controls.description">
        <mat-label>Description</mat-label>
        <mat-error>{{ getErrorMessage(form.controls.description) }}</mat-error>
    </mat-form-field>
    <mat-form-field>
        <input matInput type="number" [formControl]="form.controls.limit">
        <mat-label>Limit</mat-label>
    </mat-form-field>
    <mat-form-field>
        <select matNativeControl [formControl]="form.controls.type">
            @for (v of TriggerTypeValues; track v) {
                <option [value]="v">{{ v==='min'?'minimum':v }}</option>
            }
        </select>
        <mat-label>Limit</mat-label>
    </mat-form-field>
    <mat-form-field>
        <input matInput [formControl]="form.controls.comment">
        <mat-label>Comment</mat-label>
    </mat-form-field>
    <br>
    <mat-checkbox [formControl]="form.controls.active">Active</mat-checkbox>
    <mat-checkbox [formControl]="form.controls.unchecked">Apply trigger for unchecked measurements</mat-checkbox>

    <h2>Notification recipients</h2>
    <mat-form-field [style.width]="'90%'">
        <mat-select multiple [formControl]="form.controls.recipients" [compareWith]="equalIds" panelWidth="">
            @for (v of knownUsers(); track v.id) {
                <mat-option [value]="v">{{ v.email }}</mat-option>
            }
        </mat-select>
    </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="undefined">Cancel</button>
    <button mat-raised-button (click)="save()">Save</button>
</mat-dialog-actions>
