import {Injectable} from '@angular/core'

import * as S from "superstruct"
import {combineLatest, combineLatestWith, map, Observable, switchMap} from "rxjs"

import {ExternalObject} from "../../api/api-types"
import {TypedHttpService} from "../typed-http.service"
import {VideDataService} from "../../api/vide-data.service"
import {assertNever, isNotNull} from "../../shared/vide-helper"

// noinspection SpellCheckingInspection
/**
 * The type information is based on the types created by
 * https://github.com/charto/cxsd
 * and running
 *  ./row npm run cxsd https://opendata.smhi.se/xsd/portal.xsd
 *  ./row npm run cxsd https://opendata.smhi.se/xsd/hydroobs_v1.xsd
 *  ./row npm run cxsd https://opendata.smhi.se/xsd/metobs_v1.xsd
 *  ./row npm run cxsd https://opendata.smhi.se/xsd/ocobs_v1.xsd
 *
 * The result is found in ../../../../xmlns/opendata.smhi.se/xsd/*.
 *
 *  The structs here are modified from the above result according to what SMHI actually delivers.
 */

const BaseLink = S.object({
    href: S.string(),
    rel: S.string(),
    type: S.string()
})
const ForwardLink = S.object({
    key: (S.string()),
    // key: S.optional(S.string()),
    link: (S.array(BaseLink)), // optional
    summary: (S.string()),
    title: S.string(),
    updated: S.nullable(S.integer())
})
const Category = S.object({
    key: S.string(),
    link: S.array(BaseLink), // optional
    summary: S.string(),
    title: S.string(),
    updated: S.integer(),
    version: S.array(ForwardLink), //optional
})
type Category = S.Infer<typeof Category>
const GeoBox = S.object({
    maxLatitude: S.number(),
    maxLongitude: S.number(),
    minLatitude: S.number(),
    minLongitude: S.number(),
})
const ParameterLink = S.assign(ForwardLink, S.object({
    geoBox: GeoBox,
    unit: S.string()
}))
const Version = S.object({
    key: S.string(),
    updated: S.integer(),
    title: S.string(),
    summary: S.string(),
    link: S.optional(S.array(BaseLink)),
    resource: S.array(ParameterLink), // optional
})
type Version = S.Infer<typeof Version>
const MetobsStationLink = S.assign(ForwardLink, S.object({
    // key: S.string(), // TODO: correct?
    active: S.boolean(),
    from: S.integer(),
    height: S.number(),
    id: S.integer(),
    latitude: S.number(),
    longitude: S.number(),
    measuringStations: S.enums(["ALL", "CORE", "ADDITIONAL"]),
    name: S.string(),
    owner: S.string(),
    ownerCategory: S.enums(["CLIMATE", "NATIONAL"]),
    to: S.integer(),
}))
const MetobsParameter = S.object({
    key: S.string(),
    link: S.array(BaseLink), // optional
    station: S.array(MetobsStationLink),//optional
    stationSet: S.array(S.assign(ForwardLink, S.object({updated: S.nullable(S.integer())}))), //optional
    summary: S.string(),
    title: S.string(),
    unit: S.string(),
    updated: S.integer(),
    valueType: S.enums(["SAMPLING", "INTERVAL"]),
})
export type MetobsParameter = S.Infer<typeof MetobsParameter>
const HydroobsStationLink = S.assign(ForwardLink, S.object({
    // key: S.string(), // TODO: correct?
    active: S.boolean(),
    catchmentName: S.string(),
    catchmentNumber: S.integer(),
    catchmentSize: S.number(),
    from: S.nullable(S.integer()),
    id: S.number(),
    latitude: S.number(),
    longitude: S.number(),
    measuringStations: S.enums(["ALL", "CORE", "ADDITIONAL"]),
    name: S.string(),
    owner: S.string(),
    region: S.nullable(S.number()),
    to: S.nullable(S.integer()),
}))
const HydroobsParameter = S.object({
    key: S.string(),
    link: S.array(BaseLink), // optional
    station: S.array(HydroobsStationLink), //optional
    stationSet: S.array(ForwardLink), //optional
    summary: S.string(),
    title: S.string(),
    unit: S.string(),
})
export type HydroobsParameter = S.Infer<typeof HydroobsParameter>
const OcobsStationLink = S.assign(ForwardLink, S.object({
    // key: S.string(), // TODO: correct?
    id: S.number(),
    name: S.string(),
    latitude: S.number(),
    longitude: S.number(),
    active: S.boolean(),
    from: S.integer(),
    // measuringStations: S.enums(["ALL", "CORE", "ADDITIONAL"]),
    to: S.integer(),
    // owner: S.string(),
    mobile: S.boolean(),
    // region: S.nullable(S.number()),
}))
const OcobsParameter = S.object({
    key: S.string(),
    link: (S.array(BaseLink)), // optional
    station: S.array(OcobsStationLink), //optional
    stationSet: S.array(ForwardLink), //optional
    summary: S.string(),
    title: S.string(),
    unit: S.string(),
})
export type OcobsParameter = S.Infer<typeof OcobsParameter>
const ExternalObjectSmhi = S.assign(ExternalObject, S.object({
    options: S.type({
        station: S.integer(),
        parameter: S.string(),
        domain: S.string(),
        // there are more properties delivered, that we do not use.
        // Maybe we should stop them in the backend resource?
        // updated: S.unknown(),
        // factor: S.unknown(),
    })
}))
export type SmhiStation = {
    active: boolean,
    name: string,
    key: string,
    id: number,
    latitude: number,
    longitude: number,
    from: number | null,
    to: number | null,
    updated: number | null,
}
export type ExternalObjectSmhi = S.Infer<typeof ExternalObjectSmhi>
type Domain = 'hydroobs' | 'metobs' | 'ocobs'
export type StationParameter = {
    domain: Domain
    parameter: Version['resource'][number]
}

const Metobs = {
    API_BASE: 'https://opendata-download-metobs.smhi.se/api.json',
    API_VERSION: '1.0',
} as const
const Hydroobs = {
    API_ENTRYPOINT: 'https://opendata-download-hydroobs.smhi.se/api.json',
    API_VERSION: '1.0',
} as const
const Ocobs = {
    API_ENTRYPOINT: 'https://opendata-download-ocobs.smhi.se/api.json',
    API_VERSION: '1.0',
} as const

const API_TYPE = 'application/json'

@Injectable({
    providedIn: 'root'
})
export class SmhiApiService {
    private readonly ocParams$ = this.http.getTyped(Category, Ocobs.API_ENTRYPOINT).pipe(
        map(this.getVersionedLink(Ocobs.API_VERSION)),
        switchMap(href => this.http.getTyped(Version, href)),
        map(version => version.resource),
        map(resources => resources.filter(r => r.title === 'Havsvattenstånd')),
        map(resources => resources.map(r => {
            const ret: StationParameter = {domain: 'ocobs', parameter: r}
            return ret
        })),
    )
    private readonly hydroParams$ = this.http.getTyped(Category, Hydroobs.API_ENTRYPOINT).pipe(
        map(this.getVersionedLink(Hydroobs.API_VERSION)),
        switchMap(href => this.http.getTyped(Version, href)),
        map(version => version.resource),
        map(resources => resources.filter(r => ['Vattenföring (Dygn)', 'Vattenstånd'].includes(r.title))),
        map(resources => resources.map(r => {
            const ret: StationParameter = {domain: 'hydroobs', parameter: r}
            return ret
        })),
    )
    private readonly metParameters$ = this.http.getTyped(Category, Metobs.API_BASE).pipe(
        map(this.getVersionedLink(Metobs.API_VERSION)),
        switchMap(href => this.http.getTyped(Version, href)),
        map(version => version.resource),
        combineLatestWith(this.dataService.utility$),
        map(([resources, utility]) => {
            // filter known measure types
            const known = utility.measure_type.map(mt => mt.name_smhi).filter(isNotNull)
            return resources.filter(r => {
                const smhi = `${r.title}; ${r.summary}`
                return known.includes(smhi)
            })
        }),
        map(resources => resources.map(r => {
            const ret: StationParameter = {domain: 'metobs', parameter: r}
            return ret
        })),
    )
    readonly parameters$ = combineLatest([
        this.ocParams$,
        this.hydroParams$,
        this.metParameters$,
    ]).pipe(
        map(([a, b, c]) => [...a, ...b, ...c])
    )
    readonly allExternalSmhiObjects$ = this.dataService.projectNotNull$.pipe(
        switchMap(project => this.dataService.getExternalObjects(project, 'smhi')),
        map(externals => S.create(externals, S.array(ExternalObjectSmhi))),
    )

    constructor(
        private readonly http: TypedHttpService,
        private readonly dataService: VideDataService,
    ) {
    }

    private getVersionedLink(version: string) {
        return (category: Category) => {
            const x = category.version.find(v => v.key === version)
            const link = getLink(x)
            return link.href
        }
    }

    public getStations({domain, parameter}: StationParameter):
        Observable<ReadonlyArray<StationParameter & { station: SmhiStation, }>> {
        const url = getLink(parameter).href
        let struct
        switch (domain) {
            case "metobs":
                struct = MetobsParameter
                break
            case "hydroobs":
                struct = HydroobsParameter
                break
            case "ocobs":
                struct = OcobsParameter
                break
            default:
                assertNever(domain)
        }

        return this.http.getTyped(struct, url).pipe(
            map(para => para.station.map(s => {
                return {
                    station: s,
                    domain: domain,
                    parameter,
                }
            })))
    }

}

function getLink<T extends { type: string }>(arg?: { link?: readonly T[] }) {
    const link = arg?.link?.find(l => l.type === API_TYPE)
    if (!link) {
        throw Error("link undefined")
    }
    return link
}

