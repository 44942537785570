<mat-card>
    <mat-card-header>
        <mat-card-title>
            Help topics
        </mat-card-title>
        <mat-card-subtitle>
            <p class="debug">Beta version</p>
        </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
        <mat-nav-list>
            <a mat-list-item routerLink="public-data">Public data</a>
            <a mat-list-item routerLink="ability">User abilities</a>
            <a mat-list-item routerLink="plots">Plots</a>
        </mat-nav-list>
    </mat-card-content>
</mat-card>
