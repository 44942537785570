<h1 mat-dialog-title>
    @if (data) {
        Edit user {{ data.name }} <{{ data.email }}>
    } @else {
        Add user
    }
</h1>
<mat-dialog-content>
    <p> The ability is the maximum ability for this user in any project. A license has room for a limited number of
        users of each ability. </p>

    @if (form.controls.name; as control) {
        <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput [formControl]="control">
            <mat-error>{{ getErrorMessage(control) }}</mat-error>
        </mat-form-field>
    }
    @if (form.controls.comment; as control) {
        <mat-form-field>
            <mat-label>Comment</mat-label>
            <textarea matInput [formControl]="control"></textarea>
            <mat-error>{{ getErrorMessage(control) }}</mat-error>
        </mat-form-field>
    }
    @if (form.controls.ability; as control) {
        <mat-form-field>
            <mat-label>Ability</mat-label>
            <mat-select [formControl]="control">
                @for (x of EXTENDED_PROJECT_ABILITIES; track x) {
                    <mat-option [value]="x">{{ x }}</mat-option>
                }
            </mat-select>
            <mat-error>{{ getErrorMessage(control) }}</mat-error>
        </mat-form-field>
    }
    @if (!data) {
        @if (addForm.controls.license; as control) {
            <mat-form-field>
                <mat-label>License</mat-label>
                <mat-select [formControl]="control">
                    @for (l of licenses(); track l) {
                        <mat-option [value]="l">{{ l.name }}</mat-option>
                    }
                </mat-select>
                <mat-error>{{ getErrorMessage(control) }}</mat-error>
            </mat-form-field>
        }
        @if (addForm.controls.email; as control) {
            <mat-form-field>
                <mat-label>Email</mat-label>
                <input matInput type="email" [formControl]="control">
                @if (control.pending) {
                    <mat-hint>
                        Checking...
                        <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
                    </mat-hint>
                }
                <mat-error>{{ getErrorMessage(control) }}</mat-error>
            </mat-form-field>
        }
    }

    @if (loweringAbility()) {
        <div class="warning">Lowering ability will lower project ability in all of user's projects</div>
    }
    <!--  Add list of user's projects as read only info  -->

    <mat-divider/>

    @if (data?.projects; as projects) {
        @if (projects.length) {
            <h2>Projects</h2>
            <ul>
                @for (p of data?.projects; track p.id) {
                    <li>
                        <b>{{ p.name }}</b>: {{ p.ability }}
                    </li>
                }
            </ul>
        } @else {
            No projects
        }
    }
</mat-dialog-content>

<mat-dialog-actions>
    @if (data) {
        <button mat-raised-button (click)="saveEdit(data)"> Save</button>
    } @else {
        <button mat-raised-button (click)="addUser()"> Add</button>
    }
    <button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
<app-log-list [container]="logs"/>
@if (waiting) {
    <mat-progress-bar mode="indeterminate"/>
}
