<mat-card>
    <mat-card-title>
        <h1>Reset password</h1>
    </mat-card-title>

    <mat-card-content>
        @if (token === null) {
            <div class="error">
                Missing token, is the link correct?
            </div>
        }
        @if (passwordForm.controls.email; as control) {
            <mat-form-field>
                <mat-label>Email</mat-label>
                <input matInput type="email" [formControl]="control" email required autocomplete="email username">
                <mat-error>{{ getErrorMessage(control) }}</mat-error>
            </mat-form-field>
        }
        @if (passwordForm.controls.password; as control) {
            <mat-form-field>
                <mat-label>New password</mat-label>
                <input matInput type="password" [formControl]="control" required autocomplete="new-password">
                <mat-error>{{ getErrorMessage(control) }}</mat-error>
            </mat-form-field>
        }
        @if (passwordForm.controls.password_confirmation; as control) {
            <mat-form-field>
                <mat-label>Password confirmation</mat-label>
                <input matInput type="password" [formControl]="control" required
                       autocomplete="new-password">
                <mat-error>{{ getErrorMessage(control) }}</mat-error>
            </mat-form-field>
        }
        @if (passwordForm.invalid && passwordForm.touched && !passwordForm.pristine) {
            <mat-error>
                {{ getErrorMessage(passwordForm) }}
            </mat-error>
        }
        @if (resetResult === true) {
            <p>
                Go to <a mat-raised-button [href]="main" target="_self">Main page</a> to login.
            </p>
        } @else if (resetResult === false) {
            <p>
                If the reset token is invalid, you must request a new one by the "Forgot password" link from the
                <a [href]="main">login page</a>.
            </p>
        }
    </mat-card-content>

    <mat-card-actions>
        <button mat-raised-button type="button" (click)="resetPassword()">Reset</button>
    </mat-card-actions>

</mat-card>

<app-log-list [container]="logs"></app-log-list>
