<h1 mat-dialog-title>Reset password</h1>

<mat-dialog-content>
    <div>
        <mat-form-field>
            <mat-label>Username (email)</mat-label>
            <input matInput type="email" [formControl]="control" email required autocomplete="email username">
            <mat-error>{{ getErrorMessage(control) }}</mat-error>
        </mat-form-field>
    </div>
    <app-log-list [container]="logs"/>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button (click)="sendLink()">Reset password</button>
    <button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
