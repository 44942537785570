import {AfterViewInit, Component, computed, ElementRef, OnDestroy, viewChild, viewChildren} from '@angular/core'
import {MatCardModule} from "@angular/material/card"
import {MatTab, MatTabGroup, MatTabsModule} from "@angular/material/tabs"
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop"

import {PlotlyViaWindowModule} from "angular-plotly.js"
import {Region} from "zingtouch"
import {distinctUntilChanged, map, scan, tap, throttleTime} from "rxjs"
import {formatDate} from "@angular/common"
import {isLeft, isRight} from "fp-ts/Either"

import {DebugComponent} from "../debug/debug.component"
import {MapBackground} from "../mapbox/mapbox-helper"
import {PLOT_CONFIG} from "../../constants"
import {PlotlyData, VideFigure} from "../vide-types"
import {eqEitherPos, GeolocationService} from "../shared/geolocation.service"


@Component({
    imports: [
        DebugComponent,

        MatCardModule,
        MatTabsModule,
        PlotlyViaWindowModule,
    ],
    selector: 'app-field',
    styleUrl: './field.component.scss',
    templateUrl: './field.component.html'
})
export class FieldComponent implements AfterViewInit, OnDestroy {
    protected message: string = ''
    tabb = viewChild.required(MatTabGroup)
    tabs = viewChildren(MatTab)
    selectedIndex?: number
    nrTabs = computed(() => this.tabs().length)
    private x?: Region

    // position?: any
    messages = <string[]>[]

    readonly pos$ = this.positionService.position

    readonly scan = toSignal(this.pos$.pipe(
        // auditTime(3000),
        distinctUntilChanged(eqEitherPos.equals),
        tap(x => {
            if (isLeft(x)) console.warn(x)
            else console.log(x)
        }),
        // throttleTime(3000),
        scan((acc, curr) => {
            const msg = isLeft(curr) ? `${curr.left.code}` : `${curr.right.coords.longitude}, ${curr.right.coords.latitude}`
            const date = new Date()
            const d = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
            acc.push(`${d} ${msg}`)
            return acc
        }, <string[]>[])
    ))

    readonly figure = toSignal(this.pos$.pipe(
        // auditTime(3000),
        distinctUntilChanged(eqEitherPos.equals),
        throttleTime(3000),
        // throttleTime(3000, asyncScheduler, {leading: false, trailing: true}),
        // debounceTime(3000),
        map(x => {
            const traces: PlotlyData[] = []
            let latitude = 0
            let longitude = 0
            //: number
            if (isRight(x)) {
                latitude = x.right.coords.latitude
                longitude = x.right.coords.longitude
                traces.push({
                    type: "scattermapbox",
                    lat: [latitude],
                    lon: [longitude],
                    marker: {size: 20, color: 'red'}
                })
            }
            const background: MapBackground = 'open-street-map'
            const figure: VideFigure = {
                data: traces,
                layout: {
                    mapbox: {
                        style: background,
                        // style: options.background,
                        center: {lat: latitude, lon: longitude},
                        zoom: 17,
                    },
                    margin: {r: 0, l: 0, t: 0, b: 0},
                },
                config: {
                    mapboxAccessToken: PLOT_CONFIG.mapboxAccessToken,
                    showEditInChartStudio: true,
                    scrollZoom: true,
                },
            }
            console.log(figure.data.at(0))
            return figure
        })
    ))

    constructor(
        readonly junk: ElementRef,
        readonly positionService: GeolocationService,
    ) {
        console.log("starting")
        this.pos$.pipe(takeUntilDestroyed()).subscribe(curr => {
            console.log(curr)
            const msg = isLeft(curr) ? `${curr.left.message}` : `${curr.right.coords.longitude}, ${curr.right.coords.latitude}`
            const date = new Date()
            const d = formatDate(date, 'HH:mm:ss.SSS', 'en-US',)
            // const d = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}.${date.getMilliseconds()}`
            this.messages.push(`${d}: ${msg}`)
        })
        // positionService.position.pipe(takeUntilDestroyed()).subscribe(p => {
        //     console.log(p)
        //     this.position = p
        // })
        // console.warn(junk)
        // const watchId = navigator.geolocation.watchPosition(success => {
        //     console.log(success)
        //     this.position = success
        //     this.error = null
        // }, error => {
        //     console.log(error)
        //     this.position = null
        //     this.error = error
        // }, {timeout: 1, maximumAge: 2, enableHighAccuracy: true})
        //
        // navigator.permissions.query({name: "geolocation"}).then(answer => {
        //     console.log(answer)
        // })
        //
        // setTimeout(() => {
        //     navigator.geolocation.clearWatch(watchId)
        // }, 3 * 1e3)
        // navigator.geolocation.watchPosition(pos => {
        //         console.log(pos)
        //         this.position = pos
        //     },
        //     err => {
        //         console.log(err)
        //     })
    }

    ngOnDestroy(): void {
        console.warn("unbinding the swipe stuff. Not necessary?")
        this.x?.unbind(this.junk.nativeElement)
    }

    ngAfterViewInit(): void {
        this.x = new Region(document.body,)
        this.x.bind(this.junk.nativeElement, 'swipe', (params: any) => {
            console.log("swipe", params)
            const angle = params.detail.data[0].currentDirection
            if (angle > 360 || angle < 0) {
                this.message = `Strange value ${angle}`
                return
            }
            let direction
            if (Math.abs(180 - angle) < 45) {
                direction = 'left' as const
            } else if (angle < 45 || angle > 325) {
                direction = 'right' as const
            } else {
                direction = 'uncertain' as const
            }
            this.message = `swipe ${direction}, ${params.detail.data[0].currentDirection}`
            this.changeTab(direction)
        })
    }


    private changeTab(direction: 'right' | 'left' | 'uncertain') {
        const maxIdx = this.nrTabs() - 1
        let change = 0
        switch (direction) {
            case "right":
                change = -1
                break
            case "left":
                change = 1
                break
            case "uncertain":
                break
        }
        let newIdx = (this.selectedIndex ?? 0) + change
        while (newIdx < 0) newIdx++
        while (newIdx > maxIdx) newIdx--
        this.selectedIndex = newIdx

    }

}
