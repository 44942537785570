import {ApplicationConfig, ErrorHandler, inject, provideZoneChangeDetection} from '@angular/core'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field"
import {MatSnackBar} from "@angular/material/snack-bar"
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async'
import {provideHttpClient, withInterceptors} from "@angular/common/http"
import {provideRouter, withComponentInputBinding} from '@angular/router'

import {VideErrorHandler} from "./shared/vide-error-handler"
import {interceptors} from "./app.interceptors"
import {oldRoutes} from './app.routes'

export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({eventCoalescing: true}),
        provideAnimationsAsync(),
        provideHttpClient(withInterceptors(interceptors),),
        provideRouter(oldRoutes, withComponentInputBinding()),
        // {
        //     provide: POSITION_OPTIONS,
        //     useValue: {enableHighAccuracy: true, timeout: 3000, maximumAge: 1000},
        // },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline', hideRequiredMarker: true,}
        },
        {
            provide: ErrorHandler, useFactory: () => {
                const snack = inject(MatSnackBar)
                return new VideErrorHandler(snack)
            }
        }
    ]
}
