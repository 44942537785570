import {FormBuilder, Validators} from "@angular/forms"
import {Injectable} from '@angular/core'
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"

import {Annotations} from "plotly.js"
import {
    BehaviorSubject,
    combineLatest,
    combineLatestWith,
    firstValueFrom,
    map,
    of,
    shareReplay,
    startWith,
    Subject,
    switchMap,
    tap
} from "rxjs"

import {
    annotationsToAnnotations,
    DIVER_DATA_TYPES,
    DiverDataType,
    DiverFileData,
    DiverService,
    figureClick,
    getFilterFn,
    parseDiverOfficeData,
    readAsText
} from "../diver.service"
import {FileValidator} from "../../import-file/file-validator"
import {MeasureType} from "../../../api/api-types"
import {PlotlyLayout, PlotlyMouseEvent, VideFigure} from "../../../vide-types"
import {SlimObject} from "../diver.component"
import {VideDataService} from "../../../api/vide-data.service"
import {assertNever, PLOTLY_SCATTER_TYPE} from "../../../shared/vide-helper"

@Injectable({
    providedIn: 'root'
})
export class ImportDiverService {
    readonly fileError = new Subject<string | null>()
    readonly form = this.fb.nonNullable.group({
        code: [DIVER_DATA_TYPES[0] as DiverDataType, Validators.required],
        file: [null as FileList | null, [Validators.required, FileValidator.nrFiles(1),]],
    })
    private _diverAnnotations = new BehaviorSubject<NonNullable<PlotlyLayout['annotations']>>([])
    readonly diverAnnotations$ = this._diverAnnotations.pipe(
        map(annotationsToAnnotations),
    )
    private readonly selectedFile$ = this.form.controls.file.valueChanges.pipe(
        startWith(null),
        map(v => v?.item(0)),
        tap(() => {
            this.fileError.next(null)
        }),
    )
    private readonly rawDiverFileData$ = this.selectedFile$.pipe(
        switchMap(file => {
            return !file
                ? of(null)
                : readAsText(file).then(text => parseDiverOfficeData(file.name, text)).catch(e => {
                    console.error(e)
                    if (e instanceof Error) this.fileError.next(e.message)
                    return null
                })
        }),
        tap(data => {
            const type = data?.type
            if (type) {
                this.form.patchValue({code: type})
            }
        }),
    )

    readonly diverFileData$ = this.rawDiverFileData$.pipe(
        tap(_x => {
            this.resetDiverAnnotations()
        }),
        combineLatestWith(this.form.controls.code.valueChanges.pipe()),
        map(([data, tpe]) => {
            if (!data) {
                return null
            }
            const ret: DiverFileData = {
                ...data,
                type: tpe
            }
            return ret
        }),
        shareReplay({refCount: true, bufferSize: 1}),
    )
    readonly diverFigure$ = combineLatest([
        this.diverFileData$,
        this.diverAnnotations$,
    ]).pipe(
        map(([x, annotations]) => {
            return x ? getFigure(x, annotations) : null
        }),
    )
    readonly diverClippedFileData$ = combineLatest([
        this.diverFileData$,
        this.diverAnnotations$,
    ]).pipe(
        map(([x, annotations]) => {
            if (!x) return null
            const clippedData = x.measurements.filter(getFilterFn(annotations))
            const ret: DiverFileData = {...x, measurements: clippedData}
            return ret
        }),
    )
    readonly diverNrMeasurements$ = this.diverClippedFileData$.pipe(
        map(data => data?.measurements.length),
    )


    constructor(
        private readonly fb: FormBuilder,
        private readonly dataService: VideDataService,
        private readonly diverService: DiverService,
    ) {
        // Set the data type according to what we found in the file.
        // The user can change in case of Baro-diver, as this looks as uncompensated data.
        this.rawDiverFileData$.pipe(takeUntilDestroyed()).subscribe(data => {
            // const type = data?.type
            // if (type) {
            //     this.form.patchValue({code: type})
            // }
        })
        this.diverClippedFileData$.pipe(takeUntilDestroyed()).subscribe(data => {
            this.diverService.setFileData(data)
        })
    }

    private resetDiverAnnotations() {
        this._diverAnnotations.next([])
    }

    diverDoubleClick() {
        this.resetDiverAnnotations()
    }

    diverFigureClick(event: PlotlyMouseEvent) {
        figureClick(this._diverAnnotations, event)
    }

    async save(object: SlimObject) {
        const localData = await firstValueFrom(this.diverClippedFileData$)
        const firstMeasurement = localData?.measurements.at(0)
        if (!localData || !firstMeasurement) {
            console.error('No input values')
            return
        }
        let mt: MeasureType
        switch (localData.type) {
            case "Uncompensated":
                mt = await firstValueFrom(this.dataService.measureTypeDiverUncompensated$)
                break
            case "Unreferenced":
                mt = await firstValueFrom(this.dataService.measureTypeDiverUnreferenced$)
                break
            case "Referenced":
                mt = await firstValueFrom(this.dataService.measureTypeDiver$)
                break
            case "Air pressure (baro diver)":
                mt = await firstValueFrom(this.dataService.measureTypeAirPressure$)
                break
            default:
                assertNever(localData.type)
        }

        const x = await this.diverService.saveToObject(localData.measurements, {
            measureType: mt,
            object,
            update: false,
        })
        if (!x.success) {
            return
        }
        this.form.patchValue({file: null})
        this.dataService.reloadProjectData()

        const y = await this.diverService.createDiverAnnotation(object, {
            comment: localData.type,
            first_date: firstMeasurement.dateTime,
            serial_number: localData?.serial,
        })
        if (!y.success) {
            return
        }
        return localData.type
    }
}

function getFigure(
    data: DiverFileData,
    annotations: Partial<Annotations>[],
) {
    let color
    switch (data.type) {
        case "Uncompensated":
            color = DiverService.plotColors.uncompensated
            break
        case "Unreferenced":
            color = DiverService.plotColors.unreferenced
            break
        case "Referenced":
            color = DiverService.plotColors.result
            break
        case "Air pressure (baro diver)":
            color = DiverService.plotColors.pressure
            break
        default:
            assertNever(data.type)
    }
    const figure: VideFigure = {
        data: [
            {
                x: data.measurements.map(z => z.dateTime),
                y: data.measurements.map(z => z.value),
                yaxis: 'y',
                mode: 'lines+markers',
                type: PLOTLY_SCATTER_TYPE,
                name: data.type,
                marker: {color,},
            },
            {
                x: data.measurements.map(z => z.dateTime),
                y: data.measurements.map(z => z.temp),
                yaxis: 'y2',
                mode: 'lines+markers',
                type: PLOTLY_SCATTER_TYPE,
                name: 'temperature',
                marker: {color: DiverService.plotColors.temperature,},
            }

        ],
        layout: {
            autosize: true,
            yaxis: {
                title: 'meter H₂O',
            },
            yaxis2: {
                title: '°C',
                overlaying: 'y',
                side: 'right',
            },
            annotations: annotations,
        },
        config: {
            // showLink: true, // text link on plot
            showEditInChartStudio: true, // icon in modebar
            plotlyServerURL: "https://chart-studio.plotly.com",
            // showLink: true,
            // linkText: 'This text is custom!' + imageSize?.width + 'x' + imageSize?.height,
            modeBarButtonsToRemove: [
                // 'pan2d',
                // 'zoom2d',
                'select2d',
                'lasso2d',
                'resetScale2d',
            ],
            modeBarButtonsToAdd: [
                'toggleSpikelines',
                // 'zoom2d',
            ],
            // Skip the size options, we use the default, the current plot size.
            // The size option is for the manual save button.
            // toImageButtonOptions: {filename: value.toImage.filename},
        }

    }
    return figure
}
