import {Component, effect} from '@angular/core'
import {NonNullableFormBuilder, ReactiveFormsModule, Validators} from '@angular/forms'
import {toSignal} from "@angular/core/rxjs-interop"
import {MatCardModule} from "@angular/material/card"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatInputModule} from "@angular/material/input"
import {MatButtonModule} from "@angular/material/button"

import {firstValueFrom} from 'rxjs'

import {AuthService} from 'src/app/auth/auth.service'
import {LogContainer} from 'src/app/log-list/log-container'
import {getErrorMessage} from 'src/app/shared/vide-helper'
import {VideDataService} from '../api/vide-data.service'
import {ComponentCanDeactivate} from '../can-deactivate.guard'
import {SelfUser} from "../api/api-types"
import {Interval} from "../api/limit-storage"
import {LogListComponent} from "../log-list/log-list.component"

@Component({
    selector: 'app-preferences',
    standalone: true,
    templateUrl: './preferences.component.html',
    imports: [
        LogListComponent,
        MatButtonModule,
        MatCardModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
    ],
    styleUrls: ['./preferences.component.scss']
})
export class PreferencesComponent extends ComponentCanDeactivate {
    readonly logs = new LogContainer()
    readonly user = toSignal(this.auth.user$)

    readonly userForm = this.nnFormBuilder.group({
        credentials: this.nnFormBuilder.group({
            email: [''],
            current_password: ['', [Validators.required]],
            password: ['', [Validators.required]],
            password_confirmation: ['', [Validators.required]],
        }),
        name: [''],
        imageOptions: this.dataService.plotlyConfigForm.controls.config.controls.toImage,
        zoomOptions: this.dataService.plotlyConfigForm.controls.dateZoom,
    })
    getErrorMessage = getErrorMessage

    constructor(
        private dataService: VideDataService,
        private auth: AuthService,
        private nnFormBuilder: NonNullableFormBuilder,
    ) {
        super()
        effect(() => {
            const u = this.user()
            this.resetUserForms(u)
        })
        this.dataService.pid = null
    }

    canDeactivate(): boolean {
        // Autofill usually fills this, and it should not stop navigating away
        this.userForm.controls.credentials.controls.current_password.markAsPristine()
        console.log(this.userForm)
        return !this.userForm.dirty
    }

    async updateName() {
        const form = this.userForm.controls.name
        form.markAllAsTouched()
        if (form.valid && !form.pristine) {
            const x = await firstValueFrom(this.dataService.updateSelfName({name: form.value}))
            this.logs.add(x, 'Update name')
            if (x.success) {
                form.markAsPristine()
                this.dataService.reloadUserData()
            }
        }
    }

    reset() {
        this.resetUserForms(this.user())
    }

    async changePassword() {
        const form = this.userForm.controls.credentials
        form.markAllAsTouched()
        if (form.valid) {
            const credentials = form.getRawValue()
            const res = await this.auth.updatePassword(credentials)
            this.logs.add(res, 'Password update')
            if (res.success) {
                form.markAsPristine()
            }
        }
    }

    async logout() {
        const x = await this.auth.logout()
        this.logs.add(x, 'Logout')
    }

    async saveImageOptions() {
        const form = this.userForm.controls.imageOptions
        form.markAllAsTouched()
        if (form.valid) {
            const options = form.getRawValue()
            const res = await firstValueFrom(this.dataService.updateSelfOptions(options))
            if (res.success) {
                this.dataService.reloadUserData()
                form.markAsPristine()
            }
            this.logs.add(res, 'Save options')
        }
    }

    async saveZoomOptions() {
        const form = this.userForm.controls.zoomOptions
        form.markAllAsTouched()
        if (form.valid) {
            const options = form.getRawValue()
            this.dataService.updateSelfOptions({dateZoom: options}).subscribe(res => {
                if (res.success) {
                    this.dataService.reloadUserData()
                    form.markAsPristine()
                }
                this.logs.add(res, 'Save options')
            })
        }
    }

    async clearLastVersion() {
        const res = await firstValueFrom(this.dataService.updateSelfOptions({lastVersion: null}))
        console.log(res)
    }

    private resetUserForms(u: SelfUser | null | undefined) {
        this.userForm.controls.credentials.controls.email.setValue(u?.email ?? '')
        const control = this.userForm.controls.name
        control.setValue(u?.name ?? '')
        control.markAsPristine()
    }

    protected readonly Interval = Interval

}
