import {Injectable} from '@angular/core'

import {BehaviorSubject, concat, distinctUntilChanged, map, of, switchMap, timer} from "rxjs"

const HIGHLIGHT_NO_SELECTION = undefined
const HIGHLIGHT_TIMEOUT_MS = 3 * 1000

@Injectable({
    providedIn: 'root'
})
export class ObjectHighlightService {

    private readonly _subject = new BehaviorSubject<number | typeof HIGHLIGHT_NO_SELECTION>(HIGHLIGHT_NO_SELECTION)

    public highlights$ = this._subject.asObservable().pipe(
        switchMap(x => {
            // Send the object id, and then undefined after a timeout, so we have no unlimited highlight period
            return concat(
                of(x),
                timer(HIGHLIGHT_TIMEOUT_MS).pipe(map(() => HIGHLIGHT_NO_SELECTION)),
            )
        }),
        distinctUntilChanged(),
    )

    constructor() {
    }

    set(objectId: number) {
        this._subject.next(objectId)
    }

    unset() {
        this._subject.next(undefined)
    }
}
