import {MapBackground, MapPosition} from "./app/mapbox/mapbox-helper"
import {environment} from "./environments/environment"
import {Font} from "plotly.js"

export const PLOT_COLOR = {
    // object_selectd: 'red',
    // object_unselectd: 'rgba(128,0,128,0.5)',
    object_unselected_opacity: 0.2,
    object_selected: 'red',
    object_unselectd: 'blue',
    grid_color: '#ddd',
} as const
const MAP_PLOT_TEXTFONT_SATELLITE = {
    color: 'white',
    weight: 1000,
// weight is missing in the definition, so we force it to be accepted...
} as Partial<Font>
const MAP_PLOT_TEXTFONT: Partial<Font> = {
    color: PLOT_COLOR.object_unselectd,
}

export const DIVER = {
    REFERENCE_OUTSIDE_DATES_DAYS: 7
}

export function getMapPlotTextFont(background: MapBackground) {
    if (
        background === 'satellite'
        || background === 'satellite-streets'
        || background === 'dark'
        || background === 'carto-darkmatter'
    ) {
        return Object.create(MAP_PLOT_TEXTFONT_SATELLITE)
    } else {
        return Object.create(MAP_PLOT_TEXTFONT)
    }
    // return background.startsWith('satellite') ? Object.create(MAP_PLOT_TEXTFONT_SATELLITE) : Object.create(MAP_PLOT_TEXTFONT)
}

export const SELECT_NO_PLOT_LIMIT = 10
export const WARN_SLOW_PLOT_MEASUREMENTS = 2000
export const SINGLE_OBJECT_ZOOM_LEVEL = 12.5
export const NO_OBJECT_MAP_POSITION: MapPosition = {
    center: {lat: 59.473196, lon: 14.468002},
    zoom: 5,
}
export const TIME_WEIGHT_MAX_DAYS = 90
export const INPUT_DATE_MIN = "0001-01-01"
export const INPUT_DATE_MAX = "3000-01-01"
export const FORM_DEBOUNCE_TIME = 500

export const JIRA_ISSUES = {
    url: 'https://akvifar.atlassian.net/secure/CreateIssueDetails!init.jspa',
    pid: 10001,
    /**
     * berättelse: 10004
     * uppgift:    10005
     * bug:        10007
     */
    issuetype: 10007,

} as const

export const VIDE_ROUTES = {
    project: {
        _base: 'project',
        correlations: 'correlations',
        popupSelect: 'popup-select',
        objects: 'objects',
        measurements: 'measurements',
        import: 'import',
        batchPlot: 'batch-plot',
        fieldApp: 'field-app',
        groups: 'groups',
        // editObject: 'edit-object',
        events: 'events',
        object: {
            _base: 'object',
            triggers: 'triggers',
            edit: 'edit',
        }
    },
    about: 'about',
    preferences: 'preferences',
} as const


export const POPUP_NAME = 'videSelectPopup'

export const UNCERTAINTIES = [0.001, 0.01, 0.1, 1, 10, null] as const
export const UNCERTAINTIES_NO_NULL = [0.001, 0.01, 0.1, 1, 10] as const
export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100, 1000] as const

export const APP_NAME = 'Akvifär hydro database'
export const NUMBER_FORMAT = '.3-3'
export const DATE_FORMAT = 'YYYY-MM-dd'
export const DATETIME_FORMAT = 'YYYY-MM-dd HH:mm:ss OOOO'
export const MAX_DEVIATION = 0.4
export const PLOT_CONFIG = {
    batchPlot: {
        width: 850,
        height: 500,
    },
    timeline: {
        /**
         * for valid symbol values, @see https://plotly.com/javascript/reference/scatter/#scatter-marker-symbol
         */
        markerCorrelationStatus: {symbol: 'circle', size: 7,} as const,
        markerNonChecked: {symbol: 'star', size: 7} as const,
        markerTriggered: {symbol: 'circle-open', size: 10, color: 'red'} as const,
        markerAffected: {symbol: 'circle-open', size: 10} as const,
        colorAffected: 'red' as const,
        colorNoResult: 'gray' as const,
        shapeLine: {width: 1, dash: 'longdash'} as const,

    },
    correlation: {
        base: {
            color: 'blue',
        },
        reference: {
            color: 'lightblue',
            verticalLineWidth: 5,
            middleColor: 'black',
            middleLineSize: 1,
            middleMarkerSize: 2,
            limitColor: 'green',
        },
    },
    plotlyStyleBig: {
        width: '100%',
        height: '95vh',
    },
    plotlyStyle: {
        width: '100%',
        height: '50vh',
    },
    // mapboxAccessToken: "pk.eyJ1IjoibWljY2UiLCJhIjoiY2ttcWNoYzc3Mm4zMjJwcGZtam5obnQ0MiJ9.Dk8-l5EEgBESQ5dg77HK1Q",
    mapboxAccessToken: "pk.eyJ1IjoiYWt2aWZhciIsImEiOiJjbTJhNGE2eTYwYTU1Mm9zNzhhdW55eTFpIn0.9ibNOgoeeIf5Xyve1Q_5-Q",
}

export const OUTDATED_CONFIG = {
    maxReloads: environment.outdatedReloads,
    waitPerObject: environment.outdatedObjectWait,
} as const

export const CORRELATION_OPTIONS = {
    refStartAheadMonths: 1
} as const

export const OBJECT_INFO = {
    nrMeasurementComments: 4,
} as const

// Your web app's Firebase configuration
// export const FIREBASE_CONFIG = {
//     apiKey: "AIzaSyAJ8wECVvL_BQkZT754S_ZivxEi8R896Fw",
//     authDomain: "akvifar.firebaseapp.com",
//     projectId: "akvifar",
//     storageBucket: "akvifar.appspot.com",
//     messagingSenderId: "249712338340",
//     appId: "1:249712338340:web:48f15667f0efd952ca8d31"
// }
// export const FIREBASE_UI_AUTH_CONFIG: firebaseui.auth.Config = {
//     signInFlow: 'popup',
//     signInOptions: [
//         // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
//         {
//             requireDisplayName: true,
//             provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
//             signInMethod: firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
//             // signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
//         },
//         // {
//         //     scopes: [
//         //         'public_profile',
//         //         'email',
//         //         'user_likes',
//         //         'user_friends'
//         //     ],
//         //     customParameters: {
//         //         'auth_type': 'reauthenticate'
//         //     },
//         //     provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID
//         // },
//         // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
//         // firebase.auth.GithubAuthProvider.PROVIDER_ID,
//         // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
//         // firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID,
//         // 'apple.com',
//         // 'microsoft.com',
//         // 'yahoo.com',
//     ],
//     // tosUrl: './junk.html42',
//     // privacyPolicyUrl: '<your-privacyPolicyUrl-link>',
//     // credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO
// }
// export const JWT_CONFIG: JwtModuleOptions = {
//     jwtOptionsProvider: {
//         provide: JWT_OPTIONS,
//         useFactory: jwtOptionsFactory,
//         deps: [AngularFireAuth]
//     }
// }
// export function jwtOptionsFactory(auth: AngularFireAuth) {
//     return {
//         tokenGetter: async () => {
//             // return 'asdasdf
// klweaflfjasdkjfkjfhasjadksfadjksfhasdjkfhadjksfadsfasdfdsffjaseijselkfjsleifjjaseil;fjasl;efijasl;eifjaslei;fjlijesaflaisejf'
// // console.log('getting token') const user = await firstValueFrom(auth.user) // console.log('getting token for ',
// user) const token = await user?.getIdToken() // console.log('got token ' + token) return token }, allowedDomains: [
// "localhost:5555", "localhost:8000", ], } }
