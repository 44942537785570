import {Component, Inject} from '@angular/core'
import {toSignal} from "@angular/core/rxjs-interop"
import {JsonPipe} from "@angular/common"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef} from "@angular/material/dialog"
import {MatDividerModule} from "@angular/material/divider"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {MatIconModule} from "@angular/material/icon"
import {MatTableDataSource, MatTableModule} from "@angular/material/table"
import {MatSortModule} from "@angular/material/sort"
import {MatTooltipModule} from "@angular/material/tooltip"
import {MatCheckboxModule} from "@angular/material/checkbox"

import {map} from "rxjs"

import {LogListComponent} from "../../../log-list/log-list.component"
import {LogContainer} from "../../../log-list/log-container"
import {CoordinateSystem, HeightSystem, LentObject, License, Project, PROJECT_SHARE_TYPES} from "../../../api/api-types"
import {VideDataService} from "../../../api/vide-data.service"
import {equalIds, getErrorMessage} from "../../../shared/vide-helper"
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
    ConformDialogResult
} from "../../../dialogs/confirm-dialog/confirm-dialog.component"

export type EditProjectComponentData = MatDialogConfig<Project & {
    lentObjects: LentObject[],
}>['data']
export type EditProjectComponentResult = boolean

type Share = {
    object: LentObject,
    sharedTo: LentObject['shared_to'][number],
    // deleted: boolean,
}

@Component({
    selector: 'app-edit-project',
    standalone: true,
    imports: [
        JsonPipe,
        LogListComponent,
        MatButtonModule,
        MatCheckboxModule,
        MatDialogModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressBarModule,
        MatSelectModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        ReactiveFormsModule,
    ],
    templateUrl: './edit-project.component.html',
    styleUrl: './edit-project.component.scss'
})
export class EditProjectComponent {
    protected waiting: boolean = false
    protected readonly logs = new LogContainer()

    protected readonly form = this.formBuilder.nonNullable.group({
        name: ['', [Validators.required, Validators.maxLength(255)]],
        comment: ['' as null | string, Validators.maxLength(255)],
        coordinate_system: [undefined as CoordinateSystem | undefined, Validators.required],
        height_system: [undefined as HeightSystem | undefined, Validators.required],
        share_type: [undefined as typeof PROJECT_SHARE_TYPES[number] | undefined, Validators.required],

    })
    protected readonly addForm = this.formBuilder.nonNullable.group({
        license: [null as License | null, Validators.required],
    })
    readonly coordinateSystems = toSignal(this.dataService.utility$.pipe(map(u => u.coordinate_system)))
    readonly heightSystems = toSignal(this.dataService.utility$.pipe(map(u => u.height_system)))
    protected readonly licenses = toSignal(this.dataService.licenses$, {initialValue: []})

    protected readonly dataSource = new MatTableDataSource(
        this.data?.lentObjects.flatMap(l => l.shared_to.map(s => {
            const newVar: Share = {object: l, sharedTo: s}
            return newVar
        })))
    protected readonly columnsToDisplay = [
        'object_name',
        'lent_to',
        'readonly',
        'delete',
    ] as const

    protected someEdit = false

    constructor(
        private readonly dialog: MatDialog,
        private readonly dataService: VideDataService,
        private readonly formBuilder: FormBuilder,
        private readonly ref: MatDialogRef<EditProjectComponent, EditProjectComponentResult>,
        @Inject(MAT_DIALOG_DATA) public data: EditProjectComponentData,
    ) {
        if (data) {
            this.form.patchValue(data)
        }
    }

    protected readonly getErrorMessage = getErrorMessage
    protected readonly equalIds = equalIds
    protected readonly PROJECT_SHARE_TYPES = PROJECT_SHARE_TYPES

    // private getFormGroup(p: LentObject['shared_to'][number]) {
    //     return this.formBuilder.group({readonly: p.readonly, comment: p.comment, delete: false})
    // }

    saveEdit(data: Project) {
        this.form.markAsTouched()
        if (!this.form.valid) {
            console.warn("Form invalid")
            return
        }
        const values = this.form.getRawValue()
        console.log(values)
        this.dataService.updateProject({
            id: data.id,
            ...values
        }).subscribe(r => {
            this.logs.add(r, `Update ${data.name}`)
            if (r.success) {
                // this.someEdit=true
                // this.dataService.reloadUserData()
                this.ref.close(true)
            }
        })
    }

    addProject() {
        this.form.markAllAsTouched()
        this.addForm.markAllAsTouched()
        if (!this.form.valid) {
            console.warn("Form invalid")
            return
        }
        if (!this.addForm.valid) {
            console.log("Add form is not valid")
            return
        }
        const res = {
            ...this.form.getRawValue(),
            ...this.addForm.getRawValue(),
        }
        if (!(res
            && res.coordinate_system
            && res.height_system
            && res.license
        )) {
            return
        }
        const values = {
            name: res.name,
            license: res.license,
            comment: res.comment,
            coordinate_system: res.coordinate_system,
            height_system: res.height_system,
        }
        console.log(values)
        this.dataService.createProject({
            ...values,
        }).subscribe(r => {
            this.logs.add(r, `Created ${res.name}`)
            if (r.success) {
                this.dataService.reloadUserData()
                this.ref.close(true)
            }
        })
    }

    toggleReadonly(row: Share) {
        console.log(row)
        if (!this.data) {
            console.log("No data, skipping")
            return
        }
        this.dataService.setSharedObject({id: row.sharedTo.project_id}, {id: row.object.id}, {
            readonly: !row.sharedTo.readonly,
            comment: row.sharedTo.comment
        }).subscribe(r => {
            if (r.success) {
                // No loading of data in the dialog, just change it...
                row.sharedTo.readonly = !row.sharedTo.readonly
                this.someEdit = true
            } else {
                this.logs.add(r, `Toggle share of ${row.object.name}`)
            }
        })

    }

    stopSharing(row: Share) {
        this.dialog.open<
            ConfirmDialogComponent,
            ConfirmDialogData,
            ConformDialogResult
        >(ConfirmDialogComponent, {
            data: {
                header: `Unshare ${row.object.name}`,
                text: `The object can be re-shared by anyone having read access to ${this.data?.name}, and write access to ${row.sharedTo.project_name}`,
                negative_text: 'Cancel',
                positive_text: 'Unshare',
            }
        }).afterClosed().subscribe(result => {
            if (result) {
                this.dataService.deleteSharedObject({id: row.sharedTo.project_id}, {id: row.object.id}).subscribe(r => {
                    if (r.success) {
                        // No loading of data in the dialog, just change it...
                        if (this.dataSource.data) {
                            const shareIdx = this.dataSource.data.findIndex(s => s.object.id === row.object.id && s.sharedTo.project_id === row.sharedTo.project_id)
                            if (shareIdx !== -1) {
                                this.dataSource.data.splice(shareIdx, 1)
                                this.dataSource.data = this.dataSource.data
                            }
                        }
                        // row.deleted = !row.deleted
                        this.someEdit = true
                    } else {
                        this.logs.add(r, `Unshare of ${row.object.name}`)
                    }
                })
            }
        })

    }
}
