import {Component} from '@angular/core'
import {DatePipe} from "@angular/common"
import {MatButtonModule} from "@angular/material/button"
import {MatDialog} from "@angular/material/dialog"
import {MatIconModule} from "@angular/material/icon"
import {MatStepperModule} from "@angular/material/stepper"
import {MatTableModule} from "@angular/material/table"
import {toSignal} from "@angular/core/rxjs-interop"

import {combineLatest, map, of, switchMap} from "rxjs"

import {DiverInstance, Measurement} from "../../../api/api-types"
import {DiverService} from "../diver.service"
import {SlimObject} from "../diver.component"
import {VideDataService} from "../../../api/vide-data.service"
import {ConfirmDialogComponent} from "../../../dialogs/confirm-dialog/confirm-dialog.component"
import {DATETIME_FORMAT} from "../../../../constants"

@Component({
    selector: 'app-existing-instances',
    standalone: true,
    imports: [
        DatePipe,
        MatButtonModule,
        MatIconModule,
        MatStepperModule,
        MatTableModule,
    ],
    templateUrl: './diver-annotations.component.html',
    styleUrl: './diver-annotations.component.scss'
})
export class DiverAnnotationsComponent {
    // inputs
    // outputs
    // view children
    // signals
    // properties
    readonly object = toSignal(this.diverService.selectedObject$)
    // readonly object = input.required<SlimObject>()
    // private readonly object$ = toObservable(this.object)
    readonly uncompensatedMeasurements = toSignal(combineLatest([
        this.dataService.projectNotNull$,
        this.diverService.selectedObject$,
        this.dataService.measureTypeDiverUncompensated$,
    ]).pipe(
        switchMap(([project, object, mt]) =>
            object
                ? this.dataService.getMeasurements(project, object, mt).pipe(map(mr => mr.measurements))
                : of([])
        ),
    ))
    readonly unreferencedMeasurements = toSignal(combineLatest([
        this.dataService.projectNotNull$,
        this.diverService.selectedObject$,
        this.dataService.measureTypeDiverUnreferenced$,
    ]).pipe(
        switchMap(([project, object, mt]) =>
            object
                ? this.dataService.getMeasurements(project, object, mt).pipe(map(mr => mr.measurements))
                : of([])
        ),
    ))

    readonly annotations$ = combineLatest([
        this.dataService.projectNotNull$,
        this.diverService.selectedObject$,
    ]).pipe(
        switchMap(([p, o]) =>
            o
                ? this.dataService.getDiverAnnotations(p, o)
                : of([])
        ),
    )

    displayedColumns = [
        'comment',
        'first_date',
        // "id",
        // "object_id",
        // "reference_measurement_id",
        "reference",
        'serial_number',
        'created_at',
        'updated_at',
        'delete',
    ]

    constructor(
        private readonly dataService: VideDataService,
        private readonly dialog: MatDialog,
        private readonly diverService: DiverService,
    ) {
    }

    delete(element: DiverInstance) {
        this.diverService.deleteInstance(element)
    }


    deleteMeasurements(object: SlimObject, measurements: readonly Measurement[]) {
        this.dialog.open<
            ConfirmDialogComponent,
            ConfirmDialogComponent['data'],
            ConfirmDialogComponent['response']
        >(ConfirmDialogComponent, {
            data: {
                text: `Really delete ${measurements.length} measurements? This cannot be undone.`,
                header: `Delete measurements
                         from ${object.name}`,
                positive_text: 'Delete',
                negative_text: 'Cancel',
            }
        }).afterClosed().subscribe(x => {
            if (x) this.diverService.deleteMeasurements(object, measurements)
        })
    }

    protected readonly DATETIME_FORMAT = DATETIME_FORMAT
}
