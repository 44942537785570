<div class="padded">
    <h1>Plots</h1>

    <section>
        <h2>Measurements</h2>

        Each measurement has a status:
        <app-constant-name>{{ joinWithEnding(dataStatusNames(), 'or') }}.</app-constant-name>
        It also has a text alternative and a value. Either the text alternative or the value may be empty, but not both.

        <dl>
            <dt> Normal measurement</dt>
            <dd> The measurement status is not <em>{{ dataStatusName('data_status_invalid') }}</em>. If there is no
                value, there must be a text alternative.
            </dd>
            <dt> Checked measurement</dt>
            <dd> Normal measurement, with status
                <app-constant-name>{{ dataStatusName('data_status_standard') }}</app-constant-name>
                or
                <app-constant-name>{{ dataStatusName('data_status_affected') }}.</app-constant-name>
            </dd>
            <dt> Numeric measurement</dt>
            <dd> Normal measurement with no text alternative. Represents a valid measurement value.</dd>
            <dt> Checked numeric measurement</dt>
            <dd> Checked measurement with no text alternative. Represents a valid measurement value.</dd>
            <dt> Non affected measurement</dt>
            <dd> Normal measurement, with status
                <app-constant-name>{{ dataStatusName('data_status_standard') }}.</app-constant-name>
                If the object has a defined date
                <app-constant-name>Permanently affected after,</app-constant-name>
                measurements after that date are excluded. If correlation calculations are used, measurements confirmed
                as affected are also excluded.
            </dd>
            <dt> Non affected numeric measurement</dt>
            <dd> Non affected measurement with no text alternative. Represents a valid measurement value.</dd>
            <dt> Non standard measurement</dt>
            <dd> Normal measurement, with non-normal status
                <app-constant-name>({{ dataStatusName('data_status_affected') }}</app-constant-name>
                or
                <app-constant-name>{{ dataStatusName('data_status_not_checked') }}).</app-constant-name>
            </dd>
            <dt> Error measurement</dt>
            <dd> Normal measurement, but with a text alternative (e.g. dry). If a value exists, it does not represent a
                valid
                measurement.
            </dd>
        </dl>
    </section>

    <section>
        <h2>Plot types</h2>
        <dl>
            <dt>Timeline plot</dt>
            <dd> Plot values as a function of time. Values are displayed using one of the transformation below.
                Consecutive
                <app-constant-name>Numeric measurement</app-constant-name>
                is plotted as a line, interrupted by
                <app-constant-name>Error measurement,</app-constant-name>
                plotted with error symbols.
                <app-constant-name>Non standard measurements</app-constant-name>
                are optionally indicated with extra circles.
            </dd>
            <dt>Map plot</dt>
            <dd> Map with object measurements or other data on the object location. Object with a value is plotted as a
                colored marker, objects without are plotted black with error code in the label.
                <dl>
                    <dt>Value from range</dt>
                    <dd>Measurement of selected type, such as first, last or average, within a specified time interval.
                        Measurements are displayed using one of the transformation below.
                    </dd>
                    <dt>Statistics for range</dt>
                    <dd>Statistics for measurements of the selected type. Limited by selected time range. An unset date
                        means no limit in that direction.
                    </dd>
                    <dt>Object attributes</dt>
                    <dd>Attribute values, primarily applicable to wells.</dd>
                </dl>
            </dd>
            <dt>Correlation plot</dt>
            <dd>Timeline plot of measurements of the base object and reference interval from the correlated object. Base
                objects are plotted similar to Timeline plot, and the reference interval is derived from
                <app-constant-name>Non affected numeric measurement.</app-constant-name>
            </dd>
            <dt>Correlation map</dt>
            <dd>Map with objects correlation status. For the chosen time period, objects are plotted with the difference
                from expected value, and black if there is no such calculation for the period.
            </dd>
        </dl>
    </section>

    <section>
        <h2>Statistics</h2>

        <p> Different statistics are calculated from the measurements.
            The statistical values are used in Statistics view and in different plots.</p>
        <p> When an time range is set in map plot, the measurements are limited to that range.</p>
        <p> In map plot, when <code>Affected measurements not included</code> is selected, Non affected [numeric]
            measurement is used instead of Checked [numeric] measurements</p>

        <dl>
            <dt>First/last date [value]</dt>
            <dd>Date of first/last <em>Checked [numeric] measurements</em></dd>
            <dt>First/last [value]</dt>
            <dd>First/last <em>Checked [numeric] measurements</em>.</dd>

            <dt>Fill rate</dt>
            <dd>The value as percentage of Average yearly min (0%) and Average yearly max (100%)</dd>

            <dt>Number of measurements [with value]</dt>
            <dd>Number of <em>Checked [numeric] measurements</em></dd>
            <dt>Share of measurements having value</dt>
            <dd>Percentage of <em>number of measurements with value</em> to <em>number of measurements</em></dd>

            <dt>Average yearly minimum/maximum</dt>
            <dd>Arithmetic average of the yearly minimum/maximum of <em>Checked numeric measurements</em>.</dd>

            <dt>Trend</dt>
            <dd>Linear trend of <em>checked numeric measurements</em>. The results are presented as average change
                per {{ TREND_NR_DAYS }} days
            </dd>

            <dt>Min/max/average</dt>
            <dd>Minimum/maximum/arithmetic average of the <em>checked numeric measurements</em>
            </dd>

            <dt>Time weighted average</dt>
            <dd>Weighted average of <em>checked numeric measurements</em>. Weights are time between measurements,
                limited to
                maximum {{ TIME_WEIGHT_MAX_DAYS }} days.
            </dd>
        </dl>
    </section>

    <section>
        <h2>Measurement transformation</h2>
        <p>The values shown can be transformed in several ways.</p>
        <dl>
            <dt> Resulting value</dt>
            <dd> Result of measurement</dd>
            <dt> Fill rate</dt>
            <dd> The value is normalized in relation to the object’s average yearly minimum (0 %) and yearly maximum
                (100 %).
            </dd>
            <dt> Deviation from average</dt>
            <dd> The value’s deviation from the object’s
                <app-constant-name>Time weighted average.</app-constant-name>
            </dd>
            <dt>Deviation from zero level date</dt>
            <dd> The value’s deviation from the first value after zero level date. If no date is given deviation from
                first measurement is used.
            </dd>
            <dt> Below surface</dt>
            <dd> The distance from the surface to the measurement. Only relevant to wells.</dd>
            <dt> Measured value</dt>
            <dd> The actually measured value, e.g. the measured depth in a well before calculating the level.</dd>
        </dl>
    </section>

</div>
