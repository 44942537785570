@if (figure(); as figure) {
    <plotly-plot [data]="figure.data" [layout]="figure.layout"
                 [config]="figure.config" [style]="plotlyStyle" [useResizeHandler]="true"
                 (selected)="plotlySelected($event)"
                 (plotlyClick)="plotlyClick($event)" (relayout)="plotlyRelayout($event)">
    </plotly-plot>
} @else {
    <div [style.height]="plotlyStyle.height" class="plot-spinner">
        <mat-spinner mode="indeterminate"></mat-spinner>
    </div>
}
