@if (object(); as object) {
    <p>
        The file format is assumed to be csv-files from DiverOffice and time is interpreted as local time.
    </p>

    <div class="vide-form-field">
        <div class="vide-columns-wide vide-form-field-row">
            <app-input
                type="file"
                [formControl]="form.controls.file"
                [multiple]="false"
                placeholder="Select file"
                accept=".csv"
            />
        </div>
    </div>

    @if (fileError(); as e) {
        <div class="error"> {{ e }}</div>
    } @else {
        <div [style.visibility]="'hidden'">X</div>
    }

    @if (figure(); as figure) {
        <p>Click in the plot to set start and end of interval to use. Double click to clear.</p>
        <plotly-plot
            [data]="figure.data"
            [layout]="figure.layout"
            [config]="figure.config"
            [useResizeHandler]="true"
            (plotlyClick)="plotlyClick($event)"
            (doubleClick)="doubleClick()"
        />
    }

    @if (fileData()) {
        <div class="vide-columns-wide">
            @if (annotations(); as ann) {
                @if (ann[0]; as begin) {
                    <div>Begin: {{ begin.x }}</div>
                }
                @if (ann[1]; as end) {
                    <div>End: {{ end.x }}</div>
                }
            }

            <p> Found {{ nrMeasurements() ?? 'no' }} measurements </p>

            <button mat-raised-button (click)="showHead()">Show file header</button>
        </div>

        <div>
            <div class="vide-columns-wide vide-form-field vide-form-field-row">
                <ng-select
                    [clearable]="false"
                    [formControl]="form.controls.code"
                    [items]="DIVER_DATA_TYPES"
                    placeholder="Type of measurement"
                />
            </div>
        </div>

        <div>
            Continue with the following steps according to the type,
            or save the measurements to {{ object.name }}.
        </div>
    }


    @if (saving()) {
        <mat-progress-bar mode="indeterminate"/>
    }

    <div class="stepper-navigate">
        <button mat-button matStepperPrevious type="button">Previous</button>
        <button mat-button matStepperNext type="button">Next</button>
        <button
            mat-button
            [disabled]="!nrMeasurements() || saving()"
            (click)="save(object)"
        >
            @if (saving()) {
                Saving...
            } @else {
                Save to {{ object.name }}
            }
        </button>
    </div>
}
