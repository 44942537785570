<h1 mat-dialog-title>
    @if (data) {
        Edit project {{ data.name }}
    } @else {
        Add project
    }
</h1>

<mat-dialog-content>
    <div>
        @if (form.controls.name; as control) {
            <mat-form-field>
                <mat-label>Name</mat-label>
                <input matInput [formControl]="control">
                <mat-error>{{ getErrorMessage(control) }}</mat-error>
            </mat-form-field>
        }
        @if (form.controls.coordinate_system; as control) {
            <mat-form-field>
                <mat-label>Coordinate system</mat-label>
                <mat-select [formControl]="control" [compareWith]="equalIds">
                    @for (c of coordinateSystems(); track c.id) {
                        <mat-option [value]="c">{{ c.name }}</mat-option>
                    }
                </mat-select>
                <mat-error>{{ getErrorMessage(control) }}</mat-error>
            </mat-form-field>
        }
        @if (form.controls.height_system; as control) {
            <mat-form-field>
                <mat-label>Height system</mat-label>
                <mat-select [formControl]="control" [compareWith]="equalIds">
                    @for (c of heightSystems(); track c.id) {
                        <mat-option [value]="c">{{ c.name }}</mat-option>
                    }
                </mat-select>
                <mat-error>{{ getErrorMessage(control) }}</mat-error>
            </mat-form-field>
        }
        @if (form.controls.share_type; as control) {
            <mat-form-field>
                <mat-label>Share type</mat-label>
                <mat-select [formControl]="control">
                    @for (c of PROJECT_SHARE_TYPES; track c) {
                        <mat-option [value]="c">{{ c }}</mat-option>
                    }
                </mat-select>
                <mat-error>{{ getErrorMessage(control) }}</mat-error>
            </mat-form-field>
        }

        @if (!data) {
            @if (addForm.controls.license; as control) {
                <mat-form-field>
                    <mat-label>License</mat-label>
                    <mat-select [formControl]="control">
                        @for (l of licenses(); track l) {
                            <mat-option [value]="l">{{ l.name }}</mat-option>
                        }
                    </mat-select>
                    <mat-error>{{ getErrorMessage(control) }}</mat-error>
                </mat-form-field>
            }
        }

    </div>
    <div>
        @if (form.controls.comment; as control) {
            <mat-form-field [style.width]="'40ex'">
                <mat-label>Comment</mat-label>
                <textarea rows="8" matInput [formControl]="control"></textarea>
                <mat-error>{{ getErrorMessage(control) }}</mat-error>
            </mat-form-field>
        }
    </div>

    <!--  If sharing is changed  -->
    @if (data
    && form.controls.share_type.dirty
    && form.controls.share_type.value !== 'Public'
    && data.share_type !== 'Private'
    && form.controls.share_type.value !== data.share_type) {
        <div class="warning">Limiting sharing may cause current object shares to be revoked</div>
    }

    <!--    {{lentObjects|json}}-->
    @if (data) {
        <!--        <ul>-->
            <!--            @for (o of data.lentObjects; track o.id) {-->
            <!--                @for (s of o.shared_to; track s.id) {-->
            <!--                    <li> {{ o.name }} shared to {{ s.name }}  {{ s.owner_name }}-->
            <!--                        <button mat-mini-fab aria-label="Toggle readonly state">-->
            <!--                            {{ s.readonly ? 'Readonly' : 'Writeable' }}-->
            <!--                            <mat-icon>delete</mat-icon>-->
            <!--                        </button>-->
            <!--                        <button mat-mini-fab aria-label="Stop sharing">-->
            <!--                            <mat-icon>delete</mat-icon>-->
            <!--                        </button>-->
            <!--                    </li>-->
            <!--                }-->
            <!--            } @empty {-->
            <!--                <p>No objects shared with other projects</p>-->
            <!--            }-->
            <!--        </ul>-->

        @if (dataSource.data.length) {
            <h2> Objects in  {{ data.name }} shared with other projects</h2>
            <table mat-table matSort [dataSource]="dataSource">

                <ng-container matColumnDef="test">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Object</th>
                    <td *matCellDef="let row">{{ row|json }}</td>
                </ng-container>
                <ng-container matColumnDef="object_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Object</th>
                    <td *matCellDef="let row">{{ row.object.name }}</td>
                </ng-container>
                <ng-container matColumnDef="lent_to">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Shared to</th>
                    <td *matCellDef="let row">
                        {{ row.sharedTo.project_name }} [{{ row.sharedTo.license_name }}]
                    </td>
                </ng-container>
                <ng-container matColumnDef="comment">
                    <th mat-header-cell *matHeaderCellDef> Comment</th>
                    <td *matCellDef="let row">
                        @if (row.form.controls.comment; as cntrl) {
                            <div class="vide-editable-value" [class.dirty]="cntrl.dirty">
                                <input matInput [formControl]="cntrl">
                            </div>
                        }
                    </td>
                </ng-container>
                <ng-container matColumnDef="readonly">
                    <th mat-header-cell *matHeaderCellDef>Readonly</th>
                    <td *matCellDef="let row">
                        <mat-checkbox [checked]="row.sharedTo.readonly" (click)="toggleReadonly(row)"></mat-checkbox>
                        <!--                        <button-->
                        <!--                            mat-mini-fab aria-label="Toggle readonly state"-->
                        <!--                            (click)="toggleReadonly(row)"-->
                        <!--                            [matTooltip]="'Toggle readonly state'"-->
                        <!--                        >-->
                        <!--                            @if (row.sharedTo.readonly) {-->
                        <!--                                <mat-icon>edit_off</mat-icon>-->
                        <!--                                &lt;!&ndash;                                <mat-icon>lock</mat-icon>&ndash;&gt;-->
                        <!--                            } @else {-->
                        <!--                                <mat-icon>edit</mat-icon>-->
                        <!--                                &lt;!&ndash;                                <mat-icon>lock_open_right</mat-icon>&ndash;&gt;-->
                        <!--                            }-->
                        <!--                        </button>-->
                    </td>
                </ng-container>
                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td *matCellDef="let row">
                        <button
                            mat-mini-fab aria-label="Stop sharing" matTooltip="Stop sharing"
                            (click)="stopSharing(row)"
                        >
                            <mat-icon>cancel</mat-icon>
                        </button>
                        <!--                        <button mat-mini-fab aria-label="Stop sharing" matTooltip="Stop sharing"-->
                        <!--                                (click)="toggleShare(row)">-->
                        <!--                            @if (row.deleted) {-->
                        <!--                                <mat-icon>undelete</mat-icon>-->
                        <!--                            } @else {-->
                        <!--                                <mat-icon>delete</mat-icon>-->
                        <!--                            }-->
                        <!--                        </button>-->
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
            </table>
        } @else {
            <h2>No objects shared with other projects</h2>
        }
    }
    <mat-divider/>
</mat-dialog-content>

<mat-dialog-actions>
    @if (data) {
        <button mat-raised-button (click)="saveEdit(data)"> Save</button>
    } @else {
        <button mat-raised-button (click)="addProject()"> Add</button>
    }
    <button mat-raised-button [mat-dialog-close]="someEdit">Exit</button>
</mat-dialog-actions>
<app-log-list [container]="logs"/>
@if (waiting) {
    <mat-progress-bar mode="indeterminate"/>
}
