import {PlotlyMouseEvent} from "../vide-types"

export function moveMenu(event: PlotlyMouseEvent) {
    return () => {
        const menuCollection = document.getElementsByClassName('cdk-overlay-pane') as HTMLCollectionOf<HTMLElement>
        const menu = menuCollection[0]
        if (!menu) return
        const screenWidth = window.innerWidth
        const screenHeight = window.innerHeight
        const pageX = event.event.pageX
        const pageY = event.event.pageY
        const clientHeight = menu.clientHeight
        const clientWidth = menu.clientWidth

        const diffX = pageX + clientWidth > screenWidth ? -5 - clientWidth : 5
        const diffY = pageY + clientHeight > screenHeight ? -5 - clientHeight : 5
        menu.style.position = 'absolute'
        menu.style.left = event.event.pageX + diffX + 'px'
        menu.style.top = event.event.pageY + diffY + 'px'
    }
}
