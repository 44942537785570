<mat-card>
    <mat-card-header>
        <mat-card-title> Göteborg stad grundvatten</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <p> Selected stations will be added to the project. New water level measurements will be added nightly. </p>
        <p> Data provided from <a href="https://www.vattenigoteborg.se/Groundwater/ShowObservations">Vatten i
            Göteborg</a>. </p>
        <mat-checkbox [formControl]="form.controls.showInactive">Show inactive stations</mat-checkbox>

        <mat-menu #clickMenu>
            <ng-template matMenuContent let-element="element">
                @if (element.tainted) {
                    <button mat-menu-item disabled>{{ element.station.name }} in progress</button>
                } @else if (element.external) {
                    <button mat-menu-item (click)="remove(element)">Remove {{ element.station.name }}</button>
                } @else {
                    <button mat-menu-item (click)="add(element)">Add {{ element.station.name }}</button>
                }
            </ng-template>
        </mat-menu>

        <mat-tab-group>
            <mat-tab label="List">
                <table mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button
                                [disabled]="element.tainted"
                                [matMenuTriggerFor]="clickMenu"
                                [matMenuTriggerData]="{element}"
                                mat-icon-button>
                                <mat-icon>more_horiz</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="imported">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Imported</th>
                        <td mat-cell *matCellDef="let element">
                            @if (element.external) {
                                <mat-icon>check</mat-icon>
                            }
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="catchment">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Catchment</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.station.catchment }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="active">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Active</th>
                        <td mat-cell *matCellDef="let element">
                            @if (element.station.isActive) {
                                <mat-icon>check</mat-icon>
                            }
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.station.name }}
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </mat-tab>

            <mat-tab label="Map">
                <ng-template matTabContent>
                    @if (figure(); as figure) {
                        <plotly-plot
                            [data]="figure.data"
                            [layout]="figure.layout"
                            [config]="figure.config"
                            [useResizeHandler]="true"
                            [style]="plotlyStyle"
                            (plotlyClick)="plotlyClick($event)"
                            (relayout)="onPlotlyRelayout($event)"
                        />
                    } @else {
                        <div [style.height]="plotlyStyle.height" class="plot-spinner">
                            <mat-spinner [style.height]="plotlyStyle.height" mode="indeterminate"/>
                        </div>
                    }
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>

<mat-card>
    @if (httpExtern()) {
        <mat-progress-bar mode="buffer"/>
    }
    @if (httpStatus.vide.hasValue()) {
        <mat-progress-bar mode='indeterminate'/>
    }
    <app-log-list [container]="logs"></app-log-list>
</mat-card>
