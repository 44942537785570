import {Injectable} from '@angular/core'

import proj4 from 'proj4'
import {CoordinateSystem, Position} from "../api/api-types"

const WGS_PROJ = 'WGS84'

/**

 Axis order

 By default, proj4 uses [x,y] axis order for projected (cartesian) coordinate systems and [x=longitude,y=latitude] for
 geographic coordinates. To enforce the axis order of the provided proj or wkt string, use the

 proj4(fromProjection, toProjection).forward(coordinate, enforceAxis);
 proj4(fromProjection, toProjection).inverse(coordinate, enforceAxis);

 signatures with enforceAxis set to true.

 x = ew
 y = ns
 in vide.

 */
@Injectable({
    providedIn: 'root'
})
export class CoordinateTransformService {

    private readonly cache = new Map<string, Readonly<proj4.Converter>>()

    constructor() {
    }

    private getTag(a: string, b: string) {
        return `${a}-${b}`
    }

    /**

     Axis order

     By default, proj4 uses [x,y] axis order for projected (cartesian) coordinate systems and [x=longitude, y=latitude] for
     geographic coordinates.

     x = ew
     y = ns
     in vide.

     * @deprecated Confusing coordinates
     */
    getWgsConverter(fromCoordinateSystem: CoordinateSystem) {
        return this._getConverter(fromCoordinateSystem.proj4, WGS_PROJ)
    }

    /**
     *      x = ew
     *      y = ns
     *      in vide.
     *
     * @param toCoordinateSystem
     */
    getTransformerFromWgs(toCoordinateSystem: CoordinateSystem): (p: Position) => { x: number, y: number } {
        const converter = this._getConverter(WGS_PROJ, toCoordinateSystem.proj4)
        return p => {
            return converter.forward({x: p.lon, y: p.lat})
        }
    }

    /**
     *      x = ew
     *      y = ns
     *      in vide.
     *
     * @param fromCoordinateSystem
     */
    getTransformerToWgs(fromCoordinateSystem: CoordinateSystem): (p: { x: number, y: number }) => Position {
        const converter = this._getConverter(fromCoordinateSystem.proj4, WGS_PROJ,)
        return p => {
            const geo = converter.forward(p)
            return {lat: geo.y, lon: geo.x}
        }
    }

    /**

     Axis order

     By default, proj4 uses [x,y] axis order for projected (cartesian) coordinate systems and [x=longitude,y=latitude] for
     geographic coordinates.
     x = ew
     y = ns
     in vide.

     * @deprecated
     */
    getConverter(fromCoordinateSystem: CoordinateSystem, toCoordinateSystem: CoordinateSystem) {
        return this._getConverter(fromCoordinateSystem.proj4, toCoordinateSystem.proj4)
    }

    private _getConverter(fromCoordinateSystem: string, toCoordinateSystem: string) {
        const tag = this.getTag(fromCoordinateSystem, toCoordinateSystem)
        let c = this.cache.get(tag)
        if (c === undefined) {
            c = proj4(fromCoordinateSystem, toCoordinateSystem)
            this.cache.set(tag, c)
        }
        return c
    }

}
