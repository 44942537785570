<h2 mat-dialog-title>Edit selected objects</h2>
<mat-dialog-content>
    <p class="warning"> The  properties available here are limited. For full edit, use the link from object info on the main page.</p>

    <p>
        For <em>Comment</em>, the value can start with a '+' sign. Then the new
        value will be appended to the existing value instead of replacing it.
        You probably want to add a space right after the '+'-sign.
    </p>

    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>Comment</mat-label>
            <textarea matInput formControlName="comment"></textarea>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Not used as reference from</mat-label>
            <input matInput type="date" formControlName="not_reference_from" [max]="INPUT_DATE_MAX">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Object status</mat-label>
            <select matNativeControl formControlName="object_status">
                @for (s of data.objectStatuses; track s.id) {
                    <option [ngValue]="s"> {{ s.name }}</option>
                }
            </select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Aquifer</mat-label>
            <select matNativeControl formControlName="aquifer">
                @for (a of data.aquifers; track a.id) {
                    <option [ngValue]="a"> {{ a.name }}</option>
                }
            </select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Object type</mat-label>
            <select matNativeControl formControlName="object_type">
                @for (item of groupedOptions | keyvalue; track item) {
                    <optgroup [label]="item.key">
                        @for (t of item.value; track t) {
                            <option [ngValue]="t"> {{ t.name }}</option>
                        }
                    </optgroup>
                }
            </select>
        </mat-form-field>
        <mat-form-field>
            <mat-label> Correlation base</mat-label>
            <select matNativeControl formControlName="correlation_base">
                @for (t of [true, false]; track t) {
                    <option [ngValue]="t"> {{ t }}</option>
                }
            </select>
        </mat-form-field>
        <mat-form-field>
            <mat-label> Correlation reference</mat-label>
            <select matNativeControl formControlName="correlation_reference">
                @for (t of [true, false]; track t) {
                    <option [ngValue]="t"> {{ t }}</option>
                }
            </select>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button [disabled]="form.invalid" (click)="onApply()">Apply</button>
</mat-dialog-actions>
