@if (data) {
    <h1 mat-dialog-title>
        {{ data.header }}
    </h1>

    <mat-dialog-content>
        @if (data.text; as text) {
            {{ text }}
        }
        @if (data.textList; as list) {
            <ul>
                @for (item of list; track item) {
                    <li> {{ item }}</li>
                }
            </ul>
        }
        @if (data.textPre; as text) {
            <pre>{{ text }}</pre>
        }
    </mat-dialog-content>

    @if (data.negative_text || data.positive_text) {
        <mat-dialog-actions>
            @if (data.positive_text !== null) {
                <button mat-raised-button [mat-dialog-close]="true" type="button">
                    {{ data.positive_text ?? 'confirm' }}
                </button>
            }
            @if (data.negative_text !== null) {
                <button mat-raised-button [mat-dialog-close]="false" type="button">
                    {{ data.negative_text ?? 'cancel' }}
                </button>
            }
        </mat-dialog-actions>
    }
} @else {
    No data
}
