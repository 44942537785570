import {Injectable, signal} from '@angular/core'

@Injectable({
    providedIn: 'root'
})
export class HelpService {

    private readonly _openState = signal(false)

    readonly state = this._openState.asReadonly()

    constructor() {
    }

    open() {
        this._openState.set(true)
    }

    close() {
        this._openState.set(false)
    }

    toggle(){
        this._openState.set(!this._openState())
    }
}
