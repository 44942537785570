<!--<div class="vide-form-field">-->
<!--    @if (nrInputSignal(); as nr) {-->
<!--        <div>-->
<!--            Found {{ nr.file ?? 'no' }} uncompensated measurements from file-->
<!--        </div>-->
<!--        <div>-->
<!--            Found {{ nr.database ?? 'no' }} uncompensated measurements from database-->
<!--        </div>-->
<!--    }-->

<!--</div>-->

<mat-form-field [style.width]="'25rem'">
    <mat-label>
        Reference pressure
    </mat-label>
    <mat-select [formControl]="form">
        @for (item of baroCombos(); track item.object.id) {
            <mat-option [value]="item">
                {{ item.object.name }}
                {{ item.statistics.measure_type.name }}
                ({{ item.statistics.n_value }} measurements)
            </mat-option>
        }
    </mat-select>
</mat-form-field>

@if (figure(); as figure) {
    <plotly-plot
        [data]="figure.data"
        [layout]="figure.layout"
        [config]="figure.config"
        [useResizeHandler]="true"
    />
}

<div>
    Calculated {{ nrCompensated() ?? 'no' }} values
</div>

<button
    mat-raised-button
    [disabled]="!nrCompensated()||saving.value"
    (click)="save(diverObject())"
> @if (saving.value) {
    Saving...
} @else {
    Save to {{ diverObject().name }}
}
</button>

<div>
    This will overwrite existing uncompensated measurements with the new unreferenced values.
</div>

<div class="stepper-navigate">
    <button mat-button matStepperPrevious type="button">Previous</button>
    <button mat-button matStepperNext type="button">Next</button>
</div>
