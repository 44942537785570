import {FormBuilder} from '@angular/forms'
import {Injectable} from '@angular/core'
import {SelectionModel} from "@angular/cdk/collections"

import * as S from "superstruct"
import {
    combineLatest,
    combineLatestWith,
    filter,
    finalize,
    firstValueFrom,
    map,
    shareReplay,
    startWith,
    switchMap,
    tap
} from 'rxjs'

import {AbstractMapboxService} from 'src/app/mapbox/abstract-mapbox.service'
import {ExternalObject} from "../../api/api-types"
import {LogContainer} from 'src/app/log-list/log-container'
import {PLOT_COLOR,} from 'src/constants'
import {TypedHttpService} from "../typed-http.service"
import {isNotNull} from 'src/app/shared/vide-helper'
import {objectWithPosition, PlotlyScattermapboxData, VideFigure} from 'src/app/vide-types'
import {sguGetObjectName, sguGetStationId, SguGrundvattenstationer, SguObject} from './sgu-types'

const SGU_GRUNDVATTEN_ENDPOINT = ' https://resource.sgu.se/oppnadata/grundvatten/api/grundvattennivaer/stationer'

const ExternalObjectSgu = S.assign(ExternalObject, S.object({
    options: S.object({id: S.string(), updated: S.string()})
}))
type ExternalObjectSgu = S.Infer<typeof ExternalObjectSgu>

@Injectable({
    providedIn: 'root'
})
export class SguService extends AbstractMapboxService {
    private readonly ExternalType = 'sgu'
    static readonly SGU_SWEDISH_LAN = [
        {name: 'Blekinge län', code: '10'},
        {name: 'Dalarnas län', code: '20'},
        {name: 'Gotlands län', code: '09'},
        {name: 'Gävleborgs län', code: '21'},
        {name: 'Hallands län', code: '13'},
        {name: 'Jämtlands län', code: '23'},
        {name: 'Jönköpings län', code: '06'},
        {name: 'Kalmar län', code: '08'},
        {name: 'Kronobergs län', code: '07'},
        {name: 'Norrbottens län', code: '25'},
        {name: 'Skåne län', code: '12'},
        {name: 'Stockholms län', code: '01'},
        {name: 'Södermanlands län', code: '04'},
        {name: 'Uppsala län', code: '03'},
        {name: 'Värmlands län', code: '17'},
        {name: 'Västerbottens län', code: '24'},
        {name: 'Västernorrlands län', code: '22'},
        {name: 'Västmanlands län', code: '19'},
        {name: 'Västra Götalands län', code: '14'},
        {name: 'Örebro län', code: '18'},
        {name: 'Östergötlands län', code: '05'},
    ] as const
    protected override useColorbar: boolean = false
    readonly httpStatus = {
        vide: new SelectionModel<string>(true, [], true),
        extern: 0,
    }
    readonly logs = new LogContainer('Save')
    readonly form = this.formBuilder.nonNullable.group({
        lan: [null as null | typeof SguService.SGU_SWEDISH_LAN[number]],
        showInactive: [false],
    })
    private readonly grundvattenstationerForSelectedLan$ = this.form.controls.lan.valueChanges.pipe(
        filter(isNotNull),
        tap(() => {
            this.httpStatus.extern++
        }),
        switchMap(value => {
            const url = `${SGU_GRUNDVATTEN_ENDPOINT}/${value.code}`
            const params = {format: 'json'}
            return this.http.getTyped(url, SguGrundvattenstationer, {params}).pipe(
                finalize(() => {
                    this.httpStatus.extern--
                }),
            )
        }),
        tap(_x => {
            this.clearPosition()
        }),
        shareReplay({bufferSize: 1, refCount: true}),
    )
    /** The external objects for the selected parameter (Län) */
    readonly externalSelection$ = this.grundvattenstationerForSelectedLan$.pipe(
        map(stationer => stationer.features.map(s =>
            sguGetStationId(s))),
        combineLatestWith(this.dataService.projectNotNull$),
        switchMap(([stationIds, p]) =>
            this.dataService.getExternalObjects(p, 'sgu').pipe(
                map(xs => S.create(xs, S.array(ExternalObjectSgu))),
                map(xs => xs.filter(x => stationIds.includes(sguGetId(x)))),
            )),
        shareReplay(1),
    )
    readonly stationsToShow$ = combineLatest([
        this.grundvattenstationerForSelectedLan$,
        this.dataService.getConverterToWgs(3006),
        this.externalSelection$,
        this.form.controls.showInactive.valueChanges.pipe(startWith(true)),
        this.httpStatus.vide.changed.pipe(startWith(null)),
    ]).pipe(
        map(([data, converter3006, ex,]) => {
            if (data.crs.properties.name !== 'EPSG:3006') {
                console.error(`Wrong coordinate system ${data.crs.properties.name}`)
            }
            const showInactive = this.form.getRawValue().showInactive
            const filtered = showInactive
                ? data.features
                : data.features.filter(f => f.properties.slutdatum_for_matning === undefined)
            const objects = filtered.map(station => {
                let position
                let positionM
                if (station.geometry === null) {
                    position = null
                    positionM = null
                } else {
                    const [x, y] = station.geometry.coordinates
                    positionM = {x, y}
                    position = converter3006(positionM)
                }
                const external =
                    ex.find(x => x.options.id === sguGetStationId(station)) ?? null
                const tainted = this.httpStatus.vide.isSelected(sguGetStationId(station))
                const ret: SguObject = {
                    external,
                    position,
                    positionM,
                    properties: station.properties,
                    tainted,
                }
                return ret
            }).filter(isNotNull)
            objects.sort((a, b) => a.properties.stationens_namn.localeCompare(b.properties.stationens_namn))
            return objects
        }),
        shareReplay({bufferSize: 1, refCount: true}),
    )
    readonly figure$ = combineLatest([
        this.stationsToShow$,
        this.forceRelayout$,
    ]).pipe(
        map(([o]) => o),
        map(objects => objects.filter(objectWithPosition)),
        combineLatestWith(this.dataService.plotlyToImage$),
        map(([objects, toImage]) => {
            console.log(objects)
            const text = objects.map(o => o.properties.stationens_namn)
            const customdata = objects as any[]
            const color = objects.map(o =>
                o.external ? PLOT_COLOR.object_selected : PLOT_COLOR.object_unselectd
            )
            const hovertemplate = objects.map(o => {
                const p = o.properties

                const desc = []
                {
                    let key: keyof typeof p
                    for (key in p) {
                        desc.push(`${key}: ${p[key]}`)
                    }
                }

                return `<em>%{text} </em> <br>` + desc.join("<br>") + "<extra></extra>"
            })
            const data: PlotlyScattermapboxData[] = [{
                customdata,
                hovertemplate,
                lat: objects.map(o => o.position.lat),
                lon: objects.map(o => o.position.lon),
                marker: {
                    color,
                },
                text,
                type: 'scattermapbox',
            }]
            const layout = this.getLayout(objects, {background: 'open-street-map'})
            const config = this.getMapConfig(toImage.toImageOptions)
            const figure: VideFigure = {
                config,
                data,
                layout,
            }
            return figure
        }),
    )

    constructor(
        private formBuilder: FormBuilder,
        private http: TypedHttpService,
    ) {
        super()

        setTimeout(() => {
            const sthlm = SguService.SGU_SWEDISH_LAN.find(l => l.name === 'Stockholms län')
            this.form.patchValue({lan: sthlm,})
        })
    }


    async add(x: SguObject) {
        const project = await firstValueFrom(this.dataService.projectNotNull$)
        const id = sguGetStationId(x)
        const properties = {id}
        this.httpStatus.vide.select(sguGetStationId(x))
        const res = await firstValueFrom(
            this.dataService.createExternalObject(project, this.ExternalType, properties)
        ).finally(() => {
            this.httpStatus.vide.deselect(sguGetStationId(x))
        })
        this.logs.add(res, `Add ${sguGetObjectName(x)}`)
        if (res.success) {
            this.dataService.reloadProjectData()
        }
    }

    async remove(x: SguObject) {
        if (!x.external) return
        const project = await firstValueFrom(this.dataService.projectNotNull$)
        this.httpStatus.vide.select(sguGetStationId(x))
        const res = await firstValueFrom(
            this.dataService.deleteExternalSource(project, x.external)
        ).finally(() => {
            this.httpStatus.vide.deselect(sguGetStationId(x))
        })
        this.logs.add(res, `Remove ${sguGetObjectName(x)}`)
        if (res.success) {
            this.dataService.reloadProjectData()
        }
    }
}

function sguGetId(x: ExternalObjectSgu): string {
    return x.options.id
}

