import {Injectable} from '@angular/core'
import {ActivatedRoute, Router} from "@angular/router"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {SelectionModel} from "@angular/cdk/collections"

import {distinctUntilChanged, filter, map} from "rxjs"

import {arraysEqual, compareNumbers, isDefined, parseIntOrThrow} from "../shared/vide-helper"

/**
 * Service responsible for keeping the object selection synced with the url query parameter `oids`.
 *
 * The selection is always read from the url, and all updates should go through it. Either by calling the functions here,
 * or possibly by changing the url, e.g., by navigating in the browser.
 */
@Injectable({
    providedIn: 'root'
})
export class ObjectRouteSelectionService {

    private readonly model = new SelectionModel<number>(true, [], true)
    readonly changed = this.model.changed.asObservable()

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
    ) {
        route.queryParamMap.pipe(
            takeUntilDestroyed(),
            filter(isDefined),
            map(p => {
                const oidsString = p.get('oids')
                if (oidsString === null) {
                    return null
                }
                if (oidsString === '') {
                    console.debug("oids empty string, removing")
                    router.navigate([document.location.pathname],
                        {
                            queryParams: {oids: undefined,},
                            queryParamsHandling: 'merge',
                        })
                    return []
                }
                return oidsString
                    .split(',')
                    .map(parseIntOrThrow)
                    .sort(compareNumbers)
            }),
            distinctUntilChanged(arraysEqual),
        ).subscribe(oids => {
            if (oids) {
                // console.debug("setting model ", oids)
                this.model.setSelection(...oids)
            } else {
                // console.debug("clearing model")
                this.model.clear()
            }
        })

    }

    isSelected = this.model.isSelected.bind(this.model)

    get selected() {
        return this.model.selected
    }

    clear() {
        this.setRoute([])
    }

    deselect(...values: readonly number[]) {
        const oids = this.getOidSet()
        values.forEach(id => oids.delete(id))
        this.setRoute(Array.from(oids))
    }

    select(...values: readonly number[]) {
        const oids = this.getOidSet()
        values.forEach(id => oids.add(id))
        this.setRoute(Array.from(oids))
    }

    toggle(value: number) {
        const oids = this.getOidSet()
        if (!oids.delete(value)) {
            oids.add(value)
        }
        this.setRoute(Array.from(oids))
    }

    setSelection(...values: readonly number[]) {
        this.setRoute(Array.from(new Set(values)))
    }

    private getOidSet() {
        const string = this.route.snapshot.queryParamMap.get('oids')
        const values = (string && string !== '') ? string.split(',').map(parseIntOrThrow) : []
        return new Set(values)
    }

    /**
     * Update the route with the new oids.
     *
     * @param values Preferably use unique array like `Array.from(new Set(values)`
     * @private
     */
    private setRoute(values: number[]) {
        values.sort(compareNumbers)
        const updated = values.length === 0 ? undefined : values.join(',')
        // console.debug("setting url", values)
        this.router.navigate([], {
            queryParams: {oids: updated},
            queryParamsHandling: 'merge'
        }).then()
    }

}
