import {Injectable} from '@angular/core'
import {Router} from '@angular/router'

import {BehaviorSubject, filter, firstValueFrom, ReplaySubject, switchMap} from 'rxjs'

import {ApiCallService} from '../api/api-call.service'
import {getHttpErrorMessage, isNotNull} from '../shared/vide-helper'
import {SelfUser} from "../api/api-types"

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private readonly forceReload$ = new ReplaySubject<void>()
    private readonly user = new BehaviorSubject<SelfUser | null>(null)
    readonly user$ = this.user.asObservable().pipe(filter(isNotNull))

    constructor(
        private apiCall: ApiCallService,
        private router: Router,
    ) {
        this.initUser()
        this.reloadUser()
    }

    reloadUser() {
        this.forceReload$.next()
    }

    async login(credentials: {
        email: string
        password: string
        remember: boolean
    }) {
        try {
            const res = await firstValueFrom(this.apiCall.loginUser(credentials))
            console.debug("Logged in?", res.success)
            if (res.success) {
                this.reloadUser()
            }
            return res
        } catch (error) {
            const msg = getHttpErrorMessage(error)
            return {success: false as const, error: msg}
        }
    }

    async logout() {
        const x = await firstValueFrom(this.apiCall.logoutUser())
        if (x.success) {
            document.location = 'https://akvifar.se/index.html'
        }
        return x
    }

    async sendResetLink(email: string) {
        return await firstValueFrom(this.apiCall.forgotPassword(email))
    }

    async resetPassword(credentials: {
        token: string
        email: string
        password: string
        password_confirmation: string
    }) {
        return await firstValueFrom(this.apiCall.resetPassword(credentials))
    }

    async updatePassword(credentials: {
        current_password: string
        password: string
        password_confirmation: string
    }) {
        try {
            const res = await firstValueFrom(this.apiCall.updatePassword(credentials))
            console.warn("Update?", res.success)
            return res
        } catch (error) {
            console.warn(error)
            const msg = getHttpErrorMessage(error)
            return {success: false as const, error: msg}
        }
    }

    private initUser() {
        this.forceReload$.pipe(
            switchMap(() => this.apiCall.getUser()),
            filter(isNotNull),
        ).subscribe((user) => {
            this.user.next(user)
        })
    }

}

