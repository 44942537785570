import {HttpClient} from "@angular/common/http"
import {Injectable, isDevMode} from '@angular/core'

import * as S from "superstruct"
import {map, Observable} from "rxjs"

@Injectable({
    providedIn: 'root'
})
export class TypedHttpService {

    constructor(
        private readonly http: HttpClient,
    ) {
    }

    getTyped<U extends S.Struct<any, any>, T = S.Infer<U>>(url: string, struct: U, params?: {}): Observable<T> {
        return this.http.get(url, params).pipe(
            map(x => {
                if (isDevMode()) {
                    const [error, value] = S.validate(x, struct)
                    if (error === undefined) {
                        return value
                    } else {
                        console.error(`Type error for endpoint GET '${url}'`, x, error.message)
                        throw error
                    }
                } else {
                    return x
                }
            }),
        )
    }
}
