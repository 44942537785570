import {Component, computed, effect, Inject, LOCALE_ID} from '@angular/core'
import {FormBuilder, ReactiveFormsModule} from "@angular/forms"
import {MatButtonModule} from '@angular/material/button'
import {MatCardModule} from '@angular/material/card'
import {MatDialog} from "@angular/material/dialog"
import {MatSnackBar} from "@angular/material/snack-bar"
import {MatTooltipModule} from '@angular/material/tooltip'
import {RouterLink} from '@angular/router'
import {formatPercent} from "@angular/common"
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop"

import {NgSelectModule} from '@ng-select/ng-select'
import {PlotlySharedModule} from 'angular-plotly.js'
import {combineLatest, filter, first, firstValueFrom, map, of, startWith, switchMap} from "rxjs"

import {ABILITY} from "../../ability"
import {AddObjectComponent, AddObjectComponentData} from "../../view-objects/add-object/add-object.component"
import {COLOR_SEQUENCES} from "../../shared/colors"
import {ConfirmDialogComponent, ConfirmDialogData} from "../../dialogs/confirm-dialog/confirm-dialog.component"
import {DASHES, DEFAULT_TIMELINE_MARKERS, getSplitter, getTimelineTracesV2} from "../timeline-plot/timeline-functions"
import {HasUncheckedData} from "../has-unchecked-data"
import {MeasureType, MeasureUnit, VideObject} from "../../api/api-types"
import {OBJECT_INFO} from "../../../constants"
import {VideDataService} from "../../api/vide-data.service"
import {equalIds, getFillRate, getObjectFullName, isDefined, isNotNull} from "../../shared/vide-helper"
import {measurementNonAffected} from "../measurement-categories"
import {omit, VideFigure,} from "../../vide-types"
import {timeWeightAverage} from "../plot-functions"

@Component({
    selector: 'app-object-info',
    templateUrl: './object-info.component.html',
    styleUrls: ['./object-info.component.scss'],
    standalone: true,
    providers: [
        {provide: HasUncheckedData, useExisting: this},
    ],
    imports: [
        MatButtonModule,
        MatCardModule,
        MatTooltipModule,
        NgSelectModule,
        PlotlySharedModule,
        ReactiveFormsModule,
        RouterLink,
    ]
})
export class ObjectInfoComponent implements HasUncheckedData {
    protected readonly copyTooltip = 'Create new object using information from the selected object.'

    protected readonly plotlyStyle = {
        // width: '95vw',
        // 'flex-grow': '2',
        // height: '100%',
    } as const
    protected readonly OBJECT_INFO = OBJECT_INFO
    readonly form = this.formBuilder.group({
        object: [null as VideObject | null],
        measureType: [null as MeasureType | null],
    })

    protected readonly getFileUrl = this.dataService.getFileUrl
    protected readonly equalIds = equalIds

    protected readonly levelUnit = toSignal(this.dataService.utility$.pipe(
        map(u => u.measure_type.find(t => t.constant_name === 'measure_type_level')?.measure_unit)))
    protected readonly timeWeightedAverage = computed(() => {
        const m = this.measurementResponse()
        if (!m) {
            return null
        }
        const measurements = m.measurements.filter(measurementNonAffected(m.object)).map(x => {
            const value = x.resulting_value
            return {time: x.measuretime, value: value}
        }).filter(isNotNull)
        return timeWeightAverage(measurements)
    })
    protected readonly project = this.dataService.project
    protected readonly objects = this.dataService.selectedObjects
    protected readonly selectedMeasureType = toSignal(this.form.controls.measureType.valueChanges, {initialValue: null})
    protected readonly selectedObject = toSignal(this.form.controls.object.valueChanges, {initialValue: null})
    protected readonly measureTypes = computed(() =>
        this.selectedObject()?.statistics.map(s => s.measure_type) ?? [])
    protected readonly status = computed(() => this.selectedObject()?.object_status)
    protected readonly editable = computed(() => {
        const sel = this.selectedObject()
        return sel && !sel.readonly && !sel.owner
    })
    protected readonly measurementLink = computed(() => {
        const p = this.project()
        const link = p ? this.dataService.videLink('measurements') : null
        const param = {oids: this.selectedObject()?.id}
        return {link, param}
    })
    protected readonly editLink = computed(() => {
        const o = this.selectedObject()
        const link = (o) ? this.dataService.videLink('editObject', {object: o}) : null
        return {link, param: {}}
    })
    protected readonly notificationLink = computed(() => {
        const o = this.selectedObject()
        const link = (o) ? this.dataService.videLink('objectTriggers', {object: o}) : null
        return {link, param: {}}
    })
    protected readonly triggers = toSignal(combineLatest([
        this.dataService.projectNotNull$,
        this.form.controls.object.valueChanges,
    ]).pipe(
        switchMap(([p, o]) => {
            if (!o) return of([])
            return this.dataService.getTriggers(p, o)
        }),
        map(triggers => triggers.length > 0 ? triggers : null)
    ))
    protected readonly attributes = computed(() => {
        const object = this.selectedObject()
        const project = this.project()
        const measureUnit = this.selectedMeasureType()?.measure_unit
        const statistics = this.selectedStatistics()
        const coordinateQualityUnit = 'm'
        const lengthUnit = 'm'
        const heightUnit = 'm'
        const levelUnit = this.levelUnit()
        if (!object) {
            return []
        }
        const ret = [
            {name: 'Type', value: object.object_type.name},
            {name: 'Status', value: object.object_status.name + (object.readonly ? ' (Read only)' : '')},
            {name: 'Alias', value: object.alias},
            {name: 'Aquifer', value: object.aquifer?.name},
            {name: 'Position', value: object.settlement_position?.name},
            {name: 'Coordinate system', value: project?.coordinate_system.name},
            {name: 'Coordinate determination method', value: object.coordinate_determination_method?.name},
            {name: 'Coordinate precision', value: this.formatValue(object.coordinate_quality, coordinateQualityUnit)},
            {name: 'East/west', value: this.formatValue(object.positionM?.x)},
            {name: 'North/south', value: this.formatValue(object.positionM?.y)},
            {name: 'Height system', value: object.owner?.height_system ?? project?.height_system.name},
            {name: 'Level precision', value: this.formatValue(object.level_quality, heightUnit)},
            {name: 'Reference level', value: this.formatValue(object.reference_level, levelUnit)},
            {name: 'Ground level', value: this.formatValue(object.ground_level, levelUnit)},
            {name: 'Bottom level', value: this.formatValue(object.bottom_level, levelUnit)},
            {name: 'Measurable depth', value: this.formatValue(object.measurableDepth, lengthUnit)},
            {name: 'Well dimension', value: this.formatValue(object.well_dimension, lengthUnit)},
            {name: 'Filter length', value: this.formatValue(object.filter_length, lengthUnit)},
            {name: 'Filter type', value: object.filter_type?.name},
            {name: 'Pipe material', value: object.pipe_material?.name},
            {name: 'Tip type', value: object.tip_type?.name},
        ]
        if (statistics) {
            const fillRate = getFillRate(statistics.last_value, statistics)
            ret.push(
                {name: 'Measure type', value: statistics.measure_type.name},
                {name: 'Last date', value: statistics.last_date?.substring(0, 10) ?? ''},
                {name: 'Last value', value: this.formatValue(statistics.last_value, measureUnit)},
                {
                    name: 'Last fill rate',
                    value: fillRate ? formatPercent(fillRate, this.locale,) : undefined
                },
                {name: 'Number of measurements', value: statistics.n.toString()},
                {name: 'Time weighted average', value: this.formatValue(this.timeWeightedAverage(), measureUnit)},
                {name: 'Arithmetic average', value: this.formatValue(statistics.mean, measureUnit)},
            )
        }
        if (object.not_reference_from) {
            ret.push({name: 'Affected from', value: object.not_reference_from})
        }
        return ret

    })
    readonly selectedStatistics = computed(() => {
        const mtId = this.selectedMeasureType()?.id
        return this.selectedObject()?.statistics.find(s => s.measure_type.id === mtId)
    })
    protected readonly allFiles = toSignal(combineLatest([
        this.dataService.projectNotNull$,
        this.form.controls.object.valueChanges,
    ]).pipe(
        switchMap(([p, vc]) => {
            if (!vc) return of([])
            return this.dataService.getObjectFiles(p, vc)
        }),
        map(x => {
            return {
                images: x.filter(y => y.kind === 'picture'),
                documents: x.filter(y => y.kind === 'document'),
            }
        })
    ))
    protected readonly files = toSignal(this.form.controls.object.valueChanges.pipe(
        filter(isDefined),
        switchMap(o => this.dataService.projectNotNull$.pipe(
            first(),
            switchMap(p => this.dataService.getObjectFiles(p, o))
        )),
    ), {initialValue: []})
    protected readonly images = computed(() => {
        return this.files().filter(f => f.kind === 'picture')
    })
    protected readonly documents = computed(() => {
        return this.files().filter(f => f.kind === 'document')
    })
    protected readonly measurementResponse$ = combineLatest([
        this.form.valueChanges,
        this.dataService.projectNotNull$,
    ]).pipe(
        switchMap(([vc, proj]) => {
            return vc.object && vc.measureType
                ? this.dataService.getExtendedMeasurements(proj, vc.object, vc.measureType)
                : of(null)
        }),
    )
    protected readonly measurementResponse = toSignal(this.measurementResponse$, {initialValue: null})
    protected readonly measurementComments = computed(() =>
        this.measurementResponse()?.measurements
            .filter(m => m.comment !== null && m.comment !== '')
            .slice(-1 * OBJECT_INFO.nrMeasurementComments)
            .reverse()
            .map(x => {
                const date = x.measuretime.substring(0, 10) // date part
                const comment = x.comment
                return {date, comment}
            })
    )
    protected readonly figure = computed(() => {
        const x = this.measurementResponse()
        if (x === null) {
            return {config: {}, data: [], layout: {}}
        }
        const plotMargins = 40
        const split = getSplitter({
            yaxis: 'y',
            yaxisOptions: {transformKind: 'Resulting value', zeroLevelDateTime: ''},
            useMeasureTypeInLabel: false, wrapYears: false, legendMaxLength: 123456
        })(x)
        const yMin = split.valid.flat().map(x => x.value).filter(isNotNull).reduce((acc, curr) => acc < curr ? acc : curr, Infinity)
        const y = getTimelineTracesV2(
            split,
            {
                y: {markers: DEFAULT_TIMELINE_MARKERS},
                y2: {markers: DEFAULT_TIMELINE_MARKERS},
                legendBelow: false,
                wrapYears: false,
                timeaxis:'Datetime',
            }, {y: yMin, y2: Infinity}, COLOR_SEQUENCES['D3'][0], DASHES[0])
        const ret: VideFigure = {
            data: y,
            layout: {
                autosize: true,
                margin: {l: plotMargins, r: plotMargins, t: plotMargins, b: plotMargins},
                showlegend: false,
                yaxis: {title: x.measure_type.measure_unit.name},
            },
            config: {
                modeBarButtonsToRemove: ['select2d', 'lasso2d'],
            },
        }
        return ret
    })
    private readonly fractionDigits = 2

    constructor(
        @Inject(LOCALE_ID) private readonly locale: string,
        private readonly dataService: VideDataService,
        private readonly formBuilder: FormBuilder,
        private readonly dialog: MatDialog,
        private readonly snackBar: MatSnackBar
    ) {
        effect(() => {
            const mt = this.measureTypes()
            const old = this.form.getRawValue().measureType
            if (mt.find(t => t.id === old?.id) === undefined) {
                // selected type is no longer available
                this.form.patchValue({measureType: mt?.at(0)})
            }
        }, {allowSignalWrites: true})
        combineLatest([
            this.dataService.selectionModel.changed.pipe(startWith(undefined)),
            this.dataService.selectedObjects$,
        ]).pipe(takeUntilDestroyed()).subscribe(
            {
                next: ([change, objects]) => {
                    if (objects.length === 0) {
                        this.form.patchValue({object: undefined})
                        return
                    }
                    const added = change?.added.at(0)
                    const removed = change?.removed.at(0)
                    let newId: number | undefined = undefined
                    if (added) {
                        // select added
                        newId = added
                    } else if (removed === undefined || !this.form.controls.object.value || removed === this.form.controls.object.value.id) {
                        // In any of these cases:
                        // * no added, no removed, i.e., no selection change.
                        // * no selected object
                        // * selected object was removed
                        // ==> select random!
                        newId = this.dataService.selectionModel.selected.at(0)
                    } else {
                        // do nothing
                    }
                    if (newId !== undefined) {
                        const newObject = objects.find(o => o.id === newId)
                        this.form.patchValue({object: newObject})
                    }
                },
                complete: () => {
                    // console.warn("Completed!", this)
                },
            })
    }


    private formatValue(value: number | null | undefined, unit?: string | MeasureUnit) {
        if (!isDefined(value)) return null
        switch (typeof unit) {
            case "object":
                if (unit.constant_name === 'plushojd') {
                    const prefix = value >= 0 ? '+' : '−'
                    return prefix + Math.abs(value).toFixed(this.fractionDigits)
                }
                return `${value.toFixed(this.fractionDigits)} ${unit.name}`
            case "string":
                return `${value.toFixed(this.fractionDigits)} ${unit}`
            case "undefined":
                return value.toFixed(this.fractionDigits)

        }
    }

    protected readonly ABILITY = ABILITY


    copyObject(object: VideObject) {
        const project = this.project()
        if (!project) return
        const model = omit(object, 'correlation_reference', 'correlation_base')
        const ref = this.dialog.open<
            AddObjectComponent, AddObjectComponentData, AddObjectComponent['result']
        >(AddObjectComponent, {data: {model}})
        ref.afterClosed().subscribe(object => {
            console.log(object)
            if (object) {
                // const x = await firstValueFrom(
                this.dataService.createObject(project, object).subscribe(x => {
                    let message = 'Add ' + object.name
                    if (x.success) {
                        message += ' successful'
                        this.dataService.reloadProjectData()
                        this.dataService.selectionModel.select(x.data.id)
                    } else {
                        message += ' failed:' + x.error
                    }
                    this.snackBar.open(message, 'Dismiss', {duration: 3000})
                })
            }
        })
    }

    deleteObject(object: VideObject) {
        const project = this.project()
        if (!project) return
        const ref = this.dialog.open<
            ConfirmDialogComponent,
            ConfirmDialogData
        >(
            ConfirmDialogComponent,
            {
                data: {
                    header: 'Really delete object',
                    text: `This will delete ${object.name} and it's measurements permanently. This cannot be undone.`,
                    positive_text: 'Delete',
                    negative_text: 'Cancel'
                }
            })
        ref.afterClosed().subscribe(answer => {
            console.log(answer)
            if (answer !== true) {
                return
            }
            this.dataService.deleteObject(project, object).subscribe(x => {
                let message = 'Delete ' + object.name
                if (x.success) {
                    message += ' successful'
                    this.dataService.reloadProjectData()
                } else {
                    message += ' failed:' + x.error
                }
                this.snackBar.open(message, 'Dismiss', {duration: 3000})
            })
        })
    }

    deleteSharedObject(object: VideObject) {
        const project = this.project()
        if (!project) return
        const ref = this.dialog.open<
            ConfirmDialogComponent,
            ConfirmDialogData
        >(
            ConfirmDialogComponent,
            {
                data: {
                    header: 'Really delete object',
                    text: `This will delete ${object.name} from this project. It will remain in ${object.owner?.project_name}.`,
                    positive_text: 'Delete',
                    negative_text: 'Cancel'
                }
            })
        ref.afterClosed().subscribe(answer => {
            console.log(answer)
            if (answer !== true) {
                return
            }
            this.dataService.deleteSharedObject(project, object).subscribe(x => {
                let message = 'Delete share of ' + object.name
                if (x.success) {
                    message += ' successful'
                    this.dataService.reloadProjectData()
                } else {
                    message += ' failed:' + x.error
                }
                this.snackBar.open(message, 'Dismiss', {duration: 3000})
            })
        })
    }

    protected readonly getObjectFullName = getObjectFullName

    async getUncheckedData(): Promise<readonly { id: number; object_id: number }[]> {
        const util = await firstValueFrom(this.dataService.utility$)
        const status = util.data_status.find(s => s.constant_name === 'data_status_standard')
        const response = this.measurementResponse()
        if (!status || !response) {
            return []
        }
        const unchecked = response.measurements
            .filter(m => m.data_status.constant_name === 'data_status_not_checked')
        const number = unchecked.length
        if (number < 1) {
            this.snackBar.open('No unchecked measurements', 'Dismiss', {duration: 2000})
            return []
        }

        const res = unchecked.map(m => ({
            id: m.id,
            object_id: response.object.id,
        }))
        return res

    }
}
