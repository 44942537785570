/**
 * After Numerical Recipes: The Art of Scientific Computing, Third Edition, 15.2
 *
 * Calculates parameters for `y = a + bx`

 * @param x
 * @param y
 */
export function regression(x: readonly number[], y: readonly number[]) {
    const nData = x.length
    if (nData !== y.length) {
        throw Error("x and y must be the same length")
    }
    let sx = 0
    let sy = 0
    let st2 = 0
    // let chi2 = 0
    // const q = 1
    // let sigdat = 0
    let b = 0
    for (let i = 0; i < nData; i++) {
        sx += x[i]!
        sy += y[i]!
    }
    const ss = nData
    const sxoss = sx / ss
    let t
    for (let i = 0; i < nData; i++) {
        t = x[i]! - sxoss
        st2 += t * t
        b += t * y[i]!
    }
    b /= st2
    const a = (sy - sx * b) / ss
    // let siga = Math.sqrt((1.0 + sx * sx / (ss * st2)) / ss)
    // let sigb = Math.sqrt(1.0 / st2)
    // for (let i = 0; i < nData; i++) {
    //     const someNumber = y[i]! - a - b * x[i]!
    //     chi2 += someNumber * someNumber
    // }
    // if (nData > 2) sigdat = Math.sqrt(chi2 / (nData - 2))
    // siga *= sigdat
    // sigb *= sigdat
    return {
        a,
        b,
        // siga,
        // sigb,
        // chi2,
        // q,
        // sigdat
    }
}
