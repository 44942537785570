import {booleanAttribute, ChangeDetectionStrategy, Component, input} from '@angular/core'
import {MatTooltip} from "@angular/material/tooltip"
import {
    AbstractControl,
    ControlValueAccessor,
    FormBuilder,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
    ValidationErrors,
    Validator
} from "@angular/forms"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"

import {INPUT_DATE_MAX, INPUT_DATE_MIN} from "../../../constants"
import {InputComponent} from "../input/input.component"

/**
 * The value can be set to either a date (`1999-03-04`) or a date-time  (`1999-03-04 12:30`) or (`1999-03-04 12:30:00`)
 */
@Component({
    selector: 'app-datetime',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        InputComponent,
        MatTooltip,
        ReactiveFormsModule
    ],
    templateUrl: './datetime.component.html',
    styleUrl: './datetime.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: DatetimeComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: DatetimeComponent
        },
    ]
})
export class DatetimeComponent implements ControlValueAccessor, Validator {
    // Based on https://blog.angular-university.io/angular-custom-form-controls/

    protected readonly INPUT_DATE_MIN = INPUT_DATE_MIN
    protected readonly INPUT_DATE_MAX = INPUT_DATE_MAX

    protected readonly useSeconds = input(false, {transform: booleanAttribute})

    protected readonly form = this.fb.nonNullable.group({
        date: [''],
        time: [''],
    })

    /**
     * Call this if validation must be redone, maybe if some component input changes (not <input> values)
     */
    private _onValidatorChange = () => {
    }

    constructor(
        private readonly fb: FormBuilder,
    ) {
        this.form.valueChanges.pipe(takeUntilDestroyed()).subscribe({
            next: c => {
                const value = this.value
                // let value = ''
                // if (c.date) {
                //     value += c.date
                //     if (c.time) {
                //         value += ' ' + c.time
                //     }
                // }
                this._onChange(value)
            },
        })
    }

    /**
     * Regexp finding date and time value from a string like 1900-02-03 12:34:00
     * @private
     */
    private readonly dateTimeRegExp = /^(?<date>\d{4}-\d{2}-\d{2})( (?<time>\d{2}:\d{2}(:\d{2})?))?$/

    public writeValue(obj: any): void {
        // console.log("write datetim" + obj)
        if (typeof obj !== 'string') {
            console.warn('Invalid input')
            return
        }
        const match = obj.match(this.dateTimeRegExp)
        if (!match?.groups) {
            // empty or just invalid, set to ''
            this.form.setValue({date: '', time: ''}, {emitEvent: false})
            return
        }
        this.form.patchValue(match.groups, {emitEvent: false})
    }

    _onTouched: Function = () => {
    }
    _onChange: Function = () => {
    }

    registerOnChange(fn: any): void {
        this._onChange = fn
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn
    }

    registerOnValidatorChange(fn: () => void): void {
        this._onValidatorChange = fn
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.form.disable()
        } else {
            this.form.enable()
        }
    }

    get value() {
        const values = this.form.getRawValue()
        let ret = ''
        if (values.date) {
            ret = values.date
            if (values.time) {
                ret += ' ' + values.time
            }
        }
        return ret
    }

    validate(control: AbstractControl): ValidationErrors | null {
        const values = this.form.getRawValue()
        if ((!values.date || values.date === '') && values.time !== '') {
            return {onlyTime: true}
        }
        return null
        // // We don't  have anything to validate really, but anyway:
        // if (this.form.valid) {
        //     return null
        // }
        //
        // let errors: any = {}
        //
        // errors = this.addControlErrors(errors, "time")
        // errors = this.addControlErrors(errors, "date")
        //
        // return errors
    }

    // addControlErrors(allErrors: any, controlName: keyof typeof this.form.controls) {
    //
    //     const errors = {...allErrors}
    //
    //     const controlErrors = this.form.controls[controlName].errors
    //
    //     if (controlErrors) {
    //         errors[controlName] = controlErrors
    //     }
    //
    //     return errors
    // }

}
