import {DataCorrelationStatus, Measurement, MeasurementWithValue, VideObject} from "../api/api-types"

/**
 * Normal measurement: The data is valid. If there is no error, there must be a
 * resulting_value.
 */
export function measurementNormal(x: Measurement) {
    return x.data_status.constant_name !== 'data_status_invalid'
        && (x.resulting_value !== null || x.error_code !== null)
}

/**
 * Numeric measurement: The data is not invalid, and `x.resulting_value` represents a
 * valid measurement value.
 */
export function measurementNumeric(x: Measurement): x is MeasurementWithValue {
    return x.error_code === null && measurementNormal(x)
    // return x.resulting_value !== null
    //     && x.data_status.constant_name !== 'data_status_invalid'
    //     && x.error_code === null
}

/**
 * Measurement with measured value: The data is not invalid, and `x.measured_value` represents a
 * valid measurement value.
 */
export function measurementWithMeasuredValue(x: Measurement): x is { measured_value: number } & Measurement {
    return x.measured_value !== null && x.data_status.constant_name !== 'data_status_invalid'
}

/**
 * Checked measurement: The data is checked, and if there is no error, there
 * must be a resulting_value.
 */
export function measurementChecked(x: Measurement) {
    // return x.data_status.constant_name !== 'data_status_not_checked' && measurementNormal(x)
    return (x.data_status.constant_name === 'data_status_standard'
            || x.data_status.constant_name === 'data_status_affected')
        && (x.resulting_value !== null || x.error_code !== null)
}

/**
 * Checked numeric measurement: The data is checked, and has a valid measurement value.
 */
export function measurementCheckedNumeric(x: Measurement): x is MeasurementWithValue {
    return x.error_code === null && measurementChecked(x)
    // return (x.data_status.constant_name === 'data_status_standard'
    //         || x.data_status.constant_name !== 'data_status_affected')
    //     && x.error_code === null && x.resulting_value !== null
}

/**
 * Non-affected measurement: The data is normal numeric, and it is not confirmed as affected.
 * Also, the correlation status is not set as affected.
 */
export function measurementNonAffected(object: VideObject) {
    return (x: Measurement): x is MeasurementWithValue => {
        return x.data_status.constant_name === 'data_status_standard'
            && (x.resulting_value !== null || x.error_code !== null)
            && (!object.not_reference_from || x.measuretime < object.not_reference_from)
            && x.data_correlation_status?.constant_name !== 'affected_up'
            && x.data_correlation_status?.constant_name !== 'affected_down'
    }
}

/**
 * Non-affected numeric measurement: The data is valid numeric, and it is not confirmed as affected.
 */
export function measurementNonAffectedNumeric(object: VideObject) {
    return (x: Measurement): x is MeasurementWithValue => {
        return x.error_code === null && measurementNonAffected(object)(x)
        // return x.data_status.constant_name === 'data_status_standard'
        //     && x.resulting_value !== null
        //     && x.error_code === null
        //     && (!object.not_reference_from || x.measuretime < object.not_reference_from)
        //     && x.data_correlation_status?.constant_name !== 'affected_up'
        //     && x.data_correlation_status?.constant_name !== 'affected_down'
    }
}

/**
 * Non-standard measurement: The data is normal, with status affected or non-checked
 */
export function measurementNonStandard(x: Measurement) {
    return (x.data_status.constant_name === 'data_status_affected'
            || x.data_status.constant_name === 'data_status_not_checked')
        && measurementNormal(x)
    // return (x.resulting_value !== null
    //         || x.error_code !== null)
    //     && (x.data_status.constant_name === 'data_status_affected'
    //         || x.data_status.constant_name === 'data_status_not_checked')
}

/**
 * Error measurement: The data is normal, with error code
 */
export function measurementError(x: Measurement) {
    return x.error_code !== null
        && measurementNormal(x)
    // return x.error_code !== null
    //     && x.data_status.constant_name !== 'data_status_invalid'
}


/**
 * @param x
 */
export function measurementNotChecked(x: Measurement): x is MeasurementWithValue {
    return x.data_status.constant_name === 'data_status_not_checked'
        && measurementNormal(x)
    // return (x.resulting_value !== null || x.error_code !== null)
    //     && x.data_status.constant_name === 'data_status_not_checked'
}

/**
 * Skall correlatsion status användas här? @see https://akvifar.atlassian.net/browse/VA-417
 *
 */
export function measurementAffected(object: VideObject) {
    return (x: Measurement): x is MeasurementWithValue => {
        // If we use correlation status:
        // return !measurementNonAffected(object)(x)
        // else
        return (x.resulting_value !== null || x.error_code !== null)
            && (x.data_status.constant_name === 'data_status_affected'
                || (object.not_reference_from !== null && x.measuretime >= object.not_reference_from))
    }
}

/**
 * Correlation status is not null (e.g., affected).
 */
export function measurementWithCorrelationStatus<T>(x: T & { data_correlation_status: DataCorrelationStatus | null }):
    x is T & { data_correlation_status: DataCorrelationStatus } {
    return x.data_correlation_status !== null
}
