<mat-card>
    <mat-card-header>
        <mat-card-title>User admin</mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <table mat-table matSort [dataSource]="dataSource">
            <app-sorted-text-column name="name"/>
            <app-sorted-text-column name="comment"/>
            <app-sorted-text-column name="email"/>
            <app-sorted-text-column name="ability"/>

            <ng-container matColumnDef="license">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>License</th>
                <td mat-cell *matCellDef="let user">
                    {{user.license.name}}
                </td>
            </ng-container>

            <!--  Buttons  -->
            <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let user">
                    <button mat-icon-button (click)="edit(user)" aria-label="edit" matTooltip="Edit {{user.email}}">
                        <mat-icon> edit</mat-icon>
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let user">
                    <button mat-icon-button aria-label="delete" (click)="delete(user)"
                            matTooltip="Delete {{user.email}}">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
        </table>
    </mat-card-content>

    <mat-card-actions>
        <button mat-fab aria-label="add user" (click)="addUser()" matTooltip="Add user">
            <mat-icon>add</mat-icon>
        </button>
    </mat-card-actions>

    <mat-card-footer>
        <app-log-list [container]="logs"/>
    </mat-card-footer>

</mat-card>
