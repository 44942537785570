import {AfterViewInit, Component, computed, DestroyRef, viewChild} from '@angular/core'
import {MatPaginator, PageEvent} from '@angular/material/paginator'
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop"
import {MatSnackBar} from "@angular/material/snack-bar"
import {MatActionList} from "@angular/material/list"
import {MatTooltip} from "@angular/material/tooltip"
import {MatExpansionPanelActionRow} from "@angular/material/expansion"
import {MatIcon} from "@angular/material/icon"

import {Subject} from 'rxjs'
import {startWith} from 'rxjs/operators'

import {ObjectSelectionDataService} from '../object-selection-data.service'
import {VideObject} from "../../api/api-types"
import {equalIds, isNotNull} from "../../shared/vide-helper"

const LIST_PAGE_SIZE_OPTIONS = [100, 500] as const

/**
 * Displays the objects in a simple list.
 */
@Component({
    selector: 'app-object-list',
    standalone: true,
    templateUrl: './object-list.component.html',
    styleUrls: ['./object-list.component.scss'],
    imports: [
        MatActionList,
        MatTooltip,
        MatExpansionPanelActionRow,
        MatIcon,
        MatPaginator
    ]
})
export class ObjectListComponent implements AfterViewInit {
    readonly selectionModel = this.dataService.selectionModel
    readonly copyTooltip: string = "Copy object names to clipboard"

    private readonly page$ = new Subject<PageEvent>()
    private readonly page = toSignal(this.page$)
    private readonly paginator = viewChild.required(MatPaginator)
    private readonly objects = toSignal(this.dataService.objectsToDisplay$, {initialValue: []})
    
    readonly pageSize = computed(() => {
        // add total number of objects as pageSize option (i.e. show all)
        const os = this.objects()
        const length = os.length
        const max = Math.max(...LIST_PAGE_SIZE_OPTIONS)
        const options = (length > max) ? [...LIST_PAGE_SIZE_OPTIONS, length] as const : LIST_PAGE_SIZE_OPTIONS
        return {options, length} as const
    })
    readonly pagedObjects = computed(() => {
        const p = this.page()
        const os = this.objects()
        if (p && os) {
            const start = p.pageIndex
            const size = p.pageSize
            return os.slice(start * size, (start + 1) * size)
        }
        return []
    })

    constructor(
        private dataService: ObjectSelectionDataService,
        private destroyRef: DestroyRef,
        private snack: MatSnackBar,
    ) {
    }

    ngAfterViewInit(): void {
        this.paginator().page.pipe(
            startWith(this.paginator()),
            takeUntilDestroyed(this.destroyRef),
        ).subscribe(this.page$)
    }

    objectInfo(o: VideObject) {
        return [
            o.name,
            o.owner ? `[${o.owner.project_name}]` : null,
            o.comment,
            o.statistics.map(s => s.measure_type.name).join(', '),
        ].filter(isNotNull).join("\n")
    }

    copyObjectNames() {
        const names = this.objects().map(o => o.name).join("\n")
        navigator.clipboard.writeText(names).then(() => {
            this.snack.open("Names copied to clipboard", "", {duration: 2000})
        }).catch(console.error)
    }

    protected readonly equalIds = equalIds
}
