<h2 mat-dialog-title> Apply changes to {{data.number}} selected measurements from objects {{data.names}}  </h2>

<mat-dialog-content>
    <p>
        For <em>Measured value</em> and <em>Resulting value</em>, a value can start with a +/-/* sign. Then the new
        value will be added to/subtracted from/multiplied with the current value instead of replacing it. <em>Only one
        of the values can be changed! </em> Changes to Measured value will trigger a recalculation of the Resulting
        value. </p>
    <p>
        For <em>Comment</em>, the value can start with a '+' sign. Then the new value will be appended to the existing
        value instead of replacing it. You probably want to add a space right after the '+'-sign. To remove the
        comments, set it to blank space. </p>

    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>Measured value</mat-label>
            <input matInput type="text" formControlName="measured_value">
            <mat-error>Invalid number</mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Resulting value</mat-label>
            <input matInput type="text" formControlName="resulting_value">
            <mat-error>Invalid number</mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Comment</mat-label>
            <input matInput type="text" formControlName="comment">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Data status</mat-label>
            <select matNativeControl formControlName="data_status">
                @for (s of dataStatuses(); track s) {
                    <option [ngValue]="s"> {{ s.name }}</option>
                }
            </select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Error code</mat-label>
            <select matNativeControl formControlName="error_code">
                @for (e of errorCodes(); track e) {
                    <option [ngValue]="e"> {{ e.name }}</option>
                }
                <option [ngValue]="MAGIC_STRING"> Delete error code</option>
            </select>
        </mat-form-field>

        @if (form.errors?.['multipleValues']) {
            <mat-error>Cannot set both numbers</mat-error>
        }
    </form>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button mat-raised-button [disabled]="form.invalid" (click)="apply()">Apply</button>
</mat-dialog-actions>

<!--<div class="debug">-->
<!--valid:    {{form.valid | json}} <br>-->
<!--errors:    {{form.errors | json}} <br>-->
<!--value:    {{form.value|json}} <br>-->
<!--</div>-->
