import { ActivatedRoute, Router } from "@angular/router"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {DestroyRef} from "@angular/core"

type Constructor<T = {}> = new (...args: any[]) => T

// A rather complicated way to use mixins. Easier to achieve the same result with mat-navigate tabs
export function mixinNavigateTabs<T extends Constructor<{ router: Router, route: ActivatedRoute, readonly  destroyRef:DestroyRef}>>(
    base: T,
    name: string,
    defaultTab = 0,
) {
    return class extends base {
        get selectedIndex() {
            return this._selectedIndex
        }

        private _selectedIndex = defaultTab
        readonly _parameterName = name

        constructor(...args: any[]) {
            super(...args)
            this.route.queryParamMap.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(map => {
                const param = map.get(this._parameterName)
                // console.error(`${this._parameterName} route changed to ${param}, selectedIndex
                // ${this._selectedIndex}`)
                const a = parseInt(param ?? '')
                this._selectedIndex = isFinite(a) ? a : defaultTab
            })
        }

        selectedIndexChange(index: number) {
            // console.error(`${this._parameterName} tab changed to ${index}, selectedIndex ${this._selectedIndex}`)
            this.setRoute(index)
        }

        private setRoute(index: number) {
            // console.warn(`${this._parameterName} request tab ${index}`)
            if (index === this._selectedIndex) {
                // console.error(`${this._parameterName} Request for current tab, no action`)
            } else {
                // console.warn(`${this._parameterName} Request for new tab, moving`)
                this.router.navigate(
                    [],
                    { queryParams: { [this._parameterName]: index }, queryParamsHandling: 'merge' },
                )
            }
        }
    }
}
