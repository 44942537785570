import {Component, ElementRef, input, viewChild} from '@angular/core'
import {MatButtonModule} from "@angular/material/button"
import {MatExpansionModule} from "@angular/material/expansion"
import {MatSelectModule} from "@angular/material/select"
import {MatStepperModule, MatStepperNext} from "@angular/material/stepper"
import {ReactiveFormsModule} from "@angular/forms"
import {outputFromObservable, takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop"

import {PlotlyViaWindowModule} from "angular-plotly.js"

import {DIVER_DATA_TYPES} from "../diver.service"
import {ImportDiverService} from "./import-diver.service"
import {InputComponent} from "../../../forms/input/input.component"
import {LogListComponent} from "../../../log-list/log-list.component"
import {SlimObject} from "../diver.component"
import {VideObject} from "../../../api/api-types"

@Component({
    selector: 'app-import-diver',
    standalone: true,
    imports: [
        InputComponent,
        LogListComponent,

        MatButtonModule,
        MatExpansionModule,
        MatSelectModule,
        MatStepperModule,
        PlotlyViaWindowModule,
        ReactiveFormsModule,
    ],
    templateUrl: './import-diver.component.html',
    styleUrl: './import-diver.component.scss'
})
export class ImportDiverComponent {
    protected readonly DIVER_DATA_TYPES = DIVER_DATA_TYPES
    protected readonly nextButton = viewChild.required(MatStepperNext, {read: ElementRef})
    protected readonly annotations = toSignal(this.service.diverAnnotations$)
    protected readonly data = outputFromObservable(this.service.diverClippedFileData$)
    protected readonly figure = toSignal(this.service.diverFigure$)
    protected readonly fileError = toSignal(this.service.fileError)
    protected readonly form = this.service.form
    protected readonly nrMeasurements = toSignal(this.service.diverNrMeasurements$)
    protected readonly rawFileData = toSignal(this.service.rawDiverFileData$)
    protected readonly saving = this.service.saving
    readonly diverObject = input.required<SlimObject>()

    constructor(
        private readonly service: ImportDiverService,
    ) {
        this.service.rawDiverFileData$.pipe(takeUntilDestroyed()).subscribe(data => {
            const type = data?.dataType
            if (type) {
                this.form.patchValue({code: type})
            }
        })
    }

    readonly plotlyClick = this.service.diverFigureClick.bind(this.service)
    readonly doubleClick = this.service.diverDoubleClick.bind(this.service)

    // readonly save = this.service.save.bind(this.service)

    async save(object: Pick<VideObject, 'id' | 'name'>) {
        const res = await this.service.save(object)
        if (res) {
            const el = this.nextButton().nativeElement
            if (el instanceof HTMLButtonElement) {
                el.click()
            }
        }
    }

}
