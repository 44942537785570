import {Component, Inject} from '@angular/core'
import {
    MAT_DIALOG_DATA,
    MatDialogActions,
    MatDialogClose,
    MatDialogConfig,
    MatDialogContent,
    MatDialogRef,
    MatDialogTitle
} from "@angular/material/dialog"
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms"
import {MatButton, MatFabButton} from "@angular/material/button"
import {InputComponent} from "../../forms/input/input.component"
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field"
import {MatInput} from "@angular/material/input"
import {MatCheckbox} from "@angular/material/checkbox"
import {equalIds, getErrorMessage} from "../../shared/vide-helper"
import {KnownUser, Trigger, TriggerTypeValues} from "../../api/api-types"
import {MatIcon} from "@angular/material/icon"
import {JsonPipe} from "@angular/common"
import {VideDataService} from "../../api/vide-data.service"
import {toSignal} from "@angular/core/rxjs-interop"
import {MatOption, MatSelect} from "@angular/material/select"

export type EditTriggerData = MatDialogConfig<Omit<Trigger, 'id'>>['data']
// export type EditTriggerData = MatDialogConfig<{
//     description: string,
//     comment: string,
//     limit: number,
//     type: string,
//     active: boolean,
//     on_unchecked: boolean,
// }>
export type EditTriggerResult = Pick<Trigger,
    | 'active'
    | 'comment'
    | 'description'
    | 'limit'
    | 'type'
    | 'unchecked'
    | 'recipients'
>

@Component({
    selector: 'app-edit-trigger',
    standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        MatDialogActions,
        MatButton,
        MatDialogClose,
        InputComponent,
        ReactiveFormsModule,
        MatFormField,
        MatInput,
        MatLabel,
        MatCheckbox,
        MatError,
        MatFabButton,
        MatIcon,
        JsonPipe,
        MatSelect,
        MatOption,
    ],
    templateUrl: './edit-trigger.component.html',
    styleUrl: './edit-trigger.component.scss'
})
export class EditTriggerComponent {
    public result: EditTriggerResult | undefined

    protected readonly knownUsers = toSignal(this.dataService.knownUsers$, {initialValue: []})
    protected readonly form = this.fb.nonNullable.group({
        description: ['', Validators.required],
        comment: ['' as string | null],
        limit: [0],
        type: ['min' as Trigger['type']],
        active: [true],
        unchecked: [false],
        recipients: [[] as KnownUser[]],
    })

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: EditTriggerData,
        private readonly fb: FormBuilder,
        private readonly dataService: VideDataService,
        private readonly ref: MatDialogRef<EditTriggerComponent, EditTriggerComponent['result']>
    ) {
        // console.warn(this.data)
        if (this.data) {
            this.form.patchValue(this.data)
        } else {
            const thisUser = this.dataService.user()
            if (thisUser) this.form.patchValue({recipients: [thisUser]})
        }
    }

    save() {
        if (this.form.invalid || this.form.pristine) return
        this.ref.close(this.form.getRawValue())
    }

    protected readonly getErrorMessage = getErrorMessage
    protected readonly TriggerTypeValues = TriggerTypeValues

    // addRecipient() {
    //     this.dialog.open<>().afterClosed().subscribe(answer => {
    //     })
    //
    // }
    protected readonly equalIds = equalIds
}
